import React from 'react';
import store from "store";
import config from '../../../config';
import classNames from 'classnames';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

class Logo extends React.Component {
    state = {
        isLoggedIn: store.get(config.user.token),
        exploreMode: store.get(config.user.exploreMode),
        isSecondWave: false,
        token: null
    };
    componentDidMount() {
        if(window.location.href.indexOf('gratuit') !== -1) {
            this.setState({isSecondWave: true})
        }

    }

    render() {

        const {isSecondWave} = this.state

        var logoClass = classNames({
            logo: true,
            summerTime: true,
            noAuth: !this.props.isLoggedIn
        });

        return (
            <LinkContainer to={localStorage.getItem('token') === null ? '#' : isSecondWave ? `${config.path.index2}/${localStorage.getItem('token')}` : `${config.path.index}/${localStorage.getItem('token')}`} className={logoClass}>
                <Nav.Link eventKey="">IQOS Club</Nav.Link>
            </LinkContainer>
        );
    }
}

export default Logo;
