import './styles/App.scss';
import React, {Component} from 'react';
import Router from './components/Routes/router';
import {ConnectedRouter} from 'connected-react-router';
import {history} from './stores/createStore';
import {Provider} from 'react-redux';
import createStore from './stores';
import config from "./config";
import moment from 'moment';
import store from 'store';
import AgeGate from "./containers/AgeGate/AgeGate";
import CookieDisclaimer from "./components/CookieDisclaimer";

const storeProvider = createStore(history);

export default class App extends Component {


    render() {
        const isAdult = store.get(config.user.isAdult);

        return (
            <Provider store={storeProvider}>
                <ConnectedRouter history={history}>
                    <div className={'App'}>
                        <Router/>
                    </div>
                    {!isAdult && <AgeGate />}
                    <CookieDisclaimer />
                </ConnectedRouter>
            </Provider>
        );
    }
}
