import { createReducer } from 'reduxsauce';
import { ReferralTypes } from './Actions';

const INITIAL_STATE = {
	user: null,
	referralModal: false,
	referralCardLoading: false,
	loading: false,
	errors: null,
	success: false,
	cardData: {
		image: {},
		user: {}
	}
};

export const referralLoading = (state) => ({
	...state,
	user: null,
	loading: true,
	errors: null
});

export const referralSuccess = (state, { user }) => ({
	...state,
	user: user,
	loading: false,
	errors: null
});

export const referralFail = (state, { formErrors }) => ({
	...state,
	user: null,
	loading: false,
	formErrors: formErrors
});

export const referralModal = (state, {formData}) => ({
	...state,
	showModal: !state.showModal,
	formData: formData
});

export const toggleModal = (state) => ({
	...state,
	showModal: !state.showModal
});


// Get referrals
export const referralTree = (state) => ({
	...state,
	loadingTree: true,
});

export const referralTreeSuccess = (state, {data}) => ({
	...state,
	loadingTree: false,
	data: data
});

export const referralTreeFail = (state, {data}) => ({
	...state,
	loadingTree: false,
	data: data
});


//Get referral cards
export const referralCards = (state) => ({
	...state,
	loading: true
});

export const referralCardsSuccess = (state, {data}) => ({
	...state,
	loading: false,
	cardsData: data
});

export const referralCardsFail = (state, {data}) => ({
	...state,
	loading: false,
	cardError: data
});

// Submit cards
export const referralSubmitCards = (state) => ({
	...state,
	loading: true,
	success: false,
    errors: null
});

export const referralSubmitCardsSuccess = (state, {data}) => ({
	...state,
    loading: false,
    success: true,
	errors: null,
	token: data.token
});

export const referralSubmitCardsFail = (state, {errors}) => ({
	...state,
    loading: false,
    success: false,
    errors: errors
});

//Get selected card
export const referralCard = (state) => ({
	...state,
	loading: true
});

export const referralCardSuccess = (state, {data}) => ({
	...state,
	loading: false,
	cardData: data
});


export const referralCardFail = (state, {data}) => ({
	...state,
	loading: false,
	cardError: data
});


export const reducer = createReducer(INITIAL_STATE, {
	[ReferralTypes.REFERRAL_LOADING]: referralLoading,
	[ReferralTypes.REFERRAL_SUCCESS]: referralSuccess,
	[ReferralTypes.REFERRAL_MODAL]: referralModal,
	[ReferralTypes.REFERRAL_FAIL]: referralFail,
	[ReferralTypes.TOGGLE_MODAL]: toggleModal,


	[ReferralTypes.REFERRAL_TREE]: referralTree,
	[ReferralTypes.REFERRAL_TREE_SUCCESS]: referralTreeSuccess,
	[ReferralTypes.REFERRAL_TREE_FAIL]: referralTreeFail,

	[ReferralTypes.REFERRAL_CARDS_SUCCESS]: referralCardsSuccess,
	[ReferralTypes.REFERRAL_CARDS]: referralCards,
	[ReferralTypes.REFERRAL_CARDS_FAIL]: referralCardsFail,

	[ReferralTypes.REFERRAL_SUBMIT_CARDS_SUCCESS]: referralSubmitCardsSuccess,
	[ReferralTypes.REFERRAL_SUBMIT_CARDS]: referralSubmitCards,
	[ReferralTypes.REFERRAL_SUBMIT_CARDS_FAIL]: referralSubmitCardsFail,

	[ReferralTypes.REFERRAL_CARD_SUCCESS]: referralCardSuccess,
	[ReferralTypes.REFERRAL_CARD]: referralCard,
	[ReferralTypes.REFERRAL_CARD_FAIL]: referralCardFail
});
