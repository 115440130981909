import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
	sections: null,
	sectionsLoading: null,
	sectionsSuccess: ['sections'],
	sectionsFail: ['errors']
});

export const SectionsTypes = Types;
export default Creators;
