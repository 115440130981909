import {call, put, takeLatest} from 'redux-saga/effects';
import UserActions, {UserTypes} from './Actions';
import axios, {setAxiosToken} from '../../utils/axios';
import store from 'store';
import config from '../../config';
import moment from 'moment';
import {push, go} from 'connected-react-router';
import DateFormatter from '../../utils/dateFormatter';


const transformResponse = (data) => {
    if (data.telcor_profile.success) {
        data.telcor_profile.response.content.created_at = data.telcor_profile.response.content.createdAt ? DateFormatter.formatDateWithRomanianMonth(data.telcor_profile.response.content.createdAt) : '-';
        data.telcor_profile.response.content.activeDays = data.telcor_profile.response.content.createdAt ? ((new moment).diff((new moment(data.telcor_profile.response.content.createdAt)), 'days')) : '-';
        data.telcor_profile.response.content.expiringPointsDisplay = data.telcor_profile.response.content.expiringInfo.points.map((pointInfo) => {
            return {value: pointInfo.value, date: DateFormatter.formatDateWithRomanianMonth(pointInfo.date)}
        });
    }
    data.dcs_profile.birthday = data.dcs_profile.birthday ? DateFormatter.formatDateWithRomanianMonth(data.dcs_profile.birthday) : '-';
    return data;
};

export function* currentUser(params) {
	yield put(UserActions.currentUserLoading());

    try {
    const response = yield call(() => axios.get('/me', params.params));
    yield store.set(config.user.data, response.data.user);
    yield put(UserActions.currentUserSuccess(transformResponse(response.data)));
    } catch (error) {
        yield put(UserActions.currentUserFail(error.response.data));
    }
}


export default function* referralSaga() {
	yield takeLatest(UserTypes.CURRENT_USER, currentUser);
}
