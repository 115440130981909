import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
	images: null,
	imagesLoading: null,
	imagesSuccess: ['images'],
	imagesFail: ['errors'],
	uploadImage: ['image'],
	uploadLoading: null,
	uploadSuccess: null,
	resetUploadSuccess: null,
	uploadFail: ['errors']
});

export const ImageChallengeTypes = Types;
export default Creators;
