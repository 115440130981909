import React from 'react';
import store from 'store';
import classnames from 'classnames';
import { connect } from 'react-redux';
import MarkersActions from '../../../stores/Map/Actions';

// Assets
import Config from '../../../config';
import Styles from './style.module.scss';
import SearchIcon from '../../../assets/images/icons/search-icon.svg';

// Components
import GoogleMap from './Map';
import MarkerCard from './MarkerCard';
import Layout from '../../../components/Layout';
import Loader from '../../../components/Loader';
import VirtualList from 'react-tiny-virtual-list';

// Offline Redepmtion is filtered by a different category, always 0
const OFFLINE_REDEMPTION_ID = "0";

class MapPage extends React.Component {
    state = {
        isPWA: store.get(Config.user.isPWA),
        searchTerm: '',
        checkedFilters: new Map(),
        markers: [],
        showList: false,
        showFilters: false
    };

    componentDidMount() {
        this.props.getMarkers();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.markersLoading !== this.props.markersLoading) {
            this.setState(
                {
                    markers: this.props.markers
                },
                () => {
                    navigator.geolocation.getCurrentPosition((position) => {
                        const { latitude, longitude } = position.coords;
                        this.props.setCenter(latitude, longitude, 16);
                        this.props.setLocation(latitude, longitude);
                    });
                }
            );
        }
    }

    onSearch = (e) => {
        this.setState(
            {
                searchTerm: e.target.value
            },
            () => this.filterData()
        );
    };

    onFilter = (e) => {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(
            (prevState) => ({ checkedFilters: prevState.checkedFilters.set(item, isChecked) }),
            () => this.filterData()
        );
    };

    filterData = () => {
        const { searchTerm, checkedFilters } = this.state;
        const { markers } = this.props;
        const checkedFiltersIDs = [...checkedFilters].filter(([key, value]) => value === true).map(([k, v]) => k);

        let filteredMarkers = markers;

        if (searchTerm) {
            filteredMarkers = filteredMarkers.filter((marker) => {
                const searchText = `${marker.name} ${marker.address} ${marker.city} ${marker.zip_code}`.toLowerCase();
                return searchText.includes(searchTerm);
            });
        }

        if (checkedFiltersIDs.length) {
            if (checkedFiltersIDs.includes(OFFLINE_REDEMPTION_ID)) {
                const localCheckedFilters = checkedFiltersIDs.filter((id) => id !== OFFLINE_REDEMPTION_ID);
                if (localCheckedFilters.length === 0) {
                    filteredMarkers = filteredMarkers.filter((marker) => marker.offline_redemption === 1);
                } else {
                    filteredMarkers = filteredMarkers.filter((marker) => marker.offline_redemption === 1).filter((marker) => localCheckedFilters.includes(marker.category_id.toString()));
                }
            } else {
                filteredMarkers = filteredMarkers.filter((marker) => checkedFiltersIDs.includes(marker.category_id.toString()));
            }
        }

        this.setState({
            markers: filteredMarkers
        });
    };

    onMarkerPress = (marker) => {
        this.props.selectMarker(marker.id);
        this.props.setCenter(marker.latitude, marker.longitude, 16);
        this.setState({
            showList: false
        });
    };

    goToSelfLocation = () => {
        const { selfLocation } = this.props;
        this.props.selectMarker('SELF_MARKER');
        this.props.setCenter(selfLocation.lat, selfLocation.lng, 16);
    };

    render() {
        const { isPWA, searchTerm, markers, checkedFilters, showList, showFilters } = this.state;
        const { markersLoading, categories, selfLocation } = this.props;

        return (
            <Layout>
                <div className={classnames('page_heading mapPage', isPWA ? 'isPWA' : '')}>
                    <h1>Găsește IQOS</h1>
                    <p>Harta locațiilor unde găsești IQOS și HEETS &trade;</p>
                </div>
                <div className={Styles.mapContainer}>
                    {markersLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <GoogleMap data={markers} />
                            <div className={Styles.searchContainer} style={{ display: showList ? 'flex' : '' }}>
                                <img className={Styles.searchIcon} src={SearchIcon} />
                                <input type="text" placeholder="Caută" value={searchTerm} onChange={this.onSearch} />
                            </div>
                            <div className={Styles.markersContainer} style={{ display: showList ? 'flex' : '' }}>
                                <VirtualList height={900} itemCount={(markers && markers.length) || 0} itemSize={160} renderItem={({ index, style }) => <MarkerCard key={index} marker={markers[index]} index={index} onClick={() => this.onMarkerPress(markers[index])} style={style} />} />
                            </div>
                            <div className={Styles.filtersContainer} style={{ display: showFilters ? 'flex' : '' }}>
                                {categories &&
                                categories.map((category) => (
                                    <label className={Styles.filter}>
                                        <input type="checkbox" checked={checkedFilters.get(category.id)} value={category.id} name={category.id} onChange={this.onFilter} />
                                        <span>{category.name}</span>
                                    </label>
                                ))}
                            </div>
                            {!showFilters && !showList ? (
                                <div className={Styles.toggleContainer}>
                                    <button onClick={() => this.setState({ showFilters: true })}>
                                        <i className="fa fa-filter" />
                                        <span>Filtre</span>
                                    </button>
                                    <button onClick={() => this.setState({ showList: true })}>
                                        <i className="fa fa-map-marker" />
                                        <span>Vizualizare listă</span>
                                    </button>
                                </div>
                            ) : (
                                <div className={Styles.toggleContainer}>
                                    <button onClick={() => this.setState({ showFilters: false, showList: false })}>
                                        <span>Înapoi la hartă</span>
                                    </button>
                                </div>
                            )}
                            {selfLocation && (
                                <div className={Styles.goToLocation} onClick={this.goToSelfLocation}>
                                    <i className="fa fa-compass" />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({
    markers: state.map.data.markers,
    categories: state.map.data.categories,
    markersLoading: state.map.loading,
    selectedMarker: state.map.selectedMarker,
    selfLocation: state.map.selfLocation
});

const mapDispatchToProps = {
    getMarkers: () => MarkersActions.getMarkers(),
    setCenter: (latitude, longitude, zoomLevel) => MarkersActions.setCenter(latitude, longitude, zoomLevel),
    setLocation: (latitude, longitude) => MarkersActions.setLocation(latitude, longitude),
    selectMarker: (id) => MarkersActions.selectMarker(id)
};

export default connect(mapStateToProps, mapDispatchToProps)(MapPage);
