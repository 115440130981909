import { call, put, takeLatest } from 'redux-saga/effects';
import InsertCodesActions, { InsertCodesTypes } from './Actions';
import axios from '../../utils/axios';
import store from 'store';

export function* insertCodes({ codes }) {
    yield put(InsertCodesActions.insertCodesLoading());
    try {
        const response = yield call(() => axios.post('/mass-store-codes', { codes }));
        yield put(InsertCodesActions.insertCodesSuccess(response.data));
    } catch (error) {

    }
}

export function* getCount() {
    yield put(InsertCodesActions.getCountLoading());

    try {
        const response = yield call(() => axios.get('/dates-count', {}));
        yield put(InsertCodesActions.getCountSuccess(response.data));
    } catch (error) {
        yield put(InsertCodesActions.getCountSuccess({insertedToday: 0, insertedThisMonth: 0}));
    }
}

export function* getMassCodes({ codes }) {
    yield put(InsertCodesActions.insertCodesLoading());
    try {
        const response = yield call(() => axios.post('/scan-image', {image: codes}));
        yield put(InsertCodesActions.massCodesSuccess(response.data));
    } catch (error) {

    }
}

export default function* insertCodesSaga() {
    yield takeLatest(InsertCodesTypes.INSERT_CODES, insertCodes);
    yield takeLatest(InsertCodesTypes.GET_MASS_CODES, getMassCodes);
    yield takeLatest(InsertCodesTypes.GET_COUNT, getCount);
}
