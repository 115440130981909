import { createReducer } from 'reduxsauce';
import { ImageChallengeTypes } from './Actions';
import { TestimonialChallengeTypes } from '../TestimonialChallenge/Actions';

const INITIAL_STATE = {
	images: {
		data: [],
		pager: undefined
	},
	loading: false,
	errors: null,
	uploadLoading: false,
	uploadSuccess: false,
	uploadErrors: null
};

export const imagesLoading = (state) => ({
	...state,
	images: [],
	loading: true,
	errors: null
});

export const imagesSuccess = (state, { images }) => ({
	...state,
	images: images,
	loading: false,
	errors: null
});

export const imagesFail = (state, { errors }) => ({
	...state,
	images: [],
	loading: false,
	errors: errors
});

export const uploadLoading = (state) => ({
	...state,
	uploadLoading: true,
	uploadErrors: null
});

export const uploadSuccess = (state) => ({
	...state,
	uploadLoading: false,
	uploadSuccess: true,
	uploadErrors: null
});

export const resetUploadSuccess = (state) => ({
	...state,
	uploadSuccess: false,
	uploadLoading: false
});

export const uploadFail = (state, { errors }) => ({
	...state,
	uploadLoading: true,
	uploadSuccess: false,
	uploadErrors: errors
});

export const reducer = createReducer(INITIAL_STATE, {
	[ImageChallengeTypes.IMAGES_LOADING]: imagesLoading,
	[ImageChallengeTypes.IMAGES_SUCCESS]: imagesSuccess,
	[ImageChallengeTypes.IMAGES_FAIL]: imagesFail,
	[ImageChallengeTypes.UPLOAD_LOADING]: uploadLoading,
	[ImageChallengeTypes.UPLOAD_SUCCESS]: uploadSuccess,
	[ImageChallengeTypes.RESET_UPLOAD_SUCCESS]: resetUploadSuccess,
	[ImageChallengeTypes.UPLOAD_FAIL]: uploadFail
});
