import { createReducer } from 'reduxsauce';
import { OfflineRedemptionTypes } from './Actions';

const INITIAL_STATE = {
	data: null,
	loading: true,
	errors: null
};

export const redeemed = (state) => ({
	...state,
	loading: true,
});

export const redeemedSuccess = (state, {data}) => ({
	...state,
	loading: false,
	data: data
});

export const redeemedFail = (state, {data}) => ({
	...state,
	loading: false,
	data: data
});

export const submitRedeem = (state, {data}) => ({
	...state,
	loading: true,
	formData: data
});

export const submitRedeemSuccess = (state, {data}) => ({
	...state,
	loading: false,
	submitResponse: data
});

export const submitRedeemFail = (state, {data}) => ({
	...state,
	loading: false,
	submitResponse: data
});


export const reducer = createReducer(INITIAL_STATE, {
	[OfflineRedemptionTypes.REDEEMED]: redeemed,
	[OfflineRedemptionTypes.REDEEMED_SUCCESS]: redeemedSuccess,
	[OfflineRedemptionTypes.REDEEMED_FAIL]: redeemedFail,
	[OfflineRedemptionTypes.SUBMIT_REDEEM]: submitRedeem,
	[OfflineRedemptionTypes.SUBMIT_REDEEM_SUCCESS]: submitRedeemSuccess,
	[OfflineRedemptionTypes.SUBMIT_REDEEM_FAIL]: submitRedeemFail
});
