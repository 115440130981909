import React, {Fragment} from "react";
import store from "store";
import classnames from "classnames";
import {connect} from "react-redux";
import { animateScroll as scroll } from 'react-scroll';

// Assets
import config from "../../../config";
import styles from "./style.module.scss";

// Components
import Layout from "../../../components/Layout";
import Loader from "../../../components/Loader";
import {Accordion, Row} from "react-bootstrap";
import ReactPaginate from "react-paginate";

// Saga
import FaqsActions from "../../../stores/FAQs/Actions";

class FAQs extends React.Component{
	state = {
		isPWA: store.get(config.user.isPWA),
		pageNumber: 0,
		offset: 0
	};

	componentDidMount() {
		this.props.getFaqs(10, 1);
	}

	handleAccordionToggle = (key) => {
		const {faqs} = this.props;

		this.setState((state) => ({
			...state,
			faqs: faqs.map((faq, faqKey) => {
				if (faqKey === key) {
					faq.isActive = !faq.isActive;
				} else {
					faq.isActive = false;
				}
				return faq;
			})
		}));
	};

	handlePageClick = data => {
		let selected = data.selected;
		let offset = Math.ceil(selected * 10);
		scroll.scrollToTop();

		this.setState({
			offset: offset,
			pageCount: this.props.pager.last_page,
			pageNumber: selected
		}, () => {
			this.props.getFaqs(10, selected + 1);
		});
	};


	render() {
		const {isPWA, pageNumber} = this.state;
		const {faqs, pager, loading} = this.props;

		return (
			<Layout>
				<div className={classnames('page_heading', isPWA ? 'isPWA' : '')}>
					<h1>Întrebări frecvente</h1>
				</div>

				{loading ? <Loader /> :
					<div className={styles.container}>
						<Accordion className={styles.accordion}>
							{faqs.map((faq, key) => (
								<Fragment key={key}>
									<Accordion.Toggle as={Row} eventKey={key}
													  className={classnames(faq.isActive ? styles.activeToggler : styles.toggler)}
													  onClick={(eventKey) => this.handleAccordionToggle(key)}>
										<h3>{faq.title}</h3>
									</Accordion.Toggle>

									<Accordion.Collapse eventKey={key} className={styles.collapse}>
										<div dangerouslySetInnerHTML={{ __html: faq.content}} />
									</Accordion.Collapse>
								</Fragment>
							))}
						</Accordion>

						{pager.last_page > 1 &&
							<div className="pagination">
								<ReactPaginate
									previousLabel={''}
									nextLabel={''}
									breakLabel={'...'}
									breakClassName={'break-me'}
									pageCount={pager.last_page}
									marginPagesDisplayed={1}
									pageRangeDisplayed={2}
									onPageChange={this.handlePageClick}
									containerClassName={'pagination'}
									subContainerClassName={'pages pagination'}
									activeClassName={'active'}
									forcePage={pageNumber}
								/>
							</div>
						}
					</div>
				}

			</Layout>
		)
	}
}

const mapStateToProps = (state) => ({
	loading: state.faqs.loading,
	faqs: state.faqs.faqs,
	pager: state.faqs.pager
});

const mapDispatchToProps = {
	getFaqs: (per_page, page) => FaqsActions.faqs(per_page, page)
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FAQs);
