import React from 'react';
import classnames from 'classnames';

export const TextInput = ({ id, title, placeholder, value, errors, onClick, handleChange }) => (
    <label className={classnames({ 'has-error': errors.has(id) })}>
        <p>{title}</p>

        <input
            type="text"
            placeholder={placeholder}
            id={id}
            name={id}
            value={value}
            onClick={onClick}
            onChange={(e) => handleChange(id, e.target ? e.target.value : e)}
        />

        {errors.has(id) && <span className="error">{errors.first(id)}</span>}
    </label>
);
