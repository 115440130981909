import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
	counties: null,
	countiesLoading: null,
	countiesSuccess: ['counties'],
	countiesFail: ['errors'],
	cities: ['id'],
	citiesLoading: null,
	citiesSuccess: ['cities'],
	citiesFail: ['errors'],
	citiesReset: null
});

export const CountiesTypes = Types;
export default Creators;

