import React from 'react';
import store from 'store';
import Popup from 'reactjs-popup';
import config from '../../config';
import classnames from 'classnames';
import cookies from '../../utils/cookies';
import styles from './Styles.module.scss';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import ManageCookies from '../../components/Footer/parts/ManageCookies';

class CookieDisclaimer extends React.Component {

    state = {
        showManageCookiesPopup: false,
        isPWA: store.get(config.user.isPWA),
        visible: true
    };

    toggleModal = (event) => {
        event && event.preventDefault();

        this.state.showManageCookiesPopup ? enableBodyScroll('body') : disableBodyScroll('body');
        this.setState({
            showManageCookiesPopup: !this.state.showManageCookiesPopup
        });
    };

    componentDidMount() {
        if (cookies.read('cookies_accepted') == 1) {
            this.setState({visible: false});
        } else {
            this.setState({visible: true});
            cookies.create('dw_Technical_cookie', 'opt-in', 333);
            cookies.create('dw_Advertisement_cookie', 'opt-out', 333);
        }
    }

    acceptCookies = () => {
        cookies.create('cookies_accepted', 1, 333);
        cookies.create('dw_Technical_cookie', 'opt-in', 333);
        cookies.create('dw_Advertisement_cookie', 'opt-in', 333);
        this.setState({visible: false});
    };

    closeDisclaimer = () => {
        this.setState({
            visible: false
        })
    }

    render() {
        const { visible, isPWA } = this.state;

        return visible && (
            <>
                <div className={classnames(styles.cookie_disclaimer, isPWA ? styles.isPWA : '')}>
                    <div className={styles.container}>
                        <p>Folosim cookies pentru a-ți oferi o experiență personalizată de
                            comunicare și pentru a-ți oferi publicitate relevantă, bazată atât pe comportamentul tău pe
                            acest site, cât și pe altele, pentru optimizarea platformei, funcționarea corectă a acesteia
                            și
                            pentru a reține preferințele tale. Apasă <b>“Află mai multe”</b> pentru detalii sau pentru
                            a-ți
                            ajusta preferințele. Te poți răzgândi oricând apăsând butonul “Gestionează
                            cookies.” <br/><br/>
                            Datele personale colectate vor fi folosite conform <a className={styles.buton_conditii}
                                                                                  href="https://www.iqos.ro/privacy"
                                                                                  target="_blank">Notificării de
                                protecție a
                                datelor</a>.</p>

                        <div className={styles.linksContainer}>
                            <a href="#" className={styles.cookie_hint_settings} onClick={(e) => this.toggleModal(e)}>AFLĂ
                                MAI MULTE</a>
                            <a className={styles.accept_terms} onClick={this.acceptCookies}>ACCEPT TOATE</a>
                        </div>
                    </div>
                </div>

                <Popup modal closeOnDocumentClick
                       className={'manageCookies'}
                       open={this.state.showManageCookiesPopup}
                       onClose={this.toggleModal}
                >
                    {close => (
                        <>
                            <img src="https://sandbox.gd.ro/images/esc.png" className={styles.closePopup}
                                 onClick={close} alt="close-popup"/>
                            <ManageCookies onClose={close} closeDisclaimer={this.closeDisclaimer}/>
                        </>
                    )}
                </Popup>
            </>
        );
    }
}

export default CookieDisclaimer;
