import React from 'react';
import GoogleMapReact from 'google-map-react';
import Config from '../../../config';
import MapStyles from './MapStyles';
import MarkerClusterer from '@google/markerclusterer';
import Pointer from '../../../assets/images/icons/pointer.svg';
import BluePointer from '../../../assets/images/icons/blue-pointer.svg';
import PointerSelected from '../../../assets/images/icons/pointer-selected.svg';
import MarkersActions from '../../../stores/Map/Actions';
import { connect } from 'react-redux';

let gMarkers = [],
    markerCluster;

const setGoogleMapRef = (map, maps, locations = [], selectMarker, selfLocation) => {
    const getGoogleMapsLink = (street) => `https://maps.google.com/?q=${street}&output=classic`;
    let previousInfoWindow;

    markerCluster && markerCluster.clearMarkers();

    gMarkers = locations.map((location) => {
        const marker = new maps.Marker({
            icon: Pointer,
            position: { lat: location.latitude, lng: location.longitude },
            markerId: location.id
        });

        const infoWindow = new maps.InfoWindow({
            content: `
                    <article id="store-detail-template">
                        <h5>${location.name}</h5>
                        <address>
                            <p>${location.address} ${location.city} ${location.zip_code}</p>
                            <small class="location-required">
                                <a href="${getGoogleMapsLink(
                `${location.address},${location.city},${location.zip_code}`
            )}" target="_blank">Vizualizați în Google Maps</a>
                            </small>
                        </address>
                    </article> 
                `
        });

        new maps.event.addListener(marker, 'click', function() {
            selectMarker(marker.markerId);
            map.panTo(marker.getPosition());
            if (previousInfoWindow) previousInfoWindow.close();
            infoWindow.open(map, marker);
            previousInfoWindow = infoWindow;
        });

        new maps.event.addListener(marker, 'mouseover', function() {
            marker.setIcon(PointerSelected);
        });

        new maps.event.addListener(marker, 'mouseout', function() {
            marker.setIcon(Pointer);
        });

        return marker;
    });

    if (selfLocation) {
        const selfMarker = new maps.Marker({
            icon: BluePointer,
            position: { lat: selfLocation.lat, lng: selfLocation.lng },
            markerId: 'SELF_MARKER'
        });

        new maps.event.addListener(selfMarker, 'click', function() {
            map.setZoom(16);
            map.panTo(selfMarker.getPosition());
        });

        gMarkers.push(selfMarker);
    }

    markerCluster = new MarkerClusterer(map, gMarkers, {
        styles: Array(5).fill({
            height: 47,
            width: 27,
            url: Pointer
        }),
        imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        gridSize: 60,
        minimumClusterSize: 3,
        maxZoom: 15,
        averageCenter: true
    });

    markerCluster.setCalculator(function(markers, numStyles) {
        let index = 0;
        let dv = markers.length;
        while (dv !== 0) {
            dv = parseInt(dv / 10, 10);
            index++;
        }
        index = Math.min(index, numStyles);
        return {
            text: '', // Remove the counter from clusters, show only the pin icon
            index: index
        };
    });
};

class Map extends React.Component {
    state = {
        map: undefined,
        maps: undefined,
        locationMarkerAdded: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { data, selectMarker, center, zoomLevel, selectedMarker, selfLocation } = this.props;
        const { map, maps, locationMarkerAdded } = this.state;
        if (map && maps) {
            if (prevProps.data !== this.props.data) {
                setGoogleMapRef(map, maps, data, selectMarker, selfLocation);
            }

            if (prevProps.center !== center) {
                const selectedMarkerReference = gMarkers.find((marker) => marker.markerId === selectedMarker);
                this.state.map.setZoom(zoomLevel);
                this.state.maps.event.trigger(selectedMarkerReference, 'mouseover');
                this.state.maps.event.trigger(selectedMarkerReference, 'click');
            }

            if (selfLocation && !locationMarkerAdded) {
                this.setState(
                    {
                        locationMarkerAdded: true
                    },
                    () => setGoogleMapRef(map, maps, data, selectMarker, selfLocation)
                );
            }
        }
    }

    render() {
        const { data, selectMarker, center, zoomLevel } = this.props;
        return (
            <GoogleMapReact
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) =>
                    this.setState({ map, maps }, () => setGoogleMapRef(map, maps, data, selectMarker))
                }
                bootstrapURLKeys={{ key: Config.googleMapsKey }}
                defaultCenter={{ lat: 45.94316, lng: 24.96676 }}
                center={center}
                defaultZoom={7}
                zoom={zoomLevel}
                options={{ styles: MapStyles }}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    selectedMarker: state.map.selectedMarker,
    markersLoading: state.map.loading,
    center: state.map.center,
    zoomLevel: state.map.zoomLevel,
    selfLocation: state.map.selfLocation
});

const mapDispatchToProps = {
    selectMarker: (id) => MarkersActions.selectMarker(id),
    setCenter: (latitude, longitude, zoomLevel) => MarkersActions.setCenter(latitude, longitude, zoomLevel)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Map);
