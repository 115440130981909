import React from 'react';
import store from 'store';
import classnames from 'classnames';
import {connect} from 'react-redux';
import validURL from '../../utils/validURL';

// Assets
import config from '../../config';
import styles from './Button.module.scss';

// Components
import {Nav} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

class Button extends React.Component {
	state = {
		isPWA: store.get(config.user.isPWA)
	};

	render() {
		const { toPage, type, color, textAlign, noLink, style, content, disabled } = this.props;
		const { isPWA } = this.state;

		return (
			noLink ?
				<button type="button" disabled={disabled} style={style} onClick={() => this.props.onClick()} className={
					classnames(
						type 		=== 'primary' 			? styles.primaryButton 		: '',
						type 		=== 'secondary' 		? styles.secondaryButton 	: '',
						type 		=== 'goBackSecondary' 	? styles.goBackSecondary  	: '',
						type 		=== 'third' 			? styles.thirdButton 		: '',
						type 		=== 'simple' 			? styles.simpleButton 		: '',
						type 		=== 'goBack' 			? styles.goBack  			: '',
						color 	 	=== 'gold' 				? styles.gold 				: '',
						textAlign 	=== 'left' 				? styles.leftAlign 			: '',
						textAlign 	=== 'right' 			? styles.rightAlign			: '',
						isPWA 								? styles.isPWA  			: ''
					)
				}>
					{ content ? <div dangerouslySetInnerHTML={{ __html: content}} /> : this.props.children}
				</button>
			:
				validURL(toPage) ?
					<a href={toPage} target="_blank" style={style} className={
						classnames(
							type 		=== 'goBackSecondary' 	? styles.goBackSecondary  	: '',
							type 		=== 'primary' 	? styles.primaryButton 		: '',
							type 		=== 'secondary' ? styles.secondaryButton 	: '',
							type 		=== 'third' 	? styles.thirdButton 		: '',
							type 		=== 'fourth' 	? styles.fourthButton 		: '',
							type 		=== 'goBack' 	? styles.goBack  			: '',
							type 		=== 'simple' 	? styles.simpleButton 		: '',
							color 	 	=== 'gold' 		? styles.gold 				: '',
							textAlign 	=== 'left' 		? styles.leftAlign 			: '',
							textAlign 	=== 'right' 	? styles.rightAlign			: '',
							isPWA 						? styles.isPWA  			: '',
						)
					}>
						{content ? <div dangerouslySetInnerHTML={{ __html: content}} /> : this.props.children}
					</a>
				:
				<LinkContainer to={toPage} style={style} className={
					classnames(
						type 		=== 'goBackSecondary' 	? styles.goBackSecondary  	: '',
						type 		=== 'primary' 	? styles.primaryButton 		: '',
						type 		=== 'secondary' ? styles.secondaryButton 	: '',
						type 		=== 'third' 	? styles.thirdButton 		: '',
						type 		=== 'fourth' 	? styles.fourthButton 		: '',
						type 		=== 'goBack' 	? styles.goBack  			: '',
						type 		=== 'simple' 	? styles.simpleButton 		: '',
						color 	 	=== 'gold' 		? styles.gold 				: '',
						textAlign 	=== 'left' 		? styles.leftAlign 			: '',
						textAlign 	=== 'right' 	? styles.rightAlign			: '',
						isPWA 						? styles.isPWA  			: '',
					)
				}>
					<Nav.Link eventKey="">
						{ content ? <div dangerouslySetInnerHTML={{ __html: content}} /> : this.props.children}
					</Nav.Link>
				</LinkContainer>
		)
	}
}

export default connect()(Button)
