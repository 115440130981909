import React from 'react';
import classnames from 'classnames';
import InputMask from 'react-input-mask';

export const InputWithMask = ({ id, title, placeholder, value, errors, mask, handleChange }) => (
    <label className={classnames({ 'has-error': errors.has(id) })}>
        <p>{title}</p>
        <InputMask
            mask={mask}
            maskChar={null}
            placeholder={placeholder}
            name={id}
            value={value}
            onChange={(e) => handleChange(id, e.target ? e.target.value : e)}
        />

        {errors.has(id) && <span className="error">{errors.first(id)}</span>}
    </label>
);
