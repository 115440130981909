import React from 'react';
import store from 'store';
import config from '../../config';
import { connect } from 'react-redux';
import cookies from '../../utils/cookies';
import styles from './AgeGate.module.scss';

class AgeGate extends React.Component {
	state = {
		visible: false,
		userDecline: false,
		isPWA: store.get(config.user.isPWA)
	};

	componentDidMount() {
		if (cookies.read('iqos-age-verified') === 1) {
			this.setState({visible: false});
		} else {
			this.setState({visible: true});
		}
	}

	handleAccept = () => {
		store.set(config.user.isAdult, true);
		cookies.create('iqos-age-verified', 1, 333);
		window.location.reload();
	};

	handleDecline = () => {
		this.setState({ userDecline: true });
	};

	goBack = () => {
		this.setState({ userDecline: false });
	};

	render() {
		const { isPWA, visible } = this.state;

		return (
			visible ?
				<div className={styles.ageGate}>
					<div className={styles.centerAlign}>
						<a href="http://iqos.ro" target="_blank" className={styles.logo} />

						{this.state.userDecline ? (
							<div className={styles.secondStep}>
								<h2>Trebuie să ai peste 18 ani pentru a accesa acest website.</h2>
								<a
									role="button"
									onClick={this.goBack}
									className={isPWA ? styles.primaryButtonPWA : styles.primaryButton}
								>
									Ok
								</a>
							</div>
						) : (
							<div className={styles.firstStep}>
								<h2>
									ACEST WEBSITE ESTE DESTINAT PERSOANELOR CU VÂRSTA PESTE 18 ANI, FUMĂTOARE ȘI REZIDENTE
									ÎN ROMÂNIA. DOREȘTI SĂ CONTINUI?
								</h2>

								<a
									role="button"
									id="agegate-no"
									className={isPWA ? styles.primaryButtonPWA : styles.primaryButton}
									onClick={this.handleDecline}
								>
									Nu
								</a>

								<a
									role="button"
									id="agegate-yes"
									className={isPWA ? styles.primaryButtonPWA : styles.primaryButton}
									onClick={this.handleAccept}
								>
									Da
								</a>

								<p>
									Centrul de Asistență Clienți – LiveChat pe IQOS Club;
									zilnic, în intervalul 8:00 – 22:00;
									<br />
									Copyright 2015 - {new Date().getFullYear()} Philip Morris Trading S.R.L. Toate
									drepturile rezervate.
								</p>

								<p>
									<small>
										Bine ai venit pe CLUB.IQOS.ro, un website operat de Philip Morris Trading S.R.L. Aici vei
										putea afla mai multe despre elementele și caracteristicile produsului IQOS și, de
										asemenea, vei putea cumpăra sistemul IQOS, componente ale sistemului IQOS și HEETS,
										pentru a fi utilizate exclusiv cu IQOS. Noi tratăm foarte atent modul în care
										publicul larg află despre produsele noastre și, de asemenea, modul în care acesta le
										achiziționează. Poți accesa acest site numai dacă ai peste 18 ani, ești fumător și
										rezident în România. Pentru a confirma faptul că ești fumător și ai vârsta de peste
										18 ani, te rugăm să folosești butonul „DA”. În caz contrar, te rugăm să folosești
										butonul „NU”. În continuare, în interiorul site-ului, îţi pot fi solicitate
										informații suplimentare cu caracter personal, în vederea achiziționării IQOS, a
										produselor IQOS sau HEETS.
									</small>
								</p>
							</div>
						)}
					</div>
				</div> : ''
		);
	}
}

export default connect()(AgeGate);
