import { call, put, takeLatest } from 'redux-saga/effects';
import DevicesActions, { DevicesTypes } from './Actions';
import axios, { setAxiosToken } from '../../utils/axios';
import store from 'store';

export function* getDevices() {
	yield put(DevicesActions.devicesLoading());
	try {
		const response = yield call(() => axios.get('/a/my-devices', {}));
		yield put(DevicesActions.devicesSuccess(response.data));
	} catch (error) {
		yield put(DevicesActions.devicesFail(error.response.data));
	}
}

export default function* countiesSaga() {
	yield takeLatest(DevicesTypes.DEVICES, getDevices);
}
