import { createReducer } from 'reduxsauce';
import { InsertCodesTypes } from './Actions';

const INITIAL_STATE = {
    codes: null,
    countLoading: true,
    loading: false,
    success: null,
    counts: null,
    errors: []
};

export const getCount = (state) => ({
    ...state,
    errors: null
});

export const getCountLoading = (state) => ({
    ...state,
    countLoading: true,
    errors: null
});

export const getCountSuccess = (state, { counts }) => ({
    ...state,
    countLoading: false,
    counts: counts,
    errors: null
});

//

export const insertCodesLoading = (state) => ({
    ...state,
    loading: true,
    errors: null
});

export const insertCodesSuccess = (state, { success }) => ({
    ...state,
    success: success,
    loading: false,
    errors: null
});

export const insertCodesFail = (state, { errors }) => ({
    ...state,
    loading: false,
    errors: errors
});

export const massCodesSuccess = (state, { codes }) => ({
   ...state,
   codes: codes,
   loading: false,
   errors: null
});

export const reducer = createReducer(INITIAL_STATE, {
    [InsertCodesTypes.INSERT_CODES_LOADING]: insertCodesLoading,
    [InsertCodesTypes.INSERT_CODES_SUCCESS]: insertCodesSuccess,
    [InsertCodesTypes.INSERT_CODES_FAIL]: insertCodesFail,
    [InsertCodesTypes.MASS_CODES_SUCCESS]: massCodesSuccess,
    [InsertCodesTypes.GET_COUNT_SUCCESS]: getCountSuccess,
    [InsertCodesTypes.GET_COUNT_LOADING]: getCountLoading,
    [InsertCodesTypes.GET_COUNT]: getCount
});
