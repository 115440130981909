import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
	redeemed: null,
	redeemedSuccess: ['data'],
	redeemedFail: ['data'],

	submitRedeem: ['params'],
	submitRedeemSuccess: ['data'],
	submitRedeemFail: ['data']
});

export const OfflineRedemptionTypes = Types;
export default Creators;
