import React from "react";
import store from "store";
import classnames from "classnames";
import config from "../../../config";

// Assets
import styles from "./style.module.scss";

// Components
import Form from "./Form";
import Layout from "../../../components/Layout";
import Button from "../../../components/Button/Button";

// Images
import image01 from "../../../assets/images/ui/asistenta_icon_01.png";
import image02 from "../../../assets/images/ui/asistenta_icon_02.png";
import image03 from "../../../assets/images/ui/messengerIcon.png";
import image04 from "../../../assets/images/ui/supportIcon.png";
import image05 from "../../../assets/images/ui/img1.png";
import image06 from "../../../assets/images/ui/img2.png";
import image07 from "../../../assets/images/ui/img3.png";
import image08 from "../../../assets/images/ui/contact-img.png";

import messengerIcon from "../../../assets/images/icons/messenger-icon.svg";
import phoneIcon from "../../../assets/images/icons/phone-icon.svg";

// Saga
import {connect} from "react-redux";
import UsefulLinksActions from "../../../stores/UsefulLinks/Actions";
import {LinkContainer} from "react-router-bootstrap";
import {Nav} from "react-bootstrap";
import Loader from "../../../components/Loader";


class ContactBoxes extends React.Component{
	state = {
		isPWA: store.get(config.user.isPWA)
	};

	render() {
		const {isPWA} = this.state;

		return(
			<div className={classnames(isPWA ? styles.contactBoxesPWA : styles.contactBoxes)}>
				<div className={styles.column}>
					<h3>Cum folosesc <br/>punctele</h3>
					<p>Află cum poți revendica beneficiile din IQOS Club, online sau offline.</p>
					<br />
					<Button type="primary" toPage={config.path.howTo}>Vezi mai multe</Button>
					<img src={image01} alt="cum-folosesc-punctele" />
				</div>

				<div className={styles.column}>
					<h3>De unde <br/>revendici IQOS</h3>
					<p>Descoperă harta cu locațiile și vezi care e cea mai apropiată locație.</p>
					<br />

					<Button type="primary" toPage={config.path.offlineRedemption}>Vezi mai multe</Button>
					<img src={image02} alt="revendica-din-magazin" />
				</div>
			</div>
		)
	}
}

class ContactDetails extends React.Component{
	state = {
		isPWA: store.get(config.user.isPWA)
	};

	render() {
		const {isPWA} = this.state;

		return(
			<div className={styles.wrapper}>
				<div className={styles.contactDetails}>
					{isPWA ? (
						<>
							<div className={styles.details}>
								<h3>Contactează-ne</h3>ONTACTEAZĂ-NE
								<p>Dacă ai nevoie de mai multe informații, ne poți contacta folosind una din metodele de
									mai jos sau completând <a href="#contact">formularul de contact.</a></p>
							</div>

							<img src={image08} alt="contact-image" />
						</>
					) : (
						<>
							<h3>Contactează-ne</h3>
							<p>Dacă ai nevoie de mai multe informații, ne poți contacta folosind una din metodele de
								mai jos sau completând <a href="#contact">formularul de contact.</a></p>
						</>
					)}

					<div className={classnames(isPWA ? styles.columnsPWA : styles.columns)}>
						<div className={styles.column}>
							<img src={isPWA ? messengerIcon : image03} alt="facebook-icon" />

							<div className={styles.details}>
								<p>Ne poți scrie pe Facebook Messenger în intervalul 8:00-22:00, de luni până duminică.</p>
								<br />
								<a href="https://m.me/iqos.ro" className={styles.messageUs} target="_blank"><img src="images/fb_icon.png" alt="" /> Message us</a>
							</div>
						</div>

						<div className={styles.column}>
							<img src={isPWA ? phoneIcon : image04} alt="contact-icon" />

							<div className={styles.details}>
								<p>Ne poți suna în intervalul 8:00 - 22:00, de luni până duminică, la numărul:</p>
								<br />
								<a href="tel:0800030333">0800.030.333</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

class UsefulLinks extends React.Component{
	state = {
		isPWA: store.get(config.user.isPWA)
	};

	render() {
		const {isPWA} = this.state;
		const {usefulLinks} = this.props;

		return(
			<>
				{usefulLinks && usefulLinks.length > 0 &&
					<>
						<h3 className={isPWA ? styles.sectionTitle : "section-title"}>Află mai multe</h3>

						<ul className={isPWA ? styles.listBenefitsPWA : styles.listBenefits} id="benefitsTarget">
							{usefulLinks.map((usefulLink, key) => (
								<li key={key}>
									<LinkContainer to={config['path'][usefulLink.route]} className={styles.centerAlign}>
										<Nav.Link eventKey="">
											<img src={usefulLink.images} alt={usefulLink.title} />
											<p dangerouslySetInnerHTML={{ __html: usefulLink.title}} />
											<span className={styles.read_more}>{usefulLink.cta}</span>
										</Nav.Link>
									</LinkContainer>
								</li>
							))}
						</ul>
					</>
				}
			</>
		)
	}
}

class Contact extends React.Component{
	state = {
		isPWA: store.get(config.user.isPWA)
	};

	componentDidMount() {
		this.props.getUsefulLinks(2);
	}

	render() {
		const {isPWA} = this.state;
		const {usefulLinks, loading} = this.props;

		return(
			<Layout>
				<div className={classnames('page_heading', isPWA ? 'isPWA' : '')}>
					<h1>Asistență</h1>
					{isPWA ? '' : <p>Suntem pregătiți să te ajutăm și să-ți răspundem la întrebări, în fiecare zi, orice problemă ai avea. Află totul despre cum îți folosești punctele și de unde revendici IQOS. </p>}
				</div>

				{isPWA ? (
					<>
						<div className="container">
							<p className={styles.introParagraph}>Suntem pregătiți să te ajutăm și să-ți răspundem la întrebări, în fiecare zi, orice problemă ai avea. Află totul despre cum îți folosești punctele și de unde revendici IQOS. </p>

							<ContactBoxes />
							<ContactDetails />
						</div>

						<Form />
						{loading ? <Loader /> : <UsefulLinks usefulLinks={usefulLinks} />}
					</>
				) : (
					<>
						<div className="container">
							<ContactBoxes />
							<ContactDetails />
						</div>

						<Form />
						{loading ? <Loader /> : <UsefulLinks usefulLinks={usefulLinks} />}
					</>
				)}
			</Layout>
		)
	}
}

const mapStateToProps = (state) => ({
	loading: state.usefulLinks.loading,
	usefulLinks: state.usefulLinks.usefulLinks
});

const mapDispatchToProps = {
	getUsefulLinks: (id) => UsefulLinksActions.usefulLinks(id)
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Contact);
