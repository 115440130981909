import React from 'react';
import store from 'store';
import classnames from 'classnames';
import config from '../../../config';

// Assets
import styles from './style.module.scss';

// Components
import Layout from '../../../components/Layout';

class Regulation extends React.Component {
	state = {
		isPWA: store.get(config.user.isPWA)
	};

	componentDidMount() {
		window.jQuery('#acc_regulament').bwlAccordion({
			placeholder: 'Caută după cuvinte cheie',
			highlight_bg: '#d6a76f',
			highlight_color: '#fff',
			msg_item_found: ' Rezultat(e)',
			msg_no_result: '0 Rezultate'
		});
	}

	render() {
		const { isPWA } = this.state;

		return (
			<Layout>
				<div className={classnames('page_heading', isPWA ? 'isPWA' : '')}>
					<h1>
						Condiții generale ale platformei <br />
						promoționale online "IQOS CLUB"
					</h1>
				</div>

				<div className={styles.container}>
					<div className="bwl_acc_container" id="acc_regulament">
						<div className="accordion_search_container">
							<input
								type="text"
								className="accordion_search_input_box search_icon"
								defaultValue=""
								placeholder="Caută după cuvinte cheie"
							/>
						</div>

						<div className="search_result_container" />

						<section>
							<h2 className="acc_title_bar">
								<a href="#">SECŢIUNEA 1. ORGANIZATORUL</a>
							</h2>
							<div className="acc_container">
								<div className="block">
									<p>
										Platforma online <b>„IQOS Club”</b> (numită în cele ce urmează „Campania”), o
										inițiativă cu caracter promoțional, este deţinută şi se află sub gestiunea
										Philip Morris Trading S.R.L (numită în prezentul document “Organizatorul”), cu
										sediul în Otopeni, Str. Horia, Cloşca şi Crişan nr. 83-105, clădirea A, Judeţul
										Ilfov, România, înmatriculată la Registrul Comerţului sub numărul J23/511/2004,
										având CIF RO 4888165. Campania este accesibilă publicului larg la adresa web{' '}
										<a href="https://club.iqos.ro">club.iqos.ro</a>. Participanţii la această
										Campanie sunt obligaţi să respecte termenii şi condiţiile de participare,
										potrivit celor menţionate mai jos (denumit în continuare “Regulament Oficial”).
										În cadrul Campaniei se desfășoară o serie de activități promotionale diferite,
										dar conexe, denumite „Promoții Curente” ale căror detalii de implementare sunt
										comunicate în cadrul platformei online, atât prin comunicări publicitare
										specifice (de tip materiale vizuale online, e-mailuri etc) cât și prin secțiunea
										„Promoții Curente” a acestor Condiții Generale. Organizatorul își rezervă
										dreptul să adauge și/sau să retragă diverse promoții în cadrul Campaniei, urmând
										a aduce la zi secțiunea Promoții Curente a prezentului regulament Oficial, și
										informând participanții la Campanie cu privire la debutul/încetarea
										valabilității anumitor promoții prin e-mail și/sau direct prin intermediul
										platformei online, la adresa web <a href="https://club.iqos.ro">club.iqos.ro</a>
										.
									</p>
								</div>
							</div>
						</section>

						<section>
							<h2 className="acc_title_bar">
								<a href="#">SECŢIUNEA 2. CONDIŢII DE PARTICIPARE</a>
							</h2>
							<div className="acc_container">
								<div className="block">
									<p>
										La această Campanie pot participa cetăţenii români, cu vârsta peste 18 ani
										(împliniţi până la data înscrierii lor în cadrul prezentei Campanii),
										utilizatori de IQOS, cu cont activ pe website-ul{' '}
										<a href="https://iqos.ro">www.iqos.ro</a>
										şi care au înregistrat în contul lor minim un dispozitiv IQOS compus din Holder
										(„Stilou”) şi încărcător portabil şi care au domiciliul sau reşedinţa sau sunt
										rezidenţi în România.
									</p>

									<p>
										Din dorinţa de a nu prejudicia niciun participant care doreşte să participe la
										prezenta Campanie, Organizatorul îşi rezervă dreptul să poată efectua verificări
										în ceea ce priveşte corecta participare.
									</p>

									<p>
										O persoană poate deține un singur cont individual pe platforma online{' '}
										<a href="https://club.iqos.ro">club.iqos.ro</a>. Orice încercare de duplicare a
										unui cont sau de înregistrare a unei persoane cu date modificate/fictive/cu alte
										conturi (același nume, prenume, adresă de livrare, număr de telefon), va atrage
										după sine ștergerea conturilor deschise ulterior contului inițial, iar în caz de
										repetare a acțiunii, se va putea trece inclusiv la dezactivarea contului inițial
										de utilizator.
									</p>

									<p>
										În cazul în care, în urma verificărilor desfăşurate de către Organizator,
										rezultă că procesul de participare a fost fraudat, Organizatorul îşi rezervă
										dreptul de a retrage participanţilor premiile obţinute ca rezultat al
										activităţii lor şi / sau să restricţioneze participarea la Campanie.{' '}
									</p>

									<p>
										În cazul în care sunt identificate persoane care au influenţat sau au facilitat
										câştigarea de premii necuvenite, Organizatorul are dreptul de a cere urmărirea
										în instanţă a respectivelor persoane, pe baza dovezilor existente.
									</p>

									<p>
										Organizatorul îşi rezervă dreptul de a exclude participanţi din Campanie cu sau
										fără indicarea motivului. Acest lucru se aplică mai ales participanţilor care
										îşi manipulează sau încearcă să îşi manipuleze participarea (în principal prin
										crearea de conturi multiple sau încercând să obţină sau să creeze şanse
										suplimentare de a câştiga în mod incorect) sau care încalcă condiţiile specifice
										promoţiei sau care, într-un mod sau altul, utilizează abuziv promoţiile propuse.
									</p>
								</div>
							</div>
						</section>

						<section>
							<h2 className="acc_title_bar">
								<a href="#">SECŢIUNEA 3. ÎNSCRIEREA ÎN CAMPANIE</a>
							</h2>
							<div className="acc_container">
								<div className="block">
									<p>
										Participarea la prezenta Campanie se face prin intermediul website-ului dedicat.
										Pentru a participa la prezenta Campanie, Participanţii trebuie să îndeplinească
										cumulativ următoarele condiţii: să deţină cont activ pe website-ul{' '}
										<a href="https://iqos.ro">www.iqos.ro</a> şi să aibă înregistrat în contul lui
										pe website-ul www.iqos.ro minim un dispozitiv IQOS compus din Holder („Stilou”)
										şi încărcător portabil.
										<br />
										Utilizatorul va putea accesa şi se va putea autentifica în cadrul website-ului
										dedicat prezentei Campanii promoţionale utilizând userul şi parola stabilite
										anterior la crearea şi activarea contului său pe website-ul
										<a href="https://iqos.ro">www.iqos.ro</a>.
										<br />
										Un utilizator care nu deţine un cont activ pe website-ul{' '}
										<a href="https://iqos.ro">www.iqos.ro</a>
										nu se va putea autentifica în cadrul website-ului destinat prezentei campanii şi
										nici nu îşi va putea crea cont în cadrul platformei online dedicate acestei
										Campanii.
										<br />
										Un utilizator care deţine un cont activ pe website-ul www.iqos.ro dar nu are
										înregistrat în contul lui pe website-ul{' '}
										<a href="https://iqos.ro">www.iqos.ro</a> minim un dispozitiv IQOS compus din
										Holder („Stilou”) şi încărcător portabil se va putea autentifica în cadrul
										website-ului destinat prezentei campanii promoţionale dar va avea acces limitat
										la conţinut şi nu va putea participa activ la prezenta Campanie.
									</p>
								</div>
							</div>
						</section>

						<section>
							<h2 className="acc_title_bar">SECŢIUNEA 4. PROMOȚII CURENTE</h2>
							<div className="acc_container">
								<div className="block">
									<h3 className="acc_title_bar">
										<a href="#">Categoria promoții: Acumulare de puncte</a>
									</h3>
									<div className="acc_container">
										<div className="block">
											<h4 className="acc_title_bar">
												Promoţia: Înscrierea de coduri din pachetele de HEETS
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														În secţiunea special dedicată înscrierii de coduri,
														Participanţii pot înscrie coduri unice alfanumerice alcătuite
														din 9 caractere alfanumerice, tipărite în interiorul capacelor
														pachetelor de HEETS™.
														<br />
														Este valabilă prima înscriere înregistrată a unui cod unic,
														indiferent de modalitatea de înscriere a acestuia, dacă se
														îndeplinesc următoarele condiţii cumulativ:
													</p>

													<ul>
														<li>
															Codul unic, înscris prin modalitatea descrisă anterior, se
															află printre codurile formate din 9 caractere alfanumerice
															tipărite în interiorul capacului pachetului de HEETS™;
														</li>
														<li>
															Codul unic este valid înscris, o singură dată, pe toată
															perioada de desfăşurare a Campaniei;
														</li>
														<li>
															Codul unic înscris în Campanie este format din 9 (nouă)
															caractere alfanumerice, aşa cum apare acesta tipărit în
															interiorul capacului pachetelor de HEETS™.
														</li>
													</ul>

													<p>
														Prin înscrierea unui cod unic valid, Participantul va acumula în
														contul său <b>1 punct</b>.
													</p>

													<p>
														Dacă se încearcă înscrierea unui cod unic valid a doua oară în
														Campanie, de pe contul aceluiaşi Participant sau de pe alt cont,
														acest cod nu va fi luat în considerare, iar Organizatorul va
														anunţa participantului faptul că acel cod a fost deja înscris în
														Campania promoţională, prin trimiterea unui mesaj de răspuns.
														Aceste încercări nu reprezintă înscrieri valide de coduri în
														Campania promoţională, nu vor fi luate în considerare şi nu se
														vor acumula puncte în contul Participantului, deoarece nu sunt
														coduri înscrise corect.
													</p>

													<p>
														Un participant, poate înscrie în total, în intervalul de
														participare 00:00:00 – 23:59:59, maxim{' '}
														<b>10 (zece) coduri unice valide pe zi</b>; în cazul în care
														participantul va încerca să înscrie mai multe coduri, începând
														cu al 11-lea cod unic înscris va primi mesaj de eroare prin care
														va fi informat că a atins limita de participări valide pentru
														ziua respectivă.
													</p>

													<p>
														Un participant, poate înscrie în total, în intervalul de
														participare cuprins într-o lună calendaristică, maxim
														<b>100 (o sută) coduri unice valide pe lună</b>; în cazul în
														care participantul va încerca să înscrie mai multe coduri,
														începând cu al 101-lea cod unic înscris va primi mesaj de eroare
														prin care va fi informat că a atins limita de participări valide
														pentru luna respectivă.
													</p>

													<p>
														Organizatorul a decis limitarea numărului de coduri unice valide
														înscrise de pe contul unui Participant în unitatea de timp
														(maxim 10 (zece) coduri unice valide pe zi, maxim 100 (o sută)
														coduri unice valide pe lună deoarece acesta sustine utilizarea
														responsabilă a produselor din tutun şi a produselor conexe.
														Organizatorul nu intenţionează şi nu se face responsabil, prin
														intermediul Campaniilor Promoţionale sau al ofertelor
														disponibile în piata locală, pentru utilizarea excesivă de către
														consumatori a produselor care conţin nicotină sau tutun.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">Promoţia: Înscrierea de recomandări</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Participanţii pot recomanda persoane fumătoare adulte care şi-au
														exprimat interesul legat de sistemul IQOS prin următoarele
														metode:
													</p>

													<ul className="dashLine">
														<li>
															Prin intermediul website-ului prezentei Campanii
															Promoţionale, în cadrul secţiunii speciale dedicate
															înscrierii de recomandări;
														</li>
														<li>Prin intermediul aplicaţiei digitale iSPOT.</li>
													</ul>

													<p>
														Persoanele fumătoare adulte pe care urmează să le recomande
														trebuie recrutate de Participanţi doar prin interacţiune
														directă, faţă în faţă cu aceasta. Se interzice Participanţilor
														utilizarea brandului IQOS în campanii de publicitate, recrutări
														prin mijloace electronice, cum ar fi, dar fără a se limita la,
														comunicări pe e-mail, comunicări SMS utilizând baze de date din
														orice surse, realizarea de website-uri în scop de promovare şi
														recrutare potenţiali cumpărători, promovarea pe paginile de
														socializare şi orice altă acţiune care poate fi considerată
														acţiune de publicitate prin mijloace electronice. În cazul în
														care, în urma verificărilor desfăşurate de către Organizator,
														rezultă că procesul de participare a fost fraudat, Organizatorul
														îşi rezervă dreptul de a retrage participanţilor punctele
														acumulate / premiile obţinute ca rezultat al activităţii lor şi
														/ sau să restricţioneze participarea la Campania promoţională
														până la încheierea acesteia.
													</p>

													<p>
														Pentru a înscrie o recomandare prin oricare din modalităţile
														descrise anterior în cadrul prezentei secţiuni, Participantul va
														completa un formular cu următoarele date personale ale persoanei
														fumătoare adulte recomandate: nume, prenume, judeţ, localitate,
														număr telefon mobil. Totodată va confirma în cadrul formularului
														de recomandare faptul că persoana recomandată este adultă şi
														fumătoare şi este de acord cu termenii şi condiţiile
														recomandării. <br />
														Pentru ca o recomandare să fie înscrisă cu succes în cadrul
														platformei online trebuie să întrunească cumulativ următoarele
														criterii:
													</p>

													<ul className="dashLine">
														<li>Datele introduse să fie corecte şi complete;</li>
														<li>Numărul de telefon mobil să fie valid;</li>
														<li>
															Persoana recomandată, identificată după numărul de telefon
															nu a fost înscrisă / recomandată anterior, prin acelaşi sau
															orice alt canal de adaugare recomandări, cum ar fi dar fără
															a se limita la iSPOT, iQOACH.
														</li>
													</ul>

													<p>
														După completarea cu succes a formularului de recomandare,
														persoana recomandată va primi, prin comunicare SMS la numărul de
														telefon mobil completat în formularul de recomandare, informaţii
														referitoare la persoana care l-a recomandat, precum şi faptul că
														va fi contactat de un reprezentant al Organizatorului în
														interval de maxim 48 de ore. Organizatorul nu îşi asumă
														răspunderea pentru neprimirea sau primirea întârziată a
														SMS-urilor datorită unor cauze care nu depind direct de el, cum
														ar fi, dar nelimitându-se la, defecţiuni tehnice ale
														operatorilor de telefonie mobilă.
														<br />
														Toate persoanele recomandate înscrise de Participant prin
														oricare din modalităţile descrise anterior în cadrul prezentei
														secţiuni vor fi contactate de un reprezentant al Organizatorului
														în vederea stabilirii unei întâlniri, a prezentării detaliilor
														despre sistemul IQOS.
													</p>

													<p>
														Recomandările care se finalizează cu achiziţionarea de către
														persoana recomandată a unui sistem IQOS (dispozitiv IQOS şi un
														cartuş HEETS™ sau dispozitiv IQOS şi 3 pachete HEETS™ sau orice
														altă combinaţie de dispozitiv IQOS şi pachete HEETS™ disponibilă
														conform ofertei comerciale active la momentul achiziţionării
														dispozitivului IQOS) sunt numite în continuare „recomandări
														finalizate cu vânzare” şi doar acestea vor fi contorizate în
														vederea acumulării de puncte.{' '}
													</p>

													<p>
														Pentru ca o recomandare finalizată cu vânzare să fie considerată
														validă, achiziţionarea de către persoana recomandată a unui
														sistem IQOS trebuie să fie realizată doar de la reprezentantul
														IQOS de care a fost contactat în termenul descris mai sus din
														momentul primirii SMS-ului şi doar ca urmare a respectării
														paşilor descrişi prin SMS. Nu vor fi considerate recomandări
														finalizate cu vânzare valide şi nu vor fi contorizate în contul
														participantului care a înregistrat recomandarea acele
														achiziţionări de sisteme IQOS din alte surse decât cea
														menţionată anterior în cadrul prezentului paragraf, cum ar fi,
														dar fără a se limita la, direct de pe website-ul{' '}
														<a href="https://www.iqos.ro/" target="_blank">
															www.iqos.ro
														</a>{' '}
														sau din magazinele partenere sau ca urmare a interacţiunii
														cumpărătorilor cu un reprezentant IQOS, alta decât prin
														întalnirea cu reprezentantul IQOS de care a fost contactat în
														termenul descris mai sus din momentul primirii SMS-ului şi alta
														ca urmare a recomandării făcute de Participantul înscris în
														prezenta Campanie Promoţională.
													</p>
													<p>
														Participantul va acumula în contul său 60 puncte pentru orice
														recomandare finalizată cu vânzare.{' '}
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Interacţiune „Spune-ne despre tine”
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														La prima autentificare în cadrul platformei online IQOS Club,
														utilizatorul va avea posibilitatea să răspundă la un chestionar,
														iar în urma completării răspunsurilor şi a finalizării
														chestionarului va acumula în contul său <b>5 puncte</b>.
													</p>

													<p>
														Utilizatorul, identificat după datele cu care şi-a creat contul
														în cadrul platformei online IQOS Club, poate realiza o singură
														dată această interacţiune, iar punctele vor acordate o singură
														dată, la finalizarea acesteia. Răspunsurile completate de
														utilizator devin proprietatea Philip Morris Trading cu toate
														drepturile aferente, inclusiv dreptul de a prelucra sau utiliza
														în scopuri de marketing direct informaţiile conţinute, fără alte
														obligaţii şi plăţi ulterioare faţă de persoana respectivă.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">Promoţia: Interacţiune „Creştem împreună”</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														În cadrul platformei online IQOS Club, secţiunea special
														dedicate, utilizatorul care are deja contul activ de mai mult de
														6 luni calendaristice va avea posibilitatea să răspundă la un
														chestionar. În urma completării răspunsurilor şi a finalizării
														chestionarului va acumula în contul său <b>5 puncte</b>.
													</p>

													<p>
														Utilizatorul, identificat după datele cu care şi-a creat contul
														în cadrul platformei online IQOS Club, poate realiza o singură
														dată această interacţiune, iar punctele vor acordate o singură
														dată, la finalizarea acesteia. Răspunsurile completate de
														utilizator devin proprietatea Philip Morris Trading cu toate
														drepturile aferente, inclusiv dreptul de a prelucra sau utiliza
														în scopuri de marketing direct informaţiile conţinute, fără alte
														obligaţii şi plăţi ulterioare faţă de persoana respectivă.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Interacţiune „Aniversăm 1 an de IQOS Club”
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														În cadrul platformei online IQOS Club, secţiunea special
														dedicate, utilizatorul care are deja contul activ de mai mult de
														12 luni calendaristice va avea posibilitatea să răspundă la un
														chestionar. În urma completării răspunsurilor şi a finalizării
														chestionarului va acumula în contul său <b>5 puncte</b>.
													</p>
													<p>
														Utilizatorul, identificat după datele cu care şi-a creat contul
														în cadrul platformei online IQOS Club, poate realiza o singură
														dată această interacţiune, iar punctele vor acordate o singură
														dată, la finalizarea acesteia. Răspunsurile completate de
														utilizator devin proprietatea Philip Morris Trading cu toate
														drepturile aferente, inclusiv dreptul de a prelucra sau utiliza
														în scopuri de marketing direct informaţiile conţinute, fără alte
														obligaţii şi plăţi ulterioare faţă de persoana respectivă.{' '}
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">Promoţia: Valabilitate puncte</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Un punct acumulat în contul Participantului şi neutilizat de
														acesta are termen de valabilitate după cum urmează:
													</p>
													<ul className="dashLine">
														<li>
															Punctele acumulate în perioada 01 ianuarie ora 00:00:00 – 30
															iunie ora 23:59:59 a unui an calendaristic şi neutilizate
															pâna la 31 decembrie ora 23:59:59, acelaşi an calendaristic,
															îşi vor pierde valabilitatea la această dată şi vor fi
															scăzute automat din contul participantului;
														</li>

														<li>
															Punctele acumulate în perioada 01 iulie ora 00:00:00 – 31
															decembrie ora 23:59:59 a unui an calendaristic şi
															neutilizate până la data de 30 iunie ora 23:59:59 a anului
															calendaristic următor, îşi vor pierde valabilitatea la
															această dată şi vor fi scăzute automat din contul
															participantului.
														</li>
													</ul>
												</div>
											</div>


											<h4 className="acc_title_bar">
												Promoţia: Înscrierea de recomandări în perioada 01 septembrie 2020 – 01 noiembrie 2020
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>Participanţii pot recomanda persoane fumătoare adulte care şi-au exprimat interesul legat de sistemul IQOS conform metodologiei descrise în cadrul Promoţiei „Înscrierea de recomandări”.</p>
													<p>Prezenta promoţie este independentă de alte promoţii similare desfăşurate anterior în cadrul platformei online IQOS Club, iar efectele nu sunt cumulative.</p>
													<p>Beneficiile pe care le vor primi Participanţiişi/sau persoanele recomandate în urma finalizării cu vânzare a recomandărilor înregistrate în perioada 01 septembrie 2020 ora 00:00:00 – 01 noiembrie 2020 ora  23:59:59  vor  fi  cele  descrise  în  cadrul  Promoţiei  „Înscrierea  de  recomandări”,  respectiv Participantul va acumula în contul său 60 puncte, cărora li se adaugă beneficii după cum urmează:</p>
													<p>Pentru prima recomandareînregistratăşifinalizată cu vânzare în perioada 01 septembrie 2020 ora 00:00:00  –  01  noiembrie  2020,  Participantul  va  primi,  pe  o  perioadă  de  timp  de  30  zile calendaristice, bonus suplimentar dublarea punctelor acumulate în contul său din cadrul platformei online IQOS Club în următoarele tipuri de promoţii:</p>
													<ul className="letters">
														<li>a) Introducere  de  coduri  din  pachetele  de  HEETS,  conform  mecanismului  descris  în regulamentul promoţiei „Înscrierea de coduri din pachetele de HEETS”;</li>
														<li>b) Comandă  plasată   pe   website-ul <a href="www.iqos.ro">www.iqos.ro</a>,   conform   mecanismului   descris   în regulamentul promoţieie „Beneficii garantate”, punctele b.1 şi b.2;</li>
														<li>c) Comandă plasată din catalogul online disponibil în cadrul platformei IQOS Club, conform mecanismului descris în regulamentul promoţieie „Beneficii garantate”, punctele b.1 şi b.2;</li>
														<li>d) Recomandare  înregistrată  de  utilizator  şi finalizată  în  decursul  perioadei  menţionate anterior,   conform   mecanismului   descris   în   regulamentul   promoţiei   „Înscrierea   de recomandări”;</li>
														<li>e) Finalizarea cu succes a interacţiunii conform mecanismului promoţiei„Interacţiune „Card aniversar 1 an de membru IQOS Club””;</li>
														<li>f) Finalizarea cu succes a interacţiunii conform mecanismului promoţiei„Interacţiune „Spune-ne despre tine””;</li>
														<li>g) Finalizarea  cu  succes  a  interacţiunii  conform  mecanismului  promoţiei „Interacţiune„Creştemîmpreună””;</li>
														<li>h) Finalizarea  cu  succes  a  interacţiunii  conform  mecanismului  promoţiei „Interacţiune„Rămânemalături de tine””;</li>
													</ul>
													<p>În situaţia în care, pe perioada de desfăşurare a prezentei promoţii,acelaşi participant înregistreazămai  multe  recomandări şi  acestea  din  urmă  se  finalizează  cu  vânzare,  conform  termenilor  descrişianterior,  perioada  de  timp   în  care  Participantul  primeşte  bonus  suplimentar  se  modifică după  cum urmează:</p>
													<ul className="dashLine">
														<li>În  cazul  în  care  perioada  de  bonus  suplimentar  s-a  finalizat  deja,  Participantul  va  primi  bonus suplimentar dublarea punctelor pe o perioadănouă de timp de 30 zile calendaristice de la finalizarea cu vânzare a noii recomandări;</li>
														<li>În cazul în care Participantul se află deja în perioada de 30 zile calendaristice în care primeşte bonus suplimentar dublarea punctelor, aceastăperioadă se resetează, iar Participantul va beneficia de o perioadă  de  30  zile  calendaristice  în  care  primeste  bonus  suplimentar  dublarea  punctelor,  de  la ultima recomandare finalizată cu vânzare.</li>
													</ul>
													<p>Persoana  recomandată  care  a  finalizat  cu  vânzare  recomandarea  înregistrată  de  participant  pe perioada  de  desfăşurare  a  prezentei  promoţii,  va  primi  în  contul  său  din  cadrul  platformei  online <a href="www.iqos.ro">www.iqos.ro</a> un voucher electronic în valoarea de 50 Ron. Acesta poate fi utilizat pentru achiziţiade HEETS pe website-ul www.iqos.ro, la accesarea ofertei DUO HEETS disponibilă conform ofertelor comerciale în vigoare pe website-ul <a href="www.iqos.ro">www.iqos.ro</a> la momentul plasării comenzii. Reducerea va fi aplicată direct în coşul de cumpărături, la finalizarea comenzii. Acest tip de voucher se alocă unui utilizator o singură dată şi are termen de valabilitate 30 zile de la data acordării. Voucherul poate fi utilizat exclusiv în scopul în care a fost acordat, utilizatorul nu poate cere, fără a se limita la, prelungirea perioadei de valabilitate, preschimbarea în bani sau alte bunuri, utilizarea voucherului în alte scopuri decât acesta a fost destinat. Comenzile plasate în cadrul website-ului <a href="www.iqos.ro">www.iqos.ro</a> vor fi supuse termenilor şi condiţiilor de vânzare descrise în cadrul acestuia.</p>
													<p>În  plus,  persoana  recomandată  va  primi,  pe  o  perioadă  de  timp  de  30  zile  calendaristice,  bonus suplimentar dublarea punctelor acumulate în contul său din cadrul platformei online IQOS Club în următoarele tipuri de promoţii:</p>
													<ul className="letters">
														<li>a) Introducere  de  coduri  din  pachetele  de  HEETS,  conform  mecanismului  descris  în regulamentul promoţiei „Înscrierea de coduri din pachetele de HEETS”;</li>
														<li>b) Comandă  plasată   pe   website-ul <a href="www.iqos.ro">www.iqos.ro</a>,   conform   mecanismului   descris   în regulamentul promoţieie „Beneficii garantate”, punctele b.1 şi b.2;</li>
														<li>c) Comandă plasată din catalogul online disponibil în cadrul platformei IQOS Club, conform mecanismului descris în regulamentul promoţieie „Beneficii garantate”, punctele b.1 şi b.2;</li>
														<li>d) Recomandare  înregistrată  de  utilizator  şi finalizată  în  decursul  perioadei  menţionateanterior,   conform   mecanismului   descris   în   regulamentul   promoţiei   „Înscrierea   de recomandări”;</li>
														<li>e) Finalizarea cu succes a interacţiunii conform mecanismului promoţiei „Interacţiune „Card aniversar 1 an de membru IQOS Club””;</li>
														<li>f) Finalizarea cu succes a interacţiunii conform mecanismului promoţiei „Interacţiune „Spune-ne despre tine””;</li>
														<li>g) Finalizarea  cu  succes  a  interacţiunii  conform  mecanismului  promoţiei „Interacţiune „Creştem împreună””;</li>
														<li>h) Finalizarea  cu  succes  a  interacţiunii  conform  mecanismului  promoţiei „Interacţiune„ Rămânem alături de tine””.</li>
													</ul>

													<p>Participantul poate beneficia de dublarea de puncte pentru activitățile pentru care este eligibil. Pe perioada în care Participantul este înscris activ într-un program de testare a unui dispozitiv IQOS, acesta va beneficia de o perioadă de 30 de zile de dublare a punctelor doar pentru activitatea de înscriere recomandări.</p>
													<p>În cazul în care Participantul achiziționează un dispozitiv IQOS și se înscrie în cadrul platformei club.iqos.ro îndeplinind criteriile de eligibilitate, acesta va beneficia de o perioadă de 30 de zile de puncte duble ca urmare a trimiterii unei recomandări finalizate cu achiziție pentru următoarele activități de acumulare de puncte:</p>
													<ul className="letters">
														<li>a) Introducere  de  coduri  din  pachetele  de  HEETS,  conform  mecanismului  descris  în regulamentul promoţiei „Înscrierea de coduri din pachetele de HEETS”;</li>
														<li>b) Comandă  plasată   pe   website-ul <a href="www.iqos.ro">www.iqos.ro</a>,   conform   mecanismului   descris   în regulamentul promoţieie „Beneficii garantate”, punctele b.1 şi b.2;</li>
														<li>c) Comandă plasată din catalogul online disponibil în cadrul platformei IQOS Club, conform mecanismului descris în regulamentul promoţieie „Beneficii garantate”, punctele b.1 şi b.2;</li>
														<li>d) Recomandare  înregistrată  de  utilizator  şi finalizată  în  decursul  perioadei  menţionateanterior,   conform   mecanismului   descris   în   regulamentul   promoţiei   „Înscrierea   de recomandări”;</li>
														<li>e) Finalizarea cu succes a interacţiunii conform mecanismului promoţiei „Interacţiune „Card aniversar 1 an de membru IQOS Club””;</li>
														<li>f) Finalizarea cu succes a interacţiunii conform mecanismului promoţiei „Interacţiune „Spune-ne despre tine””;</li>
														<li>g) Finalizarea  cu  succes  a  interacţiunii  conform  mecanismului  promoţiei „Interacţiune „Creştem împreună””;</li>
														<li>h) Finalizarea  cu  succes  a  interacţiunii  conform  mecanismului  promoţiei „Interacţiune„ Rămânem alături de tine””.</li>
													</ul>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Utilizatori în perioada de testare IQOS
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>La această promoţie participă cetăţenii români, cu vârsta peste 18 ani (împliniţi până la data înscrierii lor în cadrul prezentei Campanii), care au domiciliul sau reşedinţa sau sunt rezidenţi în România, care au primit cu titlu de „împrumut” dispozitivul IQOS şi care se află în perioada de testare, conform termenilor şi condiţiilor promoţiei „Încearcă IQOS” derulată prin intermediul reprezentanţilor Organizatorului.</p>
													<p>În cadrul interacţiunii cu reprezentantul Organizatorului, Participantul va primi link-ul de acces în cadrul unei secţiuni special dedicate utilizatorului aparţinând platformei online IQOS Club.</p>
													<p>În calitate de utilizator aflat în perioada de testare, Participantul va putea accesa doar secţiunile din cadrul platformei online IQOS Club la care va avea drept de vizualizare, restul secţiunilor fiind restricţionate.</p>
													<p>Un Participant aflat în perioada de testare poate accesa promoţiile disponibile în cadrul platformei online IQOS Club astfel:</p>

													<p>A. Categoria promoţii: Acumulare puncte, Promoţia: Înscrierea de recomandări</p>
													<p>B. Categoria promoţii: Valorificare puncte</p>
													<ul>
														<li><p>Promoţia: Revendicare pachete HEETS din magazine</p></li>
														<li><p>Promoţia: Anulare coduri revendicare HEETS</p></li>
													</ul>

													<p>Un Participant aflat în perioada de testare IQOS poate transforma punctele dintre cele acumulate în contul său în beneficii de tip pachete HEETS™ în limita a <b>maxim 10 (zece) pachete de HEETS</b>, indiferent de numărul de puncte acumulate de acesta. În situaţia în care Participantul acumulează în contul său un număr mai mare de puncte, atunci diferenţa de puncte acumulate şi neutilizate încă va rămâne în contul asociat participantului până la expirarea acestora, conform promoţiei „Promoţia: Valabilitate puncte”.</p>
													<p>În situaţia în care Participantul restituie dispozitivul primit împrumut la finalul perioadei de Împrumut sau anterior acesteia şi nu achiziţionează acelaşi sau alt dispozitiv IQOS, atunci accestul în cadrul platformei online IQOS Club va fi înrerupt. Punctele acumulate de Participant rămân în contul asociat participantului până la expirarea acestora, conform promoţiei „Promoţia: Valabilitate puncte”.</p>
													<p>În situaţia în care Participantul achiziţionează un dispozitiv IQOS, în timpul sau la finalul perioadei de testare sau ulterior finalizării perioadei de testare, atunci acesta va putea accesa din nou platforma online IQOS Club, cu toate drepturile Participanţilor conform condiţiilor de participare şi a regulilor de înscriere. Punctele acumulate de acesta în perioada de testare IQOS, neutilizate şi aflate încă în perioada de valabilitate vor fi disponibile în contul Participantului la momentul accesării.</p>
												</div>
											</div>
										</div>
									</div>

									<h3 className="acc_title_bar">
										<a href="#">Categoria promoții: Beneficii</a>
									</h3>
									<div className="acc_container">
										<div className="block">
											<h4 className="acc_title_bar">Promoţia: Beneficii garantate</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														În funcţie de numărul de puncte acumulate şi acţiunile efectuate
														de acesta în contul său, Participantul se va încadra într-unul
														dintre cele 3 Niveluri disponibile în cadrul platformei online:
													</p>

													<ul className="dashLine">
														<li>
															Nivelul 1 - SILVER – este primul nivel de acces al
															participanţilor;
														</li>
														<li>
															Nivelul 2 – GOLD – se atinge în cazul în care participantul
															acumulează în contul său minim 400 (patrusute) puncte;
														</li>
														<li>
															Nivelul 3 - PLATINUM – se atinge în cazul în care
															participantul acumulează în contul său minim 1000 (omie)
															puncte.
														</li>
													</ul>

													<p>
														Prin încadrarea într-unul dintre cele 3 Niveluri disponibile în
														cadrul platformei online, Participantul va acces la interacţiuni
														şi beneficii conform nivelului în care este încadrat la momentul
														respectiv. Acestea vor fi comunicate Participanţilor în cadrul
														platformei online.
													</p>

													<p>
														Beneficiile disponibile fiecărui nivel sunt aduse la cunoştinţă
														Participanţilori în cadrul website-ului destinat prezentei
														Campaniei, în pagina special dedicată acestor informaţii.
													</p>

													<p>
														Participanţii pot revendica doar beneficiile aferente Nivelului
														în care este încadrat, disponibile / active în cadrul
														website-ului destinat prezentei Campanii la momentul
														solicitării. Orice solicitare de beneficiu pentru care
														Participantul nu este eligibil sau pentru un beneficiu care nu
														este disponibil / activ la momentul solicitării nu va fi luată
														în considerare.
													</p>

													<p>
														Beneficiile pe care Organizatorul le poate pune la dispoziţie
														sunt, dar fără a se limita la, următoarele:
													</p>

													<p>
														<b>a.</b> Exclusiv Participanţii încadraţi în Nivelul 2 şi
														Nivelul 3 pot comanda, prin intermediul catalogului online din
														cadrul website-ului destinat prezentei Campanii Promoţionale,
														din voucherele disponbile în cadrul catalogului de produse şi
														accesorii IQOS & Parteneri, acestea fiind de următoarele tipuri:
													</p>

													<ul className="dashLine">
														<li>CinemaCity 3D</li>
														<li>CinemaCity 2D</li>
														<li>MysteryBox</li>
														<li>Vola.ro</li>
														<li>Fashion Days</li>
														<li>Emag</li>
														<li>Douglas</li>
														<li>Smart Experience</li>
													</ul>

													<p>
														Plasarea unei astfel de comenzi se supune regulilor promoţiei,
														Cheltuirea de puncte - Comandarea de produse din catalog. După
														finalizarea cu succes a comenzii, Participantul va primi prin
														comunicare e-mail codurile necesare utilizării voucherului din
														cadrul platformei online a Partenerului. Organizatorul nu îşi
														asumă răspunderea pentru neprimirea sau primirea întârziată a
														emailurilor din cauze care nu depind direct de el, cum ar fi,
														dar nelimitându-se la, defecţiuni tehnice ale furnizorilor de
														internet.
													</p>

													<p>
														Condiţiile de utilizare ale voucherelor comandate sunt cele
														impuse şi utilizate de Parteneri în cadrul platformelor proprii
														ale acestora.
													</p>

													<p>
														Perioada de valabilitate a voucherelor este comunicată
														Participanţilor în comunicarea e-mail prin care acestea sunt
														livrate.
													</p>

													<p>
														Acţiunea de comandare a voucherelor nu este reversibilă
														(utilizatorul nu poate solicita post factum renunţarea la
														transfer) și conduce la scăderea numărului total de puncte
														disponibile din IQOS Club ale participantului cu numărul celor
														selectate pentru transfer în comandarea voucherului selectat de
														catre acesta.
													</p>

													<p>
														În plus, la plasarea unei comenzi din catalogul online care
														conţine exclusiv produse de tip voucher, Participantul nu va
														beneficia de produse tip „cadou” pe care altfel le-ar putea
														primi în cazul în care optează pentru comandarea altor produse,
														a căror livrare se face prin intermediul serviciilor de
														curierat.
													</p>

													<p>
														<b>b.1</b>
														Participanţii încadraţi în Nivelul 1 – Silver vor acumula, după
														livrarea comenzilor plasate online pe website-ul
														<a href="https://www.iqos.ro/" target="_blank">
															www.iqos.ro
														</a>
														, un număr de <b>5 puncte</b> în contul lor din cadrul
														platformei IQOS Club.
													</p>

													<p>
														<b>b.2</b> Exclusiv Participanţii încadraţi în Nivelul 2 – Gold
														şi Nivelul 3 - Platinum vor primi, după livrarea comenzilor
														plasate online pe website-ul{' '}
														<a href="https://www.iqos.ro/" target="_blank">
															www.iqos.ro
														</a>
														şi/sau a comenzilor din catalogul online disponibil în cadrul
														platformei IQOS Club, puncte suplimentare în contul lor astfel:
													</p>

													<ul className="dashLine">
														<li>
															Pentru comandă plasată pe website-ul{' '}
															<a href="https://www.iqos.ro/" target="_blank">
																www.iqos.ro
															</a>
															Participantul va acumula în contul său <b>20 puncte</b>;
														</li>
														<li>
															Pentru comandă plasată din catalogul online disponibil în
															cadrul platformei IQOS Club Participantul va acumula în
															contul său <b>15 puncte</b>.
														</li>
													</ul>

													<p>
														<b>c.</b> Livrare gratuită pentru comenzile de produse cu
														livrare prin curier din catalogul online disponibil în cadrul
														platformei IQOS Club
													</p>

													<p>
														<b>d.</b> Trageri la sorţi speciale doar pentru Participanţii
														încadraţi în Nivelul 2 - Gold şi Nivelul 3 – Platinum.
													</p>

													<p>
														<b>e.</b> Livrare gratuită pentru comenzile de produse cu
														livrare prin curier din catalogul online disponibil în cadrul
														platformei IQOS.ro astfel:
													</p>
													<ul>
														<li>
															Participanții încadrați în Nivelul 1 – Silver vor beneficia
															de livrare gratuită pentru comenzile în valoare de peste 200
															RON;
														</li>
														<li>
															Participanții încadrați în Nivelul 2 – Gold vor beneficia de
															livrare gratuită pentru comenzile în valoare de peste 150
															RON;
														</li>
														<li>
															Participanții încadrați în Nivelul 3 – Platinum vor
															beneficia de livrare gratuită pentru comenzile în valoare de
															peste 100 RON.
														</li>
													</ul>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Tu şi IQOS - Provocarea lunii iulie
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Promoţia se desfăşoară în cadrul platformei online Clubul IQOS
														în perioada{' '}
														<b>01 iulie 2020 ora 00:00:00 – 31 iulie 2020 ora 23:59:59</b>.
													</p>
													<p>
														La prezenta promoţie pot participa utilizatorii cu cont activ în
														cadrul platformei online IQOS Club care întrunesc cumulativ
														criteriile de eligibilitate menţionate în cadrul prezentului
														regulament. La prezenta promoţie nu pot participa angajații
														societăţilor Philip Morris Trading S.R.L., Philip Morris România
														S.R.L. sau angajaţii companiilor organizatoare implicate în
														desfăşurarea acestei promoţii şi nici angajaţii celorlalte
														entităţi legale implicate în această acţiune (inclusiv
														reprezentanţii Organizatorului din cadrul acestei promoţii),
														precum şi niciunul dintre membrii familiilor acestora (copii,
														părinţi, soţ/soţie, frate/soră). Prin implicare în organizarea
														promoţiei se înţelege derularea activităţilor care au legătură
														cu modalitatea de acordare a materialelor, a premiilor,
														algoritmi, procese de selecţie şi orice alte acţiuni care
														influenţează sau pot influenţa determinarea câştigătorilor
														promoţiei. Din dorinţa de a nu prejudicia niciun participant
														care doreşte să participe la promoţie, Organizatorul îşi rezervă
														dreptul să poată efectua verificări în ceea ce priveşte corecta
														participare. O persoană poate deține un singur cont individual
														pe platforma online club.iqos.ro. Orice încercare de duplicare a
														unui cont sau de înregistrare a unei persoane cu date
														modificate/fictive/cu alte conturi (același nume, prenume,
														adresă de livrare, număr de telefon), va atrage după sine
														ștergerea conturilor deschise ulterior contului inițial, iar în
														caz de repetare a acțiunii, se va putea trece inclusiv la
														dezactivarea contului inițial de utilizator. În cazul în care,
														în urma verificărilor desfăşurate de către Organizator, rezultă
														că procesul de participare a fost fraudat, Organizatorul îşi
														rezervă dreptul de a retrage participanţilor beneficiile
														obţinute ca rezultat al activităţii lor şi / sau să
														restricţioneze participarea la promoţie. În cazul în care sunt
														identificate persoane care au influenţat sau au facilitat
														câştigarea de beneficii necuvenite, Organizatorul are dreptul de
														a cere urmărirea în instanţă a respectivelor persoane, pe baza
														dovezilor existente. Organizatorul îşi rezervă dreptul de a
														exclude participanţi din promoţie cu sau fără indicarea
														motivului. Acest lucru se aplică mai ales participanţilor care
														îşi manipulează sau încearcă să îşi manipuleze participarea (în
														principal prin crearea de conturi multiple sau încercând să
														obţină sau să creeze şanse suplimentare de a câştiga în mod
														incorect) sau care încalcă condiţiile specifice promoţiei sau
														care, într-un mod sau altul, utilizează abuziv promoţia propusă.
													</p>
													<p>
														Participanţii care accesează platforma online IQOS Club pot
														accesa secţiunea special dedicată acestei promoţii, unde vor
														putea încărca conţinut sub formă de fişier video. Pentru
														înscrierea în cadrul prezentei promoţii vor fi luate în
														considerare exclusiv fişierele video încărcate de participanţi
														pe perioada de desfăşurare a promoţiei. Nu vor fi luate în
														considerare fişierele video încărcate de participanţi anterior
														începerii prezentei promoţii şi nici cele încărcate de
														participanţi după termenul de finalizare al prezentei promoţii.
													</p>
													<p>
														Fişierul video pe care utilizatorul doreşte să îl încarce în
														cadrul platformei online IQOS Club trebuie să întrunească
														cumulativ următoarele condiţii de eligibilitate:
													</p>
													<ul className="dashLine">
														<li>dimensiunea fişierului să nu depăşească 2 GB;</li>
														<li>
															sunt acceptate doar fişiere care conţin vizualuri,
															informaţii şi detalii care sunt inspirate sau inspiră
															persoanei privitoare legătură cu tematica lunii, aceasta
															fiind „Spune-ne ce îţi place la IQOS”. În cadrul
															videoclipului trebuie să se regăsească cel puţin un
															dispozitiv IQOS sau parte componentă a acestuia;
														</li>
														<li>
															nu sunt acceptate fişiere video care conţin, dar fără a se
															limita la: limbaj ofensator sau violent, comentarii
															jignitoare sau discriminatorii privind rasa, etnia, religia,
															sexul, dizabilitatea, orientarea sexuală sau convingerile
															politice, link-uri sau comentarii care conțin materiale de
															conținut sexual explicit, atacuri asupra unor grupuri
															specifice sau orice comentarii menite să hărțuiască, să
															amenințe sau să abuzeze un individ, spam, link-uri externe
															sau fișiere care conțin viruși care ar putea deteriora
															funcționarea computerelor sau dispozitivelor mobile ale
															altor persoane, solicitări comerciale sau promovarea unui
															concurent;
														</li>
														<li>
															Participantul care încarcă un fişier video este direct
															responsabil şi răspunzător privind încălcarea dreptului de
															autor sau a drepturilor de proprietate intelectuală.
														</li>
													</ul>

													<p>
														Un participant poate încărca oricât de multe fişiere video, cu
														respectarea condiţiilor de eligibilitate menţionate anterior.
													</p>
													<p>
														Ulterior încărcării fişierului/fişierelor video de către
														Participanţi, toate acestea vor fi supuse unui proces de
														validare de către Organizator. Doar fişierele video aprobate de
														Organizator vor fi publicate în cadrul platformei online IQOS
														Club, în cadrul secţiunii special dedicate prezentei Promoţii.
														Participanţii înţeleg şi acceptă că Organizatorul va putea, la
														discreţia sa, să respingă fişiere video dintre cele încărcate de
														Participanţi pe perioada de desfăşurare a Promoţiei, deşi
														respectă tematica lunii. În mod nelimitativ, Organizatorul îşi
														rezervă dreptul de nu a aproba şi a nu publica în cadrul
														platformei online IQOS Club fişiere video care pot fi
														considerate, fără a se limita la, inconsistente, incoerente.
													</p>
													<p>
														Ulterior afişării în cadrul platformei online IQOS Club, în
														cadrul secţiunii special dedicate prezentei Promoţii,
														utilizatorii prezentei platforme online vor putea acorda
														aprecieri fişierelor video propuse de ceilalţi Participanţi,
														aprobate de Organizator şi publicate de acesta din urmă în
														cadrul website-ului.
													</p>
													<p>
														Un utilizator, membru în cadrul IQOS Club, poate aprecia un
														fişier video o singură dată.
														<br />
														Un utilizator, membru în cadrul IQOS Club, poate aprecia oricât
														de multe dintre fişierele video prezente în cadrul secţiunii
														special dedicate prezentei Promoţii.
														<br />
														Un utilizator, membru în cadrul IQOS Club, poate aprecia
														fişierele video propuse de alţi Participanţi şi aprobate de
														Organizator, precum şi propriile fişiere video propuse şi
														aprobate de Organizator.
														<br />
														Un utilizator, membru în cadrul IQOS Club, poate aprecia un
														fişier video propus de un participant şi aprobat de Organizator
														doar pe perioada de desfăşurare a prezentei Promoţii.
													</p>
													<p>
														După finalizarea perioadei de desfăşurare a prezentei Promoţii
														se va întocmi un clasament al fişierelor video încărcate de
														Participanţi în cadrul secţiunii special dedicate din cadrul
														platformei online şi care au acumulat aprecieri din partea
														utilizatorilor platformei IQOS Club.
													</p>
													<p>
														Clasamentul se va realiza în maxim 5 zile lucrătoare de la data
														finalizării Promoţiei şi va fi realizat în funcţie de numărul de
														aprecieri acumulate de fişierele video încărcate de Participanţi
														în perioada de desfăşurare a Promoţiei, de la momentul
														încărcării acestora şi publicate de Organizator în cadrul
														platformei web până la încetarea Promoţiei, adică anterior datei
														de 31 iulie 2020 (inclusiv).
													</p>
													<p>
														Primele 10 (zece) fişiere video din clasament determinate
														conform regulilor descrise anterior vor fi asociate
														Participanţilor care au încărcat textele respective în cadrul
														platformei web.
													</p>
													<p>
														În situaţia în care pe ultima poziţie vor fi mai multe fişiere
														video cu acelaşi număr de aprecieri, atunci departajarea se va
														face prin cumularea aprecierilor tuturor fişierelor video
														încărcate pe perioada de desfăşurare a prezentei Promoţii de
														către Participanţii aflaţi la egalitate.
													</p>
													<p>
														În situaţia în care, în urma procesului de departajare menţionat
														anterior, nu se va putea diferenţia explicit un număr de 10
														(zece) Participanţi unici, atunci se va lua în considerare
														primul fişier video încărcat, în ordine cronologică, de către
														Participanţi, dintre cele care au determinat selectarea
														Participantului respectiv.{' '}
													</p>
													<p>
														În cazul în care un Participant deţine mai multe de 1 (un)
														fişier video în clasament, atunci acesta va fi eligibil pentru a
														primi din partea Organizatorului 1 singur beneficiu acordat prin
														intermediul prezentei Promoţii. În acestă situaţie vor fi luate
														în considerare următoarele fişiere video din clasamentul
														realizat anterior, iar acţiunea poate fi reluată până la
														identificarea a 10 (zece) Participanţi unici.
													</p>
													<p>
														Un Participant poate primi din partea Organizatorului un singur
														beneficiu de acest tip, indiferent de numărul de fişiere video
														încărcate pe perioada Promoţiei şi indiferent dacă aceeaşi
														persoană deţine mai multe conturi de utilizator în cadrul
														platformei online IQOS Club.{' '}
													</p>
													<p>
														Primii <b>10 (zece)</b> Participanţi determinaţi conform
														regulilor descrise anterior vor primi gratuit, din partea
														Organizatorului, <b>1 dispozitiv IQOS 3 DUO TEAL EDITION</b>.
														Valoarea totală estimată a beneficiilor acordate în cadrul
														prezentei promoţii este de 4.490 RON (TVA inclus).
													</p>
													<p>
														Participanţii eligibili pentru a primit gratuit, din partea
														Organizatorului, beneficiul constând în{' '}
														<b>dispozitiv IQOS 3 DUO TEAL EDITION</b> vor fi contactaţi
														telefonic pentru validare la numărul de telefon completat şi
														confirmat de participant la crearea contului în cadrul platfomei
														online IQOS Club, într-un interval de maxim 5 zile lucrătoare de
														la data la care aceştia au devenit eligibili pentru a primit
														acest tip de beneficiu.
													</p>
													<p>
														Participanţii vor fi contactaţi de trei ori, în trei zile
														diferite, la intervale orare diferite, pentru a se realiza
														următoarele: identificare, validare, comunicarea detaliilor cu
														privire la livrarea premiului.
													</p>
													<p>
														Identificarea Participanţilor se va realiza pe baza datelor cu
														care acesta s-a înscris în cadrul platfomei online IQOS Club şi
														a celor comunicate în momentul validării telefonice (nume,
														prenume, data de naştere, adresă e-mail).{' '}
													</p>
													<p>
														În situaţia în care, la momentul validării telefonice,
														Participantul declară că refuză să accepte beneficiul acordat,
														sau nu întrunește toate condiţiile necesare validării sale, în
														conformitate cu prezentul Regulament, acesta va fi invalidat şi
														decăzut din drepturi.
													</p>
													<p>
														În cazul în care nu se reuşeşte validarea Participantului din
														motive independente de Organizator şi acesta nu apelează Centrul
														de Asistenţă Clienţi în termen de 15 zile lucrătoare de la data
														finalizării Promoţiei, acesta va fi decăzut din drepturi.
													</p>
													<p>
														În cazul în care nu se reuşeşte validarea unui Participant, din
														motive independente de Organizator, se va relua procesul de
														identificare a Participanţilor aflaţi în afara primelor 30
														poziţii determinate iniţial, urmându-se acelaşi procedeu ca cel
														descris anterior.
													</p>
													<p>
														La finalul apelului de validare telefonică, Participantului îi
														vor fi comunicate toate detaliile referitoare la beneficiul
														acordat şi modalitatea de livrare a acestuia.
													</p>
													<p>
														Beneficiile constând în{' '}
														<b>dispozitiv IQOS 3 DUO TEAL EDITION</b> vor fi livrate
														Participanţilor validaţi prin intermediul serviciilor de
														curierat. În faza recepţionării beneficiului, Participantul
														trebuie să prezinte în original un document de identitate
														(CI/BI). În situaţia în care, la livrare, datele din CI/BI nu
														corespund cu cele declarate în procesul de validare, acesta va
														fi invalidat şi decăzut din drepturi.
													</p>
													<p>
														Toţi Participanţii vor completa un proces verbal de
														predare-primire a beneficiului. Acesta va fi înmânat
														reprezentantului Organizatorului şi va fi folosit pentru
														validare.
													</p>
													<p>
														Se va încerca o singură dată livrarea coletului conţinând
														beneficiul pentru care Participantul a fost validat la adresa şi
														în intervalul orar menţionat de către Participant la validarea
														telefonică. O nouă încercare de livrare se va face în momentul
														în care Participantul a sunat la Centrul de Asistenţă Clienţi şi
														a menţionat detaliile referitoare la data, ora şi adresa la care
														acesta poate fi contactat pentru livrare. Curierul nu are
														obligaţia de a oferi informaţii în legătură cu prezenta
														Promoţie. Pentru detalii în acest sens, participanţii trebuie să
														apeleze Centrul de Asistenţă Clienţi, conform Secţiunii 19.
														„Centrul de asistenţă clienţi”.
													</p>
													<p>
														În situaţia în care Participanţii nu au putut fi contactaţi
														pentru livrarea beneficiului validat, din cauze independente de
														Organizator şi nu au apelat Centrul de Asistenţă Clienţi,
														revendicând beneficiul nelivrat, până la data de{' '}
														<b>15 septembrie 2020</b>, aceştia vor fi decăzuţi din dreptul
														de a-l mai revendica.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Tu şi IQOS - Provocarea lunii iunie
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Promoţia se desfăşoară în cadrul platformei online Clubul IQOS
														în perioada{' '}
														<b>02 iunie 2020 ora 00:00:00 – 30 iunie 2020 ora 23:59:59</b>.
													</p>
													<p>
														La prezenta promoţie pot participa toţi utilizatorii cu cont
														activ în cadrul platformei online IQOS Club. La prezenta
														promoţie nu pot participa angajații societăţilor Philip Morris
														Trading S.R.L., Philip Morris România S.R.L. sau angajaţii
														companiilor organizatoare implicate în desfăşurarea acestei
														promoţii şi nici angajaţii celorlalte entităţi legale implicate
														în această acţiune (inclusiv reprezentanţii Organizatorului din
														cadrul acestei promoţii), precum şi niciunul dintre membrii
														familiilor acestora (copii, părinţi, soţ/soţie, frate/soră).
														Prin implicare în organizarea promoţiei se înţelege derularea
														activităţilor care au legătură cu modalitatea de acordare a
														materialelor, a premiilor, algoritmi, procese de selecţie şi
														orice alte acţiuni care influenţează sau pot influenţa
														determinarea câştigătorilor promoţiei. Din dorinţa de a nu
														prejudicia niciun participant care doreşte să participe la
														promoţie, Organizatorul îşi rezervă dreptul să poată efectua
														verificări în ceea ce priveşte corecta participare. O persoană
														poate deține un singur cont individual pe platforma online
														club.iqos.ro. Orice încercare de duplicare a unui cont sau de
														înregistrare a unei persoane cu date modificate/fictive/cu alte
														conturi (același nume, prenume, adresă de livrare, număr de
														telefon), va atrage după sine ștergerea conturilor deschise
														ulterior contului inițial, iar în caz de repetare a acțiunii, se
														va putea trece inclusiv la dezactivarea contului inițial de
														utilizator. În cazul în care, în urma verificărilor desfăşurate
														de către Organizator, rezultă că procesul de participare a fost
														fraudat, Organizatorul îşi rezervă dreptul de a retrage
														participanţilor beneficiile obţinute ca rezultat al activităţii
														lor şi / sau să restricţioneze participarea la promoţie. În
														cazul în care sunt identificate persoane care au influenţat sau
														au facilitat câştigarea de beneficii necuvenite, Organizatorul
														are dreptul de a cere urmărirea în instanţă a respectivelor
														persoane, pe baza dovezilor existente. Organizatorul îşi rezervă
														dreptul de a exclude participanţi din promoţie cu sau fără
														indicarea motivului. Acest lucru se aplică mai ales
														participanţilor care îşi manipulează sau încearcă să îşi
														manipuleze participarea (în principal prin crearea de conturi
														multiple sau încercând să obţină sau să creeze şanse
														suplimentare de a câştiga în mod incorect) sau care încalcă
														condiţiile specifice promoţiei sau care, într-un mod sau altul,
														utilizează abuziv promoţia propusă.
													</p>
													<p>
														Participanţii eligibili conform termenilor prezentei promoţii,
														care accesează platforma online IQOS Club pot accesa secţiunea
														special dedicată acestei promoţii, unde vor putea încărca
														conţinut sub formă de text. Pentru înscrierea în cadrul
														prezentei promoţii vor fi luate în considerare exclusiv textele
														încărcate de participanţi pe perioada de desfăşurare a
														promoţiei. Nu vor fi luate în considerare textele încărcate de
														participanţi anterior începerii prezentei promoţii şi nici cele
														încărcate de participanţi după termenul de finalizare al
														prezentei promoţii.
													</p>
													<p>
														Textul pe care utilizatorul doreşte să îl încarce în cadrul
														platformei online IQOS Club trebuie să întrunească cumulativ
														următoarele condiţii de eligibilitate:
													</p>
													<ul className="dashLine">
														<li>
															sunt acceptate doar textele care sunt inspirate sau inspiră
															persoanei privitoare/cititoare legătură cu tematica lunii,
															aceasta fiind „Capsula timpului cu IQOS”;
														</li>
														<li>
															nu sunt acceptate texte care contravin termenilor şi
															condiţiilor referitoare la conţinut generat de utilizatori
															(„CGU”), cum ar fi, dar fără a se limita la: limbaj
															ofensator sau violent, comentarii jignitoare sau
															discriminatorii privind rasa, etnia, religia, sexul,
															dizabilitatea, orientarea sexuală sau convingerile politice,
															link-uri sau comentarii care conțin materiale de conținut
															sexual explicit, atacuri asupra unor grupuri specifice sau
															orice comentarii menite să hărțuiască, să amenințe sau să
															abuzeze un individ, spam, link-uri externe sau fișiere care
															conțin viruși care ar putea deteriora funcționarea
															computerelor sau dispozitivelor mobile ale altor persoane,
															solicitări comerciale sau promovarea unui concurent;
														</li>
														<li>
															Participantul care încarcă un text este direct responsabil
															şi răspunzător privind încălcarea dreptului de autor sau a
															drepturilor de proprietate intelectuală
														</li>
													</ul>
													<p>
														Un participant poate încărca oricât de multe texte, cu
														respectarea condiţiilor de eligibilitate menţionate anterior.
													</p>
													<p>
														Ulterior încărcării textului/textelor de către Participanţi,
														toate acestea vor fi supuse unui proces de validare de către
														Organizator. Doar textele aprobate de Organizator vor fi
														publicate în cadrul platformei online IQOS Club, în cadrul
														secţiunii special dedicate prezentei Promoţii. Participanţii
														înţeleg şi acceptă că Organizatorul va putea, la discreţia sa,
														să respingă texte dintre cele încărcate de Participanţi pe
														perioada de desfăşurare a Promoţiei, deşi respectă tematica
														lunii. În mod nelimitativ, Organizatorul îşi rezervă dreptul de
														nu a aproba şi a nu publica în cadrul platformei online IQOS
														Club texte care pot fi considerate, fără a se limita la,
														inconsistente, incoerente.
													</p>
													<p>
														Ulterior afişării în cadrul platformei online IQOS Club, în
														cadrul secţiunii special dedicate prezentei Promoţii,
														utilizatorii prezentei platforme online vor putea acorda
														aprecieri textelor propuse de ceilalţi Participanţi, aprobate de
														Organizator şi publicate de acesta din urmă în cadrul
														website-ului.
													</p>
													<p>
														Un utilizator, membru în cadrul IQOS Club, poate aprecia un text
														o singură dată.
														<br />
														Un utilizator, membru în cadrul IQOS Club, poate aprecia oricât
														de multe dintre textele prezente în cadrul secţiunii special
														dedicate prezentei Promoţii.
														<br />
														Un utilizator, membru în cadrul IQOS Club, poate aprecia textele
														propuse de alţi Participanţi şi aprobate de Organizator, precum
														şi propriile texte propuse şi aprobate de Organizator.
														<br />
														Un utilizator, membru în cadrul IQOS Club, poate aprecia un text
														propus de un participant şi aprobată de Organizator doar pe
														perioada de desfăşurare a prezentei Promoţii.
													</p>
													<p>
														După finalizarea perioadei de desfăşurare a prezentei Promoţii
														se va întocmi un clasament al textelor încărcate de Participanţi
														în cadrul secţiunii special dedicate din cadrul platformei
														online şi care au acumulat aprecieri din partea utilizatorilor
														platformei IQOS Club.
													</p>
													<p>
														Clasamentul se va realiza în maxim 5 zile lucrătoare de la data
														finalizării Promoţiei şi va fi realizat în funcţie de numărul de
														aprecieri acumulate de textele încărcate de Participanţi în
														perioada de desfăşurare a Promoţiei, de la momentul încărcării
														acestora şi publicate de Organizator în cadrul platformei web
														până la încetarea Promoţiei, adică anterior datei de 30 iunie
														2020 (inclusiv).
													</p>
													<p>
														Primele 30 (treizeci) texte din clasament determinate conform
														regulilor descrise anterior vor fi asociate Participanţilor care
														au încărcat textele respective în cadrul platformei web.
													</p>
													<p>
														În situaţia în care pe ultima poziţie vor fi mai multe texte cu
														acelaşi număr de aprecieri, atunci departajarea se va face prin
														cumularea aprecierilor tuturor textelor încărcate pe perioada de
														desfăşurare a prezentei Promoţii de către Participanţii aflaţi
														la egalitate.
													</p>
													<p>
														În situaţia în care, în urma procesului de departajare menţionat
														anterior, nu se va putea diferenţia explicit un număr de 30
														(treizeci) Participanţi unici, atunci se va lua în considerare
														primul text încărcat, în ordine cronologică, de către
														Participanţi, dintre cele care au determinat selectarea
														Participantului respectiv.
													</p>
													<p>
														În cazul în care un Participant deţine mai multe de 1 (un) text
														în clasament, atunci acesta va fi eligibil pentru a primi din
														partea Organizatorului 1 singur beneficiu acordat prin
														intermediul prezentei Promoţii. În acestă situaţie vor fi luate
														în considerare următoarele texte din clasamentul realizat
														anterior, iar acţiunea poate fi reluată până la identificarea a
														30 (treizeci) Participanţi unici.
													</p>
													<p>
														Un Participant poate primi din partea Organizatorului un singur
														beneficiu de acest tip, indiferent de numărul de texte încărcate
														pe perioada Promoţiei şi indiferent dacă aceeaşi persoană deţine
														mai multe conturi de utilizator în cadrul platformei online IQOS
														Club.
													</p>
													<p>
														Primii <b>30 (treizeci)</b> Participanţi determinaţi conform
														regulilor descrise anterior vor primi gratuit, din partea
														Organizatorului, <b>1 dispozitiv IQOS 3 DUO Warm Copper</b>.
														<br />
														Valoarea totală estimată a beneficiilor acordate în cadrul
														prezentei promoţii este de 13.470 RON (TVA inclus).
													</p>
													<p>
														Participanţii eligibili pentru a primit gratuit, din partea
														Organizatorului, beneficiul constând în dispozitiv IQOS 3 DUO
														Warm Copper vor fi contactaţi telefonic pentru validare la
														numărul de telefon completat şi confirmat de participant la
														crearea contului în cadrul platfomei online IQOS Club, într-un
														interval de maxim 5 zile lucrătoare de la data la care aceştia
														au devenit eligibili pentru a primit acest tip de beneficiu.
													</p>
													<p>
														Participanţii vor fi contactaţi de trei ori, în trei zile
														diferite, la intervale orare diferite, pentru a se realiza
														următoarele: identificare, validare, comunicarea detaliilor cu
														privire la livrarea premiului.
													</p>
													<p>
														Identificarea Participanţilor se va realiza pe baza datelor cu
														care acesta s-a înscris în cadrul platfomei online IQOS Club şi
														a celor comunicate în momentul validării telefonice (nume,
														prenume, data de naştere, adresă e-mail).
													</p>
													<p>
														În situaţia în care, la momentul validării telefonice,
														Participantul declară că refuză să accepte beneficiul acordat,
														sau nu întrunește toate condiţiile necesare validării sale, în
														conformitate cu prezentul Regulament, acesta va fi invalidat şi
														decăzut din drepturi.
													</p>
													<p>
														În cazul în care nu se reuşeşte validarea Participantului din
														motive independente de Organizator şi acesta nu apelează Centrul
														de Asistenţă Clienţi în termen de 15 zile lucrătoare de la data
														finalizării Promoţiei, acesta va fi decăzut din drepturi.
													</p>
													<p>
														În cazul în care nu se reuşeşte validarea unui Participant, din
														motive independente de Organizator, se va relua procesul de
														identificare a Participanţilor aflaţi în afara primelor 30
														poziţii determinate iniţial, urmându-se acelaşi procedeu ca cel
														descris anterior.
													</p>
													<p>
														La finalul apelului de validare telefonică, Participantului îi
														vor fi comunicate toate detaliile referitoare la beneficiul
														acordat şi modalitatea de livrare a acestuia.
													</p>
													<p>
														Beneficiile constând în <b>dispozitiv IQOS 3 DUO Warm Copper</b>{' '}
														vor fi livrate Participanţilor validaţi prin intermediul
														serviciilor de curierat. În faza recepţionării beneficiului,
														Participantul trebuie să prezinte în original un document de
														identitate (CI/BI). În situaţia în care, la livrare, datele din
														CI/BI nu corespund cu cele declarate în procesul de validare,
														acesta va fi invalidat şi decăzut din drepturi.
													</p>
													<p>
														Toţi Participanţii vor completa un proces verbal de
														predare-primire a beneficiului. Acesta va fi înmânat
														reprezentantului Organizatorului şi va fi folosit pentru
														validare.
													</p>
													<p>
														Se va încerca o singură dată livrarea coletului conţinând
														beneficiul pentru care Participantul a fost validat la adresa şi
														în intervalul orar menţionat de către Participant la validarea
														telefonică. O nouă încercare de livrare se va face în momentul
														în care Participantul a sunat la Centrul de Asistenţă Clienţi şi
														a menţionat detaliile referitoare la data, ora şi adresa la care
														acesta poate fi contactat pentru livrare.
														<br />
														Curierul nu are obligaţia de a oferi informaţii în legătură cu
														prezenta Promoţie. Pentru detalii în acest sens, participanţii
														trebuie să apeleze Centrul de Asistenţă Clienţi, conform
														Secţiunii 19. „Centrul de asistenţă clienţi”.
														<br />
														În situaţia în care Participanţii nu au putut fi contactaţi
														pentru livrarea beneficiului validat, din cauze independente de
														Organizator şi nu au apelat Centrul de Asistenţă Clienţi,
														revendicând beneficiul nelivrat, până la data de{' '}
														<b>15 august 2020</b>, aceştia vor fi decăzuţi din dreptul de
														a-l mai revendica.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Începe vara cu o nouă versiune HEETS
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Pe perioada <b>27 mai 2020 – 30 iunie 2019 23:59:59</b>{' '}
														(inclusiv) participanţii eligibili, utilizatori ai platformei
														online IQOS Club încadraţi în Nivelul 3 – Platinum, vor putea
														accesa secţiunea din cadrul website-ului destinată interacţiunii
														„Începe vara cu o nouă versiune de HEETS”. Utilizatorii
														platformei online IQOS Club încadraţi în Nivelul 1 - Silver şi
														Nivelul 2 – Gold nu vor putea accesa secţiunea dedicată
														prezentei Promoţii şi nu vor putea Participa la aceasta.
													</p>
													<p>
														Pe perioada de desfăşurare a prezentei Promoţii, Participanţii
														eligibili, vor putea accesa secţiunea din cadrul platformei
														online special dedicată, unde vor completa un formular de
														revendicare beneficiu cu informaţiile necesare livrării:
														localitate, judeţ, detalii adresă – stradă, număr, bloc, etaj,
														apartament, observaţii.
														<br />
														Un participant eligibil poate revendica acest beneficiu o
														singură dată, doar pe perioada de desfăşurare a prezentei
														Promoţii. Ulterior finalizării promoţiei, beneficiul nu va mai
														putea fi revendicat, indiferent dacă participantul era sau
														devine între timp eligibil pentru participare.
													</p>
													<p>
														Beneficiul care se acordă în urma completării cu succes a
														formularului conform detaliilor menţionate anterior constă
														într-un kit având în componenţă 1 (un) pachet HEETS din noua
														versiune Sienna Caps.
													</p>
													<p>
														Identificarea Participanţilor se va realiza pe baza datelor cu
														care acesta s-a înscris în cadrul platfomei online IQOS Club şi
														a celor comunicate în momentul revendicării beneficiului în
														cadrul interacţiunii dedicate prezentei Promoţii (nume, prenume,
														telefon, adresa de livrare).
													</p>
													<p>
														Beneficiile constând în kit conţinând 1 (un) pachet de HEETS
														Sienna Caps, vor fi livrate acestora prin intermediul
														serviciilor de curierat. Coletul va fi expediat către
														Participantul identificat după datele cu care acesta este
														înscris în cadrul platformei online IQOS Club (nume, prenume,
														telefon), la adresa declarată de Participant în momentul
														completării formularului din cadrul prezentei Promoţii.
														Expedierea coletului se va face în termen de 5 (cinci) zile
														lucrătoare de la data completării cu succes a formularului
														destinat prezentei Promoţii din cadrul platformei online IQOS
														Club.
													</p>
													<p>
														În situaţia în care adresa de livrare completată de Participant
														în formularul de revendicare beneficiu nu conţine toate
														detaliile necesare livrării sau este o adresă din afara
														teritoriului României, atunci participarea nu va fi considerată
														validă, Participantul va fi decăzut din drepturi, iar coletul nu
														va fi expediat.
													</p>
													<p>
														În faza recepţionării beneficiului, Participantul trebuie să
														prezinte în original un document de identitate (CI/BI). În
														situaţia în care, la livrare, datele din CI/BI (nume, prenume)
														nu corespund cu cele cu care acesta este înscris în cadrul
														platformei online IQOS Club, acesta va fi invalidat şi decăzut
														din drepturi.
													</p>
													<p>
														Se va încerca o singură dată livrarea coletului conţinând
														beneficiul pentru care Participantul a fost validat la adresa
														menţionată de Participant în momentul completării formularului
														din cadrul platformei online IQOS Club. O nouă încercare de
														livrare se va face în momentul în care Participantul a sunat la
														Centrul de Asistenţă Clienţi şi a menţionat detaliile
														referitoare la data, ora şi adresa la care acesta poate fi
														contactat pentru livrare.
													</p>
													<p>
														Curierul nu are obligaţia de a oferi informaţii în legătură cu
														prezenta Promoţie. Pentru detalii în acest sens, participanţii
														trebuie să apeleze Centrul de Asistenţă Clienţi, conform
														Secţiunii 19. „Centrul de asistenţă clienţi”.
													</p>
													<p>
														În situaţia în care Participanţii nu au putut fi contactaţi
														pentru livrarea beneficiului validat, din cauze independente de
														Organizator şi nu au apelat Centrul de Asistenţă Clienţi,
														revendicând beneficiul nelivrat, până la data de{' '}
														<b>30 iulie 2020</b>, aceştia vor fi decăzuţi din dreptul de a-l
														mai revendica.
													</p>
													<p>*Promoția este valabilă în limita stocului disponibil.</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promotia: Noul HEETS Sienna Caps vine cu oferte speciale
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														<b>
															Promoţia: Puncte duble pentru codurile din pachetele de
															HEETS Sienna Caps
														</b>
													</p>
													<p>
														În secţiunea special dedicată înscrierii de coduri,
														Participanţii pot înscrie coduri unice alfanumerice alcătuite
														din 9 caractere alfanumerice, tipărite în interiorul capacelor
														pachetelor de HEETS, conform detaliilor prevăzute în
														regulamentul promoţiei „Înscrierea de coduri din pachetele de
														HEETS”.
													</p>
													<p>
														În perioada <b>04 iunie 2020 – 30 iunie 2020</b> (inclusiv),
														prin înscrierea unui cod unic valid tipărit în interiorul
														capacelor pachetelor de HEETS Sienna Caps, Participantul va
														acumula în contul său <b>2 puncte</b>, reprezentând dublul
														punctelor acordate conform mecanismului promoţiei „Înscrierea de
														coduri din pachetele de HEETS”.
													</p>
													<p>
														Prezenta promoţie se aplică exclusiv pentru codurile tipărite în
														interiorul pachetele de HEETS Sienna Caps şi înscrise în
														secţiunea special dedicată doar în perioada de desfăşurare a
														prezentei promoţii, aşa cum aceasta a fost menţionată anterior.
													</p>
													<p>
														Ulterior finalizării perioadei de desfăşurare a prezentei
														promoţii, participanţii care înscriu coduri din pachetele de
														HEETS Sienna Caps vor acumula în contul lor puncte conform
														prevederilor regulamentului promoţiei „Înscrierea de coduri din
														pachetele de HEETS”.
													</p>

													<p>
														<b>Promoţia: Cadou pachet HEETS Sienna Caps </b>
													</p>
													<p>
														Utilizatorii care vor comanda produse conform regulamentului
														promoţiei „Comandarea de produse din catalog” în perioada{' '}
														<b>04 iunie 2020 – 30 iunie 2020</b> (inclusiv) vor primi
														gratuit, din partea Organizatorului, un cadou constând într-un
														pachet de HEETS Sienna Caps.
													</p>
													<p>
														Beneficiul se acordă doar pentru comenzile de produse cu livrare
														prin curier, dintre cele disponibile în cadrul catalogului pus
														la dispoziţie de Organizator în cadrul platformei online IQOS
														Club.
													</p>
													<p>
														Beneficiul se acordă unui (aceluiaşi) utilizator o singură dată,
														pe toată perioada de desfăşurare a prezentei promoţii,
														indiferent de numărul de comenzi plasate de acesta în cadrul
														platformei IQOS Club în perioada de referinţă.
													</p>
													<p>
														Produsele din categoria „Parteneri” nu fac obiectul prezentei
														promoţii, iar prin plasarea unei comenzi conţinând produse
														exclusiv din aceasta categorie utilizatorii nu vor beneficia de
														cadoul oferit de Organizator conform prezentei Promoţii.
													</p>
													<p>
														Cadoul constând într-un pachet de HEETS Sienna Caps va fi livrat
														participanţilor prin intermediul serviciilor de curierat şi va
														fi inclus în coletul conţinând produsele comandate din catalogul
														disponibil în cadrul platfofmei online IQOS Club.
													</p>

													<p>
														<b>
															Promoţia: Puncte bonus la comenzi care conţin HEETS Sienna
															Caps
														</b>
													</p>
													<p>Utilizatorii care întrunesc cumulativ următoarele condiţii:</p>
													<ul className="dashLine">
														<li>
															vor plasa comenzi online în cadrul website-ului{' '}
															<a href="https://www.iqos.ro/">www.iqos.ro</a> în perioada{' '}
															<b>04 iunie 2020 – 30 iunie 2020</b>
															(inclusiv).
														</li>
														<li>
															comanda include minim 1 cartuş (10 pachete) din versiunea
															HEETS Sienna Caps vor acumula în contul lor un număr
															suplimentar de <b>5 puncte</b> în cadrul platformei IQOS
															Club.
														</li>
													</ul>
													<p>
														Punctele se vor adăuga automat în contul utilizatorilor după
														înregistrarea confirmării livrării cu succes a coletului
														conţinând produsele comandate de utilizator.
													</p>
													<p>
														Beneficiul acordat conform termenilor prezentei promoţii se
														cumulează beneficiilor de acelaşi tip acordate de Organizator,
														conform regulamentului promoţiei „Beneficii garantate”.
													</p>

													<p>
														<b>Promoţia: Articol detalii HEETS Sienna Caps</b>
													</p>
													<p>
														În cadrul platformei online IQOS Club, secţiunea “Descoperă
														IQOS”, va fi disponibil un articol conţinând informaţii despre
														noua versiune de HEETS Sienna Caps.
													</p>
													<p>
														Pe perioada <b>04 iunie 2020 – 30 iunie 2020</b> (inclusiv)
														utilizatorii care vor parcurge până la final articolul referitor
														la noua de versiune de HEETS Sienna Caps vor primi automat un
														beneficiu.
													</p>
													<p>
														Beneficiul acordat este sub formă de <b>5 puncte</b> care se
														acumulează automat în contul utilizatorului după parcurgerea cu
														succes până la final a articolului menţionat anterior în cadrul
														prezentului regulament.
													</p>
													<p>
														Un utilizator va primi acest tip de beneficiu o singură dată, pe
														perioada de desfăşurare a prezentei promoţii, indiferent de
														numărul de vizualizări integrale ale articolului referitor la
														HEETS Sienna Caps.
													</p>
													<p>
														Un utilizator va primi acest tip de beneficiu doar pe perioada
														de desfăşurare a prezentei promoţii. În situaţia în care
														utilizatorul vizualizează articolul ulterior finalizării
														promoţiei, beneficiul nu va mai fi acordat.
													</p>
												</div>
											</div>
										</div>
									</div>

									<h3 className="acc_title_bar">
										<a href="#">Categoria promoții: Valorificare puncte</a>
									</h3>
									<div className="acc_container">
										<div className="block">
											<h4 className="acc_title_bar">
												Promoţia: Comandarea de produse din catalog
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														În cadrul platformei online IQOS Club va fi disponibil un
														catalog de premii sub formă de diverse produse. Fiecărui produs
														îi este atribuit un număr de puncte, necesare a fi disponibile
														în contul participantului pentru a putea fi comandat.
													</p>
													<p>
														Un participant va putea comanda premii sub forma de produse din
														catalog utilizând punctele acumulate în contul său şi
														neutilizate încă pentru comenzi anterioare în cadrul prezentei
														Campanii Promoţionale. Valoarea exprimată în puncte a produsului
														comandat trebuie să fie mai mică sau egală cu numărul de puncte
														disponibil în contul Participantului.
													</p>
													<p>
														Prin comandarea unui produs din catalogul disponibil în cadrul
														platformei online, din contul Participantului vor fi scăzute
														numărul de puncte aferente produsului comandat.
													</p>
													<p>
														Numărul maxim de puncte pe care un Participant le poate utiliza
														pentru plasarea unei singure comenzi este de 705 puncte.
													</p>
													<p>
														Numărul de puncte utilizate de Participant pentru comandarea de
														produse din catalog vor fi scăzute din contul acestuia, dar vor
														fi luate în considerare în algoritmul de stabilire a Nivelului
														în care se încadrează Participantul.
													</p>
													<p>
														Punctele disponibile în contul Participantului vor fi utilizate
														în ordinea vechimii acestora, în funcţie de momentul în care au
														fost acumulate.
													</p>
													<p>
														Valoarea exprimată în puncte a produselor disponibile pentru a
														fi comandate de Participanţi din catalogul online pus la
														dispoziţie de Organizator în cadrul website-ului destinat
														prezentei Campanii Promoţionale poate fi oricând modificată de
														Organizator, fără a fi anunţaţi Participanţii asupra
														modificărilor care au fost sau care urmează să fie aduse.
													</p>
													<p>
														Comandarea de produse din catalog prin utilizarea de puncte
														acumulate în contul Participantului se numeşte în continuare{' '}
														<b>„utilizare”</b>.
													</p>
													<p>
														Comenzile plasate în cadrul platformei online vor fi expediate
														Participanţilor prin intermediul serviciilor de curierat, la
														adresa de livrare completată de Participant în momentul plasării
														comenzii.
													</p>
													<p>
														Pentru produsele livrate prin curier se va încerca o singură
														dată livrarea la adresa menţionată de Participant la plasarea
														comenzii în cadrul platformei online dedicate Campaniei
														Promoţionale.
													</p>
													<p>
														În cazul în care livrarea expediţiei eşuează iar coletul este
														returnat la expeditor, Participantul va primi în contul său
														numărul de puncte utilizate pentru comandarea produsului /
														produselor respective din catalog.
													</p>
													<p>
														Participanţii vor putea comanda doar produsele prevăzute în
														catalog, conform detaliilor prezentate în cadrul platformei
														online pentru fiecare produs disponibil. Participanţii nu pot
														cere modificarea naturii, cantităţii, condiţiilor şi modului de
														acordare al produselor din catalog, acestea nu se pot înlocui cu
														alte produse sau beneficii şi nici nu se acordă contravaloarea
														produselor sau a punctelor în bani.
													</p>

													<p>Renunțarea</p>
													<p>
														Pentru a oferi o plajă largă de opțiuni participanților si
														pentru a asigura o satisfacție crescuță în raport cu campaniile
														promoționale derulate de Organizator, Produsele receptionate ca
														urmare a utilizarii punctelor se vor supune, in scopul acestei
														Campanii, unui regim de returnare similar celui aplicabil în
														cazul achiziției la distanță (conform O.U.G. 34/2014) și se pot
														returna. Participantul are dreptul să notifice Organizatorul că
														renunță la produsul pentru care a optat, fără penalități și fără
														invocarea unui motiv, în termen de 14 zile calendaristice de la
														primirea acestuia.
													</p>
													<p>
														{' '}
														Prin urmare, utilizatorul poate returna produsele comandate din
														catalogul virtual, în termen de 14 zile calendaristice de la
														primirea produsului, fără penalități și fără a se cere motivul
														pentru care a luat aceasta decizie.
													</p>
													<p>
														Produsul returnat trebuie să fie în aceeași stare în care a fost
														recepționat (produsul poate fi desigilat, însă cu toate
														componentele, accesoriile primite la livrarea coletului).
													</p>
													<p>
														Nu se acceptă pentru returnare produse utilizate sau care
														prezintă modificări fizice, lovituri, ciobiri, zgârieturi,
														șocuri, urme de folosire excesivă și/sau intervenții
														neautorizate, etc.
													</p>
													<p>
														Pentru ca utilizatorul să își poată exercita dreptul de
														renunţare, acesta trebuie să informeze Organizatorul cu privire
														la decizia sa prin apelarea centrului de asistenţă clienţi.
														Participantul trebuie să contacteze centrul de asistenţă clienţi
														privind exercitarea dreptului de retragere înainte de expirarea
														perioadei de retragere.
													</p>
													<p>
														În termen de maxim 3 zile lucrătoare de la momentul în care
														Organizatorul este informat cu privire la decizia
														Participantului de renunţare, Organizatorul va trimite un curier
														la adresa indicată de Participant pentru a ridica produsul/le ce
														urmează a fi returnate.
													</p>
													<p>
														Costurile legate de transport vor fi suportate de către
														Organizator.
													</p>
													<p>
														Organizatorul va rambursa punctele utilizate pentru produsele
														returnate în contul Participantului din cadrul platformei online
														IQOS Club, fără întârzieri nejustificate, și în orice caz, nu
														mai târziu de 14 zile de la data la care Participantul a
														contactat centrul de asistenţă clienţi şi a solicitat returnarea
														produsului/elor, cu condiția ca produsele returnate să respecte
														condițiile de retur.
													</p>
													<p>
														In cazul produselor din categoria “Parteneri” acţiunea de
														comandare a voucherelor nu este reversibilă (utilizatorul nu
														poate solicita post factum renunţarea la transfer) și conduce la
														scăderea numărului total de puncte disponibile din IQOS Club ale
														participantului cu numărul celor selectate pentru transfer în
														comandarea voucherului selectat de catre acesta.
													</p>
													<p>
														În situaţia în care un Participant alege să valorifice punctele
														prin comandarea de produse din catalog, în perioada de
														valabilitate a acestora, dar ulterior renunţă la comandă, atunci
														punctele care şi-ar fi pierdut valabilitatea în perioada de timp
														decursă între momentul plasării comenzii şi momentul renunţării
														la comandă nu vor mai fi rambursate în contul utilizatorului.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Revendicare pachete HEETS din magazine
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Un Participant, identificat după numărul de telefon cu care s-a
														înscris în Campanie, participant la prezenta Campanie, ca urmare
														a acumulării de puncte poate transforma total sau parţial
														punctele acumulate în contul său în beneficii de tip pachete
														HEETS™, la alegerea participantului, în limita numărului de
														puncte acumulate în contul său, dar nu mai mult de 705 puncte
														folosite pe un singur cod de validare.
													</p>
													<p>
														În momentul în care un Participant va transforma puncte
														acumulate în contul său în recompense de tip pachete HEETS™,
														Participanţii vor fi informaţi cu privire la beneficiul
														revendicat printr-un mesaj SMS, în care îi vor fi comunicate:{' '}
													</p>
													<ul className="dashLine">
														<li>
															numărul de pachete HEETS pentru care a transformat punctele,
														</li>
														<li>
															un cod de validare unic alfanumeric format din 7 caractere -
															necesar pentru revendicarea beneficiului
														</li>
														<li>modalitatea de intrare în posesia beneficiului.</li>
													</ul>
													<p>
														Organizatorul nu îşi asumă răspunderea pentru neprimirea sau
														primirea întârziată a SMS-urilor datorită unor cauze care nu
														depind direct de el, cum ar fi, dar nelimitându-se la,
														defecţiuni tehnice ale operatorilor de telefonie mobilă. De
														asemenea, codul de validare premiu va fi afişat şi în contul
														Participantului din cadrul aplicaţiei digitale iSPOT, precum şi
														în cadrul website-ului destinat prezentei Campanii Promoţionale.{' '}
													</p>
													<p>
														Un cod de revendicare generat în contul Participantului şi
														neutilizat de acesta are termen de valabilitate după cum
														urmează:
													</p>

													<ul className="dashLine">
														<li>
															Codurile de revendicare generate în perioada 01 ianuarie ora
															00:00:00 – 30 iunie ora 23:59:59 a unui an calendaristic şi
															neutilizate pâna la 31 decembrie ora 23:59:59, acelaşi an
															calendaristic, îşi vor pierde valabilitatea la această dată
															şi vor fi eliminate automat din contul participantului;
														</li>
														<li>
															Codurile de revendicare generate în perioada 01 iulie ora
															00:00:00 – 31 decembrie ora 23:59:59 a unui an calendaristic
															şi neutilizate până la data de 30 iunie ora 23:59:59 a
															anului calendaristic următor, îşi vor pierde valabilitatea
															la această dată şi vor fi eliminate automat din contul
															participantului.
														</li>
													</ul>

													<p>
														În cazul în care codul de validare a expirat, deşi Participantul
														nu a revendicat beneficiul, atunci beneficiul nu se mai acordă,
														iar punctele nu vor fi restituite în contul Participantului.
													</p>

													<p>
														Participantul poate revendica beneficiiul de tip pachet HEETS™
														din centrele de premiere desemnate de Organizator din reţelele
														de benzinării MOL şi Rompetrol, magazine din reţeaua Lagardere
														(Inmedio, 1 Minute, Relay), Tabac Xpress, precum şi din magazine
														IQOS, numite în continuare centre de premiere, pe baza codului
														de validare premiu format din 7 caractere primit prin comunicare
														SMS din partea Organizatorului, disponibil şi în contul
														participantului din cadrul aplicaţiei iSPOT şi/sau în contul
														participanţilor din cadrul website-ului destinat prezentei
														Campanii.{' '}
													</p>
													<p>
														Lista cu centrele de premiere va fi disponibilă participanţilor
														pe website-ul dedicat platformei IQOS Club.
													</p>
													<p>
														În faza recepţionării beneficiului, Participantul trebuie să se
														prezinte personal în faţa reprezentantului centrului de premiere
														şi să prezinte codul de validare unic alfanumeric format din 7
														(şapte) caractere din cadrul SMS-ul primit din partea
														Organizatorului sau disponibil în contul său din cadrul
														aplicaţiei iSPOT / website-ului destinat prezentei Campanii.
													</p>
													<p>
														Aceste informaţii sunt necesare validării premiului câştigat şi
														intrării în posesia lui.{' '}
													</p>
													<p>
														La revendicarea beneficiului dintr-unul din centrele de
														premiere, un Participant poate opta pentru una sau oricare
														conbinaţie din următoarele variante de Pachet HEETS™:{' '}
														<b>
															Amber Flavor, Yellow Flavor, Turquoise Flavor, Blue Flavor,
															Bronze Flavor, Sienna Flavor sau Sienna Caps
														</b>
														, în limita stocului disponibil existent în locaţia aleasă de
														câştigător pentru revendicarea premiului.
													</p>
													<p>
														Reprezentantul centrului de premiere nu are obligaţia de a oferi
														informaţii în legătură cu prezenta Campanie. Pentru detalii în
														acest sens, participanţii trebuie să apeleze Centrul de
														asistenţă clienţi.
													</p>
													<p>
														Participanţii care au transformat punctele în beneficii de tip
														Pachet HEETS™ nu pot cere modificarea naturii, cantităţii,
														condiţiilor şi modului de acordare al acestora. În situaţia în
														care Participantul refuză să accepte beneficiul câştigat sau nu
														poate beneficia de acesta din motive independente de
														Organizator, respectivul beneficiu nu va fi înlocuit cu un alt
														premiu sau beneficiu.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Comandarea de produse din cadrul website-ului www.iqos.ro
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Un participant, membru în cadrul platformei IQOS Club, va putea
														utiliza punctele acumulate în contul său şi neutilizate încă în
														cadrul prezentei Campanii Promoţionale, pentru comenzile plasate
														în cadrul website-ului
														<a href="https://iqos.ro" target="_blank"></a>, utilizând
														acelaşi profil ca şi cel din cadrul IQOS Club.
													</p>
													<p>
														Pentru comandarea de produse din catalogul disponibil în cadrul
														website-ului{' '}
														<a href="https://iqos.ro" target="_blank">
															www.iqos.ro
														</a>
														, din contul Participantului vor fi scăzute numărul de puncte
														alese de participant pentru a fi utilizate în cadrul comenzii
														respective. În cazul în care numărul de puncte utilizate nu
														acoperă integral valoarea comenzii, participantul va achita,
														prin metoda de plată dorită, diferenţa de preţ.{' '}
													</p>
													<p>
														Prin comandarea de produse din catalogul disponibil în cadrul
														website-ului{' '}
														<a href="https://iqos.ro" target="_blank">
															www.iqos.ro
														</a>
														, din contul Participantului vor fi scăzute numărul de puncte
														alese de participant pentru a fi utilizate în cadrul comenzii
														respective.
													</p>
													<p>
														Numărul maxim de puncte pe care un Participant le poate utiliza
														pentru plasarea unei singure comenzi este de 749 puncte.
													</p>
													<p>
														Numărul de puncte utilizate de Participant pentru comandarea de
														produse din catalog vor fi scăzute din contul acestuia, dar vor
														fi luate în considerare în algoritmul de stabilire a Nivelului
														în care se încadrează Participantul.
													</p>
													<p>
														Punctele disponibile în contul Participantului vor fi utilizate
														în ordinea vechimii acestora, în funcţie de momentul în care au
														fost acumulate.
													</p>
													<p>
														Punctele alocate în cadrul unei comenzi vor fi alocate începând
														cu cel mai scump produs din coșul de cumpărături, urmând ca, în
														cazul în care rămân puncte disponibile din totalul alocat acelei
														comenzi, acestea vor fi alocate, în continuare, în ordine
														descrescătoare, pe următoarele produse ca valoare.
													</p>
													<p>
														Valoarea exprimată în puncte a produselor disponibile pentru a
														fi comandate de Participanţi din catalogul online pus la
														dispoziţie de Organizator în cadrul website-ului{' '}
														<a href="https://iqos.ro" target="_blank">
															www.iqos.ro
														</a>
														poate fi oricând modificată de Organizator, fără a fi anunţaţi
														Participanţii asupra modificărilor care au fost sau care urmează
														să fie aduse.
													</p>
													<p>
														Verificarea și posibilitatea de folosire a punctelor de
														loialitate în cadrul unei comenzi plasate pe website-ul{' '}
														<a href="https://iqos.ro" target="_blank">
															www.iqos.ro
														</a>
														vor fi disponibile în cadrul coșului de cumpărături, la
														secțiunea “Puncte de loialitate”. În cadrul acestei secțiuni,
														consumatorii vor putea decide numărul de puncte pe care doresc
														să îl aloce pentru comanda respectivă, dar fără a depăși soldul
														total al punctelor de loialitate disponibile în contul acestuia.
													</p>
													<p>
														Comandarea de produse din cadrul website-ului{' '}
														<a href="https://iqos.ro" target="_blank">
															www.iqos.ro
														</a>
														prin utilizarea de puncte, total sau parţial, acumulate în
														contul Participantului se numeşte în continuare{' '}
														<b>„utilizare”</b>.
													</p>
													<p>
														În cadrul catalogului de produse de pe website-ul{' '}
														<a href="https://iqos.ro" target="_blank">
															www.iqos.ro
														</a>
														, Organizatorul își rezervă dreptul de a selecta produse pentru
														care nu pot fi folosite puncte de loialitate, cât și produse
														care vor putea fi achiziționate exclusiv cu puncte.
													</p>
													<p>
														Comenzile plasate în cadrul website-ului{' '}
														<a href="https://iqos.ro" target="_blank">
															www.iqos.ro
														</a>
														vor fi supuse termenilor şi condiţiilor de vânzare descrise în
														cadrul acestuia.{' '}
													</p>
													<p>
														<b>Renunțarea</b>
														<br />
														Pentru a oferi o plajă largă de opțiuni participanților și
														pentru a asigura o satisfacție crescută în raport cu campaniile
														promoționale derulate de Organizator, Produsele recepționate ca
														urmare a utilizării punctelor se vor supune, în scopul acestei
														Campanii, unui regim de returnare similar celui aplicabil în
														cazul achiziției la distanță (conform O.U.G. 34/2014) și se pot
														returna. Participantul are dreptul să notifice Organizatorul că
														renunță la produsul pentru care a optat, fără penalități și fără
														invocarea unui motiv, în termen de 14 zile calendaristice de la
														primirea acestuia.
													</p>
													<p>
														Prin urmare, utilizatorul poate returna produsele comandate din
														catalogul virtual, în termen de 14 zile calendaristice de la
														primirea produsului, fără penalități și fără a se cere motivul
														pentru care a luat aceasta decizie.
													</p>
													<p>
														Produsul returnat trebuie să fie în aceeași stare în care a fost
														recepționat (produsul poate fi desigilat, însă cu toate
														componentele, accesoriile primite la livrarea coletului). Nu se
														acceptă pentru returnare produse utilizate sau care prezintă
														modificări fizice, lovituri, ciobiri, zgârieturi, șocuri, urme
														de folosire excesivă și/sau intervenții neautorizate, etc. În
														cazul produselor de tip „Accesorii, Componente, IQOS Kit” nu se
														acceptă pentru returnare produse care prezintă modificări
														fizice, lovituri, ciobiri, zgârieturi, șocuri, urme de folosire
														excesivă și/sau intervenții neautorizate, etc. (în baza OUG
														34/2014 articolul 16, alineat „e”).
													</p>
													<p>
														Produsele din tutun - HEETS™ necesită condiții de depozitare
														aparte, adecvate (din punct de vedere temperatură, umiditate,
														miros) și prezintă un risc de deterioare mai ridicat în
														comparație cu alte produse care nu conțin tutun. Nerespectarea
														condițiilor de depozitare și transport poate influența calitatea
														oricărui produs din tutun, inclusiv cea a HEETS™. Prin urmare,
														produsele HEETS™ sunt exceptate de la exercitarea dreptului de
														retragere, bunuri care nu pot fi returnate, chiar și sigilate,
														din motive de protecţie a sănătății/din motive de igienă precum
														și având în vedere potențialul acestora de deteriorare rapidă
														(în baza OUG 34/2014 articolul 16, alineat „d” respectiv „e").
													</p>
													<p>
														Pentru ca utilizatorul să își poată exercita dreptul de
														renunţare, acesta trebuie să informeze Organizatorul cu privire
														la decizia sa prin apelarea centrului de asistenţă clienţi.
														Participantul trebuie să contacteze centrul de asistenţă clienţi
														privind exercitarea dreptului de retragere înainte de expirarea
														perioadei de retragere.
													</p>
													<p>
														În termen de maxim 3 zile lucrătoare de la momentul în care
														Organizatorul este informat cu privire la decizia
														Participantului de renunţare, Organizatorul va trimite un curier
														la adresa indicată de Participant pentru a ridica produsul/le ce
														urmează a fi returnate.
													</p>
													<p>
														Organizatorul va restitui consumatorului, prin aceleași metode
														folosite, valoarea produsului pentru care acesta dorește sa facă
														retur: bani, puncte de loialitate sau voucher.{' '}
													</p>
													<p>
														Punctele utilizate pentru produsele returnate vor fi creditate
														în contul Participantului din cadrul platformei online IQOS
														Club, fără întârzieri nejustificate, și în orice caz, nu mai
														târziu de 14 zile de la data la care Participantul a contactat
														centrul de asistenţă clienţi şi a solicitat returnarea
														produsului/elor, cu condiția ca produsele returnate să respecte
														condițiile de retur.
													</p>
													<p>
														În situaţia în care un Participant alege să valorifice punctele
														prin comandarea de produse din catalog, în perioada de
														valabilitate a acestora, dar ulterior renunţă la comandă, atunci
														punctele care şi-ar fi pierdut valabilitatea în perioada de timp
														decursă între momentul plasării comenzii şi momentul renunţării
														la comandă nu vor mai fi rambursate în contul utilizatorului.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Anulare coduri revendicare HEETS
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Promoţia se desfăşoară în cadrul platformei online IQOS Club
														începând cu data de <b>2 iunie 2020 ora 00:00:00</b>; Promoţia
														poate înceta ca urmare a unei decizii a Organizatorului, cu
														respectarea condiţiilor generale ale platformei online „IQOS
														Club”.
													</p>
													<p>
														La prezenta promoţie pot participa toţi utilizatorii cu cont
														activ în cadrul platformei online IQOS Club, care întunesc
														cumulativ condiţiile de mai jos:
													</p>
													<ul>
														<li>
															deţin în contul lor cel puţin 1 (un) cod de revendicare
															obţinut de Participant ca urmarea a participării acestuia la
															Promoţia „<u>Revendicare pachete HEETS din magazine</u>”,
															conform termenilor şi condiţiilor respectivei Promoţii;
														</li>
														<li>
															codul de revendicare deţinut de Participant este activ,
															neutilizat încă şi aflat în termen de valabilitate.
														</li>
													</ul>
													<p>
														Participanţii care accesează platforma online IQOS Club pot
														accesa secţiunea „Foloseşte puncte”, subsecţiunea „Revendică
														HEETS din magazine” unde pot vizualiza dacă deţin în contul lor
														coduri de revendicare HEETS generate, neutilizate încă şi aflate
														în termenul de valabilitate, conform regulilor promoţiei „
														<u>Revendicare pachete HEETS din magazine</u>”.{' '}
													</p>
													<p>
														Pe perioada de desfăşurare a prezentei Promoţii, Participantul
														poate opta pentru anularea codului de revendicare generat
														anterior, neutilizat încă şi aflat în termen de valabilitate.
														Pentru aceasta, participantul va putea iniţia solicitarea prin
														accesarea opțiunii „Anulează cod” din dreptul fiecărui cod
														generat. Această opțiune va fi afișată doar în dreptul codurilor
														care întunesc cumulativ condiţiile de mai sus.
													</p>
													<p>
														În urma transmiterii cu succes a solicitării Participantului,
														codul eligibil asociat va fi anulat, iar punctele utilizate
														pentru generarea codului respectiv vor fi returnate în contul
														Participantului din cadrul platformei online IQOS Club.
													</p>
													<p>
														Punctele returnate în contul Participantului nu sunt contorizate
														de Organizator în vederea avansării utilizatorului de la un
														nivel inferior către un nivel superior în cadrul platformei
														online IQOS Club, aşa cum sunt acestea descrise în cadrul
														regulamentului promoţiei „<u>Beneficii garantate</u>”.
													</p>
													<p>
														În situaţia în care utilizatorul solicită anularea unui cod de
														revendicare generat anterior intrării în vigoare a
														regulamentului prezentei Promoţii, atunci punctele returnate în
														contul lui se vor considera, din punct de vedere valabilitate,
														ca fiind acumulate la momentul anulării codului de revendicare
														şi returnării lor în contul Participantului, iar termenul lor de
														valabilitate al acestora se supune regulilor promoţiei
														„Valabilitate puncte”.
													</p>
													<p>
														În situaţia în care utilizatorul solicită anularea unui cod de
														revendicare generat ulterior intrării în vigoare a
														regulamentului prezentei Promoţii, atunci punctele care şi-ar fi
														pierdut valabilitatea în perioada de timp decursă între momentul
														generării codului de revendicare şi solicitarea de anulare a
														acestuia nu vor mai fi returnate în contul utilizatorului. Se
														vor returna în contul utilizatorului doar punctele aflate încă
														în termenul de valabilitate, iar termenul lor de valabilitate al
														acestora se supune regulilor promoţiei „Valabilitate puncte”,
														fiind luat în considerare momentul acumulării lor de către
														utilizator, anterior transformării lor în cod de revendicare.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>

						<section>
							<h2 className="acc_title_bar">
								<a href="#">SECŢIUNEA 5. PROMOȚII ÎNCETATE</a>
							</h2>
							<div className="acc_container">
								<div className="block">
									<h3 className="acc_title_bar">
										<a href="#" target="_blank">
											Categoria promoții: Acumulare de puncte
										</a>
									</h3>
									<div className="acc_container">
										<div className="block">
											<h4 className="acc_title_bar">Promoţia: Mărţisor puncte bonus</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Pe perioada <b>02 martie 2020 – 15 martie 2020</b> (inclusiv)
														participanţii eligibili, utilizatori ai platformei online IQOS
														Club de sex feminin, conform profilului acestora înregistrat pe
														website-ul{' '}
														<a href="https://www.iqos.ro" target="_blank">
															www.iqos.ro
														</a>
														, vor putea accesa secţiunea din cadrul website-ului destinată
														interacţiunii „
														<span className="highlight">Mărţişor puncte bonus</span>”.{' '}
														<br />
														Utilizatorii platformei online IQOS Club de sex masculin,
														conform profilului acestora înregistrat pe website-ul{' '}
														<a href="https://www.iqos.ro" target="_blank">
															www.iqos.ro
														</a>
														, nu vor putea accesa secţiunea dedicată prezentei Promoţii şi
														nu vor putea participa la aceasta.
													</p>
													<p>
														Pe perioada de desfăşurare a prezentei Promoţii, participanţii
														eligibili vor putea accesa secţiunea din cadrul platformei
														online special dedicată, unde vor putea finaliza interacţiunea
														dedicată acestei promoţii prin revendicarea beneficiului
														acordat. <br />
														Un participant eligibil poate revendica acest beneficiu o
														singură dată, doar pe perioada de desfăşurare a prezentei
														Promoţii.
													</p>
													<p>
														Beneficiul care se acordă în urma completării cu succes a
														formularului conform detaliilor menţionate anterior constă în 10
														puncte care se acumulează instant în contul utilizatorului,
														imediat după finalizarea cu succes a interaţiunii.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Înscrierea de recomandări în perioada 01 aprilie 2020 – 31
												iulie 2020
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Participanţii pot recomanda persoane fumătoare adulte care şi-au
														exprimat interesul legat de sistemul IQOS conform metodologiei
														descrise în cadrul Promoţiei „Înscrierea de recomandări”.
													</p>
													<p>
														Prezenta promoţie se desfăşoară în continuarea promoţiei
														„Înscrierea de recomandări în perioada 01 aprilie 2020 – 30
														iunie 2020”, iar efectele sunt cumulative, nu distincte.
													</p>
													<p>
														Beneficiile pe care le vor primi Participanţii şi/sau persoanele
														recomandate în urma finalizării cu vânzare a recomandărilor
														înregistrate în perioada 01 iulie 2020 ora 00:00:00 – 31 iulie
														2020 ora 23:59:59 vor fi cele descrise în cadrul Promoţiei
														„Înscrierea de recomandări”, cărora li se adaugă beneficii
														suplimentare după cum urmează:
													</p>
													<ul>
														<li>
															Participantul va acumula în contul său un număr suplimentar
															de <b>30 puncte</b> pentru <b>prima recomandare</b>{' '}
															înregistrată şi finalizată cu vânzare în perioada 01 aprilie
															2020 ora 00:00:00 – 31 iulie 2020;
														</li>
														<li>
															Participantul va acumula în contul său un număr suplimentar
															de <b>50 puncte</b> pentru <b>a doua recomandare</b>{' '}
															înregistrată şi finalizată cu vânzare în perioada 01 aprilie
															2020 ora 00:00:00 – 31 iulie 2020;
														</li>
														<li>
															Participantul va acumula în contul său un număr suplimentar
															de <b>70 puncte</b> începând cu <b>a treia recomandare</b>{' '}
															înregistrată şi finalizată cu vânzare în perioada 01 aprilie
															2020 ora 00:00:00 – 31 iulie 2020.
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>

									<h3 className="acc_title_bar">
										<a href="#" target="_blank">
											Categoria promoții: Beneficii
										</a>
									</h3>
									<div className="acc_container">
										<div className="block">
											<h4 className="acc_title_bar">
												Promoţia: Interacţiune August – "Dă play și reconstruiește tehnologia
												viitorului"
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Pe perioada{' '}
														<b>05 august 2019 ora 00:00:00 – 01 septembrie 2019 23:59:59</b>{' '}
														(inclusiv) Participanţii, utilizatori ai platformei online IQOS
														Club vor putea accesa secţiunea din cadrul website-ului
														destinată „Dă play și reconstruiește tehnologia viitorului”. În
														cadrul prezentei secţiuni va deveni disponibilă, în fiecare zi
														de luni din cadrul perioadei de desfăşurare câte 1 (o) întrebare
														despre sau care poate avea legătură cu produsele de tip IQOS. În
														total vor fi disponibile în platformă 4 (patru) astfel de
														întrebări. În cazul în care un participant răspunde greşit la
														întrebarea afişată, acesta va avea din nou şansa să răspundă la
														acceaşi întrebare. Întrebarea se va debloca şi va putea răspunde
														din nou după trecerea unui interval de timp care nu este
														cunoscut Participanţilor. Pentru aceasta, Participantul trebuie
														să urmărească secţiunea special dedicată din cadrul platfomei
														online IQOS Club.
													</p>

													<p>
														În cadrul acestei promoţii se vor acorda premii, prin tragere la
														sorţi dintre Participanţii care au finalizat cu succes
														interacţiunea „Dă play și reconstruiește tehnologia viitorului”
														astfel:
													</p>

													<ul>
														<li>Trotinetă electrică – 12 premii;</li>
														<li>Telefon mobil din gama Apple – 3 premii</li>
														<li>
															Kit format din dispozitiv IQOS 3 şi IQOS Multi – 28 premii
														</li>
													</ul>

													<p>
														Pentru a se califica şi a putea fi inclus în tragerea la sorţi
														pentru desemnarea premiilor din categoria „Premii August 2019”,
														Participantul trebuie să răspundă corect la toate cele 4 (patru)
														întrebări disponibile în cadrul platformei web pe toată perioada
														de desfăşurare a prezentei activităţi.
													</p>

													<p>
														Fiecare participant va avea un număr de şanse în cadrul
														tragerilor la sorţi determinat de nivelul atins de acesta în
														cadrul platformei online IQOS Club astfel:
													</p>

													<ul className="dashLine">
														<li>Membru Silver – 1 (o) şansă;</li>
														<li>Membru Gold – 2 (două) şanse;</li>
														<li>Membru Platinum – 3 (trei) şanse.</li>
													</ul>

													<p>
														Nu sunt luaţi în considerare pentru a fi incluşi în tragerile la
														sorţi participanţii care nu întrunesc cumulativ condiţiile de
														participare în prezenta Campanie promoţională şi condiţiile de
														înscriere la tragerea la sorţi conform mecanismului descris
														anterior în cadrul prezentei secţiuni.
													</p>

													<p>
														În situaţia în care nu există niciun Participant care să
														întrunească cumulativ condiţiile de participare în prezenta
														Campanie promoţională şi condiţiile de înscriere la tragerea la
														sorţi conform mecanismului descris anterior în cadrul prezentei
														secţiuni, atunci tragerile la sorţi pentru aceste tipuri de
														premii nu se vor mai desfăşura, iar premiile vor rămâne în
														posesia Organizatorului.
													</p>

													<p>
														Tragerile la sorţi pentru desemnarea câştigătorilor se vor face
														pa baza unui algortim electronic şi vor avea loc în data de{' '}
														<b>04 septembrie 2019</b>. Se va organiza câte o tragere la
														sorţi pentru fiecare tip de premiu dintre şansele acumulate de
														Participanţi pe perioada de desfăşurare a activităţii astfel:
													</p>

													<ul className="dashLine">
														<li>
															Kit format din dispozitiv IQOS 3 şi IQOS Multi. La această
															tragere la sorţi se vor desemna 28 (douăzecişiopt)
															câştigători şi 84 (optzecişipatru) rezerve;
														</li>
														<li>
															Trotinetă electrică. La această tragere la sorţi se vor
															desemna 12 (doisprezece) câştigători şi 36 (treizecişişase)
															rezerve;
														</li>
														<li>
															Telefon mobil din gama Apple. La această tragere la sorţi se
															vor desemna 3 (trei) câştigători şi 9 (nouă) rezerve.
														</li>
													</ul>

													<p>
														Un Participant valid calificat pentru a fi inclus în tragerile
														la sorţi va participa la toate extragerile, cu numărul de şanse
														determinat conform condiţiilor menţionate anterior. Un
														Participant poate câştiga maxim câte 1 (un) premiu din fiecare
														categorie:
													</p>

													<ul className="dashLine">
														<li>
															1 premiu de tipul Kit format din dispozitiv IQOS 3 şi IQOS
															Multi
														</li>
														<li>1 premiu de tipul Trotinetă electrică</li>
														<li>1 premiu de tipul Trotinetă electrică</li>
													</ul>

													<p>
														Câştigătorii desemnaţi vor fi contactaţi telefonic pentru
														validare la numărul de telefon completat şi confirmat de
														participant la crearea contului în cadrul platfomei online IQOS
														Club, într-un interval de maxim 10 zile lucrătoare de la data
														desemnării acestora.
													</p>

													<p>
														Câştigătorii vor fi contactaţi de trei ori, în trei zile
														diferite, la intervale orare diferite, pentru a se realiza
														următoarele: identificare, validare, comunicarea detaliilor cu
														privire la livrarea premiului.
													</p>

													<p>
														Identificarea câştigătorului se va realiza pe baza datelor cu
														care acesta s-a înscris în cadrul platfomei online IQOS Club şi
														a celor comunicate în momentul validării telefonice (nume,
														prenume, data de naştere, localitate, CNP – pentru premiile în
														valoare de peste 600 RON). În situaţia în care, la momentul
														validării telefonice, câştigătorul declară că refuză să accepte
														premiul câştigat, sau nu întrunește toate condiţiile necesare
														validării sale, în conformitate cu prezentul Regulament,
														câştigătorul va fi invalidat şi decăzut din drepturi, iar
														Organizatorul va apela la rezerve, în ordinea desemnării
														acestora, urmând acelaşi mecanism descris. În cazul în care nu
														se reuşeşte validarea câştigătorului din motive independente de
														Organizator şi câştigătorul nu apelează linia de telefon
														dedicată Campaniei în termen de 3 zile lucrătoare de la data
														desemnării, acesta va fi decăzut din drepturi, iar Organizatorul
														va apela la rezerve, în ordinea desemnării acestora, urmând
														acelaşi mecanism descris.
													</p>

													<p>
														La finalul apelului de validare telefonică, câştigătorului îi
														vor fi comunicate toate detaliile referitoare la premiul
														câştigat şi modalitatea de livrare a acestuia.
													</p>

													<p>
														Premiile vor fi livrate câştigătorilor validaţi prin intermediul
														serviciilor de curierat. În faza recepţionării premiilor,
														câştigătorii trebuie să prezinte în original un document de
														identitate (CI/BI). În situaţia în care, la livrare, datele din
														CI/BI nu corespund cu cele declarate în procesul de validare
														premiu, câştigătorul va fi invalidat şi decăzut din drepturi.
													</p>

													<p>
														Toţi câştigătorii vor completa un proces verbal de
														predare-primire a premiului. Acesta va fi înmânat
														reprezentantului Organizatorului şi va fi folosit pentru
														validarea premiului. Câştigătorii se obligă să menţioneze pe
														Procesul verbal de predare-primire al premiului, în baza
														prevederilor Codului Fiscal (conform căruia plătitorii de
														venituri, cu regim de reţinere la sursă a impozitelor, au
														obligaţia să depună „Declaraţia informativă privind impozitul
														reţinut la sursă, veniturile din jocuri de noroc şi
														câştigurile/pierderile din investiţii, pe beneficiari de
														venit(D205)” privind calcularea şi reţinerea impozitului pentru
														fiecare beneficiar de venit, la organul fiscal competent, până
														la data de 31 ianuarie inclusiv a anului curent pentru anul
														expirat) codul său numeric personal din cartea de identitate. În
														cazul în care participantul refuză să indice codul său numeric
														personal, acestuia nu îi va fi acordat produsul, urmând a fi
														decăzut din drepturile de a reclama acest câştig. Această
														prevedere se aplică doar pentru premiile care au valoarea mai
														mare de 600 RON.
													</p>

													<p>
														Se va încerca o singură dată livrarea premiilor la adresa şi în
														intervalul orar menţionat de către câştigător la validarea
														telefonica. O nouă încercare de livrare a premiului se va face
														în momentul în care câştigătorul a sunat la linia telefonică
														informativă a Campaniei Promoţionale şi a menţionat detaliile
														referitoare la data, ora şi adresa la care acesta poate fi
														contactat pentru livrarea premiului. Curierul nu are obligaţia
														de a obligaţia de a oferi informaţii în legătură cu prezenta
														Campanie promoţională. Pentru detalii în acest sens,
														participanţii sau câştigătorii trebuie să apeleze linia
														telefonică dedicată a Campaniei promoţionale, conform Secţiunii
														13. „Centrul de asistenţă clienţi”.
													</p>

													<p>
														În situaţia în care câştigătorii nu au putut fi contactaţi
														pentru livrarea premiului câştigat, din cauze independente de
														Organizator şi nu au apelat linia telefonică informativă a
														Campaniei Promoţionale, revendicând premiul nelivrat, până la
														data de <b>30 octombrie 2019</b>, aceştia vor fi decăzuţi din
														dreptul de a mai revendica premiul.
													</p>
													<p>
														<a href="{{route('regulament-lucky-draw')}}" target="_blank">
															Accesează regulamentul campaniei autentificat
														</a>
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Înscrierea de recomandări în perioada 01 septembrie 2019 – 30
												septembrie 2019
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Participanţii pot recomanda persoane fumătoare adulte care şi-au
														exprimat interesul legat de sistemul IQOS conform metodologiei
														descrise în cadrul Promoţiei <b>„Înscrierea de recomandări”</b>.
													</p>

													<p>
														Beneficiile pe care le vor primi Participanţii şi/sau persoanele
														recomandate în urma finalizării cu vânzare a recomandărilor
														înregistrate în perioada{' '}
														<b>
															01 septembrie 2019 ora 00:00:00 – 30 septembrie 2019 ora
															23:59:59
														</b>{' '}
														vor fi cele descrise în cadrul Promoţiei
														<b>„Înscrierea de recomandări”</b>, cărora li se adaugă
														beneficii suplimentare după cum urmează:
													</p>
													<ul>
														<li>
															Participantul va acumula în contul său un număr suplimentar
															de <b>40 puncte</b> pentru
															<b>prima recomandare</b> înregistrată şi finalizată cu
															vânzare în perioada 01 septembrie 2019 ora 00:00:00 – 30
															septembrie 2019;
														</li>
														<li>
															Participantul va acumula în contul său un număr suplimentar
															de <b>60 puncte</b> pentru <b>a doua recomandare</b>{' '}
															înregistrată şi finalizată cu vânzare în perioada 01
															septembrie 2019 ora 00:00:00 – 30 septembrie 2019;
														</li>
														<li>
															Pentru <b>a treia recomandare</b> înregistrată şi finalizată
															cu vânzare în perioada 01 septembrie 2019 ora 00:00:00 – 30
															septembrie 2019 participantul se califică pentru a primi
															gratuit, din partea Organizatorului, un dispozitiv IQOS
															MULTI.
														</li>
													</ul>
													<p>
														Participanţii eligibili pentru a primit gratuit, din partea
														Organizatorului, un dispozitiv <b>IQOS MULTI</b> vor fi
														contactaţi telefonic pentru validare la numărul de telefon
														completat şi confirmat de participant la crearea contului în
														cadrul platformei online IQOS Club, într-un interval de maxim 10
														zile lucrătoare de la data la care aceştia au devenit eligibili
														pentru a primi acest tip de beneficiu.
													</p>
													<p>
														Participanţii vor fi contactaţi de trei ori, în trei zile
														diferite, la intervale orare diferite, pentru a se realiza
														următoarele: identificare, validare, comunicarea detaliilor cu
														privire la livrarea premiului.
													</p>
													<p>
														Identificarea Participanţilor se va realiza pe baza datelor cu
														care acesta s-a înscris în cadrul platformei online IQOS Club şi
														a celor comunicate în momentul validării telefonice (nume,
														prenume, data de naştere, localitate).
													</p>
													<p>
														În situaţia în care, la momentul validării telefonice,
														Participantul declară că refuză să accepte beneficiul acordat,
														sau nu întrunește toate condiţiile necesare validării sale, în
														conformitate cu prezentul Regulament, acesta va fi invalidat şi
														decăzut din drepturi.
													</p>
													<p>
														În cazul în care nu se reuşeşte validarea Participantului din
														motive independente de Organizator şi acesta nu apelează Centrul
														de Asistenţă Clienţi în termen de 3 zile lucrătoare de la data
														la care a devenit eligibil pentru acest tip de beneficiu, acesta
														va fi decăzut din drepturi.
													</p>
													<p>
														La finalul apelului de validare telefonică, Participantului îi
														vor fi comunicate toate detaliile referitoare la beneficiul
														acordat şi modalitatea de livrare a acestuia.
													</p>
													<p>
														Beneficiile constând în dispozitiv IQOS MULTI vor fi livrate
														Participanţilor validaţi prin intermediul serviciilor de
														curierat. În faza recepţionării beneficiului, Participantul
														trebuie să prezinte în original un document de identitate
														(CI/BI). În situaţia în care, la livrare, datele din CI/BI nu
														corespund cu cele declarate în procesul de validare, acesta va
														fi invalidat şi decăzut din drepturi.
													</p>
													<p>
														Toţi Participanţii vor completa un proces verbal de
														predare-primire a beneficiului. Acesta va fi înmânat
														reprezentantului Organizatorului şi va fi folosit pentru
														validare.
													</p>
													<p>
														Se va încerca o singură dată livrarea coletului conţinând
														beneficiul pentru care Participantul a fost validat la adresa şi
														în intervalul orar menţionat de către Participant la validarea
														telefonică. O nouă încercare de livrare se va face în momentul
														în care Participantul a sunat la Centrul de Asistenţă Clienţi şi
														a menţionat detaliile referitoare la data, ora şi adresa la care
														acesta poate fi contactat pentru livrare.
													</p>
													<p>
														Curierul nu are obligaţia de a oferi informaţii în legătură cu
														prezenta Promoţie. Pentru detalii în acest sens, participanţii
														trebuie să apeleze Centrul de Asistenţă Clienţi, conform
														Secţiunii 19. „Centrul de asistenţă clienţi”.
													</p>
													<p>
														În situaţia în care Participanţii nu au putut fi contactaţi
														pentru livrarea beneficiului validat, din cauze independente de
														Organizator şi nu au apelat Centrul de Asistenţă Clienţi,
														revendicând beneficiul nelivrat, până la data de{' '}
														<b>15 noiembrie 2019</b>, aceştia vor fi decăzuţi din dreptul de
														a-l mai revendica.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Cod bonus 10 puncte în IQOS Club prin intermediul
												reprezentanţilor de vânzări
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Promoţia se desfăşoară în cadrul platformei online IQOS Club în
														perioada
														<b>
															11 noiembrie 2019 ora 00:00:00 – 04 decembrie 2019 ora
															23:59:59.
														</b>
														Prezenta promoţie înlocuieşte promoţia „Cod bonus 20 puncte în
														IQOS Club prin intermediul reprezentanţilor de vânzări”.
													</p>

													<p>
														Prezenta promoţie se desfăşoară prin intermediul
														reprezentanţilor de vânzări din cadrul magazinelor partenere,
														care deţin un cont activ în cadrul platformei web
														<a href="https://ro.pmiopen.com" target="_blank">
															https://ro.pmiopen.com
														</a>
													</p>

													<p>
														La prezenta promoţie pot participa toţi utilizatorii cu cont
														activ în cadrul platformei online IQOS Club. Utilizatorii care
														au participat la promoţia „Cod bonus 20 puncte în IQOS Club prin
														intermediul reprezentanţilor de vânzări” şi au beneficiat
														beneficiat deja de punctele bonus primite conform mecanismului
														acesteia nu mai pot participa la promoţia curentă „Cod bonus 10
														puncte în IQOS Club prin intermediul reprezentanţilor de
														vânzări”.
													</p>

													<p>
														Participanţii care interacţionează cu reprezentanţii de vânzări
														ai magazinelor partenere pot primi, ca urmare a interacţiunii
														avute cu aceştia, prin comunicare SMS, la numărul de telefon
														declarat de Participant în cadrul interacţiunii, un cod bonus
														alfanumeric. Organizatorul nu îşi asumă răspunderea pentru
														neprimirea sau primirea întârziată a SMS-urilor din cauze care
														nu depind direct de el, cum ar fi, dar nelimitându-se la,
														defecţiuni tehnice ale operatorilor de telefonie mobilă.
													</p>

													<p>
														Pentru a putea utiliza un cod bonus primit de la un reprezentat
														de vânzări dintr-un magazin partener, participantul trebuie de
														deţină un cont activ în cadrul platformei online IQOS Club. În
														cazul în care nu este deja utilizator al platformei online IQOS
														Club, acesta îşi poate crea cont cu respectarea termenilor şi
														condiţiilor prezentei platforme online.
													</p>

													<p>
														Prin introducerea în cadrul platformei online IQOS Club, în
														cadrul secţiunii dedicate de introducere coduri, a unui cod unic
														primit prin modalitătile descrise anterior de la un participant,
														acesta va acumula în contul său <b>10 puncte.</b>
													</p>

													<p>
														Un participant, poate introduce o singură dată,{' '}
														<b>un singur cod bonus unic de acest tip primit</b> de la un un
														reprezentant de vânzări dintr-un magazin partener, pe toată
														perioada de desfăşurare a prezentei promoţii, indiferent de cât
														de multe coduri primeşte de la alţi reprezentanţi de vânzări din
														cadrul aceluiaşi sau al altor magazine partenere.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Cod bonus 20 puncte în IQOS Club prin intermediul
												reprezentanţilor de vânzări
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Promoţia se desfăşoară în cadrul platformei online IQOS Club în
														perioada
														<b>
															08 octombrie 2019 ora 00:00:00 – 15 decembrie 2019 ora
															23:59:59.
														</b>
													</p>

													<p>
														Prezenta promoţie se desfăşoară prin intermediul
														reprezentanţilor de vânzări din cadrul magazinelor partenere,
														care deţin un cont activ în cadrul platformei web
														<a href="https://ro.pmiopen.com/" target="_blank">
															https://ro.pmiopen.com
														</a>
													</p>

													<p>
														La prezenta promoţie pot participa toţi utilizatorii cu cont
														activ în cadrul platformei online IQOS Club.
													</p>

													<p>
														Participanţii care interacţionează cu reprezentanţii de vânzări
														ai magazinelor partenere pot primi, ca urmare a interacţiunii
														avute cu aceştia, prin comunicare SMS, la numărul de telefon
														declarat de Participant în cadrul interacţiunii, un cod bonus
														alfanumeric. Organizatorul nu îşi asumă răspunderea pentru
														neprimirea sau primirea întârziată a SMS-urilor din cauze care
														nu depind direct de el, cum ar fi, dar nelimitându-se la,
														defecţiuni tehnice ale operatorilor de telefonie mobilă.
													</p>

													<p>
														Pentru a putea utiliza un cod bonus primit de la un reprezentat
														de vânzări dintr-un magazin partener, participantul trebuie de
														deţină un cont activ în cadrul platformei online IQOS Club. În
														cazul în care nu este deja utilizator al platformei online IQOS
														Club, acesta îşi poate crea cont cu respectarea termenilor şi
														condiţiilor prezentei platforme online.
													</p>

													<p>
														Prin introducerea în cadrul platformei online IQOS Club, în
														cadrul secţiunii dedicate de introducere coduri, a unui cod unic
														primit prin modalitătile descrise anterior de la un participant,
														acesta va acumula în contul său
														<b>20 puncte.</b>
													</p>

													<p>
														Un participant, poate introduce o singură dată,{' '}
														<b>un singur cod bonus unic de acest tip primit</b> de la un
														reprezentant de vânzări dintr-un magazin partener, pe toată
														perioada de desfăşurare a prezentei promoţii, indiferent de cât
														de multe coduri primeşte de la alţi reprezentanţi de vânzări din
														cadrul aceluiaşi sau al altor magazine partenere.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Oferă prietenilor 5 puncte in IQOS Club
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Promoţia se desfăşoară în cadrul platformei online IQOS Club în
														perioada
														<b>
															12 septembrie 2019 ora 00:00:00 – 31 octombrie 2019 ora
															23:59:59
														</b>
														.
														<br />
														La prezenta promoţie pot participa toţi utilizatorii cu cont
														activ în cadrul platformei online IQOS Club.
													</p>

													<p>
														Fiecare participant, utilizator al platformei online IQOS Club,
														va regăsi, pe perioada de desfăşurare a prezentei promoţii, în
														secţiunea special dedicată din cadrul profilului personal al
														participantului, un <b>cod unic</b> format din 9 caractere
														alfanumerice. <br />
														Participantul va putea distribui prin orice canal de comunicare
														acest cod persoanelor cunoscute, adulte fumătoare şi/sau
														utilizatoare de IQOS şi/sau utilizatoare ale platformei online
														IQOS Club.
													</p>

													<p>
														Pentru a putea utiliza un cod unic primit de la un Participant,
														persoana receptoare trebuie să deţină un cont activ în cadrul
														platformei online IQOS Club. În cazul în care nu este deja
														utilizator al platformei online IQOS Club, acesta îşi poate crea
														cont cu respectarea termenilor şi condiţiilor prezentei
														platforme online.
													</p>

													<p>
														Prin introducerea în cadrul platformei online IQOS Club, în
														cadrul secţiunii dedicate de introducere coduri, a unui cod unic
														primit prin modalitățile descrise anterior de la un participant,
														acesta va acumula în contul său
														<b>5 puncte</b>.
													</p>

													<p>
														Un participant poate distribui codul unic asociat contului său
														către oricât de multe persoane. <br />
														Un participant nu poate introduce, în cadrul secţiunii dedicate
														de introducere coduri, propriul cod unic.
													</p>

													<p>
														Un participant, receptor de coduri unice de la alţi
														participanţi, poate introduce o singură dată,{' '}
														<b>un singur cod unic de acest tip primit </b> de la un
														participant pe toată perioada de desfăşurare a prezentei
														promoţii, indiferent de cât de multe coduri primeşte de la alți
														participanţi la promoţie.
													</p>

													<p>
														După finalizarea perioadei de desfăşurare a prezentei Promoţii
														se va întocmi un clasament al participanţilor care au distribuit
														codurile unice asociate şi ale căror coduri au fost înregistrate
														de alţi participanţi, receptori de coduri unice. Clasamentul se
														va realiza în funcţie de numărul de participanţi, receptori de
														coduri unice, care au înregistat în cadrul platformei online
														IQOS Club codurile de la un (acelaşi) participant care a
														distribuit codul unic asociat contului său.
													</p>

													<p>
														Primii 30 (treizeci) de Participanţi determinaţi conform
														clasamentului descris anterior vor primi gratuit, din partea
														Organizatorului, un dispozitiv <b>IQOS 3.</b>
													</p>

													<p>
														În situaţia în care pe ultima poziţie vor fi mai mulţi
														participanţi cu acelaşi număr de coduri înregistrate, atunci
														departajarea se va face în funcţie de momentul introducerii
														codurilor.{' '}
													</p>

													<p>
														Participanţii eligibili pentru a primit gratuit, din partea
														Organizatorului, un dispozitiv <b>IQOS 3</b> vor fi contactaţi
														telefonic pentru validare la numărul de telefon completat şi
														confirmat de participant la crearea contului în cadrul platfomei
														online IQOS Club, într-un interval de maxim 10 zile lucrătoare
														de la data la care aceştia au devenit eligibili pentru a primit
														acest tip de beneficiu.
													</p>

													<p>
														Participanţii vor fi contactaţi de trei ori, în trei zile
														diferite, la intervale orare diferite, pentru a se realiza
														următoarele: identificare, validare, comunicarea detaliilor cu
														privire la livrarea premiului.
													</p>

													<p>
														Identificarea Participanţilor se va realiza pe baza datelor cu
														care acesta s-a înscris în cadrul platfomei online IQOS Club şi
														a celor comunicate în momentul validării telefonice (nume,
														prenume, data de naştere, localitate).
													</p>

													<p>
														În situaţia în care, la momentul validării telefonice,
														Participantul declară că refuză să accepte beneficiul acordat,
														sau nu întrunește toate condiţiile necesare validării sale, în
														conformitate cu prezentul Regulament, acesta va fi invalidat şi
														decăzut din drepturi.
													</p>

													<p>
														În cazul în care nu se reuşeşte validarea Participantului din
														motive independente de Organizator şi acesta nu apelează Centrul
														de Asistenţă Clienţi în termen de 3 zile lucrătoare de la data
														la care a devenit eligibil pentru acest tip de beneficiu, acesta
														va fi decăzut din drepturi.
													</p>

													<p>
														La finalul apelului de validare telefonică, Participantului îi
														vor fi comunicate toate detaliile referitoare la beneficiul
														acordat şi modalitatea de livrare a acestuia.
													</p>

													<p>
														Beneficiile constând în dispozitive <b>IQOS 3</b> vor fi livrate
														Participanţilor validaţi prin intermediul serviciilor de
														curierat. În faza recepţionării beneficiului, Participantul
														trebuie să prezinte în original un document de identitate
														(CI/BI). În situaţia în care, la livrare, datele din CI/BI nu
														corespund cu cele declarate în procesul de validare, acesta va
														fi invalidat şi decăzut din drepturi.
													</p>

													<p>
														Toţi Participanţii vor completa un proces verbal de
														predare-primire a beneficiului. Acesta va fi înmânat
														reprezentantului Organizatorului şi va fi folosit pentru
														validare.
													</p>

													<p>
														Se va încerca o singură dată livrarea coletului conţinând
														beneficiul pentru care Participantul a fost validat la adresa şi
														în intervalul orar menţionat de către Participant la validarea
														telefonică. O nouă încercare de livrare se va face în momentul
														în care Participantul a sunat la Centrul de Asistenţă Clienţi şi
														a menţionat detaliile referitoare la data, ora şi adresa la care
														acesta poate fi contactat pentru livrare.
													</p>

													<p>
														Curierul nu are obligaţia de a oferi informaţii în legătură cu
														prezenta Promoţie. Pentru detalii în acest sens, participanţii
														trebuie să apeleze Centrul de Asistenţă Clienţi, conform
														Secţiunii 19. „Centrul de asistenţă clienţi”.
													</p>

													<p>
														În situaţia în care Participanţii nu au putut fi contactaţi
														pentru livrarea beneficiului validat, din cauze independente de
														Organizator şi nu au apelat Centrul de Asistenţă Clienţi,
														revendicând beneficiul nelivrat, până la data de{' '}
														<b>15 decembrie 2019</b>, aceştia vor fi decăzuţi din dreptul de
														a-l mai revendica.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Înscrierea de recomandări în perioada 01 septembrie 2019 – 31
												octombrie 2019{' '}
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Participanţii pot recomanda persoane fumătoare adulte care şi-au
														exprimat interesul legat de sistemul IQOS conform metodologiei
														descrise în cadrul Promoţiei <b>„Înscrierea de recomandări”</b>.{' '}
													</p>

													<p>
														Prezenta promoţie se desfăşoară în continuarea promoţiei{' '}
														<b>
															„Înscrierea de recomandări în perioada 01 septembrie 2019 –
															30 septembrie 2019”
														</b>
														, iar efectele sunt cumulative, nu distincte.
													</p>

													<p>
														Beneficiile pe care le vor primi Participanţii şi/sau persoanele
														recomandate în urma finalizării cu vânzare a recomandărilor
														înregistrate în perioada{' '}
														<b>
															01 septembrie 2019 ora 00:00:00 – 31 octombrie 2019 ora
															23:59:59
														</b>{' '}
														vor fi cele descrise în cadrul Promoţiei{' '}
														<b>„Înscrierea de recomandări”</b>, cărora li se adaugă
														beneficii suplimentare după cum urmează:
													</p>

													<ul className="dashLine">
														<li>
															Participantul va acumula în contul său un număr suplimentar
															de <b>40 puncte</b> pentru <b>prima recomandare</b>
															înregistrată şi finalizată cu vânzare în perioada 01
															septembrie 2019 ora 00:00:00 – 31 octombrie 2019 ora
															23:59:59;
														</li>
														<li>
															Participantul va acumula în contul său un număr suplimentar
															de <b>60 puncte</b> pentru <b>a doua recomandare</b>
															înregistrată şi finalizată cu vânzare în perioada 01
															septembrie 2019 ora 00:00:00 – 31 octombrie 2019 ora
															23:59:59;
														</li>
														<li>
															Pentru <b>a treia recomandare</b> înregistrată şi finalizată
															cu vânzare în perioada 01 septembrie 2019 ora 00:00:00 – 31
															octombrie 2019 ora 23:59:59, participantul se califică
															pentru a primi gratuit, din partea Organizatorului,{' '}
															<b> un dispozitiv IQOS MULTI</b>;
														</li>
														<li>
															Participantul va acumula în contul său un număr suplimentar
															de <b>80 puncte</b> pentru <b>a patra recomandare</b>
															înregistrată şi finalizată cu vânzare în perioada 01
															septembrie 2019 ora 00:00:00 – 31 octombrie 2019 ora
															23:59:59;
														</li>
														<li>
															Participantul va acumula în contul său un număr suplimentar
															de <b>100 puncte</b> pentru <b>a cincea recomandare</b>{' '}
															înregistrată şi finalizată cu vânzare în perioada 01
															septembrie 2019 ora 00:00:00 – 31 octombrie 2019 ora
															23:59:59.
														</li>
													</ul>

													<p>
														Participanţii care au înregistrat cea de-a patra şi/sau cea de-a
														cincea recomandare finalizata cu vânzare în perioada 01
														septembrie 2019 ora 00:00:00 – 30 septembrie 2019 ora 23:59:59
														vor primi automat, ulterior datei de 01 octombrie 2019 ora
														00:00:00, în termen de maximum 10 zile lucrătoare, în contul
														lor, numărul de puncte suplimentare conform detaliilor
														menţionate anterior.
													</p>

													<p>
														Participanţii eligibili pentru a primit gratuit, din partea
														Organizatorului, un dispozitiv <b>IQOS MULTI</b> vor fi
														contactaţi telefonic pentru validare la numărul de telefon
														completat şi confirmat de participant la crearea contului în
														cadrul platfomei online Clubul IQOS, într-un interval de maxim
														10 zile lucrătoare de la data la care aceştia au devenit
														eligibili pentru a primit acest tip de beneficiu.
													</p>

													<p>
														Participanţii vor fi contactaţi de trei ori, în trei zile
														diferite, la intervale orare diferite, pentru a se realiza
														următoarele: identificare, validare, comunicarea detaliilor cu
														privire la livrarea premiului.
													</p>

													<p>
														Identificarea Participanţilor se va realiza pe baza datelor cu
														care acesta s-a înscris în cadrul platfomei online Clubul IQOS
														şi a celor comunicate în momentul validării telefonice (nume,
														prenume, data de naştere, localitate).
													</p>

													<p>
														În situaţia în care, la momentul validării telefonice,
														Participantul declară că refuză să accepte beneficiul acordat,
														sau nu întrunește toate condiţiile necesare validării sale, în
														conformitate cu prezentul Regulament, acesta va fi invalidat şi
														decăzut din drepturi.
													</p>

													<p>
														În cazul în care nu se reuşeşte validarea Participantului din
														motive independente de Organizator şi acesta nu apelează Centrul
														de Asistenţă Clienţi în termen de 3 zile lucrătoare de la data
														la care a devenit eligibil pentru acest tip de beneficiu, acesta
														va fi decăzut din drepturi.
													</p>

													<p>
														La finalul apelului de validare telefonică, Participantului îi
														vor fi comunicate toate detaliile referitoare la beneficiul
														acordat şi modalitatea de livrare a acestuia.
													</p>

													<p>
														Beneficiile constând în dispozitiv IQOS MULTI vor fi livrate
														Participanţilor validaţi prin intermediul serviciilor de
														curierat. În faza recepţionării beneficiului, Participantul
														trebuie să prezinte în original un document de identitate
														(CI/BI). În situaţia în care, la livrare, datele din CI/BI nu
														corespund cu cele declarate în procesul de validare, acesta va
														fi invalidat şi decăzut din drepturi.
													</p>

													<p>
														Toţi Participanţii vor completa un proces verbal de
														predare-primire a beneficiului. Acesta va fi înmânat
														reprezentantului Organizatorului şi va fi folosit pentru
														validare.
													</p>

													<p>
														Se va încerca o singură dată livrarea coletului conţinând
														beneficiul pentru care Participantul a fost validat la adresa şi
														în intervalul orar menţionat de către Participant la validarea
														telefonică. O nouă încercare de livrare se va face în momentul
														în care Participantul a sunat la Centrul de Asistenţă Clienţi şi
														a menţionat detaliile referitoare la data, ora şi adresa la care
														acesta poate fi contactat pentru livrare.
													</p>

													<p>
														Curierul nu are obligaţia de a oferi informaţii în legătură cu
														prezenta Promoţie. Pentru detalii în acest sens, participanţii
														trebuie să apeleze Centrul de Asistenţă Clienţi, conform
														Secţiunii 19. „Centrul de asistenţă clienţi”.
													</p>

													<p>
														În situaţia în care Participanţii nu au putut fi contactaţi
														pentru livrarea beneficiului validat, din cauze independente de
														Organizator şi nu au apelat Centrul de Asistenţă Clienţi,
														revendicând beneficiul nelivrat, până la data de{' '}
														<b>15 decembrie 2019</b>, aceştia vor fi decăzuţi din dreptul de
														a-l mai revendica.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Înscrierea de recomandări – valabila in perioada
												01.08.2019-31.01.2020
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Participanţii pot recomanda persoane fumătoare adulte care şi-au
														exprimat interesul legat de sistemul IQOS prin următoarele
														metode:
													</p>

													<ul className="dashLine">
														<li>
															Prin intermediul website-ului prezentei Campanii
															Promoţionale, în cadrul secţiunii speciale dedicate
															înscrierii de recomandări;
														</li>
														<li>Prin intermediul aplicaţiei digitale iSPOT.</li>
													</ul>

													<p>
														Participanţii la prezenta Campanie pot solicita, prin
														intermediul website-ului dedicat campaniei, urmând etapele
														menţionate în cadrul website-ului, în cazul în care nu deţin
														deja, crearea unui cont în cadrul aplicaţiei digitale iSPOT.
														Pentru a putea beneficia de acces în cadrul aplicaţiei digitale
														iSPOT, Participantul trebuie să confirme, în contul lui din
														cadrul website-ului dedicat campaniei, numărul de telefon mobil.
														Participantul va primi, pe numărul de telefon mobil declarat
														anterior, prin comunicare SMS şi/sau email, adresele de
														descărcare a aplicaţiei de pe platformele iOS şi Android,
														împreună cu datele de autentificare în cadrul aplicaţiei.
														Organizatorul nu îşi asumă răspunderea pentru neprimirea sau
														primirea întârziată a SMS-urilor datorită unor cauze care nu
														depind direct de el, cum ar fi, dar nelimitându-se la,
														defecţiuni tehnice ale operatorilor de telefonie mobilă.{' '}
													</p>

													<p>
														Participantul va descărca aplicaţia digitală iSPOT, o va instala
														pe dispozitivul mobil compatibil şi se va autentifica în cadrul
														aplicaţiei cu datele primite anterior prin comunicare SMS.
													</p>

													<p>
														Persoanele fumătoare adulte pe care urmează să le recomande
														trebuie recrutate de Participanţi doar prin interacţiune
														directă, faţă în faţă cu aceasta. Se interzice Participanţilor
														utilizarea mărcii IQOS în campanii de publicitate, recrutări
														prin mijloace electronice, cum ar fi, dar fără a se limita la,
														comunicări pe e-mail, comunicări SMS utilizând baze de date din
														orice surse, realizarea de website-uri în scop de promovare şi
														recrutare potenţiali cumpărători, promovarea pe paginile de
														socializare şi orice altă acţiune care poate fi considerată
														acţiune de publicitate prin mijloace electronice. În cazul în
														care, în urma verificărilor desfăşurate de către Organizator,
														rezultă că procesul de participare a fost fraudat, Organizatorul
														îşi rezervă dreptul de a retrage participanţilor punctele
														acumulate / premiile obţinute ca rezultat al activităţii lor şi
														/ sau să restricţioneze participarea la Campania promoţională
														până la încheierea acesteia.
													</p>

													<p>
														Pentru a înscrie o recomandare prin oricare din modalităţile
														descrise anterior în cadrul prezentei secţiuni, Participantul va
														completa un formular cu următoarele date personale ale persoanei
														fumătoare adulte recomandate: nume, prenume, judeţ, localitate,
														număr telefon mobil. Totodată va confirma în cadrul formularului
														de recomandare faptul că persoana recomandată este adultă şi
														fumătoare şi este de acord cu termenii şi condiţiile
														recomandării.
													</p>

													<p>
														Pentru ca o recomandare să fie înscrisă cu succes în cadrul
														platformei online trebuie să întrunească cumulativ următoarele
														criterii:
													</p>

													<ul className="dashLine">
														<li>Datele introduse să fie corecte şi complete;</li>
														<li>Numărul de telefon mobil să fie valid;</li>
														<li>
															Persoana recomandată, identificată după numărul de telefon
															nu a fost înscrisă / recomandată anterior iar recomandarea
															este în curs de finalizare;
														</li>
														<li>
															Persoana recomandată, identificată după numărul de telefon
															nu deţine deja un cont activ în cadrul aplicaţiei mobile
															iSPOT;
														</li>
														<li>
															Persoana recomandată, identificată după numărul de telefon,
															nu are deja un cont activ, valid înregistrat pe site-ul
															<a href="https://www.iqos.ro/" target="_blank">
																www.iqos.ro
															</a>
															, de pe care să se fi efectuat în trecut achiziţia unui
															produs de tip „Starter Kit”;
														</li>
													</ul>

													<p>
														După completarea cu succes a formularului de recomandare,
														persoana recomandată trebui să aștepte primirea unei comunicări
														de tip SMS la numărul de telefon mobil completat în formularul
														de recomandare. Mesajul SMS va conține informaţii referitoare la
														persoana care l-a recomandat, precum şi faptul că va fi
														contactat de un reprezentant al Organizatorului în interval de
														maxim 48 de ore. Organizatorul nu îşi asumă răspunderea pentru
														neprimirea sau primirea întârziată a SMS-urilor datorită unor
														cauze care nu depind direct de el, cum ar fi, dar nelimitându-se
														la, defecţiuni tehnice ale operatorilor de telefonie mobilă.
														Toate persoanele recomandate înscrise de Participant prin
														oricare din modalităţile descrise anterior în cadrul prezentei
														secţiuni vor fi contactate de un reprezentant al Organizatorului
														în vederea stabilirii unei întâlniri, a prezentării detaliilor
														despre sistemul IQOS.
													</p>

													<p>
														Recomandările care se finalizează cu achiziţionarea de către
														persoana recomandată a unui sistem IQOS (dispozitiv IQOS şi un
														cartuş HEETS™ sau dispozitiv IQOS şi 5 pachete HEETS™ sau orice
														altă combinaţie de dispozitiv IQOS şi pachete HEETS™ disponibilă
														conform ofertei comerciale active la momentul achiziţionării
														dispozitivului IQOS) sunt numite în continuare „recomandări
														finalizate cu vânzare” şi doar acestea vor fi contorizate în
														vederea acumulării de puncte.{' '}
													</p>

													<p>
														<b>
															Pentru ca o recomandare finalizată cu vânzare să fie
															considerată validă, achiziţionarea de către persoana
															recomandată a unui sistem IQOS trebuie să fie realizată doar
															de la sau în urma interacțiunii persoanei recomandate cu un
															reprezentant specializat IQOS şi doar ca urmare a
															respectării paşilor descrişi prin SMS.
														</b>{' '}
														Nu vor fi considerate recomandări finalizate cu vânzare valide
														şi nu vor fi contorizate în contul participantului care a
														înregistrat recomandarea acele achiziţionări de sisteme IQOS din
														alte surse decât cea menţionată anterior în cadrul prezentului
														paragraf, cum ar fi, dar fără a se limita la, direct de pe
														website-ul <a href="https://iqos.ro">www.iqos.ro</a>
														sau din magazinele partenere sau ca urmare a interacţiunii
														cumpărătorilor cu un reprezentant IQOS, alta decât prin
														întalnirea cu reprezentantul IQOS de care a fost contactat în
														termenul descris mai sus din momentul primirii SMS-ului şi alta
														ca urmare a recomandării făcute de Participantul înscris în
														prezenta Campanie Promoţională.
													</p>

													<p>
														Participantul va acumula în contul său 60 puncte pentru orice
														recomandare finalizată cu vânzare.{' '}
													</p>

													<p>
														În plus, persoana recomandată va beneficia de un discount în
														valoare de 51 RON dacă în momentul achiziţiei optează pentru
														Starter Kit conţinând dispozitiv IQOS versiunea 2.4 Plus, cu
														respectarea tuturor celorlalte condiţii menţionate anterior,
														adică doar în situaţia în care achiziţia se finalizează ca
														urmare a interacţiunii cu reprezentantul IQOS care l-a contactat
														ca urmare a înscrierii de recomandare din partea
														Participantului.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Interacţiune „Testează-ți cunoștințele”
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														În cadrul platformei online IQOS Club, secţiunea special
														dedicate, utilizatorul va avea posibilitatea să răspundă la un
														chestionar prin care îşi testează cunoştinţele despre sistemul
														IQOS, în urma completării răspunsurilor şi a finalizării
														chestionarului va acumula în contul său <b>10 puncte</b>.
													</p>

													<p>
														Utilizatorul, identificat după datele cu care şi-a creat contul
														în cadrul platformei online IQOS Club, poate realiza o singură
														dată această interacţiune, iar punctele vor acordate o singură
														dată, la finalizarea acesteia. Răspunsurile completate de
														utilizator devin proprietatea Philip Morris Trading cu toate
														drepturile aferente, inclusiv dreptul de a prelucra sau utiliza
														în scopuri de marketing direct informaţiile conţinute, fără alte
														obligaţii şi plăţi ulterioare faţă de persoana respectivă.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Tu şi IQOS - Provocarea lunii octombrie
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Promoţia se desfăşoară în cadrul platformei online Clubul IQOS
														în perioada{' '}
														<b>
															03 octombrie 2019 ora 00:00:00 – 31 octombrie 2019 ora
															23:59:59
														</b>
														.
													</p>
													<p>
														La prezenta promoţie pot participa toţi utilizatorii cu cont
														activ în cadrul platformei online IQOS Club.
													</p>
													<p>
														Participanţii care accesează platforma online IQOS Club pot
														accesa secţiunea special dedicată acestei promoţii, unde vor
														putea încărca conţinut sub forma de imagine.
													</p>

													<p>
														Imaginea pe care utilizatorul doreşte să o încarce în cadrul
														platformei online IQOS Club trebuie să întrunească cumulativ
														următoarele condiţii de eligibilitate:
													</p>

													<ul className="dashLine">
														<li>
															fişierul trebuie să fie în format jpg, jpeg sau derivate ale
															acestuia;
														</li>
														<li>dimensiunea fişierului să nu depăşească 10 MB;</li>
														<li>
															sunt acceptate doar imagini care sunt inspirate sau inspiră
															persoanei privitoare legătură cu tematica lunii, aceasta
															fiind „Momentele tale cu IQOS şi prietenii”. În cadrul
															imaginilor trebuie să se regăsească cel puţin un dispozitiv
															IQOS sau parte componentă a acestuia;
														</li>
														<li>
															nu sunt acceptate imagini care conţin, dar fără a se limita
															la: limbaj ofensator sau violent, comentarii jignitoare sau
															discriminatorii privind rasa, etnia, religia, sexul,
															dizabilitatea, orientarea sexuală sau convingerile politice,
															link-uri sau comentarii care conțin materiale de conținut
															sexual explicit, atacuri asupra unor grupuri specifice sau
															orice comentarii menite să hărțuiască, să amenințe sau să
															abuzeze un individ, spam, link-uri externe sau fișiere care
															conțin viruși care ar putea deteriora funcționarea
															computerelor sau dispozitivelor mobile ale altor persoane,
															solicitări comerciale sau promovarea unui concurent;
														</li>
														<li>
															participantul care încarcă o imagine este direct responsabil
															şi răspunzător privind încălcarea dreptului de autor sau a
															drepturilor de proprietate intelectuală.
														</li>
													</ul>

													<p>
														Un participant poate încărca oricât de multe imagini, cu
														respectarea condiţiilor de eligibilitate menţionate anterior.
													</p>

													<p>
														Ulterior încărcării imaginii de către Participanţi, toate
														imaginile vor fi supuse unui proces de validare de către
														Organizator. Doar imaginile aprobate de Organizator vor fi
														publicate în cadrul platformei online IQOS Club, în cadrul
														secţiunii special dedicate prezentei Promoţii.
													</p>

													<p>
														Ulterior afişării în cadrul platformei online IQOS Club, în
														cadrul secţiunii special dedicate prezentei Promoţii,
														utilizatorii prezentei platforme online vor putea acorda
														aprecieri imaginilor propuse de ceilalţi Participanţi, aprobate
														de Organizator şi publicate de acesta din urmă în cadrul
														website-ului.
													</p>

													<p>
														Un utilizator, membru în cadrul IQOS Club, poate aprecia o
														imagine o singură dată. Un utilizator, membru în cadrul IQOS
														Club, poate aprecia oricât de multe dintre imaginile prezente în
														cadrul secţiunii special dedicate prezentei Promoţii. Un
														utilizator, membru în cadrul IQOS Club, poate aprecia imaginile
														propuse de alţi Participanţi şi aprobate de Organizator, precum
														şi propriile imagini propuse şi aprobate de Organizator. Un
														utilizator poate aprecia o imagine propusă de un participant şi
														aprobată de Organizator doar pe perioada de desfăşurare a
														prezentei Promoţii.
													</p>

													<p>
														După finalizarea perioadei de desfăşurare a prezentei Promoţii
														se va întocmi un clasament al imaginilor încărcate de
														Participanţi în cadrul secţiunii special dedicate în cadrul
														platformei online şi care au acumulat aprecieri din partea
														utilizatorilor platformei IQOS Club.
													</p>

													<p>
														Clasamentul se va realiza în maxim 5 zile lucrătoare de la data
														finalizării Promoţiei şi va fi realizat în funcţie de numărul de
														aprecieri acumulate de imaginile încărcate de Participanţi în
														perioada de desfăşurare a Promoţiei, de la momentul încărcării
														acestora şi publicate de Organizator în cadrul platformei web
														până la încetarea Promoţiei, adică anterior datei de 31
														octombrie 2019 ora 23:59:59.
													</p>

													<p>
														Primele 5 (cinci) imagini din clasament determinate conform
														regulilor descrise anterior vor fi asociate Participanţilor care
														au încărcat imaginile respective în cadrul platformei web.
													</p>

													<p>
														În situaţia în care pe ultima poziţie vor fi mai multe imagini
														cu acelaşi număr de aprecieri, atunci departajarea se va face
														prin cumularea aprecierilor tuturor imaginilor încărcate pe
														perioada de desfăşurare a prezentei Promoţii de către
														Participanţii aflaţi la egalitate.
													</p>

													<p>
														În situaţia în care, în urma procesului de departajare menţionat
														anterior, nu se va putea diferenţia explicit un număr de 5
														(cinci) Participanţi unici, atunci se va lua în considerare
														prima imagine încărcată, în ordine cronologică, de către
														Participanţi, dintre cele care au determinat selectarea
														Participantului respectiv.{' '}
													</p>

													<p>
														În cazul în care un Participant deţine mai multe de 1 (o)
														imagine în clasament, atunci acesta va fi eligibil pentru a
														primi din partea Organizatorului 1 singur beneficiu acordat prin
														intermediul prezentei Promoţii. În acestă situaţie vor fi luate
														în considerare următoarele imagini din clasamentul realizat
														anterior, iar acţiunea poate fi reluată până la identificarea a
														5 (cinci) Participanţi unici.
													</p>

													<p>
														Un Participant poate primi din partea Organizatorului un singur
														beneficiu de acest tip, indiferent de numărul de imagini
														încărcate pe perioada Promoţiei şi indiferent dacă aceeaşi
														persoană deţine mai multe conturi de utilizator în cadrul
														platformei online IQOS Club.{' '}
													</p>

													<p>
														Primele 5 (cinci) Participanţi determinaţi conform regulilor
														descrise anterior vor primi gratuit, din partea Organizatorului,
														un <b>voucher “Smart Experience”</b> în valoare unitară de 2.000
														RON (TVA inclus).
													</p>

													<p>
														„Smart experience” este o platformă parteneră Organizatorului,
														prin intermediul căreia utilizatorii pot comanda experienţe de
														diverse tipuri, cum ar fi, dar fără a se limita la: zboruri cu
														balon cu aer cald, salt cu paraşuta, etc. Organizatorul nu este
														şi nu poate fi făcut responsabil privind termenii şi condiţiile
														de utilizare ale platformei partenere.
													</p>

													<p>
														Participanţii eligibili pentru a primit gratuit, din partea
														Organizatorului, un <b>voucher “Smart Experience”</b> vor fi
														contactaţi telefonic pentru validare la numărul de telefon
														completat şi confirmat de participant la crearea contului în
														cadrul platfomei online IQOS Club, într-un interval de maxim 5
														zile lucrătoare de la data la care aceştia au devenit eligibili
														pentru a primit acest tip de beneficiu.
													</p>

													<p>
														Participanţii vor fi contactaţi de trei ori, în trei zile
														diferite, la intervale orare diferite, pentru a se realiza
														următoarele: identificare, validare, comunicarea detaliilor cu
														privire la livrarea premiului.
													</p>

													<p>
														Identificarea Participanţilor se va realiza pe baza datelor cu
														care acesta s-a înscris în cadrul platfomei online IQOS Club şi
														a celor comunicate în momentul validării telefonice (nume,
														prenume, data de naştere, adresă e-mai, CNP).{' '}
													</p>

													<p>
														Câştigătorii se obligă să menţioneze, în baza prevederilor
														Codului Fiscal (conform căruia plătitorii de venituri, cu regim
														de reţinere la sursă a impozitelor, au obligaţia să depună
														„Declaraţia informativă privind impozitul reţinut la sursă,
														veniturile din jocuri de noroc şi câştigurile/pierderile din
														investiţii, pe beneficiari de venit(D205)” privind calcularea şi
														reţinerea impozitului pentru fiecare beneficiar de venit, la
														organul fiscal competent, până la data de 31 ianuarie inclusiv a
														anului curent pentru anul expirat) codul său numeric personal
														din cartea de identitate. În cazul în care participantul refuză
														să indice codul său numeric personal, acestuia nu îi va fi
														acordat produsul, urmând a fi decăzut din drepturile de a
														reclama acest câştig. Această prevedere se aplică deoarece
														beneficiile acordate în cadrul prezentei Promoţii au valoarea
														mai mare de 600 RON.
													</p>

													<p>
														În situaţia în care, la momentul validării telefonice,
														Participantul declară că refuză să accepte beneficiul acordat,
														sau nu întrunește toate condiţiile necesare validării sale, în
														conformitate cu prezentul Regulament, acesta va fi invalidat şi
														decăzut din drepturi.
													</p>

													<p>
														În cazul în care nu se reuşeşte validarea Participantului din
														motive independente de Organizator şi acesta nu apelează Centrul
														de Asistenţă Clienţi în termen de 15 zile lucrătoare de la data
														finalizării Promoţiei, acesta va fi decăzut din drepturi.
													</p>

													<p>
														În cazul în care nu se reuşeşte validarea unui Participant, din
														motive independente de Organizator, se va relua procesul de
														identificare a Participanţilor aflaţi în afara primelor 5
														poziţii determinate iniţial, urmându-se acelaşi procedeu ca cel
														descris anterior.
													</p>

													<p>
														La finalul apelului de validare telefonică, Participantului îi
														vor fi comunicate toate detaliile referitoare la beneficiul
														acordat şi modalitatea de livrare a acestuia.
													</p>

													<p>
														Beneficiile constând în voucher “Smart Experience” vor fi
														livrate Participanţilor validaţi prin comunicare email, la
														adresa de email comunicată de Participant în cadrul procesului
														de validare.
													</p>

													<p>
														În situaţia în care Participanţii nu au primit emailul de
														livrare a beneficiului validat, din cauze independente de
														Organizator şi nu au apelat Centrul de Asistenţă Clienţi,
														revendicând beneficiul nelivrat, până la data de{' '}
														<b>01 decembrie 2019</b>, aceştia vor fi decăzuţi din dreptul de
														a-l mai revendica.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Tu şi IQOS - Provocarea lunii noiembrie
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Promoţia se desfăşoară în cadrul platformei online Clubul IQOS
														în perioada{' '}
														<b>
															01 noiembrie 2019 ora 00:00:00 – 30 noiembrie 2019 ora
															23:59:59.
														</b>
													</p>

													<p>
														La prezenta promoţie pot participa toţi utilizatorii cu cont
														activ în cadrul platformei online IQOS Club.
													</p>

													<p>
														Participanţii care accesează platforma online IQOS Club pot
														accesa secţiunea special dedicată acestei promoţii, unde vor
														putea încărca conţinut sub forma de imagine. Pentru înscrierea
														în cadrul prezentei promoţii vor fi luate în considerare
														exclusiv imaginile încărcate de participanţi pe perioada de
														desfăşurare a promoţiei. Nu vor fi luate în considerare
														imaginile încărcate de participanţi anterior începerii prezentei
														promoţii şi nici cele încărcate de participanţi după termenul de
														finalizare al prezentei promoţii.
													</p>

													<p>
														Imaginea pe care utilizatorul doreşte să o încarce în cadrul
														platformei online IQOS Club trebuie să întrunească cumulativ
														următoarele condiţii de eligibilitate:
													</p>

													<ul className="dashLine">
														<li>
															fişierul trebuie să fie în format jpg, jpeg sau derivate ale
															acestuia;
														</li>
														<li>dimensiunea fişierului să nu depăşească 10 MB;</li>
														<li>
															sunt acceptate doar imagini care sunt inspirate sau inspiră
															persoanei privitoare legătură cu tematica lunii, aceasta
															fiind „Momente speciale în doi cu IQOS”. În cadrul
															imaginilor trebuie să se regăsească cel puţin un dispozitiv
															IQOS sau parte componentă a acestuia;
														</li>
														<li>
															nu sunt acceptate imagini care conţin, dar fără a se limita
															la: limbaj ofensator sau violent, comentarii jignitoare sau
															discriminatorii privind rasa, etnia, religia, sexul,
															dizabilitatea, orientarea sexuală sau convingerile politice,
															link-uri sau comentarii care conțin materiale de conținut
															sexual explicit, atacuri asupra unor grupuri specifice sau
															orice comentarii menite să hărțuiască, să amenințe sau să
															abuzeze un individ, spam, link-uri externe sau fișiere care
															conțin viruși care ar putea deteriora funcționarea
															computerelor sau dispozitivelor mobile ale altor persoane,
															solicitări comerciale sau promovarea unui concurent
														</li>
														<li>
															Participantul care încarcă o imagine este direct
															responsabilă şi răspunzătoare privind încălcarea dreptului
															de autor sau a drepturilor de proprietate intelectuală
														</li>
													</ul>

													<p>
														Un participant poate încărca oricât de multe imagini, cu
														respectarea condiţiilor de eligibilitate menţionate anterior.
													</p>

													<p>
														Ulterior încărcării imaginii de către Participanţi, toate
														imaginile vor fi supuse unui proces de validare de către
														Organizator. Doar imaginile aprobate de Organizator vor fi
														publicate în cadrul platformei online IQOS Club, în cadrul
														secţiunii special dedicate prezentei Promoţii. Participanţii
														înţeleg şi acceptă că Organizatorul va putea, la discreţia sa,
														să respingă imagini dintre cele încărcate de Participanţi pe
														perioada de desfăşurare a Promoţiei, deşi respectă tematica
														lunii. În mod nelimitativ, Organizatorul îşi rezervă dreptul de
														nu a aproba şi a nu publica în cadrul platformei online IQOS
														Club imagini care pot fi considerate, fără a se limita la,
														neclare, vizibile parţial, iligibile.
													</p>

													<p>
														Ulterior afişării în cadrul platformei online IQOS Club, în
														cadrul secţiunii special dedicate prezentei Promoţii,
														utilizatorii prezentei platforme online vor putea acorda
														aprecieri imaginilor propuse de ceilalţi Participanţi, aprobate
														de Organizator şi publicate de acesta din urmă în cadrul
														website-ului.
													</p>

													<p>
														Un utilizator, membru în cadrul IQOS Club, poate aprecia o
														imagine o singură dată.
													</p>

													<p>
														Un utilizator, membru în cadrul IQOS Club, poate aprecia oricât
														de multe dintre imaginile prezente în cadrul secţiunii special
														dedicate prezentei Promoţii.
													</p>

													<p>
														Un utilizator, membru în cadrul IQOS Club, poate aprecia
														imaginile propuse de alţi Participanţi şi aprobate de
														Organizator, precum şi propriile imagini propuse şi aprobate de
														Organizator.
													</p>

													<p>
														Un utilizator poate aprecia o imagine propusă de un participant
														şi aprobată de Organizator doar pe perioada de desfăşurare a
														prezentei Promoţii.
													</p>

													<p>
														După finalizarea perioadei de desfăşurare a prezentei Promoţii
														se va întocmi un clasament al imaginilor încărcate de
														Participanţi în cadrul secţiunii special dedicate din cadrul
														platformei online şi care au acumulat aprecieri din partea
														utilizatorilor platformei IQOS Club.
													</p>

													<p>
														Clasamentul se va realiza în maxim 5 zile lucrătoare de la data
														finalizării Promoţiei şi va fi realizat în funcţie de numărul de
														aprecieri acumulate de imaginile încărcate de Participanţi în
														perioada de desfăşurare a Promoţiei, de la momentul încărcării
														acestora şi publicate de Organizator în cadrul platformei web
														până la încetarea Promoţiei, adică anterior datei de 30
														noiembrie 2019 ora 23:59:59.
													</p>

													<p>
														Primele 5 (cinci) imagini din clasament determinate conform
														regulilor descrise anterior vor fi asociate Participanţilor care
														au încărcat imaginile respective în cadrul platformei web.
													</p>

													<p>
														În situaţia în care pe ultima poziţie vor fi mai multe imagini
														cu acelaşi număr de aprecieri, atunci departajarea se va face
														prin cumularea aprecierilor tuturor imaginilor încărcate pe
														perioada de desfăşurare a prezentei Promoţii de către
														Participanţii aflaţi la egalitate.
													</p>

													<p>
														În situaţia în care, în urma procesului de departajare menţionat
														anterior, nu se va putea diferenţia explicit un număr de 5
														(cinci) Participanţi unici, atunci se va lua în considerare
														prima imagine încărcată, în ordine cronologică, de către
														Participanţi, dintre cele care au determinat selectarea
														Participantului respectiv.{' '}
													</p>

													<p>
														În cazul în care un Participant deţine mai multe de 1 (o)
														imagine în clasament, atunci acesta va fi eligibil pentru a
														primi din partea Organizatorului 1 singur beneficiu acordat prin
														intermediul prezentei Promoţii. În acestă situaţie vor fi luate
														în considerare următoarele imagini din clasamentul realizat
														anterior, iar acţiunea poate fi reluată până la identificarea a
														5 (cinci) Participanţi unici.
													</p>

													<p>
														Un Participant poate primi din partea Organizatorului un singur
														beneficiu de acest tip, indiferent de numărul de imagini
														încărcate pe perioada Promoţiei şi indiferent dacă aceeaşi
														persoană deţine mai multe conturi de utilizator în cadrul
														platformei online IQOS Club.
													</p>

													<p>
														Primii 5 (cinci) Participanţi determinaţi conform regulilor
														descrise anterior vor primi gratuit, din partea Organizatorului,
														un <b>set format din 2 (două) dispozitive IQOS 3 Duo.</b>
													</p>

													<p>
														Participanţii eligibili pentru a primit gratuit, din partea
														Organizatorului, un{' '}
														<b>set format din 2 (două) dispozitive IQOS 3 Duo </b> vor fi
														contactaţi telefonic pentru validare la numărul de telefon
														completat şi confirmat de participant la crearea contului în
														cadrul platfomei online IQOS Club, într-un interval de maxim 5
														zile lucrătoare de la data la care aceştia au devenit eligibili
														pentru a primit acest tip de beneficiu.
													</p>

													<p>
														Participanţii vor fi contactaţi de trei ori, în trei zile
														diferite, la intervale orare diferite, pentru a se realiza
														următoarele: identificare, validare, comunicarea detaliilor cu
														privire la livrarea premiului.
													</p>

													<p>
														Identificarea Participanţilor se va realiza pe baza datelor cu
														care acesta s-a înscris în cadrul platfomei online IQOS Club şi
														a celor comunicate în momentul validării telefonice (nume,
														prenume, data de naştere, adresă e-mai, CNP).
													</p>

													<p>
														Câştigătorii se obligă să menţioneze, în baza prevederilor
														Codului Fiscal (conform căruia plătitorii de venituri, cu regim
														de reţinere la sursă a impozitelor, au obligaţia să depună
														„Declaraţia informativă privind impozitul reţinut la sursă,
														veniturile din jocuri de noroc şi câştigurile/pierderile din
														investiţii, pe beneficiari de venit(D205)” privind calcularea şi
														reţinerea impozitului pentru fiecare beneficiar de venit, la
														organul fiscal competent, până la data de 31 ianuarie inclusiv a
														anului curent pentru anul expirat) codul său numeric personal
														din cartea de identitate. În cazul în care participantul refuză
														să indice codul său numeric personal, acestuia nu îi va fi
														acordat produsul, urmând a fi decăzut din drepturile de a
														reclama acest câştig. Această prevedere se aplică deoarece
														beneficiile acordate în cadrul prezentei Promoţii au valoarea
														mai mare de 600 RON.
													</p>

													<p>
														În situaţia în care, la momentul validării telefonice,
														Participantul declară că refuză să accepte beneficiul acordat,
														sau nu întrunește toate condiţiile necesare validării sale, în
														conformitate cu prezentul Regulament, acesta va fi invalidat şi
														decăzut din drepturi.
													</p>

													<p>
														În cazul în care nu se reuşeşte validarea Participantului din
														motive independente de Organizator şi acesta nu apelează Centrul
														de Asistenţă Clienţi în termen de 15 zile lucrătoare de la data
														finalizării Promoţiei, acesta va fi decăzut din drepturi.
													</p>

													<p>
														În cazul în care nu se reuşeşte validarea unui Participant, din
														motive independente de Organizator, se va relua procesul de
														identificare a Participanţilor aflaţi în afara primelor 5
														poziţii determinate iniţial, urmându-se acelaşi procedeu ca cel
														descris anterior.
													</p>

													<p>
														La finalul apelului de validare telefonică, Participantului îi
														vor fi comunicate toate detaliile referitoare la beneficiul
														acordat şi modalitatea de livrare a acestuia.
													</p>

													<p>
														Beneficiile constând în{' '}
														<b>set format din 2 (două) dispozitive IQOS 3 Duo</b> vor fi
														livrate Participanţilor validaţi prin intermediul serviciilor de
														curierat. În faza recepţionării beneficiului, Participantul
														trebuie să prezinte în original un document de identitate
														(CI/BI). În situaţia în care, la livrare, datele din CI/BI nu
														corespund cu cele declarate în procesul de validare, acesta va
														fi invalidat şi decăzut din drepturi.
													</p>

													<p>
														Toţi Participanţii vor completa un proces verbal de
														predare-primire a beneficiului. Acesta va fi înmânat
														reprezentantului Organizatorului şi va fi folosit pentru
														validare.
													</p>

													<p>
														Se va încerca o singură dată livrarea coletului conţinând
														beneficiul pentru care Participantul a fost validat la adresa şi
														în intervalul orar menţionat de către Participant la validarea
														telefonică. O nouă încercare de livrare se va face în momentul
														în care Participantul a sunat la Centrul de Asistenţă Clienţi şi
														a menţionat detaliile referitoare la data, ora şi adresa la care
														acesta poate fi contactat pentru livrare. Curierul nu are
														obligaţia de a oferi informaţii în legătură cu prezenta
														Promoţie. Pentru detalii în acest sens, participanţii trebuie să
														apeleze Centrul de Asistenţă Clienţi, conform Secţiunii 19.
														„Centrul de asistenţă clienţi”.
													</p>

													<p>
														În situaţia în care Participanţii nu au putut fi contactaţi
														pentru livrarea beneficiului validat, din cauze independente de
														Organizator şi nu au apelat Centrul de Asistenţă Clienţi,
														revendicând beneficiul nelivrat, până la data de{' '}
														<b>15 ianuarie 2020</b>, aceştia vor fi decăzuţi din dreptul de
														a-l mai revendica.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Tu şi IQOS - Provocarea lunii decembrie
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Promoţia se desfăşoară în cadrul platformei online Clubul IQOS
														în perioada{' '}
														<b>
															02 decembrie 2019 ora 00:00:00 – 31 decembrie 2019 ora
															23:59:59
														</b>
														.
													</p>
													<p>
														La prezenta promoţie pot participa toţi utilizatorii cu cont
														activ în cadrul platformei online IQOS Club.
													</p>

													<p>
														Participanţii care accesează platforma online IQOS Club pot
														accesa secţiunea special dedicată acestei promoţii, unde vor
														putea încărca conţinut sub forma de imagine. Pentru înscrierea
														în cadrul prezentei promoţii vor fi luate în considerare
														exclusiv imaginile încărcate de participanţi pe perioada de
														desfăşurare a promoţiei. Nu vor fi luate în considerare
														imaginile încărcate de participanţi anterior începerii prezentei
														promoţii şi nici cele încărcate de participanţi după termenul de
														finalizare al prezentei promoţii.
													</p>

													<p>
														Imaginea pe care utilizatorul doreşte să o încarce în cadrul
														platformei online IQOS Club trebuie să întrunească cumulativ
														următoarele condiţii de eligibilitate:
													</p>

													<ul className="dashLine">
														<li>
															fişierul trebuie să fie în format jpg, jpeg sau derivate ale
															acestuia;
														</li>
														<li>dimensiunea fişierului să nu depăşească 10 MB;</li>
														<li>
															sunt acceptate doar imagini care sunt inspirate sau inspiră
															persoanei privitoare legătură cu tematica lunii, aceasta
															fiind „Sărbătorile de iarnă cu IQOS”. În cadrul imaginilor
															trebuie să se regăsească cel puţin un dispozitiv IQOS sau
															parte componentă a acestuia;
														</li>
														<li>
															nu sunt acceptate imagini care conţin, dar fără a se limita
															la: limbaj ofensator sau violent, comentarii jignitoare sau
															discriminatorii privind rasa, etnia, religia, sexul,
															dizabilitatea, orientarea sexuală sau convingerile politice,
															link-uri sau comentarii care conțin materiale de conținut
															sexual explicit, atacuri asupra unor grupuri specifice sau
															orice comentarii menite să hărțuiască, să amenințe sau să
															abuzeze un individ, spam, link-uri externe sau fișiere care
															conțin viruși care ar putea deteriora funcționarea
															computerelor sau dispozitivelor mobile ale altor persoane,
															solicitări comerciale sau promovarea unui concurent
														</li>
														<li>
															Participantul care încarcă o imagine este direct responsabil
															şi răspunzător privind încălcarea dreptului de autor sau a
															drepturilor de proprietate intelectuală
														</li>
													</ul>

													<p>
														Un participant poate încărca oricât de multe imagini, cu
														respectarea condiţiilor de eligibilitate menţionate anterior.
													</p>

													<p>
														Ulterior încărcării imaginii de către Participanţi, toate
														imaginile vor fi supuse unui proces de validare de către
														Organizator. Doar imaginile aprobate de Organizator vor fi
														publicate în cadrul platformei online IQOS Club, în cadrul
														secţiunii special dedicate prezentei Promoţii. Participanţii
														înţeleg şi acceptă că Organizatorul va putea, la discreţia sa,
														să respingă imagini dintre cele încărcate de Participanţi pe
														perioada de desfăşurare a Promoţiei, deşi respectă tematica
														lunii. În mod nelimitativ, Organizatorul îşi rezervă dreptul de
														nu a aproba şi a nu publica în cadrul platformei online IQOS
														Club imagini care pot fi considerate, fără a se limita la,
														neclare, vizibile parţial, iligibile.
													</p>

													<p>
														Ulterior afişării în cadrul platformei online IQOS Club, în
														cadrul secţiunii special dedicate prezentei Promoţii,
														utilizatorii prezentei platforme online vor putea acorda
														aprecieri imaginilor propuse de ceilalţi Participanţi, aprobate
														de Organizator şi publicate de acesta din urmă în cadrul
														website-ului.
													</p>

													<p>
														Un utilizator, membru în cadrul IQOS Club, poate aprecia o
														imagine o singură dată. <br />
														Un utilizator, membru în cadrul IQOS Club, poate aprecia oricât
														de multe dintre imaginile prezente în cadrul secţiunii special
														dedicate prezentei Promoţii. <br />
														Un utilizator, membru în cadrul IQOS Club, poate aprecia
														imaginile propuse de alţi Participanţi şi aprobate de
														Organizator, precum şi propriile imagini propuse şi aprobate de
														Organizator. <br />
														Un utilizator poate aprecia o imagine propusă de un participant
														şi aprobată de Organizator doar pe perioada de desfăşurare a
														prezentei Promoţii.
													</p>

													<p>
														După finalizarea perioadei de desfăşurare a prezentei Promoţii
														se va întocmi un clasament al imaginilor încărcate de
														Participanţi în cadrul secţiunii special dedicate din cadrul
														platformei online şi care au acumulat aprecieri din partea
														utilizatorilor platformei IQOS Club.
													</p>

													<p>
														Clasamentul se va realiza în maxim 5 zile lucrătoare de la data
														finalizării Promoţiei şi va fi realizat în funcţie de numărul de
														aprecieri acumulate de imaginile încărcate de Participanţi în
														perioada de desfăşurare a Promoţiei, de la momentul încărcării
														acestora şi publicate de Organizator în cadrul platformei web
														până la încetarea Promoţiei, adică anterior datei de 31
														decembrie 2019 ora 23:59:59.
													</p>

													<p>
														Primele 5 (cinci) imagini din clasament determinate conform
														regulilor descrise anterior vor fi asociate Participanţilor care
														au încărcat imaginile respective în cadrul platformei web.
													</p>

													<p>
														În situaţia în care pe ultima poziţie vor fi mai multe imagini
														cu acelaşi număr de aprecieri, atunci departajarea se va face
														prin cumularea aprecierilor tuturor imaginilor încărcate pe
														perioada de desfăşurare a prezentei Promoţii de către
														Participanţii aflaţi la egalitate.
													</p>

													<p>
														În situaţia în care, în urma procesului de departajare menţionat
														anterior, nu se va putea diferenţia explicit un număr de 5
														(cinci) Participanţi unici, atunci se va lua în considerare
														prima imagine încărcată, în ordine cronologică, de către
														Participanţi, dintre cele care au determinat selectarea
														Participantului respectiv.{' '}
													</p>

													<p>
														În cazul în care un Participant deţine mai multe de 1 (o)
														imagine în clasament, atunci acesta va fi eligibil pentru a
														primi din partea Organizatorului 1 singur beneficiu acordat prin
														intermediul prezentei Promoţii. În acestă situaţie vor fi luate
														în considerare următoarele imagini din clasamentul realizat
														anterior, iar acţiunea poate fi reluată până la identificarea a
														5 (cinci) Participanţi unici.
													</p>

													<p>
														Un Participant poate primi din partea Organizatorului un singur
														beneficiu de acest tip, indiferent de numărul de imagini
														încărcate pe perioada Promoţiei şi indiferent dacă aceeaşi
														persoană deţine mai multe conturi de utilizator în cadrul
														platformei online IQOS Club.{' '}
													</p>

													<p>
														Primii 5 (cinci) Participanţi determinaţi conform regulilor
														descrise anterior vor primi gratuit, din partea Organizatorului,
														un <b>voucher Fashion Days</b> în valoare nominală unitară de
														1.000 RON (TVA inclus).
													</p>

													<p>
														Voucherul poate fi utilizat în cadrul unei achiziţii de pe
														website-ul{' '}
														<a href="http://www.fashiondays.ro/" target="_blank">
															www.fashiondays.ro
														</a>{' '}
														Voucherul poate fi utilizat în cadrul unei singure tranzacţii şi
														doar în termenul de valabilitate comunicat la livrarea acestuia.{' '}
														<br />
														Organizatorul nu este şi nu poate fi făcut responsabil privind
														termenii şi condiţiile de utilizare ale platformei partenere.
													</p>

													<p>
														Participanţii eligibili pentru a primit gratuit, din partea
														Organizatorului, un <b>voucher Fashion Days</b>
														vor fi contactaţi telefonic pentru validare la numărul de
														telefon completat şi confirmat de participant la crearea
														contului în cadrul platfomei online IQOS Club, într-un interval
														de maxim 5 zile lucrătoare de la data la care aceştia au devenit
														eligibili pentru a primit acest tip de beneficiu.
													</p>

													<p>
														Participanţii vor fi contactaţi de trei ori, în trei zile
														diferite, la intervale orare diferite, pentru a se realiza
														următoarele: identificare, validare, comunicarea detaliilor cu
														privire la livrarea premiului.
													</p>

													<p>
														Identificarea Participanţilor se va realiza pe baza datelor cu
														care acesta s-a înscris în cadrul platfomei online IQOS Club şi
														a celor comunicate în momentul validării telefonice (nume,
														prenume, data de naştere, adresă e-mai, CNP).{' '}
													</p>

													<p>
														Câştigătorii se obligă să menţioneze, în baza prevederilor
														Codului Fiscal (conform căruia plătitorii de venituri, cu regim
														de reţinere la sursă a impozitelor, au obligaţia să depună
														„Declaraţia informativă privind impozitul reţinut la sursă,
														veniturile din jocuri de noroc şi câştigurile/pierderile din
														investiţii, pe beneficiari de venit(D205)” privind calcularea şi
														reţinerea impozitului pentru fiecare beneficiar de venit, la
														organul fiscal competent, până la data de 31 ianuarie inclusiv a
														anului curent pentru anul expirat) codul său numeric personal
														din cartea de identitate. În cazul în care participantul refuză
														să indice codul său numeric personal, acestuia nu îi va fi
														acordat produsul, urmând a fi decăzut din drepturile de a
														reclama acest câştig. Această prevedere se aplică deoarece
														beneficiile acordate în cadrul prezentei Promoţii au valoarea
														mai mare de 600 RON.
													</p>

													<p>
														În situaţia în care, la momentul validării telefonice,
														Participantul declară că refuză să accepte beneficiul acordat,
														sau nu întrunește toate condiţiile necesare validării sale, în
														conformitate cu prezentul Regulament, acesta va fi invalidat şi
														decăzut din drepturi.
													</p>

													<p>
														În cazul în care nu se reuşeşte validarea Participantului din
														motive independente de Organizator şi acesta nu apelează Centrul
														de Asistenţă Clienţi în termen de 15 zile lucrătoare de la data
														finalizării Promoţiei, acesta va fi decăzut din drepturi.
													</p>

													<p>
														În cazul în care nu se reuşeşte validarea unui Participant, din
														motive independente de Organizator, se va relua procesul de
														identificare a Participanţilor aflaţi în afara primelor 5
														poziţii determinate iniţial, urmându-se acelaşi procedeu ca cel
														descris anterior.
													</p>

													<p>
														La finalul apelului de validare telefonică, Participantului îi
														vor fi comunicate toate detaliile referitoare la beneficiul
														acordat şi modalitatea de livrare a acestuia.
													</p>

													<p>
														Beneficiile constând în voucher Fashion Days vor fi livrate
														Participanţilor validaţi prin comunicare email, la adresa de
														email comunicată de Participant în cadrul procesului de
														validare.{' '}
													</p>

													<p>
														În situaţia în care Participanţii nu au primit emailul de
														livrare a beneficiului validat, din cauze independente de
														Organizator şi nu au apelat Centrul de Asistenţă Clienţi,
														revendicând beneficiul nelivrat, până la data de{' '}
														<b>01 februarie 2020</b>, aceştia vor fi decăzuţi din dreptul de
														a-l mai revendica.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Tu şi IQOS - Provocarea lunii ianuarie
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Promoţia se desfăşoară în cadrul platformei online Clubul IQOS
														în perioada{' '}
														<b>
															06 ianuarie 2020 ora 00:00:00 – 31 ianuarie 2020 ora
															23:59:59
														</b>
														.
													</p>

													<p>
														La prezenta promoţie pot participa toţi utilizatorii cu cont
														activ în cadrul platformei online IQOS Club.
													</p>

													<p>
														Participanţii care accesează platforma online IQOS Club pot
														accesa secţiunea special dedicată acestei promoţii, unde vor
														putea încărca conţinut sub formă de imagine. Pentru înscrierea
														în cadrul prezentei promoţii vor fi luate în considerare
														exclusiv imaginile încărcate de participanţi pe perioada de
														desfăşurare a promoţiei. Nu vor fi luate în considerare
														imaginile încărcate de participanţi anterior începerii prezentei
														promoţii şi nici cele încărcate de participanţi după termenul de
														finalizare al prezentei promoţii.
													</p>

													<p>
														Imaginea pe care utilizatorul doreşte să o încarce în cadrul
														platformei online IQOS Club trebuie să întrunească cumulativ
														următoarele condiţii de eligibilitate:
													</p>

													<ul className="dashLine">
														<li>
															fişierul trebuie să fie în format jpg, jpeg sau derivate ale
															acestuia;
														</li>

														<li>dimensiunea fişierului să nu depăşească 10 MB;</li>

														<li>
															sunt acceptate doar imagini care sunt inspirate sau inspiră
															persoanei privitoare legătură cu tematica lunii, aceasta
															fiind „Explorează natura”. În cadrul imaginilor trebuie să
															se regăsească cel puţin un dispozitiv IQOS sau parte
															componentă a acestuia;
														</li>

														<li>
															nu sunt acceptate imagini care conţin, dar fără a se limita
															la: limbaj ofensator sau violent, comentarii jignitoare sau
															discriminatorii privind rasa, etnia, religia, sexul,
															dizabilitatea, orientarea sexuală sau convingerile politice,
															link-uri sau comentarii care conțin materiale de conținut
															sexual explicit, atacuri asupra unor grupuri specifice sau
															orice comentarii menite să hărțuiască, să amenințe sau să
															abuzeze un individ, spam, link-uri externe sau fișiere care
															conțin viruși care ar putea deteriora funcționarea
															computerelor sau dispozitivelor mobile ale altor persoane,
															solicitări comerciale sau promovarea unui concurent
														</li>

														<li>
															Participantul care încarcă o imagine este direct responsabil
															şi răspunzător privind încălcarea dreptului de autor sau a
															drepturilor de proprietate intelectuală
														</li>
													</ul>

													<p>
														Un participant poate încărca oricât de multe imagini, cu
														respectarea condiţiilor de eligibilitate menţionate anterior.
													</p>

													<p>
														Ulterior încărcării imaginii de către Participanţi, toate
														imaginile vor fi supuse unui proces de validare de către
														Organizator. Doar imaginile aprobate de Organizator vor fi
														publicate în cadrul platformei online IQOS Club, în cadrul
														secţiunii special dedicate prezentei Promoţii. Participanţii
														înţeleg şi acceptă că Organizatorul va putea, la discreţia sa,
														să respingă imagini dintre cele încărcate de Participanţi pe
														perioada de desfăşurare a Promoţiei, deşi respectă tematica
														lunii. În mod nelimitativ, Organizatorul îşi rezervă dreptul de
														nu a aproba şi a nu publica în cadrul platformei online IQOS
														Club imagini care pot fi considerate, fără a se limita la,
														neclare, vizibile parţial, iligibile.
													</p>

													<p>
														Ulterior afişării în cadrul platformei online IQOS Club, în
														cadrul secţiunii special dedicate prezentei Promoţii,
														utilizatorii prezentei platforme online vor putea acorda
														aprecieri imaginilor propuse de ceilalţi Participanţi, aprobate
														de Organizator şi publicate de acesta din urmă în cadrul
														website-ului.
													</p>

													<p>
														Un utilizator, membru în cadrul IQOS Club, poate aprecia o
														imagine o singură dată. <br />
														Un utilizator, membru în cadrul IQOS Club, poate aprecia oricât
														de multe dintre imaginile prezente în cadrul secţiunii special
														dedicate prezentei Promoţii. <br />
														Un utilizator, membru în cadrul IQOS Club, poate aprecia
														imaginile propuse de alţi Participanţi şi aprobate de
														Organizator, precum şi propriile imagini propuse şi aprobate de
														Organizator. <br />
														Un utilizator poate aprecia o imagine propusă de un participant
														şi aprobată de Organizator doar pe perioada de desfăşurare a
														prezentei Promoţii.
													</p>

													<p>
														După finalizarea perioadei de desfăşurare a prezentei Promoţii
														se va întocmi un clasament al imaginilor încărcate de
														Participanţi în cadrul secţiunii special dedicate din cadrul
														platformei online şi care au acumulat aprecieri din partea
														utilizatorilor platformei IQOS Club.
													</p>

													<p>
														Clasamentul se va realiza în maxim 5 zile lucrătoare de la data
														finalizării Promoţiei şi va fi realizat în funcţie de numărul de
														aprecieri acumulate de imaginile încărcate de Participanţi în
														perioada de desfăşurare a Promoţiei, de la momentul încărcării
														acestora şi publicate de Organizator în cadrul platformei web
														până la încetarea Promoţiei, adică anterior datei de 31 ianuarie
														2020 ora 23:59:59.
													</p>

													<p>
														Primele 5 (cinci) imagini din clasament determinate conform
														regulilor descrise anterior vor fi asociate Participanţilor care
														au încărcat imaginile respective în cadrul platformei web.
													</p>

													<p>
														În situaţia în care pe ultima poziţie vor fi mai multe imagini
														cu acelaşi număr de aprecieri, atunci departajarea se va face
														prin cumularea aprecierilor tuturor imaginilor încărcate pe
														perioada de desfăşurare a prezentei Promoţii de către
														Participanţii aflaţi la egalitate.
													</p>

													<p>
														În situaţia în care, în urma procesului de departajare menţionat
														anterior, nu se va putea diferenţia explicit un număr de 5
														(cinci) Participanţi unici, atunci se va lua în considerare
														prima imagine încărcată, în ordine cronologică, de către
														Participanţi, dintre cele care au determinat selectarea
														Participantului respectiv.{' '}
													</p>

													<p>
														În cazul în care un Participant deţine mai multe de 1 (o)
														imagine în clasament, atunci acesta va fi eligibil pentru a
														primi din partea Organizatorului 1 singur beneficiu acordat prin
														intermediul prezentei Promoţii. În acestă situaţie vor fi luate
														în considerare următoarele imagini din clasamentul realizat
														anterior, iar acţiunea poate fi reluată până la identificarea a
														5 (cinci) Participanţi unici.
													</p>

													<p>
														Un Participant poate primi din partea Organizatorului un singur
														beneficiu de acest tip, indiferent de numărul de imagini
														încărcate pe perioada Promoţiei şi indiferent dacă aceeaşi
														persoană deţine mai multe conturi de utilizator în cadrul
														platformei online IQOS Club.{' '}
													</p>

													<p>
														Primii 5 (cinci) Participanţi determinaţi conform regulilor
														descrise anterior vor primi gratuit, din partea Organizatorului,{' '}
														<b>
															un set format din cameră video sport Go Pro şi kit Starter
															cu accesorii
														</b>{' '}
														în valoare nominală unitară de 2.031 RON (TVA inclus). <br />
														Valoarea totală a beneficiilor acordate în cadrul prezentei
														promoţii este de 10.155 RON (TVA inclus).
													</p>

													<p>
														Participanţii eligibili pentru a primit gratuit, din partea
														Organizatorului, un{' '}
														<b>
															set format din cameră video sport Go Pro şi kit Starter cu
															accesorii
														</b>{' '}
														vor fi contactaţi telefonic pentru validare la numărul de
														telefon completat şi confirmat de participant la crearea
														contului în cadrul platfomei online IQOS Club, într-un interval
														de maxim 5 zile lucrătoare de la data la care aceştia au devenit
														eligibili pentru a primit acest tip de beneficiu.
													</p>

													<p>
														Participanţii vor fi contactaţi de trei ori, în trei zile
														diferite, la intervale orare diferite, pentru a se realiza
														următoarele: identificare, validare, comunicarea detaliilor cu
														privire la livrarea premiului.
													</p>

													<p>
														Identificarea Participanţilor se va realiza pe baza datelor cu
														care acesta s-a înscris în cadrul platfomei online IQOS Club şi
														a celor comunicate în momentul validării telefonice (nume,
														prenume, data de naştere, adresă e-mail, CNP).{' '}
													</p>

													<p>
														Câştigătorii se obligă să menţioneze, în baza prevederilor
														Codului Fiscal (conform căruia plătitorii de venituri, cu regim
														de reţinere la sursă a impozitelor, au obligaţia să depună
														„Declaraţia informativă privind impozitul reţinut la sursă,
														veniturile din jocuri de noroc şi câştigurile/pierderile din
														investiţii, pe beneficiari de venit(D205)” privind calcularea şi
														reţinerea impozitului pentru fiecare beneficiar de venit, la
														organul fiscal competent, până la data de 31 ianuarie inclusiv a
														anului curent pentru anul expirat) codul său numeric personal
														din cartea de identitate. În cazul în care participantul refuză
														să indice codul său numeric personal, acestuia nu îi va fi
														acordat produsul, urmând a fi decăzut din drepturile de a
														reclama acest câştig. Această prevedere se aplică deoarece
														beneficiile acordate în cadrul prezentei Promoţii au valoarea
														mai mare de 600 RON.
													</p>

													<p>
														În situaţia în care, la momentul validării telefonice,
														Participantul declară că refuză să accepte beneficiul acordat,
														sau nu întrunește toate condiţiile necesare validării sale, în
														conformitate cu prezentul Regulament, acesta va fi invalidat şi
														decăzut din drepturi.
													</p>

													<p>
														În cazul în care nu se reuşeşte validarea Participantului din
														motive independente de Organizator şi acesta nu apelează Centrul
														de Asistenţă Clienţi în termen de 15 zile lucrătoare de la data
														finalizării Promoţiei, acesta va fi decăzut din drepturi.
													</p>

													<p>
														În cazul în care nu se reuşeşte validarea unui Participant, din
														motive independente de Organizator, se va relua procesul de
														identificare a Participanţilor aflaţi în afara primelor 5
														poziţii determinate iniţial, urmându-se acelaşi procedeu ca cel
														descris anterior.
													</p>

													<p>
														La finalul apelului de validare telefonică, Participantului îi
														vor fi comunicate toate detaliile referitoare la beneficiul
														acordat şi modalitatea de livrare a acestuia.
													</p>

													<p>
														Beneficiile constând în{' '}
														<b>
															set format din cameră video sport Go Pro şi kit Starter cu
															accesorii
														</b>{' '}
														vor fi livrate Participanţilor validaţi prin intermediul
														serviciilor de curierat. În faza recepţionării beneficiului,
														Participantul trebuie să prezinte în original un document de
														identitate (CI/BI). În situaţia în care, la livrare, datele din
														CI/BI nu corespund cu cele declarate în procesul de validare,
														acesta va fi invalidat şi decăzut din drepturi.
													</p>

													<p>
														Toţi Participanţii vor completa un proces verbal de
														predare-primire a beneficiului. Acesta va fi înmânat
														reprezentantului Organizatorului şi va fi folosit pentru
														validare.
													</p>

													<p>
														Se va încerca o singură dată livrarea coletului conţinând
														beneficiul pentru care Participantul a fost validat la adresa şi
														în intervalul orar menţionat de către Participant la validarea
														telefonică. O nouă încercare de livrare se va face în momentul
														în care Participantul a sunat la Centrul de Asistenţă Clienţi şi
														a menţionat detaliile referitoare la data, ora şi adresa la care
														acesta poate fi contactat pentru livrare. Curierul nu are
														obligaţia de a oferi informaţii în legătură cu prezenta
														Promoţie. Pentru detalii în acest sens, participanţii trebuie să
														apeleze Centrul de Asistenţă Clienţi, conform Secţiunii 19.
														„Centrul de asistenţă clienţi”.
													</p>

													<p>
														În situaţia în care Participanţii nu au putut fi contactaţi
														pentru livrarea beneficiului validat, din cauze independente de
														Organizator şi nu au apelat Centrul de Asistenţă Clienţi,
														revendicând beneficiul nelivrat, până la data de{' '}
														<b>15 februarie 2020</b>, aceştia vor fi decăzuţi din dreptul de
														a-l mai revendica.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Tu şi IQOS - Provocarea lunii februarie
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Promoţia se desfăşoară în cadrul platformei online Clubul IQOS
														în perioada{' '}
														<b>
															03 februarie 2020 ora 00:00:00 – 28 februarie 2020 ora
															23:59:59
														</b>
														.
													</p>

													<p>
														La prezenta promoţie pot participa toţi utilizatorii cu cont
														activ în cadrul platformei online IQOS Club.
													</p>
													<p>
														Participanţii care accesează platforma online IQOS Club pot
														accesa secţiunea special dedicată acestei promoţii, unde vor
														putea încărca conţinut sub formă de text. Pentru înscrierea în
														cadrul prezentei promoţii vor fi luate în considerare exclusiv
														textele încărcate de participanţi pe perioada de desfăşurare a
														promoţiei. Nu vor fi luate în considerare textele încărcate de
														participanţi anterior începerii prezentei promoţii şi nici cele
														încărcate de participanţi după termenul de finalizare al
														prezentei promoţii.
													</p>

													<p>
														Textul pe care utilizatorul doreşte să îl încarce în cadrul
														platformei online IQOS Club trebuie să întrunească cumulativ
														următoarele condiţii de eligibilitate:
													</p>
													<ul className="dashLine">
														<li>
															sunt acceptate doar textele care sunt inspirate sau inspiră
															persoanei privitoare/cititoare legătură cu tematica lunii,
															aceasta fiind „Momentul tău preferat cu IQOS”;
														</li>
														<li>
															nu sunt acceptate texte care contravin termenilor şi
															condiţiilor referitoare la conţinut generat de utilizatori
															(„CGU”), cum ar fi, dar fără a se limita la: limbaj
															ofensator sau violent, comentarii jignitoare sau
															discriminatorii privind rasa, etnia, religia, sexul,
															dizabilitatea, orientarea sexuală sau convingerile politice,
															link-uri sau comentarii care conțin materiale de conținut
															sexual explicit, atacuri asupra unor grupuri specifice sau
															orice comentarii menite să hărțuiască, să amenințe sau să
															abuzeze un individ, spam, link-uri externe sau fișiere care
															conțin viruși care ar putea deteriora funcționarea
															computerelor sau dispozitivelor mobile ale altor persoane,
															solicitări comerciale sau promovarea unui concurent;
														</li>
														<li>
															Participantul care încarcă un text este direct responsabil
															şi răspunzător privind încălcarea dreptului de autor sau a
															drepturilor de proprietate intelectuală
														</li>
													</ul>

													<p>
														Un participant poate încărca oricât de multe texte, cu
														respectarea condiţiilor de eligibilitate menţionate anterior.
													</p>

													<p>
														Ulterior încărcării textului/textelor de către Participanţi,
														toate acestea vor fi supuse unui proces de validare de către
														Organizator. Doar textele aprobate de Organizator vor fi
														publicate în cadrul platformei online IQOS Club, în cadrul
														secţiunii special dedicate prezentei Promoţii. Participanţii
														înţeleg şi acceptă că Organizatorul va putea, la discreţia sa,
														să respingă texte dintre cele încărcate de Participanţi pe
														perioada de desfăşurare a Promoţiei, deşi respectă tematica
														lunii. În mod nelimitativ, Organizatorul îşi rezervă dreptul de
														nu a aproba şi a nu publica în cadrul platformei online IQOS
														Club texte care pot fi considerate, fără a se limita la,
														inconsistente, incoerente.
													</p>

													<p>
														Ulterior afişării în cadrul platformei online IQOS Club, în
														cadrul secţiunii special dedicate prezentei Promoţii,
														utilizatorii prezentei platforme online vor putea acorda
														aprecieri textelor propuse de ceilalţi Participanţi, aprobate de
														Organizator şi publicate de acesta din urmă în cadrul
														website-ului.
													</p>

													<p>
														Un utilizator, membru în cadrul IQOS Club, poate aprecia un text
														o singură dată.
														<br />
														Un utilizator, membru în cadrul IQOS Club, poate aprecia oricât
														de multe dintre textele prezente în cadrul secţiunii special
														dedicate prezentei Promoţii.
														<br />
														Un utilizator, membru în cadrul IQOS Club, poate aprecia textele
														propuse de alţi Participanţi şi aprobate de Organizator, precum
														şi propriile texte propuse şi aprobate de Organizator.
														<br />
														Un utilizator poate aprecia un text propus de un participant şi
														aprobată de Organizator doar pe perioada de desfăşurare a
														prezentei Promoţii.
													</p>

													<p>
														După finalizarea perioadei de desfăşurare a prezentei Promoţii
														se va întocmi un clasament al textelor încărcate de Participanţi
														în cadrul secţiunii special dedicate din cadrul platformei
														online şi care au acumulat aprecieri din partea utilizatorilor
														platformei IQOS Club.
													</p>

													<p>
														Clasamentul se va realiza în maxim 5 zile lucrătoare de la data
														finalizării Promoţiei şi va fi realizat în funcţie de numărul de
														aprecieri acumulate de textele încărcate de Participanţi în
														perioada de desfăşurare a Promoţiei, de la momentul încărcării
														acestora şi publicate de Organizator în cadrul platformei web
														până la încetarea Promoţiei, adică anterior datei de 28
														februarie 2020 ora 23:59:59.
													</p>

													<p>
														Primele 10 (zece) texte din clasament determinate conform
														regulilor descrise anterior vor fi asociate Participanţilor care
														au încărcat textele respective în cadrul platformei web.
													</p>

													<p>
														În situaţia în care pe ultima poziţie vor fi mai multe texte cu
														acelaşi număr de aprecieri, atunci departajarea se va face prin
														cumularea aprecierilor tuturor textelor încărcate pe perioada de
														desfăşurare a prezentei Promoţii de către Participanţii aflaţi
														la egalitate.
													</p>

													<p>
														În situaţia în care, în urma procesului de departajare menţionat
														anterior, nu se va putea diferenţia explicit un număr de 10
														(zece) Participanţi unici, atunci se va lua în considerare
														primul text încărcat, în ordine cronologică, de către
														Participanţi, dintre cele care au determinat selectarea
														Participantului respectiv.{' '}
													</p>

													<p>
														În cazul în care un Participant deţine mai multe de 1 (un) text
														în clasament, atunci acesta va fi eligibil pentru a primi din
														partea Organizatorului 1 singur beneficiu acordat prin
														intermediul prezentei Promoţii. În acestă situaţie vor fi luate
														în considerare următoarele texte din clasamentul realizat
														anterior, iar acţiunea poate fi reluată până la identificarea a
														10 (zece) Participanţi unici.
													</p>

													<p>
														Un Participant poate primi din partea Organizatorului un singur
														beneficiu de acest tip, indiferent de numărul de texte încărcate
														pe perioada Promoţiei şi indiferent dacă aceeaşi persoană deţine
														mai multe conturi de utilizator în cadrul platformei online IQOS
														Club.{' '}
													</p>

													<p>
														Primii 10 (zece) Participanţi determinaţi conform regulilor
														descrise anterior vor primi gratuit, din partea Organizatorului,
														la alegerea Participantului, un <b>set de accesorii IQOS</b>{' '}
														format din{' '}
													</p>

													<ul className="dashLine">
														<li>
															Căpăcel colorat IQOS 2.4+ şi Suport din piele IQOS 2.4+{' '}
															<b>SAU</b>
														</li>
														<li>
															Căpăcel colorat IQOS 3DUO şi Carcasă din silicon IQOS 3 DUO
															<b>SAU</b>
														</li>
														<li>
															Căpăcel colorat IQOS 3 Multi şi Husă din silicon IQOS Multi.
														</li>
													</ul>

													<p>
														Valoarea totală a beneficiilor acordate în cadrul prezentei
														promoţii este de 1.000 RON (TVA inclus).
													</p>

													<p>
														Participanţii eligibili pentru a primit gratuit, din partea
														Organizatorului, un <b>set de accesorii IQOS</b> vor fi
														contactaţi telefonic pentru validare la numărul de telefon
														completat şi confirmat de participant la crearea contului în
														cadrul platfomei online IQOS Club, într-un interval de maxim 5
														zile lucrătoare de la data la care aceştia au devenit eligibili
														pentru a primit acest tip de beneficiu.
													</p>

													<p>
														Participanţii vor fi contactaţi de trei ori, în trei zile
														diferite, la intervale orare diferite, pentru a se realiza
														următoarele: identificare, validare, comunicarea detaliilor cu
														privire la livrarea beneficiului.
													</p>

													<p>
														În cadrul procesului de validare şi comunicare detalii cu
														privire la livrarea beneficiului, Participanţii vor putea să
														opteze pentru set-ul de accesorii şi culorile dorite, în limita
														stocului disponibil. Disponibilitatea va fi comunicată telefonic
														de către reprezentantul Organizatorului, în timpul convorbirii
														telefonice.
													</p>

													<p>
														Identificarea Participanţilor se va realiza pe baza datelor cu
														care acesta s-a înscris în cadrul platfomei online IQOS Club şi
														a celor comunicate în momentul validării telefonice (nume,
														prenume, data de naştere, adresă e-mai, CNP).{' '}
													</p>

													<p>
														În situaţia în care, la momentul validării telefonice,
														Participantul declară că refuză să accepte beneficiul acordat,
														sau nu întrunește toate condiţiile necesare validării sale, în
														conformitate cu prezentul Regulament, acesta va fi invalidat şi
														decăzut din drepturi.
													</p>

													<p>
														În cazul în care nu se reuşeşte validarea Participantului din
														motive independente de Organizator şi acesta nu apelează Centrul
														de Asistenţă Clienţi în termen de 15 zile lucrătoare de la data
														finalizării Promoţiei, acesta va fi decăzut din drepturi.
													</p>

													<p>
														În cazul în care nu se reuşeşte validarea unui Participant, din
														motive independente de Organizator, se va relua procesul de
														identificare a Participanţilor aflaţi în afara primelor 5
														poziţii determinate iniţial, urmându-se acelaşi procedeu ca cel
														descris anterior.
													</p>

													<p>
														La finalul apelului de validare telefonică, Participantului îi
														vor fi comunicate toate detaliile referitoare la beneficiul
														acordat şi modalitatea de livrare a acestuia.
													</p>

													<p>
														Beneficiile constând set de accesorii IQOS vor fi livrate
														Participanţilor validaţi prin intermediul serviciilor de
														curierat. În faza recepţionării beneficiului, Participantul
														trebuie să prezinte în original un document de identitate
														(CI/BI). În situaţia în care, la livrare, datele din CI/BI nu
														corespund cu cele declarate în procesul de validare, acesta va
														fi invalidat şi decăzut din drepturi.
													</p>

													<p>
														Toţi Participanţii vor completa un proces verbal de
														predare-primire a beneficiului. Acesta va fi înmânat
														reprezentantului Organizatorului şi va fi folosit pentru
														validare.
													</p>

													<p>
														Se va încerca o singură dată livrarea coletului conţinând
														beneficiul pentru care Participantul a fost validat la adresa şi
														în intervalul orar menţionat de către Participant la validarea
														telefonică. O nouă încercare de livrare se va face în momentul
														în care Participantul a sunat la Centrul de Asistenţă Clienţi şi
														a menţionat detaliile referitoare la data, ora şi adresa la care
														acesta poate fi contactat pentru livrare. Curierul nu are
														obligaţia de a oferi informaţii în legătură cu prezenta
														Promoţie. Pentru detalii în acest sens, participanţii trebuie să
														apeleze Centrul de Asistenţă Clienţi, conform Secţiunii 19.
														„Centrul de asistenţă clienţi”.
													</p>

													<p>
														În situaţia în care Participanţii nu au putut fi contactaţi
														pentru livrarea beneficiului validat, din cauze independente de
														Organizator şi nu au apelat Centrul de Asistenţă Clienţi,
														revendicând beneficiul nelivrat, până la data de 15 martie 2020,
														aceştia vor fi decăzuţi din dreptul de a-l mai revendica.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Tu şi IQOS - Provocarea lunii martie{' '}
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Promoţia se desfăşoară în cadrul platformei online IQOS Club în
														perioada <b>02 martie 2020 – 31 martie 2020 (inclusiv)</b>.
													</p>
													<p>
														La prezenta promoţie pot participa toţi utilizatorii cu cont
														activ în cadrul prezentei platforme online.
													</p>
													<p>
														Participanţii care accesează platforma online IQOS Club pot
														accesa secţiunea special dedicată acestei promoţii, unde vor
														putea încărca conţinut sub formă de text. Pentru înscrierea în
														cadrul prezentei promoţii vor fi luate în considerare exclusiv
														textele încărcate de participanţi pe perioada de desfăşurare a
														promoţiei. Nu vor fi luate în considerare textele încărcate de
														participanţi anterior începerii prezentei promoţii şi nici cele
														încărcate de participanţi după termenul de finalizare al
														prezentei promoţii.
													</p>
													<p>
														Textul pe care utilizatorul doreşte să îl încarce în cadrul
														platformei online IQOS Club trebuie să întrunească cumulativ
														următoarele condiţii de eligibilitate:
													</p>

													<ul className="dashLine">
														<li>
															sunt acceptate doar textele care sunt inspirate sau inspiră
															persoanei privitoare/cititoare legătură cu tematica lunii,
															aceasta fiind „
															<b>Alegeri care ţi-au adus o perspectivă fresh</b>”;
														</li>
														<li>
															nu sunt acceptate texte care contravin termenilor şi
															condiţiilor referitoare la conţinut generat de utilizatori
															(„CGU”), cum ar fi, dar fără a se limita la: limbaj
															ofensator sau violent, comentarii jignitoare sau
															discriminatorii privind rasa, etnia, religia, sexul,
															dizabilitatea, orientarea sexuală sau convingerile politice,
															link-uri sau comentarii care conțin materiale de conținut
															sexual explicit, atacuri asupra unor grupuri specifice sau
															orice comentarii menite să hărțuiască, să amenințe sau să
															abuzeze un individ, spam, link-uri externe sau fișiere care
															conțin viruși care ar putea deteriora funcționarea
															computerelor sau dispozitivelor mobile ale altor persoane,
															solicitări comerciale sau promovarea unui concurent;
														</li>
														<li>
															Participantul care încarcă un text este direct responsabil
															şi răspunzător privind încălcarea dreptului de autor sau a
															drepturilor de proprietate intelectuală
														</li>
													</ul>
													<p>
														Un participant poate încărca oricât de multe texte, cu
														respectarea condiţiilor de eligibilitate menţionate anterior.
													</p>
													<p>
														Ulterior încărcării textului/textelor de către Participanţi,
														toate acestea vor fi supuse unui proces de validare de către
														Organizator. Doar textele aprobate de Organizator vor fi
														publicate în cadrul platformei online IQOS Club, în cadrul
														secţiunii special dedicate prezentei Promoţii. Participanţii
														înţeleg şi acceptă că Organizatorul va putea, la discreţia sa,
														să respingă texte dintre cele încărcate de Participanţi pe
														perioada de desfăşurare a Promoţiei, deşi respectă tematica
														lunii. În mod nelimitativ, Organizatorul îşi rezervă dreptul de
														nu a aproba şi a nu publica în cadrul platformei online IQOS
														Club texte care pot fi considerate, fără a se limita la,
														inconsistente, incoerente.
													</p>
													<p>
														Ulterior afişării în cadrul platformei online IQOS Club, în
														cadrul secţiunii special dedicate prezentei Promoţii,
														utilizatorii prezentei platforme online vor putea acorda
														aprecieri textelor propuse de ceilalţi Participanţi, aprobate de
														Organizator şi publicate de acesta din urmă în cadrul
														website-ului.
													</p>
													<p>
														Un utilizator, membru în cadrul IQOS Club, poate aprecia un text
														o singură dată. <br />
														Un utilizator, membru în cadrul IQOS Club, poate aprecia oricât
														de multe dintre textele prezente în cadrul secţiunii special
														dedicate prezentei Promoţii.
														<br />
														Un utilizator, membru în cadrul IQOS Club, poate aprecia textele
														propuse de alţi Participanţi şi aprobate de Organizator, precum
														şi propriile texte propuse şi aprobate de Organizator.
														<br />
														Un utilizator, membru în cadrul IQOS Club, poate aprecia un text
														propus de un participant şi aprobată de Organizator doar pe
														perioada de desfăşurare a prezentei Promoţii.
													</p>
													<p>
														După finalizarea perioadei de desfăşurare a prezentei Promoţii
														se va întocmi un clasament al textelor încărcate de Participanţi
														în cadrul secţiunii special dedicate din cadrul platformei
														online şi care au acumulat aprecieri din partea utilizatorilor
														platformei IQOS Club.
													</p>
													<p>
														Clasamentul se va realiza în maxim 5 zile lucrătoare de la data
														finalizării Promoţiei şi va fi realizat în funcţie de numărul de
														aprecieri acumulate de textele încărcate de Participanţi în
														perioada de desfăşurare a Promoţiei, de la momentul încărcării
														acestora şi publicate de Organizator în cadrul platformei web
														până la încetarea Promoţiei, adică anterior datei de 31 martie
														2020 (inclusiv).
													</p>
													<p>
														Primele 5 (cinci) texte din clasament determinate conform
														regulilor descrise anterior vor fi asociate Participanţilor care
														au încărcat textele respective în cadrul platformei web.
													</p>
													<p>
														În situaţia în care pe ultima poziţie vor fi mai multe texte cu
														acelaşi număr de aprecieri, atunci departajarea se va face prin
														cumularea aprecierilor tuturor textelor încărcate pe perioada de
														desfăşurare a prezentei Promoţii de către Participanţii aflaţi
														la egalitate.
													</p>
													<p>
														În situaţia în care, în urma procesului de departajare menţionat
														anterior, nu se va putea diferenţia explicit un număr de 5
														(cinci) Participanţi unici, atunci se va lua în considerare
														primul text încărcat, în ordine cronologică, de către
														Participanţi, dintre cele care au determinat selectarea
														Participantului respectiv.{' '}
													</p>
													<p>
														În cazul în care un Participant deţine mai multe de 1 (un) text
														în clasament, atunci acesta va fi eligibil pentru a primi din
														partea Organizatorului 1 singur beneficiu acordat prin
														intermediul prezentei Promoţii. În acestă situaţie vor fi luate
														în considerare următoarele texte din clasamentul realizat
														anterior, iar acţiunea poate fi reluată până la identificarea a
														5 (cinci) Participanţi unici.
													</p>
													<p>
														Un Participant poate primi din partea Organizatorului un singur
														beneficiu de acest tip, indiferent de numărul de texte încărcate
														pe perioada Promoţiei şi indiferent dacă aceeaşi persoană deţine
														mai multe conturi de utilizator în cadrul platformei online IQOS
														Club.{' '}
													</p>
													<p>
														Primii 5 (cinci) Participanţi determinaţi conform regulilor
														descrise anterior vor primi gratuit, din partea Organizatorului,
														câte <b>500 puncte</b> în cadrul platformei online IQOS Club.
														Punctele se vor acumula în contul Participanţilor şi vor fi
														alocate după finalizarea realizării clasamentului, aşa cum acest
														proces este descris în cadrul prezentului regulament.
													</p>
													<p>
														Identificarea Participanţilor se va realiza pe baza datelor cu
														care acesta s-a înscris în cadrul platfomei online IQOS Club.{' '}
													</p>
													<p>
														Participanţii vor primi notificare în contul lor din cadrul
														platformei IQOS Club după alocarea punctelor în contul lor, iar
														numele acestora, împreună cu beneficiul acordat, vor fi
														publicate în cadrul platformei IQOS Club în cadrul secţiunii
														„Listă câștigători”.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Tu şi IQOS - Provocarea lunii aprilie{' '}
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Promoţia se desfăşoară în cadrul platformei online Clubul IQOS
														în perioada{' '}
														<b>
															01 aprilie 2020 ora 00:00:00 – 30 aprilie 2020 ora 23:59:59.
														</b>
													</p>
													<p>
														La prezenta promoţie pot participa toţi utilizatorii cu cont
														activ în cadrul platformei online IQOS Club.
													</p>
													<p>
														Participanţii care accesează platforma online IQOS Club pot
														accesa secţiunea special dedicată acestei promoţii, unde vor
														putea încărca conţinut sub forma de imagine. Pentru înscrierea
														în cadrul prezentei promoţii vor fi luate în considerare
														exclusiv imaginile încărcate de participanţi pe perioada de
														desfăşurare a promoţiei. Nu vor fi luate în considerare
														imaginile încărcate de participanţi anterior începerii prezentei
														promoţii şi nici cele încărcate de participanţi după termenul de
														finalizare al prezentei promoţii.
													</p>
													<p>
														Imaginea pe care utilizatorul doreşte să o încarce în cadrul
														platformei online IQOS Club trebuie să întrunească cumulativ
														următoarele condiţii de eligibilitate:
													</p>
													<ul className="dashLine">
														<li>
															fişierul trebuie să fie în format jpg, jpeg sau derivate ale
															acestuia;
														</li>
														<li>dimensiunea fişierului să nu depăşească 10 MB;</li>
														<li>
															sunt acceptate doar imagini care sunt inspirate sau inspiră
															persoanei privitoare legătură cu tematica lunii, aceasta
															fiind „Cum arată dimineţile tale cu IQOS”. În cadrul
															imaginilor trebuie să se regăsească cel puţin un dispozitiv
															IQOS sau parte componentă a acestuia;
														</li>
														<li>
															nu sunt acceptate imagini care conţin, dar fără a se limita
															la: limbaj ofensator sau violent, comentarii jignitoare sau
															discriminatorii privind rasa, etnia, religia, sexul,
															dizabilitatea, orientarea sexuală sau convingerile politice,
															link-uri sau comentarii care conțin materiale de conținut
															sexual explicit, atacuri asupra unor grupuri specifice sau
															orice comentarii menite să hărțuiască, să amenințe sau să
															abuzeze un individ, spam, link-uri externe sau fișiere care
															conțin viruși care ar putea deteriora funcționarea
															computerelor sau dispozitivelor mobile ale altor persoane,
															solicitări comerciale sau promovarea unui concurent;
														</li>
														<li>
															Participantul care încarcă o imagine este direct
															responsabilă şi răspunzătoare privind încălcarea dreptului
															de autor sau a drepturilor de proprietate intelectuală;
														</li>
													</ul>
													<p>
														Un participant poate încărca oricât de multe imagini, cu
														respectarea condiţiilor de eligibilitate menţionate anterior.
													</p>
													<p>
														Ulterior încărcării imaginii de către Participanţi, toate
														imaginile vor fi supuse unui proces de validare de către
														Organizator. Doar imaginile aprobate de Organizator vor fi
														publicate în cadrul platformei online IQOS Club, în cadrul
														secţiunii special dedicate prezentei Promoţii. Participanţii
														înţeleg şi acceptă că Organizatorul va putea, la discreţia sa,
														să respingă imagini dintre cele încărcate de Participanţi pe
														perioada de desfăşurare a Promoţiei, deşi respectă tematica
														lunii. În mod nelimitativ, Organizatorul îşi rezervă dreptul de
														nu a aproba şi a nu publica în cadrul platformei online IQOS
														Club imagini care pot fi considerate, fără a se limita la,
														neclare, vizibile parţial, iligibile.
													</p>
													<p>
														Ulterior afişării în cadrul platformei online IQOS Club, în
														cadrul secţiunii special dedicate prezentei Promoţii,
														utilizatorii prezentei platforme online vor putea acorda
														aprecieri imaginilor propuse de ceilalţi Participanţi, aprobate
														de Organizator şi publicate de acesta din urmă în cadrul
														website-ului.
													</p>
													<p>
														Un utilizator, membru în cadrul IQOS Club, poate aprecia o
														imagine o singură dată. Un utilizator, membru în cadrul IQOS
														Club, poate aprecia oricât de multe dintre imaginile prezente în
														cadrul secţiunii special dedicate prezentei Promoţii. Un
														utilizator, membru în cadrul IQOS Club, poate aprecia imaginile
														propuse de alţi Participanţi şi aprobate de Organizator, precum
														şi propriile imagini propuse şi aprobate de Organizator. Un
														utilizator poate aprecia o imagine propusă de un participant şi
														aprobată de Organizator doar pe perioada de desfăşurare a
														prezentei Promoţii.
													</p>
													<p>
														După finalizarea perioadei de desfăşurare a prezentei Promoţii
														se va întocmi un clasament al imaginilor încărcate de
														Participanţi în cadrul secţiunii special dedicate din cadrul
														platformei online şi care au acumulat aprecieri din partea
														utilizatorilor platformei IQOS Club.
													</p>
													<p>
														Clasamentul se va realiza în maxim 5 zile lucrătoare de la data
														finalizării Promoţiei şi va fi realizat în funcţie de numărul de
														aprecieri acumulate de imaginile încărcate de Participanţi în
														perioada de desfăşurare a Promoţiei, de la momentul încărcării
														acestora şi publicate de Organizator în cadrul platformei web
														până la încetarea Promoţiei, adică anterior datei de 30 aprilie
														2020 ora 23:59:59.
													</p>
													<p>
														Primele <b>3 (trei)</b> imagini din clasament determinate
														conform regulilor descrise anterior vor fi asociate
														Participanţilor care au încărcat imaginile respective în cadrul
														platformei web.
													</p>
													<p>
														În situaţia în care pe ultima poziţie vor fi mai multe imagini
														cu acelaşi număr de aprecieri, atunci departajarea se va face
														prin cumularea aprecierilor tuturor imaginilor încărcate pe
														perioada de desfăşurare a prezentei Promoţii de către
														Participanţii aflaţi la egalitate.
													</p>
													<p>
														În situaţia în care, în urma procesului de departajare menţionat
														anterior, nu se va putea diferenţia explicit un număr de{' '}
														<b>3 (trei)</b> Participanţi unici, atunci se va lua în
														considerare prima imagine încărcată, în ordine cronologică, de
														către Participanţi, dintre cele care au determinat selectarea
														Participantului respectiv.
													</p>
													<p>
														În cazul în care un Participant deţine mai multe de 1 (o)
														imagine în clasament, atunci acesta va fi eligibil pentru a
														primi din partea Organizatorului 1 singur beneficiu acordat prin
														intermediul prezentei Promoţii. În acestă situaţie vor fi luate
														în considerare următoarele imagini din clasamentul realizat
														anterior, iar acţiunea poate fi reluată până la identificarea a{' '}
														<b>3 (trei)</b> Participanţi unici.
													</p>
													<p>
														Un Participant poate primi din partea Organizatorului un singur
														beneficiu de acest tip, indiferent de numărul de imagini
														încărcate pe perioada Promoţiei şi indiferent dacă aceeaşi
														persoană deţine mai multe conturi de utilizator în cadrul
														platformei online IQOS Club.{' '}
													</p>
													<p>
														Primii <b>3 (trei)</b> Participanţi determinaţi conform
														regulilor descrise anterior vor primi gratuit, din partea
														Organizatorului, o <b>boxă portabilă inteligentă</b> în valoare
														nominală unitară de 1.000 RON (TVA inclus). Valoarea totală a
														beneficiilor acordate în cadrul prezentei promoţii este de 3.000
														RON (TVA inclus).
													</p>
													<p>
														Participanţii eligibili pentru a primit gratuit, din partea
														Organizatorului, beneficiul constând în{' '}
														<b>boxă portabilă inteligentă</b> vor fi contactaţi telefonic
														pentru validare la numărul de telefon completat şi confirmat de
														participant la crearea contului în cadrul platfomei online IQOS
														Club, într-un interval de maxim 5 zile lucrătoare de la data la
														care aceştia au devenit eligibili pentru a primit acest tip de
														beneficiu.
													</p>
													<p>
														Participanţii vor fi contactaţi de trei ori, în trei zile
														diferite, la intervale orare diferite, pentru a se realiza
														următoarele: identificare, validare, comunicarea detaliilor cu
														privire la livrarea premiului.
													</p>
													<p>
														Identificarea Participanţilor se va realiza pe baza datelor cu
														care acesta s-a înscris în cadrul platfomei online IQOS Club şi
														a celor comunicate în momentul validării telefonice (nume,
														prenume, data de naştere, adresă e-mai, CNP).{' '}
													</p>
													<p>
														Câştigătorii se obligă să menţioneze, în baza prevederilor
														Codului Fiscal (conform căruia plătitorii de venituri, cu regim
														de reţinere la sursă a impozitelor, au obligaţia să depună
														„Declaraţia informativă privind impozitul reţinut la sursă,
														veniturile din jocuri de noroc şi câştigurile/pierderile din
														investiţii, pe beneficiari de venit(D205)” privind calcularea şi
														reţinerea impozitului pentru fiecare beneficiar de venit, la
														organul fiscal competent, până la data de 31 ianuarie inclusiv a
														anului curent pentru anul expirat) codul său numeric personal
														din cartea de identitate. În cazul în care participantul refuză
														să indice codul său numeric personal, acestuia nu îi va fi
														acordat produsul, urmând a fi decăzut din drepturile de a
														reclama acest câştig. Această prevedere se aplică deoarece
														beneficiile acordate în cadrul prezentei Promoţii au valoarea
														mai mare de 600 RON.
													</p>
													<p>
														În situaţia în care, la momentul validării telefonice,
														Participantul declară că refuză să accepte beneficiul acordat,
														sau nu întrunește toate condiţiile necesare validării sale, în
														conformitate cu prezentul Regulament, acesta va fi invalidat şi
														decăzut din drepturi.
													</p>
													<p>
														În cazul în care nu se reuşeşte validarea Participantului din
														motive independente de Organizator şi acesta nu apelează Centrul
														de Asistenţă Clienţi în termen de 15 zile lucrătoare de la data
														finalizării Promoţiei, acesta va fi decăzut din drepturi.
													</p>
													<p>
														În cazul în care nu se reuşeşte validarea unui Participant, din
														motive independente de Organizator, se va relua procesul de
														identificare a Participanţilor aflaţi în afara primelor 5
														poziţii determinate iniţial, urmându-se acelaşi procedeu ca cel
														descris anterior.
													</p>
													<p>
														La finalul apelului de validare telefonică, Participantului îi
														vor fi comunicate toate detaliile referitoare la beneficiul
														acordat şi modalitatea de livrare a acestuia.
													</p>
													<p>
														Beneficiile constând în <b>boxă portabilă inteligentă</b> vor fi
														livrate Participanţilor validaţi prin intermediul serviciilor de
														curierat. În faza recepţionării beneficiului, Participantul
														trebuie să prezinte în original un document de identitate
														(CI/BI). În situaţia în care, la livrare, datele din CI/BI nu
														corespund cu cele declarate în procesul de validare, acesta va
														fi invalidat şi decăzut din drepturi.
													</p>
													<p>
														Toţi Participanţii vor completa un proces verbal de
														predare-primire a beneficiului. Acesta va fi înmânat
														reprezentantului Organizatorului şi va fi folosit pentru
														validare.
													</p>
													<p>
														Se va încerca o singură dată livrarea coletului conţinând
														beneficiul pentru care Participantul a fost validat la adresa şi
														în intervalul orar menţionat de către Participant la validarea
														telefonică. O nouă încercare de livrare se va face în momentul
														în care Participantul a sunat la Centrul de Asistenţă Clienţi şi
														a menţionat detaliile referitoare la data, ora şi adresa la care
														acesta poate fi contactat pentru livrare. Curierul nu are
														obligaţia de a oferi informaţii în legătură cu prezenta
														Promoţie. Pentru detalii în acest sens, participanţii trebuie să
														apeleze Centrul de Asistenţă Clienţi, conform Secţiunii 19.
														„Centrul de asistenţă clienţi”.
													</p>
													<p>
														În situaţia în care Participanţii nu au putut fi contactaţi
														pentru livrarea beneficiului validat, din cauze independente de
														Organizator şi nu au apelat Centrul de Asistenţă Clienţi,
														revendicând beneficiul nelivrat, până la data de{' '}
														<b>15 iunie 2020</b>, aceştia vor fi decăzuţi din dreptul de a-l
														mai revendica.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Tu şi IQOS - Provocarea lunii mai{' '}
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Promoţia se desfăşoară în cadrul platformei online Clubul IQOS
														în perioada{' '}
														<b>04 mai 2020 ora 00:00:00 – 31 mai 2020 ora 23:59:59</b>.
													</p>
													<p>
														La prezenta promoţie pot participa toţi utilizatorii cu cont
														activ în cadrul platformei online IQOS Club.
													</p>
													<p>
														Participanţii care accesează platforma online IQOS Club pot
														accesa secţiunea special dedicată acestei promoţii, unde vor
														putea încărca conţinut sub forma de imagine. Pentru înscrierea
														în cadrul prezentei promoţii vor fi luate în considerare
														exclusiv imaginile încărcate de participanţi pe perioada de
														desfăşurare a promoţiei. Nu vor fi luate în considerare
														imaginile încărcate de participanţi anterior începerii prezentei
														promoţii şi nici cele încărcate de participanţi după termenul de
														finalizare al prezentei promoţii.
													</p>
													<p>
														Imaginea pe care utilizatorul doreşte să o încarce în cadrul
														platformei online IQOS Club trebuie să întrunească cumulativ
														următoarele condiţii de eligibilitate:
													</p>
													<ul className="dashLine">
														<li>
															fişierul trebuie să fie în format jpg, jpeg sau derivate ale
															acestuia;
														</li>
														<li>dimensiunea fişierului să nu depăşească 10 MB;</li>
														<li>
															sunt acceptate doar imagini care sunt inspirate sau inspiră
															persoanei privitoare legătură cu tematica lunii, aceasta
															fiind „Momente dulci cu IQOS”. În cadrul imaginilor trebuie
															să se regăsească cel puţin un dispozitiv IQOS sau parte
															componentă a acestuia;
														</li>
														<li>
															nu sunt acceptate imagini care conţin, dar fără a se limita
															la: limbaj ofensator sau violent, comentarii jignitoare sau
															discriminatorii privind rasa, etnia, religia, sexul,
															dizabilitatea, orientarea sexuală sau convingerile politice,
															link-uri sau comentarii care conțin materiale de conținut
															sexual explicit, atacuri asupra unor grupuri specifice sau
															orice comentarii menite să hărțuiască, să amenințe sau să
															abuzeze un individ, spam, link-uri externe sau fișiere care
															conțin viruși care ar putea deteriora funcționarea
															computerelor sau dispozitivelor mobile ale altor persoane,
															solicitări comerciale sau promovarea unui concurent;
														</li>
														<li>
															Participantul care încarcă o imagine este direct
															responsabilă şi răspunzătoare privind încălcarea dreptului
															de autor sau a drepturilor de proprietate intelectuală.
														</li>
													</ul>
													<p>
														Un participant poate încărca oricât de multe imagini, cu
														respectarea condiţiilor de eligibilitate menţionate anterior.
													</p>
													<p>
														Ulterior încărcării imaginii de către Participanţi, toate
														imaginile vor fi supuse unui proces de validare de către
														Organizator. Doar imaginile aprobate de Organizator vor fi
														publicate în cadrul platformei online IQOS Club, în cadrul
														secţiunii special dedicate prezentei Promoţii. Participanţii
														înţeleg şi acceptă că Organizatorul va putea, la discreţia sa,
														să respingă imagini dintre cele încărcate de Participanţi pe
														perioada de desfăşurare a Promoţiei, deşi respectă tematica
														lunii. În mod nelimitativ, Organizatorul îşi rezervă dreptul de
														nu a aproba şi a nu publica în cadrul platformei online IQOS
														Club imagini care pot fi considerate, fără a se limita la,
														neclare, vizibile parţial, iligibile.
													</p>
													<p>
														Ulterior afişării în cadrul platformei online IQOS Club, în
														cadrul secţiunii special dedicate prezentei Promoţii,
														utilizatorii prezentei platforme online vor putea acorda
														aprecieri imaginilor propuse de ceilalţi Participanţi, aprobate
														de Organizator şi publicate de acesta din urmă în cadrul
														website-ului.
													</p>
													<p>
														Un utilizator, membru în cadrul IQOS Club, poate aprecia o
														imagine o singură dată. <br />
														Un utilizator, membru în cadrul IQOS Club, poate aprecia oricât
														de multe dintre imaginile prezente în cadrul secţiunii special
														dedicate prezentei Promoţii.
														<br />
														Un utilizator, membru în cadrul IQOS Club, poate aprecia
														imaginile propuse de alţi Participanţi şi aprobate de
														Organizator, precum şi propriile imagini propuse şi aprobate de
														Organizator.
														<br />
														Un utilizator poate aprecia o imagine propusă de un participant
														şi aprobată de Organizator doar pe perioada de desfăşurare a
														prezentei Promoţii.
													</p>
													<p>
														După finalizarea perioadei de desfăşurare a prezentei Promoţii
														se va întocmi un clasament al imaginilor încărcate de
														Participanţi în cadrul secţiunii special dedicate din cadrul
														platformei online şi care au acumulat aprecieri din partea
														utilizatorilor platformei IQOS Club.
													</p>
													<p>
														Clasamentul se va realiza în maxim 5 zile lucrătoare de la data
														finalizării Promoţiei şi va fi realizat în funcţie de numărul de
														aprecieri acumulate de imaginile încărcate de Participanţi în
														perioada de desfăşurare a Promoţiei, de la momentul încărcării
														acestora şi publicate de Organizator în cadrul platformei web
														până la încetarea Promoţiei, adică anterior datei de 31 mai 2020
														ora 23:59:59.
													</p>
													<p>
														Primele <b>100 (o sută)</b> imagini din clasament determinate
														conform regulilor descrise anterior vor fi asociate
														Participanţilor care au încărcat imaginile respective în cadrul
														platformei web.
													</p>
													<p>
														În situaţia în care pe ultima poziţie vor fi mai multe imagini
														cu acelaşi număr de aprecieri, atunci departajarea se va face
														prin cumularea aprecierilor tuturor imaginilor încărcate pe
														perioada de desfăşurare a prezentei Promoţii de către
														Participanţii aflaţi la egalitate.
													</p>
													<p>
														În situaţia în care, în urma procesului de departajare menţionat
														anterior, nu se va putea diferenţia explicit un număr de{' '}
														<b>100 (o sută)</b>
														Participanţi unici, atunci se va lua în considerare prima
														imagine încărcată, în ordine cronologică, de către Participanţi,
														dintre cele care au determinat selectarea Participantului
														respectiv.{' '}
													</p>
													<p>
														În cazul în care un Participant deţine mai multe de 1 (o)
														imagine în clasament, atunci acesta va fi eligibil pentru a
														primi din partea Organizatorului 1 singur beneficiu acordat prin
														intermediul prezentei Promoţii. În acestă situaţie vor fi luate
														în considerare următoarele imagini din clasamentul realizat
														anterior, iar acţiunea poate fi reluată până la identificarea a{' '}
														<b>100 (o sută)</b> Participanţi unici.
													</p>
													<p>
														Un Participant poate primi din partea Organizatorului un singur
														beneficiu de acest tip, indiferent de numărul de imagini
														încărcate pe perioada Promoţiei şi indiferent dacă aceeaşi
														persoană deţine mai multe conturi de utilizator în cadrul
														platformei online IQOS Club.{' '}
													</p>
													<p>
														Primii <b>100 (o sută)</b> Participanţi determinaţi conform
														regulilor descrise anterior vor primi gratuit, din partea
														Organizatorului, <b>o cutie cu ciocolată belgiană „Leonidas”</b>
														. <br /> Valoarea totală estimată a beneficiilor acordate în
														cadrul prezentei promoţii este de 6.000 RON (TVA inclus).
													</p>
													<p>
														Participanţii eligibili pentru a primi gratuit, din partea
														Organizatorului, beneficiul constând în{' '}
														<b>cutie cu ciocolată belgiană „Leonidas”</b> vor fi contactaţi
														telefonic pentru validare la numărul de telefon completat şi
														confirmat de participant la crearea contului în cadrul platfomei
														online IQOS Club, într-un interval de maxim 5 zile lucrătoare de
														la data la care aceştia au devenit eligibili pentru a primi
														acest tip de beneficiu.
													</p>
													<p>
														Participanţii vor fi contactaţi de trei ori, în trei zile
														diferite, la intervale orare diferite, pentru a se realiza
														următoarele: identificare, validare, comunicarea detaliilor cu
														privire la livrarea premiului.
													</p>
													<p>
														Identificarea Participanţilor se va realiza pe baza datelor cu
														care acesta s-a înscris în cadrul platfomei online IQOS Club şi
														a celor comunicate în momentul validării telefonice (nume,
														prenume, data de naştere, adresă e-mail, CNP).{' '}
													</p>
													<p>
														În situaţia în care, la momentul validării telefonice,
														Participantul declară că refuză să accepte beneficiul acordat,
														sau nu întrunește toate condiţiile necesare validării sale, în
														conformitate cu prezentul Regulament, acesta va fi invalidat şi
														decăzut din drepturi.
													</p>
													<p>
														În cazul în care nu se reuşeşte validarea Participantului din
														motive independente de Organizator şi acesta nu apelează Centrul
														de Asistenţă Clienţi în termen de 15 zile lucrătoare de la data
														finalizării Promoţiei, acesta va fi decăzut din drepturi.
													</p>
													<p>
														În cazul în care nu se reuşeşte validarea Participantului din
														motive independente de Organizator şi acesta nu apelează Centrul
														de Asistenţă Clienţi în termen de 15 zile lucrătoare de la data
														finalizării Promoţiei, acesta va fi decăzut din drepturi.
													</p>
													<p>
														În cazul în care nu se reuşeşte validarea unui Participant, din
														motive independente de Organizator, se va relua procesul de
														identificare a Participanţilor aflaţi în afara primelor 100
														poziţii determinate iniţial, urmându-se acelaşi procedeu ca cel
														descris anterior.
													</p>
													<p>
														La finalul apelului de validare telefonică, Participantului îi
														vor fi comunicate toate detaliile referitoare la beneficiul
														acordat şi modalitatea de livrare a acestuia.
													</p>
													<p>
														Beneficiile constând în{' '}
														<b>cutie cu ciocolată belgiană „Leonidas”</b> vor fi livrate
														Participanţilor validaţi prin intermediul serviciilor de
														curierat. În faza recepţionării beneficiului, Participantul
														trebuie să prezinte în original un document de identitate
														(CI/BI). În situaţia în care, la livrare, datele din CI/BI nu
														corespund cu cele declarate în procesul de validare, acesta va
														fi invalidat şi decăzut din drepturi.
													</p>
													<p>
														Toţi Participanţii vor completa un proces verbal de
														predare-primire a beneficiului. Acesta va fi înmânat
														reprezentantului Organizatorului şi va fi folosit pentru
														validare.
													</p>
													<p>
														Se va încerca o singură dată livrarea coletului conţinând
														beneficiul pentru care Participantul a fost validat la adresa şi
														în intervalul orar menţionat de către Participant la validarea
														telefonică. O nouă încercare de livrare se va face în momentul
														în care Participantul a sunat la Centrul de Asistenţă Clienţi şi
														a menţionat detaliile referitoare la data, ora şi adresa la care
														acesta poate fi contactat pentru livrare. Curierul nu are
														obligaţia de a oferi informaţii în legătură cu prezenta
														Promoţie. Pentru detalii în acest sens, participanţii trebuie să
														apeleze Centrul de Asistenţă Clienţi, conform Secţiunii 19.
														„Centrul de asistenţă clienţi”.
													</p>
													<p>
														În situaţia în care Participanţii nu au putut fi contactaţi
														pentru livrarea beneficiului validat, din cauze independente de
														Organizator şi nu au apelat Centrul de Asistenţă Clienţi,
														revendicând beneficiul nelivrat, până la data de{' '}
														<b>15 iulie 2020</b>, aceştia vor fi decăzuţi din dreptul de a-l
														mai revendica.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: IQOS 3 Duo ofertă exclusivă destinată membrilor IQOS Club
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Pe perioada 24 octombrie 2019 ora 00:00:00 – 04 noiembrie 2019
														23:59:59 (inclusiv) Participanţii eligibili, utilizatori ai
														platformei online IQOS Club, vor putea achiziţiona din cadrul
														magazinului online disponibil pe{' '}
														<a href="https://www.iqos.ro/" target="_blank">
															www.iqos.ro
														</a>
														dispozitivul IQOS 3 Duo cu discount, conform nivelului în care
														sunt încadraţi pe baza activităţilor din IQOS Club, după cum
														urmează:
													</p>

													<ul className="dashLine">
														<li>
															Utilizatorii încadraţi în nivelul 1 (Silver) – vor putea
															achiziţiona dispozitivul IQOS 3 Duo la preţul de 359 RON,
															beneficiind de o reducere de 20% faţă de preţul de vânzare
															al produsului;
														</li>
														<li>
															Utilizatorii încadraţi în nivelul 2 (Gold) – vor putea
															achiziţiona dispozitivul IQOS 3 Duo la preţul de 269 RON,
															beneficiind de o reducere de 40% faţă de preţul de vânzare
															al produsului;
														</li>
														<li>
															Utilizatorii încadraţi în nivelul 3 (Platinum) – vor putea
															achiziţiona dispozitivul IQOS 3 Duo la preţul de 89 RON,
															beneficiind de o reducere de 80% faţă de preţul de vânazre
															al produsului.
														</li>
													</ul>

													<p>
														Un utilizator poate beneficia de ofertă o singură dată, în
														cadrul unei singure achiziţii, pentru un singur produs de tipul
														IQOS 3 Duo.
													</p>

													<p>
														Un utilizator poate beneficia de ofertă o singură dată,
														indiferent dacă pe parcursul perioadei de desfăşurare a
														prezentei oferte nivelul utilizatorului în IQOS Club se
														modifică. În situaţia în care utilizatorul a beneficiat deja de
														oferta destinată membrilor IQOS Club şi ulterior a avansat la un
														nivel superior, nu va putea beneficia din nou de oferta aferentă
														nivelului atins, chiar dacă perioada de desfăşurare a ofertei nu
														s-a finalizat.
													</p>

													<p>
														Un utilizator poate beneficia de ofertă în limita stocului
														disponibil în cadrul magazinului online{' '}
														<a href="https://www.iqos.ro/" target="_blank">
															www.iqos.ro
														</a>
													</p>

													<p>
														Prezenta ofertă nu se cumulează cu alte oferte disponibile în
														cadrul magazinului online{' '}
														<a href="https://www.iqos.ro/" target="_blank">
															www.iqos.ro
														</a>
													</p>

													<p>
														Achiziţiile realizate în cadrul magazinului online{' '}
														<a href="https://www.iqos.ro/" target="_blank">
															www.iqos.ro
														</a>
														se supun termenilor şi condiţiilor de vânzare prezentate în
														cadrul website-ului. Renunţarea la comandă va respecta, de
														asemenea, termenii şi condiţiile de vânzare, secţiunea
														Renunțarea la cumpărare.{' '}
													</p>

													<p>
														În situaţia în care utilizatorul alege să renunţe la comandă,
														indiferent de motivul invocat, nu va putea beneficia din nou de
														aceeaşi ofertă, aceasta fiind valabilă o singură dată, pentru o
														singură achiziţie.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">Promoţia: Noile pachete HEETS Selection</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Pe perioada 07 octombrie 2019 ora 00:00:00 – 30 noiembrie 2019
														23:59:59 (inclusiv) Participanţii eligibili, utilizatori ai
														platformei online IQOS Club încadraţi în Nivelul 3 – Platinum,
														vor putea accesa secţiunea din cadrul website-ului destinată
														interacţiunii „Noile pachete HEETS Selection”. Utilizatorii
														platformei online IQOS Club încadraţi in Nivelul 1 şi Nivelul 2
														nu vor putea accesa secţiunea dedicată prezentei Promoţii şi nu
														vor putea Participa la aceasta.
													</p>

													<p>
														Pe perioada de desfăşurare a prezentei Promoţii, Participanţii
														eligibili, vor putea accesa secţiunea din cadrul platformei
														online special dedicată, unde vor completa un formular de
														revendicare beneficiu cu urmatoarele informaţii necesare
														livrării:{' '}
													</p>
													<ul className="dashLine">
														<li>
															versiunea de HEETS preferată, dintre cele disponibile în
															cadrul platformei în momentul revendicării beneficiului;
														</li>
														<li>
															adresa de livrare, conţinând: localitate, judeţ, detalii
															adresă – stradă, număr, bloc, etaj, apartament, observaţii.
														</li>
													</ul>

													<p>
														Beneficiul care se acordă în urma completării cu succes a
														formularului conform detaliilor menţionate anterior constă
														într-un kit având în componenţă 1 (un) pachet HEETS în versiunea
														selectată de Participant în momentul completării formularului şi
														1 (un) set de 10 beţişoare de curăţat IQOS.
													</p>

													<p>
														Identificarea Participanţilor se va realiza pe baza datelor cu
														care acesta s-a înscris în cadrul platfomei online IQOS Club şi
														a celor comunicate în momentul validării telefonice (nume,
														prenume, telefon, adresa de e-mail).{' '}
													</p>

													<p>
														Beneficiile constând în kit conţinând 1 (un) pachet de HEETS, în
														versiunea selectată de Participant, vor fi livrate acestora prin
														intermediul serviciilor de curierat. Coletul va fi expediat
														către Participantul identificat după datele cu care acesta este
														înscris în cadrul platformei online IQOS Club (nume, prenume,
														telefon), la adresa completată de Participant în momentul
														completării formularului din cadrul prezentei Promoţii.
														Expedierea coletului se va face în termen de 5 (cinci) zile
														lucrătoare de la data completării cu succes a formularului
														destinat prezentei Promoţii din cadrul platformei online IQOS
														Club.
													</p>

													<p>
														În faza recepţionării beneficiului, Participantul trebuie să
														prezinte în original un document de identitate (CI/BI). În
														situaţia în care, la livrare, datele din CI/BI (nume, prenume)
														nu corespund cu care acesta este înscris în cadrul platformei
														online IQOS Club, acesta va fi invalidat şi decăzut din
														drepturi.
													</p>

													<p>
														Se va încerca o singură dată livrarea coletului conţinând
														beneficiul pentru care Participantul a fost validat la adresa şi
														în intervalul orar menţionat de către Participant la completare
														formularului în cadrul platformei online IQOS Club. O nouă
														încercare de livrare se va face în momentul în care
														Participantul a sunat la Centrul de Asistenţă Clienţi şi a
														menţionat detaliile referitoare la data, ora şi adresa la care
														acesta poate fi contactat pentru livrare.
													</p>

													<p>
														Curierul nu are obligaţia de a oferi informaţii în legătură cu
														prezenta Promoţie. Pentru detalii în acest sens, participanţii
														trebuie să apeleze Centrul de Asistenţă Clienţi, conform
														Secţiunii 19. „Centrul de asistenţă clienţi”.
													</p>

													<p>
														În situaţia în care Participanţii nu au putut fi contactaţi
														pentru livrarea beneficiului validat, din cauze independente de
														Organizator şi nu au apelat Centrul de Asistenţă Clienţi,
														revendicând beneficiul nelivrat, până la data de{' '}
														<b>31 decembrie 2019</b>, aceştia vor fi decăzuţi din dreptul de
														a-l mai revendica.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">Promoția: IQOS Club - 4 ani cu IQOS</h4>
											<div className="acc_container">
												<div className="block">
													<h5>
														SECŢIUNEA 1. ORGANIZATORUL ŞI REGULAMENTUL OFICIAL AL CAMPANIEI
														PROMOŢIONALE
													</h5>
													<p>
														Campania promoţională „IQOS Club – 4 ani cu IQOS” (numită în
														cele ce urmează „Campania”), este organizată de Philip Morris
														Trading S.R.L (numită în prezentul Regulament “Organizatorul”),
														cu sediul în Otopeni, Str. Horia, Cloşca şi Crişan nr. 83-105,
														clădirea A, Judeţul Ilfov, România, cu punct de lucru în
														Bucureşti, Strada Nicolae Filipescu nr. 28, Sector 2,
														înmatriculată la Registrul Comerţului sub numărul J23/511/2004,
														având CIF RO 4888165. Participanţii la această Campanie
														promoţională sunt obligaţi să respecte termenii şi condiţiile
														Regulamentului Oficial, potrivit celor menţionate mai jos
														(denumit în continuare “Regulament Oficial”). <br />
														Regulamentul Oficial este întocmit şi va fi făcut public conform
														legislaţiei aplicabile în România, fiind disponibil oricărui
														solicitant, pe perioada de desfăşurare a Campaniei promoţionale,
														în cadrul website-ului destinat campanie
														<a href="https://club.iqos.ro" target="_blank">
															www.club.iqos.ro
														</a>
														.
														<br />
														Organizatorul îşi rezervă dreptul de a modifica prezentul
														Regulament Oficial, urmând ca astfel de modificări să intre în
														vigoare numai după aducerea la cunoştinţa participanţilor a
														respectivelor modificări.
													</p>

													<h5>
														SECŢIUNEA 2. ARIA GEOGRAFICĂ DE DESFĂŞURARE A CAMPANIEI
														PROMOŢIONALE. DURATA
													</h5>
													<p>
														Campania promoţională este organizată şi se desfăşoară pe
														teritoriul României şi se va derula în perioada cuprinsă între
														14 februarie 2020 şi 28 februarie 2020 (inclusiv), cu
														respectarea datelor prevăzute în prezentul Regulament Oficial.
														Organizatorul nu îşi asumă nicio responsabilitate cu privire la
														orice considerente faptice de natură a determina participanţii
														la Campania promoţională să considere continuarea Campaniei
														promoţionale ulterior datei de 28 februarie 2020 (inclusiv), cu
														excepţia cazului în care o astfel de continuare ar avea loc ca
														rezultat al unui Act Adiţional la prezentul Regulament Oficial,
														adus la cunoştinţa participanţilor prin modalităţile prevăzute
														la Secţiunea 1 de mai sus.
													</p>

													<h5>SECŢIUNEA 3. CONDIŢII DE PARTICIPARE</h5>
													<p>
														La această Campanie pot participa cetăţenii români, cu vârsta
														peste 18 ani (împliniţi până la data înscrierii lor în cadrul
														prezentei Campanii), utilizatori de IQOS, cu cont activ pe
														website-ul www.iqos.ro şi care au înregistrat în contul lor
														minim un dispozitiv IQOS compus din Holder („Stilou”) şi
														încărcător portabil şi care au domiciliul sau reşedinţa sau sunt
														rezidenţi în România. <br />
														La prezenta Campanie nu pot participa angajații societăţilor
														Philip Morris Trading S.R.L., Philip Morris România S.R.L. sau
														angajaţii companiilor organizatoare implicate în desfăşurarea
														acestei Campanii şi nici angajaţii celorlalte entităţi legale
														implicate în această acţiune (inclusiv reprezentanţii
														Organizatorului din cadrul acestei Campanii promoţionale sau
														reprezentanții magazinelor în care sunt comercializate pachetele
														de ţigarete, parte din Campanie), precum şi niciunul dintre
														membrii familiilor acestora (copii, părinţi, soţ/soţie,
														frate/soră). Prin implicare în organizarea promoţiei se înţelege
														derularea activităţilor care au legătură cu modalitatea de
														acordare a materialelor/cupoanelor promoţionale, a premiilor,
														algoritmi, trageri la sorţi, procese de selecţie şi orice alte
														acţiuni care influenţează sau pot influenţa determinarea
														câştigătorilor Campaniei promoţionale. <br />
														Din dorinţa de a nu prejudicia niciun participant care doreşte
														să participe la prezenta Campanie, Organizatorul îşi rezervă
														dreptul să poată efectua verificări în ceea ce priveşte corecta
														participare. O persoană poate deține un singur cont individual
														pe platforma online club.iqos.ro. Orice încercare de duplicare a
														unui cont sau de înregistrare a unei persoane cu date
														modificate/fictive/cu alte conturi (același nume, prenume,
														adresă de livrare, număr de telefon), va atrage după sine
														ștergerea conturilor deschise ulterior contului inițial, iar în
														caz de repetare a acțiunii, se va putea trece inclusiv la
														dezactivarea contului inițial de utilizator. În cazul în care,
														în urma verificărilor desfăşurate de către Organizator, rezultă
														că procesul de participare a fost fraudat, Organizatorul îşi
														rezervă dreptul de a retrage participanţilor premiile obţinute
														ca rezultat al activităţii lor şi / sau să restricţioneze
														participarea la Campanie. În cazul în care sunt identificate
														persoane care au influenţat sau au facilitat câştigarea de
														premii necuvenite, Organizatorul are dreptul de a cere urmărirea
														în instanţă a respectivelor persoane, pe baza dovezilor
														existente. Organizatorul îşi rezervă dreptul de a exclude
														participanţi din Campanie cu sau fără indicarea motivului. Acest
														lucru se aplică mai ales participanţilor care îşi manipulează
														sau încearcă să îşi manipuleze participarea (în principal prin
														crearea de conturi multiple sau încercând să obţină sau să
														creeze şanse suplimentare de a câştiga în mod incorect) sau care
														încalcă condiţiile specifice promoţiei sau care, într-un mod sau
														altul, utilizează abuziv promoţia propuse.
													</p>

													<h5>
														SECŢIUNEA 4. MECANISMUL DE PARTICIPARE LA CAMPANIA PROMOŢIONALĂ;
														METODOLOGIA DE ACORDARE ŞI LIVRARE A PREMIILOR
													</h5>
													<p>
														Pentru a se înscrie cu participare validă în cadrul prezentei
														Campanii, Participanţii trebuie să întrunească cumulativ
														următoarele condiţii:
													</p>

													<ul className="dashLine">
														<li>
															Să respecte condiţiile de participare la Campanie, aşa cum
															acestea sunt descrise în cadrul secţiunii 3. Condiţii de
															participare din cadrul prezentului Regulament;
														</li>
														<li>
															În momentul participării să aibă disponibile în contul său
															din cadrul IQOS Club minim 4 (patru) puncte acumulate şi
															neutilizate.
														</li>
													</ul>
													<p>
														Pentru a se înscrie în tragerea la sorţi pentru determinarea
														câştigătorilor prezentei Campanii, Participantul trebuie să
														acceseze secţiunea special dedicată acestei Campanii din cadrul
														website-ului www.club.iqos.ro pe perioada de desfăşurare a
														prezentei Campanii şi să finalizeze cu succes interacţiunea prin
														care acesta preschimbă puncte acumulate în contul său din cadrul
														platformei online IQOS Club şi le transformă în şanse în
														tragerea la sorţi.
													</p>
													<p>
														Un Participant care doreşte să acumuleze 1 (o) şansă în tragerea
														la sorţi pentru determinarea câştigătorilor prezentei Campanii
														Promoţionale va utiliza 4 (patru) puncte dintre cele disponibile
														în contul său din cadrul IQOS Club.
													</p>
													<p>
														Un Participant poate să utilizeze, în cadrul unei singure
														interacţiuni, minim 4 (patru) puncte – pentru acumularea unei
														şanse în tragerea la sorţi – şi maxim 40 (patruzeci) puncte –
														pentru acumularea a 10 (zece) şanse în tragerea la sorţi.
													</p>
													<p>
														Pentru a acumula mai multe şanse în tragerea la sorţi pentru
														desemnarea câştigătorilor prezentei Campanii, un Participant va
														putea repeta interacţiunea prin cadrul preschimbă punctele în
														şanse, în limita numărului de puncte disponibile în contul său.
													</p>
													<p>
														<b>
															Decizia utilizatorului de a participa la prezenta Campanie
															nu este reversibilă, iar Participantul nu va putea solicita
															Organizatorului returnarea punctelor în contul său şi,
															respectiv, anularea şanselor, total sau parţial, acumulate
															pentru tragerea la sorţi pentru desemnarea câştigătorilor
															prezentei Campanii.
														</b>
													</p>
													<p>
														În cadrul prezentei Campanii se vor acorda în total{' '}
														<b>400 premii</b>, fiecare constând în dispozitiv{' '}
														<b>IQOS 3 Duo Warm Cooper</b>. Câştigătorii vor fi desemnaţi
														prin tragere la sorţi electronică, dintre toate şansele
														acumulate de Participanţi pe perioada de desfăşurare a prezentei
														Campanii Promoţionale.
													</p>
													<p>
														Valoarea totală estimată a premiilor este de 179.600 RON (TVA
														inclus).
													</p>
													<p>
														Tragerea la sorţi pentru desemnarea câştigătorilor se va
														desfăşura pe baza unui algoritm electronic şi va avea loc în
														termen de maxim 10 zile lucrătoare de la data finalizării
														promoţiei, aceasta fiind 28 februarie 2020.
													</p>
													<p>
														Fiecare participant care se califică pentru a fi inclus în
														tragerea la sorţi va avea un număr de şanse egal cu numărul de
														şanse acumulate de acesta pe perioada de desfăşurare a prezentei
														Campanii Promoţionale.
													</p>
													<p>
														În cadrul tragerii la sorţi se vor desemna 400 (patrusute)
														câştigători şi 1200 (omiedouăsute) rezerve. Un participant,
														inclus în tragerea la sorţi, va fi extras o singură dată,
														indiferent de numărul de şanse cu care participă la tragerea la
														sorţi.
													</p>
													<p>
														În situaţia în care, după finalizarea perioadei de desfăşurare a
														prezentei Campanii Promoţionale, au rezultat un număr de
														Participanţi distincţi mai mic decât numărul de premii acordate,
														atunci tragerea la sorţi nu va mai avea loc şi toţi
														Participanţii vor fi declaraţi câştigători.
													</p>
													<p>
														În situaţia în care, după finalizarea perioadei de desfăşurare a
														prezentei Campanii Promoţionale, au rezultat un număr de
														Participanţi distincţi mai mare decât numărul de premii
														acordate, dar insuficient pentru a se desemna numărul total de
														rezerve, atunci în cadrul tragerii la sorţi se vor desemna 400
														(patrusute) câştigători şi un numar de rezerve egal cu numărul
														de Participanţi rămaşi disponibili după extragerea
														câştigătorilor.
													</p>
													<p>
														<b>
															Utilizatorii care au ales să participe la prezenta Campanie
															nu vor primi înapoi în contul lor punctele cheltuite pentru
															achiziţionarea de şanse în cadrul prezentei Promoţii,
															indiferent dacă au fost desemnaţi sau nu câştigători în urma
															tragerii la sorţi.{' '}
														</b>
													</p>
													<p>
														Câştigătorii desemnaţi vor fi contactaţi în termen de 10 (zece)
														zile lucrătoare de la data la care a avut loc tragerea la sorţi,
														la numărul de telefon completat şi confirmat de participant la
														crearea contului în cadrul platformei online IQOS Club.
													</p>
													<p>
														Câştigătorii vor fi contactaţi de trei ori, în trei zile
														diferite, la intervale orare diferite, pentru a se realiza
														următoarele: identificare, validare, comunicarea detaliilor cu
														privire la livrarea premiului.
													</p>
													<p>
														Identificarea Participanţilor se va realiza pe baza datelor cu
														care acesta s-a înscris în cadrul platfomei online IQOS Club şi
														a celor comunicate în momentul validării telefonice (nume,
														prenume, data de naştere, localitate).
													</p>
													<p>
														În situaţia în care, la momentul validării telefonice,
														câştigătorul declară că refuză să accepte premiul câştigat, sau
														nu întrunește toate condiţiile necesare validării sale, în
														conformitate cu prezentul Regulament, câştigătorul va fi
														invalidat şi decăzut din drepturi, iar Organizatorul va apela la
														rezerve, în ordinea desemnării acestora, urmând acelaşi mecanism
														descris.
													</p>
													<p>
														În cazul în care nu se reuşeşte validarea câştigătorului din
														motive independente de Organizator şi câştigătorul nu apelează
														Centrul de Asistenţă Clienţi în termen de 3 zile lucrătoare de
														la data desemnării, acesta va fi decăzut din drepturi, iar
														Organizatorul va apela la rezerve, în ordinea desemnării
														acestora, urmând acelaşi mecanism descris.
													</p>
													<p>
														În situaţia în care nu se reuşeşte validarea niciunei rezerve,
														din motive independente de Organizator, premiul nevalidat nu se
														va mai acorda şi va rămâne în posesia Organizatorului.
													</p>
													<p>
														La finalul apelului de validare telefonică, Participantului îi
														vor fi comunicate toate detaliile referitoare la premiul
														câştigat şi modalitatea de livrare a acestuia.
													</p>
													<p>
														Premiile vor fi livrate câştigătorilor validaţi prin intermediul
														serviciilor de curierat. În faza recepţionării premiului,
														câştigatorul trebuie să prezinte în original un document de
														identitate (CI/BI). În situaţia în care, la livrare, datele din
														CI/BI nu corespund cu cele declarate în procesul de validare,
														acesta va fi invalidat şi decăzut din drepturi.
													</p>
													<p>
														Toţi câştigătorii vor completa un proces verbal de
														predare-primire a beneficiului. Acesta va fi înmânat
														reprezentantului Organizatorului şi va fi folosit pentru
														validare.
													</p>
													<p>
														Se va încerca o singură dată livrarea coletului conţinând
														premiul pentru care Participantul a fost validat la adresa şi în
														intervalul orar menţionat de către Participant la validarea
														telefonică. O nouă încercare de livrare se va face în momentul
														în care Participantul a sunat la Centrul de Asistenţă Clienţi şi
														a menţionat detaliile referitoare la data, ora şi adresa la care
														acesta poate fi contactat pentru livrare.
													</p>
													<p>
														Curierul nu are obligaţia de a oferi informaţii în legătură cu
														prezenta Promoţie. Pentru detalii în acest sens, participanţii
														trebuie să apeleze Centrul de Asistenţă Clienţi, conform
														Secţiunii 19. „Centrul de asistenţă clienţi”.
													</p>
													<p>
														În situaţia în care Participanţii nu au putut fi contactaţi
														pentru livrarea premiului validat, din cauze independente de
														Organizator şi nu au apelat Centrul de Asistenţă Clienţi,
														revendicând premiul validat şi nelivrat, până la data de{' '}
														<b>15 mai 2020</b>, aceştia vor fi decăzuţi din dreptul de a-l
														mai revendica.
													</p>
													<p>
														Câştigătorii nu pot cere modificarea naturii, cantităţii,
														condiţiilor şi modului de acordare a premiilor. În situaţia în
														care câştigătorul refuză să accepte premiul câştigat sau nu
														poate beneficia de acest premiu din motive independente de
														Organizator, premiul nu va fi înlocuit cu un alt premiu sau
														beneficiu.{' '}
													</p>

													<h5>SECŢIUNEA 5. RĂSPUNDERE</h5>
													<p>
														Organizatorul şi societăţile implicate în organizarea prezentei
														Campanii nu îşi asumă răspunderea pentru imposibilitatea
														participării la Campanie datorită unor cauze care nu depind
														direct de ei. Prin înscrierea la această Campanie, Participanţii
														sunt de acord să respecte condiţiile de participare precum şi
														toate deciziile luate de Organizator şi colaboratorii săi în
														toate aspectele legate de implementarea prezentei campanii.
														Organizatorul nu-şi asumă răspunderea şi nu este responsabil
														pentru situaţiile în care un participant nu poate accesa
														platforma online dedicată Campaniei din motive care nu ţin de
														funcţionalitatea website-ului, de genul: conexiune la internet,
														programe instalate, versiuni de browser vechi, probleme cu
														reţeaua electrică, erori cauzate de întreruperea neaşteptată a
														serviciului de hosting al website-ului etc sau alte motive
														independente de Organizator. Organizatorul nu are nicio
														obligaţie de a întreţine corespondenţa cu solicitanţii unor
														revendicări ce apar ulterior termenelor stabilite în prezentul
														document sau al documentelor conexe.
													</p>

													<h5>SECŢIUNEA 6. PROTECŢIA DATELOR PERSONALE</h5>
													<p>
														Tuturor participanţilor la prezenta Campanie le sunt garantate
														drepturile cu privire la protecţia datelor cu caracter personal
														în conformitate cu Regulamentul (UE) 679/2016 privind protecţia
														persoanelor fizice în ceea ce priveşte prelucrarea datelor cu
														caracter personal şi privind libera circulaţie a acestor date,
														în special drepturile persoanei vizate cu privire la informare
														şi acces la date, dreptul la rectificare, dreptul la ştergerea
														datelor, dreptul la restricţionarea prelucrării, dreptul la
														portabilitatea datelor, dreptul la opoziţie, de a se adresa
														instanţei competente şi de plângere către autoritatea de
														supraveghere.
													</p>
													<p>
														În înţelesul Regulamentului (UE) nr. 679/2016, principalele
														drepturi mai sus menţionate aparţinând persoanelor vizate au
														următorul conţinut:
													</p>
													<p>
														1. Dreptul de informare şi acces la date: Orice persoană vizată
														are dreptul de a obţine de la Operator, la cerere şi în mod
														gratuit pentru o solicitare pe an, confirmarea faptului că
														datele care o privesc sunt sau nu sunt prelucrate de către
														acesta. Astfel, la cererea scrisă, semnată şi datată a persoanei
														respective, adresată la CP84 OP83, Bucureşti sau prin email la
														contact@iqos.ro, o dată pe an, în mod gratuit, compania Philip
														Morris Trading S.R.L. va confirma dacă utilizează date care o
														privesc sau va înceta orice prelucrare a acestora. În caz
														afirmativ, va furniza persoanei vizate urmatoarele informaţii:
														a) scopurile prelucrării; b) categoriile de date cu caracter
														personal vizate; c) destinatarii sau categoriile de destinatari
														cărora datele cu caracter personal le-au fost sau urmează să le
														fie divulgate, în special destinatari din ţări terţe sau
														organizaţii internaţionale; d) acolo unde este posibil, perioada
														pentru care se preconizează că vor fi stocate datele cu caracter
														personal sau, dacă acest lucru nu este posibil, criteriile
														utilizate pentru a stabili această perioadă; e) existenţa
														dreptului de a solicita operatorului rectificarea sau ştergerea
														datelor cu caracter personal ori restricţionarea prelucrării
														datelor cu caracter personal referitoare la persoana vizată sau
														a dreptului de a se opune prelucrării; f) dreptul de a depune o
														plângere în faţa unei autorităţi de supraveghere; g) în cazul în
														care datele cu caracter personal nu sunt colectate de la
														persoana vizată, orice informaţii disponibile privind sursa
														acestora; h) existenţa unui proces decizional automatizat
														incluzând crearea de profiluri, menţionat la articolul 22
														alineatele (1) şi (4), precum şi, cel puţin în cazurile
														respective, informaţii pertinente privind logica utilizată şi
														privind importanţa şi consecinţele preconizate ale unei astfel
														de prelucrări pentru persoana vizată. <br />
														2. Dreptul la rectificare: Orice persoană vizată are dreptul de
														a obţine de la Operator, la cerere şi în mod gratuit, fără
														întârzieri nejustificate, rectificarea datelor cu caracter
														personal inexacte care o privesc. Ţinându-se seama de scopurile
														în care au fost prelucrate datele, persoana vizată are dreptul
														de a obţine completarea datelor cu caracter personal care sunt
														incomplete, inclusiv prin furnizarea unei declaraţii
														suplimentare. <br />
														3. Dreptul la ştergerea datelor ("dreptul de a fi uitat"):
														Persoana vizată are dreptul de a obţine din partea operatorului
														ştergerea datelor cu caracter personal care o privesc, fără
														întârzieri nejustificate, iar operatorul are obligaţia de a
														şterge datele cu caracter personal fără întârzieri nejustificate
														în cazul în care se aplică unul dintre următoarele motive: a)
														datele cu caracter personal nu mai sunt necesare pentru
														îndeplinirea scopurilor pentru care au fost colectate sau
														prelucrate; b) persoana vizată îşi retrage consimţământul pe
														baza căruia are loc prelucrarea; c) persoana vizată se opune
														prelucrării şi nu există motive legitime care să prevaleze în
														ceea ce priveşte prelucrarea; d) datele cu caracter personal au
														fost prelucrate ilegal; e) datele cu caracter personal trebuie
														şterse pentru respectarea unei obligaţii legale care revine
														operatorului; <br />
														4. Dreptul la restricţionarea prelucrării: Persoana vizată are
														dreptul de a obţine din partea operatorului restricţionarea
														prelucrării în cazul în care se aplică unul din următoarele
														cazuri: a) persoana vizată contestă exactitatea datelor, pentru
														o perioadă care îi permite operatorului să verifice exactitatea
														datelor; b) prelucrarea este ilegală, iar persoana vizată se
														opune ştergerii datelor cu caracter personal, solicitând în
														schimb restricţionarea utilizării lor; c) operatorul nu mai are
														nevoie de datele cu caracter personal în scopul prelucrării, dar
														persoana vizată i le solicită pentru constatarea, exercitarea
														sau apărarea unui drept în instanţă; sau d) persoana vizată s-a
														opus prelucrării, pentru intervalul de timp în care se verifică
														dacă drepturile legitime ale operatorului prevalează asupra
														celor ale persoanei vizate; <br />
														5. Dreptul la portabilitatea datelor: Persoana vizată are
														dreptul de a primi datele cu caracter personal care o privesc şi
														pe care le-a furnizat operatorului într-un format structurat,
														utilizat în mod curent şi care poate fi citit automat şi are
														dreptul de a transmite aceste date altui operator, fără
														obstacole din partea operatorului căruia i-au fost furnizate
														datele cu caracter personal, în cazul în care: a) prelucrarea se
														bazează pe consimţământ în temeiul articolului 6 alineatul (1)
														litera (a) sau al articolului 9 alineatul (2) litera (a) sau pe
														un contract în temeiul articolului 6 alineatul (1) litera (b)
														din Regulamentul (UE) nr. 679/2006; şi b) prelucrarea este
														efectuată prin mijloace automate.
													</p>
													<p>
														Dreptul la opoziţie: Persoana vizată are dreptul de a se opune
														în orice moment, în mod gratuit şi fără nicio justificare, ca
														datele care o vizează să fie prelucrate în scop de marketing
														direct, în numele operatorului sau al unui terţ sau să fie
														dezvăluite unor terţi într-un asemenea scop .
													</p>
													<p>
														Următoarele date personale vor fi colectate pe durata Campaniei
														participanţilor care se înregistrează în Baza de date PM şi
														câştigătorilor de premii: nume, prenume, data de naştere, număr
														telefon mobil, e-mail, localitate, judet, sex. Scopurile
														constituirii bazei de date sunt: înmânarea premiilor
														câştigătorilor, înregistrarea câştigătorilor Campaniei,
														realizarea de rapoarte statistice cu privire la utilizatorii
														IQOS, informarea acestora prin diverse mijloace de comunicare
														(poştă, e-mail, SMS sau alte mijloace de comunicare permise prin
														lege), cu privire la alte acţiuni desfăşurate în viitor de către
														Organizator. Organizatorul se obligă că datele personale să nu
														fie difuzate către terţi cu excepţia împuterniciţilor săi. Prin
														înscrierea în Baza de date PM prin intermediul secţiunilor
														dedicate din cadrul website-urilor deţinute de Philip Morris
														Trading S.R.L, participanţii care au optat în acest sens îşi dau
														în mod expres consimţământul ca datele lor (respectiv, nume şi
														prenume, data naşterii, sex, telefon mobil, adresă de e-mail,
														localitate, judeţ) să fie incluse în baza de date de utilizatori
														IQOS / fumători adulţi a companiei Philip Morris Trading S.R.L.
														şi să primească ocazional obiecte promoţionale, precum şi
														informaţii prin poştă, telefon, e-mail, SMS sau alte mijloace de
														comunicare permise prin lege.
													</p>
													<p>
														Organizatorul va respecta dispoziţiile art.12 din Legea nr
														506/2004 privind prelucrarea datelor cu caracter personal şi
														protecţia vieţii private în sectorul comunicaţiilor electronice,
														referitor la transmiterea de informaţii prin e-mail şi alte
														mijloace electronice.
													</p>
													<p>
														Formularele conţinând date cu caracter personal completate
														on-line, precum şi rezultatele oricăror interacţiuni finalizate
														de utilizatori, cum ar fi dar fără a se limita la răspunsurile
														date de aceştia la întrebările / chestionare disponibile în
														cadrul website-urilor deţinute de Philip Morris Trading S.R.L,
														devin proprietatea Philip Morris Trading S.R.L., cu toate
														drepturile aferente, inclusiv dreptul de a prelucra sau utiliza
														în scopuri de marketing direct informaţiile conţinute, fără alte
														obligaţii şi plăţi ulterioare faţă de persoana respectivă. Prin
														înscrierea la această Campanie, participanţii sunt de acord cu
														prevederile prezentului document şi al documentelor conexe şi cu
														prelucrarea datelor cu caracter personal şi sunt de acord ca, în
														cazul în care vor câştiga, numele, prenumele şi premiul câştigat
														să fie folosite în scopuri publicitare, fără alte obligaţii sau
														plăţi. La cererea scrisă, semnată şi datată a persoanei
														respective, adresată la CP84 OP83, Bucureşti sau prin email la
														contact@iqos.ro, o dată pe an, în mod gratuit, compania Philip
														Morris Trading S.R.L. va confirma utilizarea datelor care o
														privesc sau va înceta orice prelucrare a acestora. De asemenea,
														Philip Morris Trading S.R.L. va rectifica, actualiza, bloca,
														şterge sau transforma în date anonime pe toate acelea a căror
														folosire nu este conformă dispoziţiilor Regulamentului (UE) nr.
														679/2016.
													</p>

													<h5>SECŢIUNEA 7. CENTRUL DE ASISTENŢĂ CLIENTI</h5>
													<p>
														Pe perioada derulării prezentei Campanii, de luni până dumincă,
														orele 08.00-22.00, persoanele interesate de detalii pot contacta
														centrul de asistenţă clienţi destinat IQOS la numărul
														0800.030.333 (apel gratuit) pentru a afla mai multe informaţii
														în legătură cu prezenta Campanie. În afara programului menţionat
														mai sus, va exista un mesaj preînregistrat care va comunica
														apelanţilor programul de funcţionare a liniei telefonice.
													</p>
													<p>
														Organizatorul îşi rezervă dreptul de a nu lua în considerare
														solicitările primite la centrul de asistenţă clienţi de la
														apelanţi cu atitudine necorespunzătoare (cum ar fi, dar fără a
														se limita la aceste situaţii: limbaj agresiv, inadecvat, ton
														ridicat al vocii, ameninţări). În aceste cazuri vor fi luate în
														considerare spre analiză, doar situaţiile semnalate şi primite
														de către Organizator de la respectivii reclamanţi în scris, la
														adresa de email{' '}
														<a href="mailto:contact@iqos.ro" target="_blank">
															contact@iqos.ro
														</a>{' '}
														până la data de 15 martie 2020 (inclusiv), data poştei.
													</p>

													<h5>SECŢIUNEA 8. TAXE ŞI IMPOZITE</h5>
													<p>
														Organizatorul se obligă să calculeze, să reţină şi să vireze
														impozitul datorat pentru veniturile obţinute de către
														câştigători în conformitate cu Legea nr.227/2015 privind Codul
														fiscal, cu modificările şi completările ulterioare.{' '}
													</p>
													<p>
														De asemenea, organizatorul se obligă să depună la organul fiscal
														competent, până la data de 31 ianuarie inclusiv a anului curent
														pentru anul expirat „Declaraţia informativă privind impozitul
														reţinut la sursă, veniturile din jocuri de noroc şi
														câştigurile/pierderile din investiţii, pe beneficiari de venit
														(D205)” in calitatea sa de plătitor de venituri care are
														obligația calculării, reținerii și virării impozitului pe
														veniturile cu regim de reținere la sursă a impozitului, potrivit
														titlului IV din Legea nr.227/2015 privind Codul fiscal, cu
														modificările şi completările ulterioare. <br />
														Orice alte obligaţii de natură fiscală sau de orice altă natură
														în legatură cu acestea sunt în sarcina exclusivă a
														câştigătorului. Orice alte cheltuieli care nu sunt acoperite în
														mod specific de acest Regulament Oficial vor fi suportate de
														către câştigător.
													</p>

													<h5>SECŢIUNEA 9. LITIGII</h5>
													<p>
														În cazul unor potenţiale litigii apărute între Organizator şi
														participanţii la prezenta Campanie, acestea vor fi soluţionate
														pe cale amiabilă. Dacă nu este posibilă rezolvarea litigiilor pe
														cale amiabilă, părţile implicate în litigiu se vor adresa pentru
														soluţionare instanţelor competente române în jurisdicţia cărora
														se află sediul Organizatorului. Orice reclamaţii sau sesizări
														referitoare la prezenta Campanie promoţională se primesc în
														scris de către Organizator prin email la adresa contact@iqos.ro
														sau prin Poştă la adresa CP84, OP83, Bucureşti, România.
													</p>

													<h5>SECŢIUNEA 10. ÎNCETAREA CAMPANIEI</h5>
													<p>
														Prezenta Campanie poate înceta ca urmare a unei decizii a
														Organizatorului sau în cazul apariţiei unui eveniment ce
														constituie forţă majoră, inclusiv în cazul imposibilităţii
														Organizatorului, din motive independente de voinţa sa, de a
														continua prezenta Campanie promoţională. Organizatorul își
														rezervă dreptul să adauge și/sau să retragă diverse promoții în
														cadrul Campaniei, urmând a aduce la zi secțiunea Promoții
														Curente a prezentului regulament Oficial, și informând
														participanții la Campanie cu privire la debutul/încetarea
														valabilității anumitor promoții prin e-mail și/sau direct prin
														intermediul platformei online, la adresa web „club.iqos.ro”.
														Încetarea uneia sau mai multor promoții din cadrul Campaniei nu
														atrage după sine încetarea propriu-zisă a Campaniei.
													</p>
													<a
														href="{{ route('regulament-lucky-draw-4-ani') }}"
														className="regulamentPDF_v2"
														target="_blank"
													>
														Accesează regulamentul campaniei autentificat
													</a>
												</div>
											</div>

											<h4 className="acc_title_bar">Promoţia: O nouă versiune HEETS</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Pe perioada 26 februarie 2020 – 25 martie 2020 23:59:59
														(inclusiv) Participanţii eligibili, utilizatori ai platformei
														online IQOS Club încadraţi în Nivelul 3 – Platinum, vor putea
														accesa secţiunea din cadrul website-ului destinată interacţiunii
														„<span className="highlight">O nouă versiune HEETS</span>”.
														Utilizatorii platformei online IQOS Club încadraţi în Nivelul 1
														- Silver şi Nivelul 2 – Gold nu vor putea accesa secţiunea
														dedicată prezentei Promoţii şi nu vor putea Participa la
														aceasta.
													</p>
													<p>
														Pe perioada de desfăşurare a prezentei Promoţii, Participanţii
														eligibili, vor putea accesa secţiunea din cadrul platformei
														online special dedicată, unde vor completa un formular de
														revendicare beneficiu cu informaţiile necesare livrării:
														localitate, judeţ, detalii adresă – stradă, număr, bloc, etaj,
														apartament, observaţii. <br />
														Un participant eligibil poate revendica acest beneficiu o
														singură dată, doar pe perioada de desfăşurare a prezentei
														Promoţii.
													</p>
													<p>
														Beneficiul care se acordă în urma completării cu succes a
														formularului conform detaliilor menţionate anterior constă
														într-un kit având în componenţă 1 (un) pachet HEETS din noua
														versiune.
													</p>
													<p>
														Identificarea Participanţilor se va realiza pe baza datelor cu
														care acesta s-a înscris în cadrul platfomei online IQOS Club şi
														a celor comunicate în momentul revendicării beneficiului în
														cadrul interacţiunii dedicate prezentei Promoţii (nume, prenume,
														telefon, adresa de livrare).{' '}
													</p>
													<p>
														Beneficiile constând în kit conţinând 1 (un) pachet de HEETS,
														vor fi livrate acestora prin intermediul serviciilor de
														curierat. Coletul va fi expediat către Participantul identificat
														după datele cu care acesta este înscris în cadrul platformei
														online IQOS Club (nume, prenume, telefon), la adresa completată
														de Participant în momentul completării formularului din cadrul
														prezentei Promoţii. Expedierea coletului se va face în termen de
														5 (cinci) zile lucrătoare de la data completării cu succes a
														formularului destinat prezentei Promoţii din cadrul platformei
														online IQOS Club.
													</p>
													<p>
														În situaţia în care adresa de livrare completată de Participant
														în formularul de revendicare beneficiu nu conţine toate
														detaliile necesare livrării sau este o adresă din afara
														teritoriului României, atunci participarea nu va fi considerată
														validă, Participantul va fi decăzut din drepturi, iar coletul nu
														va fi expediat.
													</p>
													<p>
														În faza recepţionării beneficiului, Participantul trebuie să
														prezinte în original un document de identitate (CI/BI). În
														situaţia în care, la livrare, datele din CI/BI (nume, prenume)
														nu corespund cu cele cu care acesta este înscris în cadrul
														platformei online IQOS Club, acesta va fi invalidat şi decăzut
														din drepturi.
													</p>
													<p>
														Se va încerca o singură dată livrarea coletului conţinând
														beneficiul pentru care Participantul a fost validat la adresa
														menţionată de Participant în momentul completării formularului
														din cadrul platformei online IQOS Club. O nouă încercare de
														livrare se va face în momentul în care Participantul a sunat la
														Centrul de Asistenţă Clienţi şi a menţionat detaliile
														referitoare la data, ora şi adresa la care acesta poate fi
														contactat pentru livrare.
													</p>
													<p>
														Curierul nu are obligaţia de a oferi informaţii în legătură cu
														prezenta Promoţie. Pentru detalii în acest sens, participanţii
														trebuie să apeleze Centrul de Asistenţă Clienţi, conform
														Secţiunii 19. „Centrul de asistenţă clienţi”.
													</p>
													<p>
														În situaţia în care Participanţii nu au putut fi contactaţi
														pentru livrarea beneficiului validat, din cauze independente de
														Organizator şi nu au apelat Centrul de Asistenţă Clienţi,
														revendicând beneficiul nelivrat, până la data de{' '}
														<b>30 aprilie 2020</b>, aceştia vor fi decăzuţi din dreptul de
														a-l mai revendica.
													</p>
												</div>
											</div>

											<a
												href="{{ route('regulament-boost-november') }}"
												target="_blank"
												className="regulamentPDF"
											>
                                                <span>
                                                    Promoţia: Clubul IQOS - Înscrierea de recomandări în perioada 06
                                                    noiembrie 2019 – 31 ianuarie 2020
                                                </span>
											</a>
										</div>
									</div>
									<h3 className="acc_title_bar">
										<a href="#">Activitatea caritabilă Masa Bunătății</a>
									</h3>
									<div className="acc_container">
										<div className="block">
											<p>
												Activitatea caritabilă Masa Bunătății („Activitatea”) se desfăşoară în
												cadrul platformei online IQOS Club începând cu data de{' '}
												<b>12 mai 2020 ora 15:00:00</b>; Activitatea poate înceta ca urmare a
												unei decizii a Organizatorului, cu respectarea condiţiilor generale ale
												platformei online „IQOS Club”, încetarea urmând a fi comunicată public
												tot în cadrul platformei online IQOS Club.
											</p>
											<p>
												Prezenta Activitate are drept obiectiv strângerea de fonduri şi
												utilizarea acestora în scopuri caritabile, în parteneriat cu Organizaţia
												Patronală a Hotelurilor şi Restaurantelor din România, denumită în cele
												ce urmează HORA.
											</p>
											<p>
												La prezenta activitate pot participa toţi utilizatorii cu cont activ în
												cadrul platformei online IQOS Club, care deţin în contul lor cel puţin
												20 (douăzeci) puncte disponibile, acumulate prin oricare din
												modalităţile puse la dispoziţie de Organizator conform regulilor
												promoţiilor din categoria „Acumulare de puncte”.
											</p>
											<p>
												Participanţii care accesează platforma online IQOS Club pot accesa
												secţiunea „Masa bunătăţii”, unde pot vizualiza informaţii despre
												Activitate şi unde pot realiza acţiunea de transfer/cedare a numărului
												de puncte dorit de utilizator pentru susținerea Activității, cu
												respectarea condiţiilor de participare din cadrul acestui regulament.
											</p>
											<p>
												Pentru fiecare masă caldă oferită în cadrul Proiectului Masa Bunătății
												este nevoie de echivalentul a 40 de puncte. Astfel, valoarea exprimată
												în bani a punctelor cedate de participanții la prezenta Activitate va fi
												dublată de Organizator şi alocată pentru a susţine financiar iniţiativa
												caritabilă a HORA, aceasta fiind oferirea de mese calde pentru, dar fără
												a se limita la medici / cadre medicale şi persoane fără adăpost, mese
												pregătite de personal de tip bucătari/personal auxiliar ai unor unități
												de tip restaurante/cantine membre ale HORA. Ca urmare, fiecare transfer
												în valoare de 20 puncte de către un participant la această Activitate
												este, în urma dublării de către Organizator a valorii transferate,
												echivalentul unei mese calde oferite uneia dintre persoanele din
												categoriile vizate în cadrul prezentului proiect.
											</p>
											<p>
												Acţiunea de transfer puncte este inițiată de Participant la libera
												alegere, cu titlu benevol, şi îşi exprimă astfel consimţământul ca
												punctele oferite de acesta să fie utilizate de Organizator exclusiv în
												scopul declarat de acesta în cadrul prezentului regulament.
											</p>
											<p>
												Acţiunea de transfer puncte nu este reversibilă (utilizatorul nu poate
												solicita post factum renunţarea la transfer) și conduce la scăderea
												numărului total de puncte din IQOS Club ale participantului cu numărul
												celor selectate pentru transfer în susținerea Activității.
											</p>
											<p>
												Participantul poate alege să transfere, în cadrul unei interacţiuni, un
												număr de puncte dintre cele disponibile în contul său, alegând dintre
												opţiunile de transfer puse la dispoziţie de Organizator în formularul
												special dedicat din cadrul platformei IQOS Club sub denumirea generică
												de „donație”, acestea fiind în valoare de 20 puncte sau multiplu de 20
												puncte, funcţie de numărul de puncte disponibile în contul
												participantului.
											</p>
											<p>
												Un participant care nu deţine în contul său un număr de minim 20 puncte
												disponibile nu poate iniţia acţiunea de transfer.
											</p>
											<p>
												Un participant poate iniţia acţiunea de transfer de oricâte ori doreşte
												pe perioada de desfăşurare a prezentei Activități, dacă sunt întrunite
												cumulativ toate criteriile de eligibilitate.
											</p>
										</div>
									</div>
									<h3 className="acc_title_bar">
										<a href="{{route('regulament-anterior')}}" target="_blank">
											Regulament anterior
										</a>
									</h3>
								</div>
							</div>
						</section>

						<section>
							<h2 className="acc_title_bar">
								<a href="#">SECŢIUNEA 6. RĂSPUNDERE</a>
							</h2>
							<div className="acc_container">
								<div className="block">
									<p>
										Organizatorul şi societăţile implicate în organizarea prezentei Campanii nu îşi
										asumă răspunderea pentru imposibilitatea participării la Campanie datorită unor
										cauze care nu depind direct de ei.
									</p>
									<p>
										Prin înscrierea la această Campanie, Participanţii sunt de acord să respecte
										condiţiile de participare precum şi toate deciziile luate de Organizator şi
										colaboratorii săi în toate aspectele legate de implementarea prezentei campanii.
									</p>
									<p>
										Organizatorul nu-şi asumă răspunderea şi nu este responsabil pentru situaţiile
										în care un participant nu poate accesa platforma online dedicată Campaniei din
										motive care nu ţin de funcţionalitatea website-ului, de genul: conexiune la
										internet, programe instalate, versiuni de browser vechi, probleme cu reţeaua
										electrică, erori cauzate de întreruperea neaşteptată a serviciului de hosting al
										website-ului etc sau alte motive independente de Organizator.
									</p>
									<p>
										Organizatorul nu are nicio obligaţie de a întreţine corespondenţa cu
										solicitanţii unor revendicări ce apar ulterior termenelor stabilite în prezentul
										document sau al documentelor conexe.
									</p>
								</div>
							</div>
						</section>

						<section>
							<h2 className="acc_title_bar">
								<a href="#">SECŢIUNEA 7. PROTECŢIA DATELOR PERSONALE</a>
							</h2>
							<div className="acc_container">
								<div className="block">
									<p>
										Tuturor participanţilor la prezenta Campanie le sunt garantate drepturile cu
										privire la protecţia datelor cu caracter personal în conformitate cu
										Regulamentul (UE) 679/2016 privind protecţia persoanelor fizice în ceea ce
										priveşte prelucrarea datelor cu caracter personal şi privind libera circulaţie a
										acestor date, în special drepturile persoanei vizate cu privire la informare şi
										acces la date, dreptul la rectificare, dreptul la ştergerea datelor, dreptul la
										restricţionarea prelucrării, dreptul la portabilitatea datelor, dreptul la
										opoziţie, de a se adresa instanţei competente şi de plângere către autoritatea
										de supraveghere.{' '}
									</p>
									<p>
										În înţelesul Regulamentului (UE) nr. 679/2016, principalele drepturi mai sus
										menţionate aparţinând persoanelor vizate au următorul conţinut:{' '}
									</p>

									<ol>
										<li>
											Dreptul de informare şi acces la date: Orice persoană vizată are dreptul de
											a obţine de la Operator, la cerere şi în mod gratuit pentru o solicitare pe
											an, confirmarea faptului că datele care o privesc sunt sau nu sunt
											prelucrate de către acesta. Astfel, la cererea scrisă, semnată şi datată a
											persoanei respective, adresată la CP84 OP83, Bucureşti sau prin email la
											contact@iqos.ro, o dată pe an, în mod gratuit, compania Philip Morris
											Trading S.R.L. va confirma dacă utilizează date care o privesc sau va înceta
											orice prelucrare a acestora. În caz afirmativ, va furniza persoanei vizate
											urmatoarele informaţii: a) scopurile prelucrării; b) categoriile de date cu
											caracter personal vizate; c) destinatarii sau categoriile de destinatari
											cărora datele cu caracter personal le-au fost sau urmează să le fie
											divulgate, în special destinatari din ţări terţe sau organizaţii
											internaţionale; d) acolo unde este posibil, perioada pentru care se
											preconizează că vor fi stocate datele cu caracter personal sau, dacă acest
											lucru nu este posibil, criteriile utilizate pentru a stabili această
											perioadă; e) existenţa dreptului de a solicita operatorului rectificarea sau
											ştergerea datelor cu caracter personal ori restricţionarea prelucrării
											datelor cu caracter personal referitoare la persoana vizată sau a dreptului
											de a se opune prelucrării; f) dreptul de a depune o plângere în faţa unei
											autorităţi de supraveghere; g) în cazul în care datele cu caracter personal
											nu sunt colectate de la persoana vizată, orice informaţii disponibile
											privind sursa acestora; h) existenţa unui proces decizional automatizat
											incluzând crearea de profiluri, menţionat la articolul 22 alineatele (1) şi
											(4), precum şi, cel puţin în cazurile respective, informaţii pertinente
											privind logica utilizată şi privind importanţa şi consecinţele preconizate
											ale unei astfel de prelucrări pentru persoana vizată.
										</li>
										<li>
											Dreptul la rectificare: Orice persoană vizată are dreptul de a obţine de la
											Operator, la cerere şi în mod gratuit, fără întârzieri nejustificate,
											rectificarea datelor cu caracter personal inexacte care o privesc.
											Ţinându-se seama de scopurile în care au fost prelucrate datele, persoana
											vizată are dreptul de a obţine completarea datelor cu caracter personal care
											sunt incomplete, inclusiv prin furnizarea unei declaraţii suplimentare.
										</li>

										<li>
											Dreptul la ştergerea datelor ("dreptul de a fi uitat"): Persoana vizată are
											dreptul de a obţine din partea operatorului ştergerea datelor cu caracter
											personal care o privesc, fără întârzieri nejustificate, iar operatorul are
											obligaţia de a şterge datele cu caracter personal fără întârzieri
											nejustificate în cazul în care se aplică unul dintre următoarele motive: a)
											datele cu caracter personal nu mai sunt necesare pentru îndeplinirea
											scopurilor pentru care au fost colectate sau prelucrate; b) persoana vizată
											îşi retrage consimţământul pe baza căruia are loc prelucrarea; c) persoana
											vizată se opune prelucrării şi nu există motive legitime care să prevaleze
											în ceea ce priveşte prelucrarea; d) datele cu caracter personal au fost
											prelucrate ilegal; e) datele cu caracter personal trebuie şterse pentru
											respectarea unei obligaţii legale care revine operatorului;
										</li>

										<li>
											Dreptul la restricţionarea prelucrării: Persoana vizată are dreptul de a
											obţine din partea operatorului restricţionarea prelucrării în cazul în care
											se aplică unul din următoarele cazuri: a) persoana vizată contestă
											exactitatea datelor, pentru o perioadă care îi permite operatorului să
											verifice exactitatea datelor; b) prelucrarea este ilegală, iar persoana
											vizată se opune ştergerii datelor cu caracter personal, solicitând în schimb
											restricţionarea utilizării lor; c) operatorul nu mai are nevoie de datele cu
											caracter personal în scopul prelucrării, dar persoana vizată i le solicită
											pentru constatarea, exercitarea sau apărarea unui drept în instanţă; sau d)
											persoana vizată s-a opus prelucrării, pentru intervalul de timp în care se
											verifică dacă drepturile legitime ale operatorului prevalează asupra celor
											ale persoanei vizate;
										</li>
										<li>
											Dreptul la portabilitatea datelor: Persoana vizată are dreptul de a primi
											datele cu caracter personal care o privesc şi pe care le-a furnizat
											operatorului într-un format structurat, utilizat în mod curent şi care poate
											fi citit automat şi are dreptul de a transmite aceste date altui operator,
											fără obstacole din partea operatorului căruia i-au fost furnizate datele cu
											caracter personal, în cazul în care: a) prelucrarea se bazează pe
											consimţământ în temeiul articolului 6 alineatul (1) litera (a) sau al
											articolului 9 alineatul (2) litera (a) sau pe un contract în temeiul
											articolului 6 alineatul (1) litera (b) din Regulamentul (UE) nr. 679/2006;
											şi b) prelucrarea este efectuată prin mijloace automate.
										</li>
										<li>
											Dreptul la opoziţie: Persoana vizată are dreptul de a se opune în orice
											moment, în mod gratuit şi fără nicio justificare, ca datele care o vizează
											să fie prelucrate în scop de marketing direct, în numele operatorului sau al
											unui terţ sau să fie dezvăluite unor terţi într-un asemenea scop
											(reprezentând alin.2 al art.15 din legea 677/2001).
										</li>
									</ol>

									<p>
										Următoarele date personale vor fi colectate pe durata Campaniei participanţilor
										care se înregistrează în Baza de date PM şi câştigătorilor de premii: nume,
										prenume, data de naştere, număr telefon mobil, e-mail, localitate, judet, sex.
										Scopurile constituirii bazei de date sunt: înmânarea premiilor câştigătorilor,
										înregistrarea câştigătorilor Campaniei, realizarea de rapoarte statistice cu
										privire la utilizatorii IQOS, informarea acestora prin diverse mijloace de
										comunicare (poştă, e-mail, SMS sau alte mijloace de comunicare permise prin
										lege), cu privire la alte acţiuni desfăşurate în viitor de către Organizator.
										Organizatorul se obligă că datele personale să nu fie difuzate către terţi cu
										excepţia împuterniciţilor săi. Prin înscrierea în Baza de date PM prin
										intermediul secţiunilor dedicate din cadrul website-urilor deţinute de Philip
										Morris Trading S.R.L, participanţii care au optat în acest sens îşi dau în mod
										expres consimţământul ca datele lor (respectiv, nume şi prenume, data naşterii,
										sex, telefon mobil, adresă de e-mail, localitate, judeţ) să fie incluse în baza
										de date de utilizatori IQOS / fumători adulţi a companiei Philip Morris Trading
										S.R.L. şi să primească ocazional obiecte promoţionale, precum şi informaţii prin
										poştă, telefon, e-mail, SMS sau alte mijloace de comunicare permise prin lege.
									</p>
									<p>
										Organizatorul va respecta dispoziţiile art.12 din Legea nr 506/2004 privind
										prelucrarea datelor cu caracter personal şi protecţia vieţii private în sectorul
										comunicaţiilor electronice, referitor la transmiterea de informaţii prin e-mail
										şi alte mijloace electronice.
									</p>
									<p>
										Formularele conţinând date cu caracter personal completate on-line, precum şi
										rezultatele oricăror interacţiuni finalizate de utilizatori, cum ar fi dar fără
										a se limita la răspunsurile date de aceştia la întrebările / chestionare
										disponibile în cadrul website-urilor deţinute de Philip Morris Trading S.R.L,
										devin proprietatea Philip Morris Trading S.R.L., cu toate drepturile aferente,
										inclusiv dreptul de a prelucra sau utiliza în scopuri de marketing direct
										informaţiile conţinute, fără alte obligaţii şi plăţi ulterioare faţă de persoana
										respectivă. Prin înscrierea la această Campanie, participanţii sunt de acord cu
										prevederile prezentului document şi al documentelor conexe şi cu prelucrarea
										datelor cu caracter personal şi sunt de acord ca, în cazul în care vor câştiga,
										numele, prenumele şi premiul câştigat să fie folosite în scopuri publicitare,
										fără alte obligaţii sau plăţi. La cererea scrisă, semnată şi datată a persoanei
										respective, adresată la CP84 OP83, Bucureşti sau prin email la contact@iqos.ro,
										o dată pe an, în mod gratuit, compania Philip Morris Trading S.R.L. va confirma
										utilizarea datelor care o privesc sau va înceta orice prelucrare a acestora. De
										asemenea, Philip Morris Trading S.R.L. va rectifica, actualiza, bloca, şterge
										sau transforma în date anonime pe toate acelea a căror folosire nu este conformă
										dispoziţiilor Regulamentului (UE) nr. 679/2016.
									</p>
								</div>
							</div>
						</section>

						<section>
							<h2 className="acc_title_bar">
								<a href="#">SECŢIUNEA 9. CENTRUL DE ASISTENŢĂ CLIENŢI</a>
							</h2>
							<div className="acc_container">
								<div className="block">
									<p>
										Pe perioada derulării prezentei Campanii, de luni până dumincă, orele
										08.00-22.00, persoanele interesate de detalii pot contacta centrul de asistenţă
										clienţi destinat IQOS la numărul 0800.030.333 (apel gratuit) pentru a afla mai
										multe informaţii în legătură cu prezenta Campanie. În afara programului
										menţionat mai sus, va exista un mesaj preînregistrat care va comunica
										apelanţilor programul de funcţionare a liniei telefonice.{' '}
									</p>
									<p>
										Organizatorul îşi rezervă dreptul de a nu lua în considerare solicitările
										primite la centrul de asistenţă clienţi de la apelanţi cu atitudine
										necorespunzătoare (cum ar fi, dar fără a se limita la aceste situaţii: limbaj
										agresiv, inadecvat, ton ridicat al vocii, ameninţări). În aceste cazuri vor fi
										luate în considerare spre analiză, doar situaţiile semnalate şi primite de către
										Organizator de la respectivii reclamanţi în scris, la CP84 OP83, Bucureşti până
										la data de 15 septembrie 2018 (inclusiv), data poştei.
									</p>
								</div>
							</div>
						</section>

						<section>
							<h2 className="acc_title_bar">
								<a href="#">SECŢIUNEA 10. LITIGII</a>
							</h2>
							<div className="acc_container">
								<div className="block">
									<p>
										În cazul unor potenţiale litigii apărute între Organizator şi participanţii la
										prezenta Campanie, acestea vor fi soluţionate pe cale amiabilă. Dacă nu este
										posibilă rezolvarea litigiilor pe cale amiabilă, părţile implicate în litigiu se
										vor adresa pentru soluţionare instanţelor competente române în jurisdicţia
										cărora se află sediul Organizatorului. Orice reclamaţii sau sesizări referitoare
										la prezenta Campanie promoţională se primesc în scris de către Organizator prin
										email la adresa contact@iqos.ro sau prin Poştă la adresa CP84, OP83, Bucureşti,
										România.
									</p>
								</div>
							</div>
						</section>

						<section>
							<h2 className="acc_title_bar">
								<a href="#">SECŢIUNEA 11. ÎNCETAREA CAMPANIEI</a>
							</h2>
							<div className="acc_container">
								<div className="block">
									<p>
										Prezenta Campanie poate înceta ca urmare a unei decizii a Organizatorului sau în
										cazul apariţiei unui eveniment ce constituie forţă majoră, inclusiv în cazul
										imposibilităţii Organizatorului, din motive independente de voinţa sa, de a
										continua prezenta Campanie promoţională. Organizatorul își rezervă dreptul să
										adauge și/sau să retragă diverse promoții în cadrul Campaniei, urmând a aduce la
										zi secțiunea Promoții Curente a prezentului regulament Oficial, și informând
										participanții la Campanie cu privire la debutul/încetarea valabilității anumitor
										promoții prin e-mail și/sau direct prin intermediul platformei online, la adresa
										web „club.iqos.ro”. Încetarea uneia sau mai multor promoții din cadrul Campaniei
										nu atrage după sine încetarea propriu-zisă a Campaniei.
									</p>
								</div>
							</div>
						</section>
					</div>
				</div>
			</Layout>
		);
	}
}

export default Regulation;
