import React from 'react';
import store from 'store';
import cookies from '../../../../utils/cookies';

// Assets
import styles from './style.module.scss';
import { LinkContainer } from 'react-router-bootstrap';
import config from '../../../../config';
import { Nav } from 'react-bootstrap';

class ManageCookies extends React.Component {
	state = {
		marketing: false,
		performance: false,
		marketingSaved: false,
		performanceSaved: false
	};

	handleChange = (field, value) => {
		this.setState((state) => ({
			...state,
			[field]: value
		}));
	};

	save = () => {
		const { marketing, performance } = this.state;

		cookies.create('dw_Advertisement_cookie', marketing ? 'opt-in' : 'opt-out', 333);
		if (marketing) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: 'cookieSettings',
				cookieType: 'advertising',
				cookieValue: 'opt-in'
			});
		} else {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: 'cookieSettings',
				cookieType: 'advertising',
				cookieValue: 'opt-out'
			});
		}

		cookies.create('dw_Technical_cookie', performance ? 'opt-in' : 'opt-out', 333);
		if (performance) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: 'cookieSettings',
				cookieType: 'performance',
				cookieValue: 'opt-in'
			});
		} else {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: 'cookieSettings',
				cookieType: 'performance',
				cookieValue: 'opt-out'
			});
		}

		this.setState((state) => ({
			...state,
			marketingSaved: state.marketing,
			performanceSaved: state.performance
		}));

		cookies.create('cookies_accepted', 1, 333);

		// this.props.onClose();
		this.props.closeDisclaimer && this.props.closeDisclaimer();
	};

	componentDidMount() {
		this.setState((state) => ({
			...state,
			marketing: state.marketingSaved,
			performance: state.performanceSaved
		}));

		if (!cookies.read('dw_Technical_cookie') && !cookies.read('dw_Advertisement_cookie')) {
			cookies.create('dw_Technical_cookie', 'opt-in', 333);
			cookies.create('dw_Advertisement_cookie', 'opt-out', 333);
		}

		if (cookies.read('dw_Technical_cookie') === 'opt-in') {
			this.setState({ performance: true });
		} else {
			this.setState({ performance: false });
		}

		if (cookies.read('dw_Advertisement_cookie') === 'opt-in') {
			this.setState({ marketing: true });
		} else {
			this.setState({ marketing: false });
		}
	}

	render() {
		const { standard, marketing, performance } = this.state;

		return (
			<div className={styles.popup}>
				<h2>GESTIONEAZĂ COOKIE-URILE</h2>
				<p>
					În momentul în care vizitezi <b><a href="https://iqos.ro/club/">club.iqos.ro</a></b>, website-ul stochează informații despre tine, de
					obicei în format de cookies. Aceste informații sunt, de obicei, folosite pentru a-ți putea oferi o
					experiență personalizată. Poți alege să accepți toate cookie-urile sau să refuzi anumite cookie-uri.
					Alege mai jos ce tipuri de cookies accepți. Te rugăm să iei în calcul că dezactivarea anumitor
					tipuri de cookies poate avea impact în experiența ta pe website și în serviciile pe care ți le
					oferim prin intermediul acestuia.
				</p>

				<p>
					<a href={config.path.cookiesPolicy} target='_blank'>Vezi politica de cookies aici.</a>

				</p>

				<form className={styles.cookieForm}>
					<label className="control control--checkbox">
						<input type="checkbox" name="standard" disabled checked={standard} />
						<div className="control__indicator" style={{ display: 'none' }} />

						<span className="cookie-details">
                            <span>Strict necesare</span>
                            <p>
                                Cookie-urile strict necesare asigură funcționarea corectă a platformei și a tuturor
                                serviciilor oferite de aceasta. Întrucât aceste informații sunt esențiale pentru
                                operarea platformei digitale și a serviciilor sale, nu poți refuza colectarea lor.
                            </p>
                        </span>
					</label>

					<label className="control control--checkbox">
						<input
							type="checkbox"
							name="performance"
							checked={performance}
							onChange={(e) => this.handleChange('performance', e.target ? e.target.checked : e)}
						/>
						<div className="control__indicator" />

						<span className="cookie-details">
                            <span>Evaluarea performanței</span>
                            <p>
                                Cookie-urile de tip evaluarea performanței ne permit să îmbunătățim modul în care
                                platforma digitală funcționează, înțelegând modul în care vizitatorii folosesc
                                website-ul și modul în care interacționează cu acesta.
                            </p>
                            <em>Cookies folosite:</em>
                            <ul>
                                <li>Google Analytics</li>
                                <li>Optimizely</li>
                                <li>SessionCam</li>
                            </ul>
                        </span>
					</label>

					<label className="control control--checkbox">
						<input
							type="checkbox"
							name="advertising"
							checked={marketing}
							onChange={(e) => this.handleChange('marketing', e.target ? e.target.checked : e)}
						/>
						<div className="control__indicator" />

						<span className="cookie-details">
                            <span>Targetare sau publicitate</span>
                            <p>
                                Cookie-urile de tip targetare sau publicitate ne ajută să îți livrăm publicitate
                                relevantă. Partenerii noștri de Publicitate angajează terți pentru a urmări și analiza,
                                utiliza și colecta informații statistice de la persoanele care vizitează platforma
                                digitală.
                            </p>
                            <em>Cookies folosite:</em>
                            <ul>
                                <li>Doubleclick</li>
                                <li>Salesforce DMP</li>
                            </ul>
                        </span>
					</label>
				</form>
				<button className={styles.saveButton} onClick={this.save}>
					Salvează
				</button>
			</div>
		);
	}
}

export default ManageCookies;
