import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
	devices: null,
	devicesLoading: null,
	devicesSuccess: ['devices'],
	devicesFail: ['errors']
});

export const DevicesTypes = Types;
export default Creators;
