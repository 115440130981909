import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
	introSlider: null,
	introSliderLoading: null,
	introSliderSuccess: ['introSlider'],
	introSliderFail: ['errors']
});

export const IntroSliderTypes = Types;
export default Creators;
