import React from "react";
import store from "store";
import classnames from "classnames";

// Assets
import config from "../../../config";
import styles from "./style.module.scss";

// Components
import Layout from "../../../components/Layout";

class TermsConditions extends React.Component {
	state = {
		isPWA: store.get(config.user.isPWA)
	};

	render() {
		const {isPWA} = this.state;

		return (
			<Layout>
				<div className={classnames('page_heading', isPWA ? 'isPWA' : '')}>
					<h1>Termeni și condiții</h1>
				</div>

				<div className={styles.container}>
					<div className={styles.gdpr}>
						<h2>CONDIŢII DE UTILIZARE A WEBSITE-ului CLUB.IQOS.RO</h2>

						<p>Site-ul web CLUB.IQOS.RO, este supus termenilor și condițiilor ("Termenii de utilizare") descrise mai jos. Site-ul web CLUB.IQOS.RO este deținut de Philip Morris Trading S.R.L,
							societate având sediul social  în Otopeni, Str. Horia, Cloşca şi Crişan, Nr. 83-105, clădirea A, Judeţul Ilfov, România, și punct de lucru  în Bucureşti, Strada
							Nicolae Filipescu nr. 28, Sector 2,  înmatriculată la Registrul Comerţului sub numărul J23/511/2004, având Cod Unic de Identificare/Cod de  înregistrare Fiscală RO
							4888165,  înregistrată la ANSPDCP ca operator de date cu caracter personal sub numărul 3971/2007. Termenii "noi", "al nostru", "nostru" folosiți mai jos se
							referă la Philip Morris Trading S.R.L. (denumită  în continuare "Philip Morris"). Condițiile referitoare la promoțiile disponibile  în cadrul website-ului CLUB.IQOS.RO
							(denumit  în continuare "Site-ul" sau "CLUB.IQOS.ro"), precum și promoțiile  în sine sunt destinate  în exclusivitate persoanelor fizice care sunt cetăţeni
							români, au vârsta peste 18 ani ( împliniţi până la data participării pe Site), sunt fumători sau utilizatori de produse ce folosesc tutun  încălzit şi au
							domiciliul sau reşedinţa  în România, şi rezidenţilor  în România care  îndeplinesc aceleaşi cerinţe de vârstă şi care au statut de fumători (denumiți
							 în continuare "Utilizatori"). Utilizatorii trebuie să deţină Buletin/Carte de identitate/documente eliberate de autorităţile statului român care să ateste
							rezidenţa pe teritoriul României, conform cadrului legal aplicabil.</p>
						<p>Acest site,  împreună cu toate informațiile și serviciile disponibile, este adresat utilizatorilor. Accesul acestora  în site este condiționat de acceptarea tuturor Termenilor
							și Conditiilor declarate aici. Continuarea utilizarii acestui site constituie un acord din partea utilizatorilor referitor la acești Termeni și Condiții.</p>
						<p>Imaginile prezentate pe site sunt cu titlu de prezentare și pot diferi  în orice mod (culoare, aspect etc.) de imaginile produselor livrate, acestea putând prezenta abateri
							de la pozele și descrierile prezentate pe site.</p>

						<h3>1.1 Condiţii privind Site-ul şi campaniile/ofertele promoționale asccesibile prin intermediul acestuia</h3>

						<p>Site-ul, precum şi campaniile/ofertele promoționale asccesibile prin intermediul acestuia se adresează exclusiv consumatorilor de produse din tutun, având vârsta de minim 18
							ani ( împliniți la data accesării Site-ului), care au domiciliul sau reședința  în România, sau rezidenților  în România care  îndeplinesc aceleași
							criterii.</p>
						<p>Toate informațiile referitoare la campaniile/ofertele promoționale asccesibile prin intermediul site-ului sunt incluse  în Regulamente oficiale ale diverselor campanii/oferte
							promoționale ce vor fi publicat pe acest site, la secțiunea "Regulament".</p>

						<h3>1.2 Accesarea Site-ului</h3>

						<p>Site-ul poate fi accesat de către cetăţenii români, cu vârsta peste 18 ani ( împliniţi până la data accesării site-ului), utilizatori de IQOS, cu cont activ pe
							website-ul <a href="http://www.iqos.ro">www.iqos.ro</a> şi care au  înregistrat  în contul lor minim un dispozitiv IQOS compus din Holder ("Stilou") şi  încărcător
							portabil&nbsp; şi care au domiciliul sau reşedinţa sau sunt rezidenţi  în România.</p>
						<p>Nu au dreptul să acceseze acest site sau să participe la campaniile/ofertele promoționale asccesibile prin intermediul acestuia angajații societăţilor Philip Morris Trading S.R.L.,
							Philip Morris România S.R.L. sau angajaţii companiilor organizatoare implicate  în desfăşurarea campaniilor/ofertelor promoționale asccesibile prin intermediul site-ului şi
							nici angajaţii celorlalte entităţi juridice implicate  în această acţiune (inclusiv reprezentanţii Philip Morris din cadrul acestei Campanii promoţionale sau reprezentanții
							magazinelor  în care sunt comercializate pachetele de ţigarete, parte din Campanie), precum şi niciunul dintre membrii familiilor acestora (copii, părinţi, soţ/soţie, frate/soră).
							Prin implicare  în organizarea campaniilor/ofertelor promoționale asccesibile prin intermediul site-ului se  înţelege derularea activităţilor care au legătură cu modalitatea
							de acordare a materialelor/cupoanelor/codurilor promoţionale, a premiilor, algoritmi, trageri la sorţi, procese de selecţie şi orice alte acţiuni de natură similară.</p>
						<p>Din dorinţa de a nu prejudicia niciun participant care doreşte să participe la prezenta Campanie promoţională, Philip Morris  îşi rezervă dreptul să poată efectua verificări
							 în ceea ce priveşte corecta participare la campanii.</p>
						<p> în cazul  în care,  în urma verificărilor desfăşurate de către Philip Morris, rezultă că procesul de participare a fost fraudat, Philip Morris  îşi rezervă dreptul
							de a retrage participanţilor premiile obţinute ca rezultat al activităţii lor şi / sau să restricţioneze participarea la campania promoţională respectivă până la  încheierea
							acesteia.</p>
						<p> în cazul  în care sunt identificate persoane care au influenţat sau au facilitat câştigarea de premii necuvenite, Philip Morris au dreptul de a cere urmărirea  în
							instanţă a respectivelor persoane, pe baza dovezilor existente.</p>

						<h3>1.3. Indicatorul punctelor de revendicare</h3>

						<p>Site-ul conţine o secţiune care permite fiecărui utilizator să găsească punctele de revendicare  în care sunt disponibile premiile oferite spre revendicare.</p>

						<h3>1.4. Condiţii de revendicare a produselor  în cadrul Site-ului</h3>

						<p>Philip Morris este proprietarul şi operatorul Site-ului. Philip Morris a  încredinţat societăţii Open Public Service S.R.L. ("OPS"), societate având sediul social  în , România,  înmatriculată la Registrul Comerţului sub numărul x, având Cod Unic de Identificare/Cod de  înregistrare Fiscală x dreptul de a gestiona și expedia produse din cadrul Site-ului. OPS: J40/6199/1996, CUI 8654450</p>
						<h3>1.5 Drepturile si obligațiile Dumneavoastră</h3>

						<p>Cei ce accesează site-ul au următoarele drepturi:</p>
						<ul>
							<li>utilizarea facilităților pentru care a fost creat site-ul - participare la campanieile promoționale și realizarea activităților explicitate  în Regulamentul oficial al
								Campaniei promoționale ce va fi publicat pe acest site, la secțiunea "Regulament"
							</li>
							<li>explorarea conținutului informativ;</li>
							<li>reproducerea, traducerea sau utilizarea informațiilor publicate, numai cu indicarea sursei;</li>
							<li>copierea sau tipărirea unor materiale  în scop personal, fără comercializarea acestora.</li>
						</ul>

						<p>Datele și informațiile sunt prezentate  în site numai cu scop informativ.</p>
						<p>Orice  încercare, de orice natură, de modificare a imaginii și informațiilor din prezentul site, precum și realizarea de link-uri fără acordul prealabil al Philip Morris Trading
							S.R.L,  îi conferă acestuia dreptul să facă uz de toate prerogativele sale de a se adresa instanțelor competente pentru sancționarea acestor fapte</p>

						<h2>2. CONDIŢII GENERALE</h2>

						<p>Website-ul CLUB.IQOS.RO ("Site", "Website" sau "CLUB.IQOS.RO) şi aplicaţiile ("Aplicaţiile") pe care Philip Morris le-ar putea adăuga ulterior
							 în cadrul acestuia sunt puse la dispoziţia utilizatorilor conform prezentelor condiţii generale de utilizare (denumite  în continuare "Condiţii Generale"). Acest
							Site şi aceste Aplicaţii sunt proprietatea Philip Morris şi termenii "noi", "nostru/noastră", "noştri/noastre" utilizaţi mai jos se referă la Philip
							Morris.</p>

						<h2>&nbsp;Reguli comune tuturor Site-urilor și Aplicaţiilor Philip Morris</h2>

						<h3>2.1. Domeniu de aplicare</h3>

						<p>Aceste Condiţii Generale se referă exclusiv la Website-urile şi Aplicaţiile dezvoltate de Philip Morris, şi nu la alte Site-uri și Aplicaţii, mai ales cele ale Philip Morris
							International Management SA, care pot avea condiţii generale diferite. Navigând pe Website-urile şi/sau Aplicaţiile Philip Morris, fiecare utilizator acceptă prezentele Condiţii
							Generale, precum şi modificările ulterioare publicate pe aceste Site-uri şi/sau Aplicaţii. Promoţiile şi concursurile organizate  în cadrul Site-urilor şi/sau Aplicaţiilor Philip
							Morris, precum şi vânzarea de produse Philip Morris realizată de Mediapost sunt, de asemenea, supuse acestor Condiţii Generale,  în plus faţă de condiţiile specifice sau
							complementare acestora.</p>

						<h3>2.2. Acces, parolă</h3>

						<p>Website-urile şi Aplicaţiile se adresează exclusiv consumatorilor de produse din tutun cu vârsta de minim 18 ani ( împliniți la data accesării Site-ului), cetățeni români
							și domiciliaţi  în România și rezidenților din România care  îndeplinesc aceleași cerințe. Pentru obţinerea accesului la Site-uri şi/sau Aplicaţii, fiecare
							utilizator trebuie să confirme pe proprie răspundere că  îndeplinește cerințele, așa cum sunt ele definite anterior. Trebuie să se  înregistreze şi să aleagă o parolă care
							 îi vor fi destinate exclusiv. Aceste elemente de securitate nu trebuie transmise terţilor, pentru a se evita orice utilizare neautorizată.  în calitate de utilizator al
							Website-urilor şi/sau Aplicaţiilor, consumatorul are obligaţia de a lua măsurile necesare pentru a păstra secrete numele de utilizator (adresa de e-mail) şi parola. Fiecare utilizator
							va informa imediat Philip Morris dacă are motive de a crede că (i) parola sau numele său de utilizator (adresa de e-mail) ar putea fi cunoscute şi de altcineva sau (ii) ar exista un
							risc ca numele său de utilizator (adresa de e-mail) şi parola să fie utilizate fără permisiunea sa. Acesta este responsabil de orice acţiune ar putea fi realizată cu ajutorul numelui
							său de utilizator (adresei de e-mail) şi al parolei. Philip Morris declină orice responsabilitate pentru prejudiciile care rezultă din difuzarea către terţi a parolei şi/sau numelui de
							utilizator (adresei de e-mail).</p>

						<h3>2.3. Utilizarea Website-urilor şi/sau Aplicaţiilor</h3>

						<p>Website-urile şi Aplicaţiile nu pot fi utilizate decât  în România. Website-urile şi/sau Aplicaţiile, precum şi totalitatea conţinutului acestora (mai ales programele,
							fişierele, designul, graficele şi datele) sunt proprietatea Philip Morris şi sunt protejate de legile privind dreturile de autor şi mărcile. Orice utilizare neautorizată a
							Website-urilor şi/sau Aplicaţiilor, mai ales (i) utilizarea Website-urilor şi/sau Aplicaţiilor sau a conţinutului lor  în scopuri profesionale sau comerciale  în orice mod şi
							(ii) reproducerea, reprezentarea, transmiterea, comunicarea, punerea  în circulaţie, difuzarea, modificarea sau atribuirea unei licenţe, vânzarea sau altă modalitate de
							profitare de aceste Website-uri şi/sau Aplicaţii, de conţinutul, textul, părţi dintr-un text sau de imagini fixe sau animate, de date audio, produse sau servicii precum şi de alte date
							sau informaţii, este interzisă  în mod expres fără acordul scris prealabil al Philip Morris.</p>

						<h3>2.4. Absenţa garanţiilor</h3>

						<p>Philip Morris declină orice responsabilitate sau garanţie (i) pentru buna funcţionare a Website-urilor şi/sau Aplicaţiilor, (ii) pentru posibilitatea de acces şi utilizare, (iii) pentru
							caracterul complet, exact şi actual al conţinuturilor, informaţiilor sau datelor disponibile pe Website-uri şi/sau Aplicaţii, (iv) pentru absenţa defectelor acestor Site-uri şi/sau
							Aplicaţii, sau conţinuturilor lor şi mai ales pentru absenţa viruşilor sau altor "malware" şi (v) pentru absenţa unor anumite calităţi care ar putea fi aşteptate cu privire
							la aceste Website-uri şi/sau Aplicaţii, sau conţinuturile lor. Dacă un consumator accesează Website-urile şi/sau Aplicaţiile, le utilizează sau descarcă conţinut de pe acestea, face
							acest lucru din proprie voinţă şi pe propria răspundere. Prin urmare, dacă se  înregistrează o pierdere de date sau o prejudiciere a sistemului său informatic, Philip Morris nu
							poate fi responsabil. Philip Morris nu are obligaţia de a furniza niciun serviciu, nicio dată sau informaţie, nici de a crea condiţiile tehnice şi juridice necesare pentru utilizarea
							Website-urilor şi/sau Aplicaţiilor, sau conţinutului acestora  în România şi  în străinătate. Website-urile şi/sau Aplicaţiile nu sunt destinate să fie utilizate
							 în afara României. Philip Morris  îşi rezervă dreptul de a modifica, adapta, prescurta, completa şi/sau  înlătura Website-urile şi/sau Aplicaţiile sale, sau
							conţinutul acestora,  în orice moment, fără a lăsa disponibilă o versiune precedentă. Website-urile şi/sau Aplicaţiile noastre pot conţine link-uri către Website-uri ale terţilor
							care nu sunt nici operate, nici supravegheate de Philip Morris. Astfel, Philip Morris declină orice responsabilitate cu privire la conţinutul şi respectarea prevederilor legale  în
							materie de protecţie a datelor de către operatorii Website-urilor legate de Website-urile şi Aplicaţiile noastre.</p>

						<h3>2.5. Excluderea responsabilităţii</h3>

						<p>Philip Morris declină orice responsabilitate pentru eventuale prejudicii, inclusiv câştiguri nerealizate, pierderi de date,  întreruperi de activitate sau orice alt
							prejudiciu de orice natură care ar putea rezulta din utilizarea Website-urilor şi/sau Aplicaţiilor sale sau a conţinutului acestora şi mai ales din utilizarea documentelor şi/sau
							Aplicaţiilor incluse sau care ar putea rezulta din incapacitatea de a utiliza Website-urile/Aplicațiile, incapacitatea de a participa la un eventual concurs sau promoţie şi/sau la
							incapacitatea de a cumpăra sau trimite orice articol sau produs legat de Website-urile şi Aplicaţiile sale.</p>

						<h3>2.6. Diverse</h3>

						<p>Philip Morris  îşi rezervă dreptul, la libera sa discreţie, de a modifica oricând Condiţiile Generale şi Termenii de utilizare a Site-urilor şi/sau Aplicaţiilor. Fiecare
							utilizator are obligaţia de a verifica regulat Condiţiile Generale şi Termenii de Utilizare  în vigoare.  în cazul  în care una sau mai multe prevederi din Condiţiile
							Generale sau Termenii de Utilizare sunt declarate nule de instanţă, restul Condiţiilor Generale şi Termenilor de Utilizare rămân valabile. Fiecare consumator ne poate trimite
							 întrebări legate de Condiţiile Generale sau politica noastră de protecţie a datelor, contactându-ne la numărul gratuit: 0800.030.333. Utilizatorii acceptă că prezentele
							Condiţii Generale şi Termeni de Utilizare sunt guvernate de legea româna  în vigoare.</p>

						<h2>Condiţii privind participarea la promoţii şi concursuri pe Site-urile sau Aplicaţiile Philip Morris.</h2>

						<p>Promoţiile şi concursurile organizate pe Site-urile şi Aplicaţiile Philip Morris se adresează exclusiv consumatorilor de produse din tutun cu vârste de minim 18 ani  împliniţi,
							domiciliaţi  în România. Pot participa doar persoanele  înregistrate  în baza noastră de date sau care se  înscriu pe Site-urile şi/sau Aplicaţiile noastre.
							Philip Morris are dreptul să modifice, la libera sa discreţie şi  în orice moment, condiţiile și regulile de participare la aceste promoţii şi concursuri. Dacă un Site sau o
							Aplicaţie conţine indicaţii eronate privind o promoţie sau un concurs, mai ales obiectul, premiile sau câştigurile, aceste indicaţii nu angajează responsabilitatea Philip Morris,
							care le va putea modifica  în orice moment. Astfel de modificări vor fi publicate pe Site/Aplicație. Philip Morris are de asemenea dreptul să  înceteze oricând, la
							libera sa discreţie, o promoţie sau un concurs. Aceste modificări sau  încetări nu conduc la nicio despăgubire. Nu se poate stabili niciun drept şi nu se poate solicita nicio
							prestaţie. Philip Morris declină, de asemenea, orice responsabilitate dacă datele introduse  în formulare sau e-mail-uri de către participanţi nu pot fi recunoscute de sistem sau,
							mai general, dacă  în urma unui incident tehnic sau de alt tip o anumită persoană nu poate participa la o promoţie sau la un concurs. Philip Morris  îşi rezervă dreptul de a
							exclude participanţi de la promoţii sau concursuri cu sau fără indicarea motivului. Acest lucru se aplică mai ales participanţilor care  îşi manipulează sau  încearcă să
							 îşi manipuleze participarea ( în principal prin crearea de conturi multiple sau  încercând să obţină sau să creeze şanse suplimentare de a câştiga  în
							mod incorect) sau care  încalcă Condiţiile Generale, Termenii de Utilizare sau condiţiile specifice promoţiei sau concursului sau care,  într-un mod sau altul, utilizează
							abuziv promoţiile sau concursurile propuse. Niciuna din promoţiile noastre nu implică obligativitatea de a cumpăra produse.</p>

						<h2>Contact</h2>

						<p>Pentru mai multe detalii legate de acest site, vă rugăm să apelați numărul 0800.030.333 (tarif normal), de luni până duminică,  în intervalul orar 08:00 - 22:00. Tot la acest număr puteți suna dacă doriți să vă dezabonați din baza de date a Philip Morris.</p>

						<h2>Prevederi finale</h2>

						<p>Philip Morris poate face publice date statistice, neindividualizate despre acest site, cum ar fi numărul vizitatorilor, frecvența de vizitare etc. Philip Morris poate dezvălui date cu
							caracter statistic, neindividualizat către terțe părți  în scopuri generale de marketing sau demografice. Sunteți de acord  în mod voluntar cu Termenii și Condițiile acestui
							site și cu furnizarea de date personale.  în cazul  încălcării Termenilor și Condiţiilor Site-ului, Philip Morris  îşi rezervă dreptul de a interzice utilizatorului
							accesul la Site, ştergându-i contul fără preaviz. CLUB.IQOS.RO poate fi modificat, adaptat, completat şi/sau retras  în orice moment de către Philip Morris, fără să fie pusă
							la dispoziţie o versiune precedentă.  în cazul  în care nu este stipulat altfel, Termenii și Condiţiile prezente se aplică pentru toate aspectele ce țin de activitatea
							utilizatorilor pe website-ul CLUB.IQOS.RO.</p>
					</div>
				</div>
			</Layout>
		)
	}
}

export default TermsConditions;
