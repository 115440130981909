import React from "react";
import store from "store";
import classnames from "classnames";

// Assets
import config from "../../config";

// Components
import Header from "../Header";
import Footer from "../Footer";

class Layout extends React.Component{
	state = {
		isPWA: store.get(config.user.isPWA),
		isLoggedIn: store.get(config.user.token)
	};

	render() {
		const {isPWA, isLoggedIn} = this.state;

		return (
			<>

				<Header/>

				<div className={classnames('content')}>
					{this.props.children}
				</div>


				<Footer />
			</>
		)
	}
}

export default Layout;
