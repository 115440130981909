import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
	points: ['page', 'filter'],
	pointsLoading: null,
	pointsSuccess: ['points'],
	pointsFail: ['errors']
});

export const PointsTypes = Types;
export default Creators;
