import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
	referral: ['params'],
	referralModal: ['formData'],
	referralLoading: null,
	referralSuccess: ['user'],
	referralFail: ['formErrors'],

	// Get referrals
	toggleModal: null,
	referralTree: ['params'],
	referralTreeSuccess: ['data'],
	referralTreeFail: ['data'],

	//Get referral cards
	referralCards: ['params'],
	referralCardsSuccess: ['data'],
	referralCardsFail: ['data'],

	//Submit referral cards
	referralSubmitCards: ['params'],
	referralSubmitCardsSuccess: ['data'],
	referralSubmitCardsFail: ['data'],

	//Get selected referral card
	referralCard: ['params'],
	referralCardSuccess: ['data'],
	referralCardFail: ['data']
});

export const ReferralTypes = Types;
export default Creators;
