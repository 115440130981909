import { createReducer } from 'reduxsauce';
import { ArticlesTypes } from './Actions';

const INITIAL_STATE = {
	singleArticle: [],
	singleArticleLoading: false,
	singleArticleErrors: ['errors'],

	allArticles: [],
	allArticlesLoading: false,
	allArticlesErrors: ['errors'],

	popularArticles: [],
	popularArticlesLoading: false,
	popularArticlesErrors: ['errors'],
	popularArticlesPager: {},

	shuffledArticles: [],
	shuffledArticlesLoading: false,
	shuffledArticlesErrors: ['errors'],
	shuffledArticlesPager: {},

	promotedArticles: [],
	promotedArticlesLoading: false,
	promotedArticlesErrors: ['errors'],
	promotedArticlesPager: {},

	excludedPopularArticles: [],
	excludedPopularArticlesLoading: false,
	excludedPopularArticlesErrors: ['errors'],
	excludedPopularArticlesPager: {},
};

/* Single article */
export const singleArticleLoading = (state) => ({
	...state,
	singleArticleLoading: true
});

export const singleArticleSuccess = (state, { article }) => ({
	...state,
	singleArticle: article,
	singleArticleLoading: false
});

export const singleArticleFail = (state, { errors }) => ({
	...state,
	singleArticleLoading: false,
	singleArticleErrors: errors
});

/* All articles */
export const allArticlesLoading = (state) => ({
	...state,
	allArticles: [],
	allArticlesLoading: true,
	allArticlesErrors: null
});

export const allArticlesSuccess = (state, { allArticles }) => ({
	...state,
	allArticles: allArticles,
	allArticlesLoading: false,
	allArticlesErrors: null
});

export const allArticlesFail = (state, { errors }) => ({
	...state,
	allArticles: [],
	allArticlesLoading: false,
	allArticlesErrors: errors
});

/* Popular articles */
export const popularArticlesLoading = (state) => ({
	...state,
	popularArticles: [],
	popularArticlesLoading: true,
	popularArticlesErrors: null
});

export const popularArticlesSuccess = (state, { popularArticles }) => ({
	...state,
	popularArticles: popularArticles,
	popularArticlesLoading: true,
	popularArticlesErrors: null
});

export const popularArticlesFail = (state, { errors }) => ({
	...state,
	popularArticles: [],
	popularArticlesLoading: false,
	popularArticlesErrors: errors
});

/* Shuffled articles */
export const shuffledArticlesLoading = (state) => ({
	...state,
	shuffledArticles: [],
	shuffledArticlesLoading: true,
	shuffledArticlesErrors: null
});

export const shuffledArticlesSuccess = (state, { shuffledArticles }) => ({
	...state,
	shuffledArticlesPager: shuffledArticles.pager,
	shuffledArticles: shuffledArticles.data,
	shuffledArticlesLoading: true,
	shuffledArticlesErrors: null
});

export const shuffledArticlesFail = (state, { errors }) => ({
	...state,
	shuffledArticles: [],
	shuffledArticlesLoading: false,
	shuffledArticlesErrors: errors
});

/* Promoted articles */
export const promotedArticlesLoading = (state) => ({
	...state,
	promotedArticles: [],
	promotedArticlesLoading: true,
	promotedArticlesErrors: null
});

export const promotedArticlesSuccess = (state, { promotedArticles }) => ({
	...state,
	promotedArticles: promotedArticles,
	promotedArticlesLoading: true,
	promotedArticlesErrors: null
});

export const promotedArticlesFail = (state, { errors }) => ({
	...state,
	promotedArticles: [],
	promotedArticlesLoading: false,
	promotedArticlesErrors: errors
});

/* Excluded popular articles */
export const excludedPopularLoading = (state) => ({
	...state,
	excludedPopular: [],
	excludedPopularLoading: true,
	excludedPopularErrors: null
});

export const excludedPopularSuccess = (state, { excludedPopular }) => ({
	...state,
	excludedPopularPager: excludedPopular.pager,
	excludedPopular: excludedPopular.data,
	excludedPopularLoading: true,
	excludedPopularErrors: null
});

export const excludedPopularFail = (state, { errors }) => ({
	...state,
	excludedPopular: [],
	excludedPopularLoading: false,
	excludedPopularErrors: errors
});

export const reducer = createReducer(INITIAL_STATE, {
	/* Single article */
	[ArticlesTypes.SINGLE_ARTICLE_LOADING]: singleArticleLoading,
	[ArticlesTypes.SINGLE_ARTICLE_SUCCESS]: singleArticleSuccess,
	[ArticlesTypes.SINGLE_ARTICLE_FAIL]: singleArticleFail,

	/* All articles */
	[ArticlesTypes.ALL_ARTICLES_LOADING]: allArticlesLoading,
	[ArticlesTypes.ALL_ARTICLES_SUCCESS]: allArticlesSuccess,
	[ArticlesTypes.ALL_ARTICLES_FAIL]: allArticlesFail,

	/* Popular articles */
	[ArticlesTypes.POPULAR_ARTICLES_LOADING]: popularArticlesLoading,
	[ArticlesTypes.POPULAR_ARTICLES_SUCCESS]: popularArticlesSuccess,
	[ArticlesTypes.POPULAR_ARTICLES_FAIL]: popularArticlesFail,

	/* Shuffled articles */
	[ArticlesTypes.SHUFFLED_ARTICLES_LOADING]: shuffledArticlesLoading,
	[ArticlesTypes.SHUFFLED_ARTICLES_SUCCESS]: shuffledArticlesSuccess,
	[ArticlesTypes.SHUFFLED_ARTICLES_FAIL]: shuffledArticlesFail,

	/* Promoted articles */
	[ArticlesTypes.PROMOTED_ARTICLES_LOADING]: promotedArticlesLoading,
	[ArticlesTypes.PROMOTED_ARTICLES_SUCCESS]: promotedArticlesSuccess,
	[ArticlesTypes.PROMOTED_ARTICLES_FAIL]: promotedArticlesFail,

	/* Excluded popular articles */
	[ArticlesTypes.EXCLUDED_POPULAR_ARTICLES_LOADING]: excludedPopularLoading,
	[ArticlesTypes.EXCLUDED_POPULAR_ARTICLES_SUCCESS]: excludedPopularSuccess,
	[ArticlesTypes.EXCLUDED_POPULAR_ARTICLES_FAIL]: excludedPopularFail
});
