import { createReducer } from 'reduxsauce';
import { UGCCarouselTypes } from './Actions';

const INITIAL_STATE = {
	ugcCarousel: [],
	loading: false,
	errors: null
};

export const ugcCarouselLoading = (state) => ({
	...state,
	ugcCarousel: [],
	loading: true,
	errors: null
});

export const ugcCarouselSuccess = (state, { ugcCarousel }) => ({
	...state,
	ugcCarousel: ugcCarousel,
	loading: false,
	errors: null
});

export const ugcCarouselFail = (state, { errors }) => ({
	...state,
	ugcCarousel: [],
	loading: false,
	errors: errors
});

export const reducer = createReducer(INITIAL_STATE, {
	[UGCCarouselTypes.UGC_CAROUSEL_LOADING]: ugcCarouselLoading,
	[UGCCarouselTypes.UGC_CAROUSEL_SUCCESS]: ugcCarouselSuccess,
	[UGCCarouselTypes.UGC_CAROUSEL_FAIL]: ugcCarouselFail
});
