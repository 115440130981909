import React from 'react';

//components
import Button from '../../../../components/Button/Button';

//assets
import config from '../../../../config';
import styles from './fdaSectioin.module.scss';
import classnames from "classnames";

import deviceFDA from '../../images/device-fda.png';
import device from  '../../images/device.png';
import deviceMob from  '../../images/deviceMobile.png';

class fdaSection extends React.Component {
    state = {
        isMobile: window.innerWidth < 768
    };
    componentDidMount() {
        window.addEventListener('resize', this.isMobileWidth);
    }

    isMobileWidth = () => {
        this.setState({ isMobile: window.innerWidth < 768 });
    };
    render() {

        const {customButton, customMargin, isMobile, isPublic} = this.props;

        return (
            <div className={classnames(styles.fdaSection, customMargin )}>
                <div className={styles.leftSection}>
                    <p>IQOS este primul și singurul sistem de încălzire a tutunului autorizat ca produs din tutun cu risc modificat de către Administrația Americană pentru Alimente și Medicamente (FDA) din SUA, care a concluzionat că trecerea completă de la țigări la IQOS <b>reduce expunerea organismului la substanțe nocive sau potențial nocive.*</b></p>
                    <img src={deviceMob} className={styles.mobileDevice} alt=""/>
                    <Button
                        noLink onClick={() => (window.location.href = (customButton ? customButton : config.path.fdaArticle))}
                        type={'primary'}
                    >Citește mai multe</Button>
                    <div className={styles.importantInfo}>
                        <p><b>Informație importantă</b>: IQOS nu este lipsit de riscuri. Acesta eliberează nicotină, care creează dependență. Destinat exclusiv adulților.</p>
                        <span><b>*Sursa</b>: Studiile PMI referitoare la reducerea expunerii desfășurate pe durata a trei luni în Japonia și SUA în condiții de utilizare apropiate de cele obișnuite. Aceste studii au măsurat expunerea la 15 substanțe nocive și au comparat nivelurile observate în rândul fumătorilor care au trecut la IQOS cu nivelurile măsurate în rândul fumătorilor care au continuat să fumeze țigări.</span>
                    </div>
                </div>
                <div className={styles.image}>
                    <img src={device} alt=""/>
                </div>


            </div>
        );
    }
}

export default fdaSection