import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
	contactForm: ['formData'],
	contactFormLoading: null,
	contactFormFail: ['errors'],
	contactFormSuccess: null
});

export const ContactTypes = Types;
export default Creators;
