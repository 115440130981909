import { createReducer } from 'reduxsauce';
import { SectionsTypes } from './Actions';

const INITIAL_STATE = {
	sections: [],
	loading: false,
	errors: null
};

export const sectionsLoading = (state) => ({
	...state,
	sections: [],
	loading: true,
	errors: null
});

export const sectionsSuccess = (state, { sections }) => ({
	...state,
	sections: sections,
	loading: false,
	errors: null
});

export const sectionsFail = (state, { errors }) => ({
	...state,
	sections: [],
	loading: false,
	errors: errors
});

export const reducer = createReducer(INITIAL_STATE, {
	[SectionsTypes.SECTIONS_LOADING]: sectionsLoading,
	[SectionsTypes.SECTIONS_SUCCESS]: sectionsSuccess,
	[SectionsTypes.SECTIONS_FAIL]: sectionsFail
});
