import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import config from '../../config';
import store from 'store';

class ProtectedRoute extends React.Component {
    render() {
        const { component: Component, ...rest } = this.props;
        const token = store.get(config.user.token);

        if (token) {
            return <Route {...rest} component={Component} />;
        }
        return <Route {...rest} render={() => <Redirect to={config.path.login} />} />;
    }
}

export default ProtectedRoute;
