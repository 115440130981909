import React from 'react';
import { Router as BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import { history } from '../../stores/createStore';
import config from '../../config';
import store from 'store';
import 'antd/dist/antd.css';

//Scroll
import ScrollToTop from './ScrollToTop'

import MapPage from '../../containers/Pages/MapPage';
import Referral from '../../containers/Referral';

// Activities
import UserDataNotification from "../../containers/Pages/UserDataNotification";
import TermsConditions from "../../containers/Pages/TermsConditions";
import CookiesPolicy from "../../containers/Pages/CookiesPolicy";
import Regulation from "../../containers/Pages/Regulation";
import Contact from "../../containers/Pages/Contact/Contact";
import FAQs from "../../containers/Pages/FAQs/FAQs";
import ReferralFree from "../../containers/Referral2/ReferralFree";
import {extend} from "dayjs";
import NotFound from "../../containers/Pages/NotFound";

const isAdult   = store.get(config.user.isAdult);


class Router extends React.Component {

    componentDidMount() {
        const token = localStorage.getItem('token')

    }

    render() {

        return (<BrowserRouter history={history} basename={'/club/incearca-iqos'}>
            <ScrollToTop/>
            <Switch>
                {/* Landing */}
                <Route exact path={config.path.userDataNotification} component={UserDataNotification} />
                <Route exact path={config.path.termsConditions} component={TermsConditions} />
                <Route exact path={config.path.cookiesPolicy} component={CookiesPolicy} />
                <Route exact path={config.path.regulation} component={Regulation}/>
                <Route exact path={config.path.contact} component={Contact} />
                <Route exact path={config.path.FAQs} component={FAQs} />
                <Route exact path={config.path.map} component={MapPage} />
                <Route exact path={config.path.notFound} component={NotFound} />

                {/*Second wave routes*/}
                <Route exact path={config.path.userDataNotification2} component={UserDataNotification} />
                <Route exact path={config.path.termsConditions2} component={TermsConditions} />
                <Route exact path={config.path.cookiesPolicy2} component={CookiesPolicy} />
                <Route exact path={config.path.regulation2} component={Regulation}/>
                <Route exact path={config.path.contact2} component={Contact} />
                <Route exact path={config.path.FAQs2} component={FAQs} />
                <Route exact path={config.path.map2} component={MapPage} />
                <Route exact path={config.path.notFound2} component={NotFound} />
                <Route exact path={config.path.index} component={NotFound} />
                <Route exact path={config.path.index2} component={NotFound} />

                <Route path={window.location.href.indexOf("gratuit") !== -1 ? `${config.path.index2}/:token` : `${config.path.index}/:token`}
                       component={window.location.href.indexOf("gratuit") !== -1 ? ReferralFree : Referral}/>
            </Switch>
        </BrowserRouter>)

    }
}


export default Router;
