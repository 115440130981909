import { createReducer } from 'reduxsauce';
import { MarkersTypes } from './Actions';

const INITIAL_STATE = {
    data: [],
    selectedMarker: undefined,
    loading: false,
    errors: null,
    center: {
        lat: 45.94316,
        lng: 24.96676
    },
    zoomLevel: 7,
    selfLocation: undefined
};

export const markersLoading = (state) => ({
    ...state,
    data: [],
    loading: true,
    errors: null
});

export const markersSuccess = (state, { markers }) => ({
    ...state,
    data: markers,
    loading: false,
    errors: null
});

export const markersFail = (state, { errors }) => ({
    ...state,
    data: [],
    loading: false,
    errors: errors
});

export const selectMarker = (state, { id }) => ({
    ...state,
    selectedMarker: id
});

export const setCenter = (state, { lat, lng, zoomLevel }) => ({
    ...state,
    zoomLevel,
    center: {
        lat,
        lng
    },
});

export const setLocation = (state, { lat, lng }) => ({
    ...state,
    selfLocation: {
        lat,
        lng
    }
});

export const reducer = createReducer(INITIAL_STATE, {
    [MarkersTypes.MARKERS_LOADING]: markersLoading,
    [MarkersTypes.MARKERS_SUCCESS]: markersSuccess,
    [MarkersTypes.MARKERS_FAIL]: markersFail,
    [MarkersTypes.SELECT_MARKER]: selectMarker,
    [MarkersTypes.SET_CENTER]: setCenter,
    [MarkersTypes.SET_LOCATION]: setLocation
});
