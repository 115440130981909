import { call, put, takeLatest } from 'redux-saga/effects';
import PointsActions, { PointsTypes } from './Actions';
import axios, { setAxiosToken } from '../../../utils/axios';
import config from "../../../config";
import store from 'store';


export function* getPoints({page, filter}) {
	yield put(PointsActions.pointsLoading());
	try {
		const response = yield call(() => axios.get(`/profile/history`, {
			params: {
				page: page,
				filter: filter,
				per_page: 10
			}
		}));

		let points = response.data; //TO DO remove this, because only token is received

		yield store.set(config.user.points, points);
		yield put(PointsActions.pointsSuccess(points));
	} catch (error) {
		yield put(PointsActions.pointsFail(error));
	}
}

export default function* pointsSaga() {
	yield takeLatest(PointsTypes.POINTS, getPoints);
}
