import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
	usefulLinks: ['id'],
	usefulLinksLoading: null,
	usefulLinksSuccess: ['usefulLinks'],
	usefulLinksFail: ['errors']
});

export const UsefulLinksTypes = Types;
export default Creators;
