import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
	currentUser: null,
	currentUserLoading: null,
	currentUserFail: ['errors'],
	currentUserSuccess: ['userData']
});

export const UserTypes = Types;
export default Creators;
