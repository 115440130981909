import React from 'react';
import Styles from './style.module.scss';
import Pointer from '../../../assets/images/icons/pointer.svg';

const getGoogleMapsLink = (street) => `https://maps.google.com/?q=${street}&output=classic`;

const MarkerCard = ({ marker, index, onClick, style }) => (
    <div key={index} style={style} className={Styles.markerContainer} onClick={onClick}>
        <div className={Styles.markerHeader}>
            <img src={Pointer} />
            <span>{marker.name}</span>
        </div>
        <div className={Styles.address}>
            <span>{marker.address}</span>
            <span>
                {marker.city}, {marker.zip_code}
            </span>
        </div>
        <div className={Styles.orientationContainer}>
            <span className={Styles.mapsButton}>
                <a href={getGoogleMapsLink(`${marker.address},${marker.city},${marker.zip_code}`)} target="_blank">
                    Indicații de orientare
                </a>
            </span>
        </div>
    </div>
);

export default MarkerCard;
