import { combineReducers } from 'redux';
import configureStore from './createStore';
import rootSaga from './rootSaga';
import { connectRouter } from 'connected-react-router';
import { reducer as counties } from './Counties/Reducers';
import { reducer as referral } from './Referral/Reducers';
import { reducer as offlineRedemption } from './OfflineRedemption/Reducers';
import { reducer as insertCodes } from './InsertCode/Reducers';
import { reducer as user } from './User/Reducers';
import { reducer as points } from './Profile/PointsHistory/Reducers';
import { reducer as devices } from './Devices/Reducers';
import {reducer as map} from './Map/Reducers';

// Community
import { reducer as sections } from './Community/ActiveSections/Reducers';
import { reducer as introSlider } from './Community/IntroSlider/Reducers';
import { reducer as ugcCarousel } from './Community/UGCCarousel/Reducers';
import { reducer as articles } from './Community/Articles/Reducers';
import { reducer as imageChallenge } from './Community/ImageChallenge/Reducers';
import { reducer as testimonialChallenge } from './Community/TestimonialChallenge/Reducers';

export default (history) => {
    const rootReducer = combineReducers({
        router: connectRouter(history),
        counties,
        offlineRedemption,
        insertCodes,
        points,
        user,
        devices,
        ugcCarousel,
        sections,
        introSlider,
        articles,
        imageChallenge,
        testimonialChallenge,
        map
    });

    return configureStore(rootReducer, rootSaga);
};
