import { createReducer } from 'reduxsauce';
import { IntroSliderTypes } from './Actions';

const INITIAL_STATE = {
	introSlider: [],
	loading: false,
	errors: null
};

export const introSliderLoading = (state) => ({
	...state,
	introSlider: [],
	loading: true,
	errors: null
});

export const introSliderSuccess = (state, { introSlider }) => ({
	...state,
	introSlider: introSlider,
	loading: false,
	errors: null
});

export const introSliderFail = (state, { errors }) => ({
	...state,
	introSlider: [],
	loading: false,
	errors: errors
});

export const reducer = createReducer(INITIAL_STATE, {
	[IntroSliderTypes.INTRO_SLIDER_LOADING]: introSliderLoading,
	[IntroSliderTypes.INTRO_SLIDER_SUCCESS]: introSliderSuccess,
	[IntroSliderTypes.INTRO_SLIDER_FAIL]: introSliderFail
});
