import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
	singleArticle: ['sectionSlug', 'articleSlug'],
	singleArticleLoading: null,
	singleArticleSuccess: ['article'],
	singleArticleFail: ['errors'],

	allArticles: ['sectionSlug', 'limit', 'params'],
	allArticlesLoading: null,
	allArticlesSuccess: ['allArticles'],
	allArticlesFail: ['errors'],

	popularArticles: ['sectionSlug', 'limit'],
	popularArticlesLoading: null,
	popularArticlesSuccess: ['popularArticles'],
	popularArticlesFail: ['errors'],

	shuffledArticles: ['sectionSlug', 'limit'],
	shuffledArticlesLoading: null,
	shuffledArticlesSuccess: ['shuffledArticles'],
	shuffledArticlesFail: ['errors'],

	promotedArticles: ['sectionSlug', 'limit'],
	promotedArticlesLoading: null,
	promotedArticlesSuccess: ['promotedArticles'],
	promotedArticlesFail: ['errors'],

	excludedPopularArticles: ['sectionSlug', 'excluded'],
	excludedPopularArticlesLoading: null,
	excludedPopularArticlesSuccess: ['excludedPopularArticles'],
	excludedPopularArticlesFail: ['errors'],

	toggleLike: ['params']
});

export const ArticlesTypes = Types;
export default Creators;
