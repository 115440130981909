import React from "react";
import store from "store";
import classnames from "classnames";

// Assets
import config from "../../../config";
import styles from "./style.module.scss";

// Components
import Layout from "../../../components/Layout";

class UserDataNotification extends React.Component {
	state = {
		isPWA: store.get(config.user.isPWA)
	};

	render() {
		const {isPWA} = this.state;

		return (
			<Layout>
				<div className={classnames('page_heading', isPWA ? 'isPWA' : '')}>
					<h1>Notificare de protecția datelor</h1>
				</div>

				<div className={styles.container}>
					<div className={styles.gdpr}>
						<p>Noi luăm în serios confidențialitatea. Acest anunț vă spune cine suntem, ce informații colectăm despre dumneavoastră și ce facem cu ele. Pentru informații suplimentare, faceți clic pe "află mai multe" în fiecare secțiune.</p>
						<p>Citiți, de asemenea, termenii noștri de utilizare privind serviciul care vă interesează. Aceștia oferă mai multe informații despre modul în care desfășurăm afaceri și orice restricții privind eligibilitatea care se pot aplica.</p>

						<h2>Cine suntem?</h2>
						<p>Suntem membru al Philip Morris International. Detaliile noastre (nume, adresă etc.) v-au fost oferite separat în momentul (sau pentru a confirma) colectarea de informații despre dvs., de exemplu, într-o notificare dintr-o aplicație sau pe un site web sau într-un e-mail, care conține un link la această notificare.</p>
						<ul>
							<li>PMI: Philip Morris International, un grup internațional de tutun de top. Acesta se compune din mai multe companii sau &rdquo;afiliați&rdquo;.
							</li>
							<li>Afiliați PMI: Fiecare membru al grupului de companii Philip Morris International este un "afiliat PMI". "Noi" (sau "nouă" sau "al nostru") se referă la afiliatul PMI care a colectat prima dată informațiile despre dvs.
							</li>
							<li>Produs PMI: înseamnă un produs de-al nostru sau al altui afiliat PMI.</li>
						</ul>

						<h2>Cum colectăm informații despre dumneavoastră?</h2>
						<p>Putem colecta informații despre dumneavoastră în mai multe moduri.</p>
						<ul>
							<li>Ne puteți furniza informații direct (de exemplu, completarea unui formular sau apelându-ne la telefon).
							</li>
							<li>Putem colecta informațiile în mod automat (de exemplu, atunci când utilizați o aplicație sau un site web PMI).
							</li>
							<li>Putem obține informații de la terțe părți (de exemplu, informații disponibile public pe platforme de socializare, cum ar fi Facebook și Twitter).
							</li>
						</ul>
						<p>în această notificare, ne referim la toate metodele prin care sunteți în contact cu noi ca "puncte de contact PMI". Punctele de contact PMI le includ atât pe cele fizice (de exemplu, puncte de vânzare și evenimente de vânzare retail), cât și pe cele digitale (de exemplu, aplicații și site-uri web).</p>
						<p>Putem colecta informații pe care le furnizați direct. De obicei, acest lucru se va întâmpla când:
						</p>
						<ul>
							<li>vă înscrieți să deveniți membru în bazele noastre de date (acest lucru ar putea fi, de exemplu, în persoană, prin intermediul aplicației sau online);
							</li>
							<li>achiziționați produse sau servicii PMI dintr-un magazin de vânzare cu amănuntul;
							</li>
							<li>descărcați sau utilizați un punct de contact digital (de exemplu, o aplicație sau un site web);
							</li>
							<li>ne contactați printr-un punct de contact, prin e-mail, prin platforme de socializare sau prin telefon;
							</li>
							<li>înregistrați un dispozitiv cu noi;</li>
							<li>vă abonați la un portal de panouri PMI;</li>
							<li>vă înregistrați pentru a primi comunicate de presă, alerte prin e-mail sau comunicări de marketing PMI;
							</li>
							<li>participați la studii PMI sau la concursurile sau promoțiile PMI (acolo unde legea permite); sau
							</li>
							<li>participați la un eveniment organizat de un afiliat PMI.</li>
						</ul>
						<p>Putem colecta informații despre dumneavoastră în mod automat. De obicei, acest lucru se va întâmpla când:</p>
						<ul>
							<li>vizitați un magazin care vinde produse PMI (de exemplu, prin colectarea datelor la casierie sau prin intermediul senzorilor din locație care se conectează la tehnologia mobilă);
							</li>
							<li>participați la un eveniment organizat de un afiliat PMI (de exemplu prin achiziții în timpul evenimentului sau prin senzori amplasați la eveniment, care se conectează la tehnologia mobilă);
							</li>
							<li>comunicați cu noi (de exemplu, printr-un punct de contact sau platforme sociale);</li>
							<li>utilizați puncte de contact PMI (de exemplu, prin mecanisme de urmărire într-o aplicație sau pe un site web); sau
							</li>
							<li>faceți postări publice pe platformele de socializare pe care le urmărim (de exemplu, pentru a înțelege opinia publică sau pentru a răspunde solicitărilor privind produsele PMI).
							</li>
						</ul>
						<p>De asemenea, putem să colectăm automat informații despre dumneavoastră prin utilizarea cookie-urilor și a tehnologiilor de urmărire similare pe punctele de contact digitale PMI. Cookie-urile și tehnologiile specifice utilizate vor depinde de punctul de contact PMI în cauză. Pentru a afla despre cookie-uri (inclusiv cookie-urile Google Analytics) și despre tehnologiile similare utilizate într-un punct de contact, inclusiv modul în care puteți accepta sau refuza cookie-urile, vă rugăm să consultați notificarea privind cookie-urile pusă la dispoziție pe sau prin acel punct de contact.
						</p>
						<p>Dacă acest lucru este permis de lege, putem obține informații despre dumneavoastră de la terțe părți. Aceasta poate include informații partajate între afiliații PMI, informații de profil disponibile public (cum ar fi preferințele și interesele dumneavoastră) de pe site-uri de social media terțe (cum ar fi Facebook și Twitter) și liste de marketing obținute de la agenții de marketing terțe.</p>
						<p>Putem, de asemenea, să colectăm informații în alte contexte care vă sunt aduse la cunoștință în momentul respectiv.</p>

						<h2>Ce informații colectăm despre dumneavoastră?</h2>
						<p>Putem colecta diverse tipuri de informații despre dvs.:</p>
						<ul>
							<li>informații necesare pentru a vă onora comenzile</li>
							<li>informații necesare pentru a furniza servicii de garanție</li>
							<li>informații pe care ni le oferiți în formulare sau studii</li>
							<li>informații despre vizitele dvs. la punctele de vânzare și evenimentele noastre
							</li>
							<li>informații pe care ni le oferiți în apelurile efectuate la call center</li>
							<li>informații despre preferințele și interesele dvs.</li>
							<li>informații necesare pentru a vă verifica vârsta</li>
						</ul>
						<p>Informațiile pe care le colectăm direct de la dvs. vor fi evidente din contextul în care le furnizați. De exemplu:</p>
						<ul>
							<li>dacă comandați un produs de la noi printr-un punct de contact, vă furnizați numele dvs., contactul, detaliile de facturare și produsele pe care le-ați ales astfel încât să vă putem onora comanda;
							</li>
							<li>puteți furniza informații despre preferințele și interesele dvs. privind produsele, astfel încât să vă putem oferi produse și servicii care vă vor interesa;
							</li>
							<li>dacă faceți o programare pentru a ne vedea (sau pe cineva care oferă servicii de suport pentru produsele sau serviciile noastre), este posibil să colectăm numele și datele de contact;
							</li>
							<li>este posibil să colectăm informații care ne permit să vă verificăm vârsta, de exemplu o copie a unui document de identitate sau imaginea dumneavoastră facială.
							</li>
						</ul>
						<p>Informațiile pe care le colectăm în mod automat vor viza în general:</p>
						<ul>
							<li>detalii despre vizita sau apelul dvs. (cum ar fi timpul și durata);</li>
							<li>într-un punct de vânzări sau la un eveniment (inclusiv zonele din imediata vecinătate), cât de frecvent le vizitați, zonele pe care le vizitați și pentru cât timp, și ce achiziții faceți;
							</li>
							<li>utilizarea punctelor de contact PMI digitale (cum ar fi paginile pe care le vizitați, pagina de unde ați venit și pagina spre care ați navigat când ați plecat, termenii de căutare introduși sau link-urile pe care le-ați accesat în cadrul punctului de contact) de către dumneavoastră; și
							</li>
							<li>dispozitivul dvs. (cum ar fi adresa dvs. IP sau identificatorul unic al dispozitivului, datele despre locație, detalii despre orice cookie-uri pe care le-am stocat pe dispozitivul dumneavoastră).
							</li>
						</ul>
						<p>Informațiile pe care le colectăm de la terți vor consta, în general, în informații de profil disponibile în mod public (cum ar fi preferințele și interesele dumneavoastră), de exemplu din postările publice pe social media.</p>

						<h2>În ce scop folosim informațiile despre dumneavoastră și pe ce bază legală?</h2>
						<p>În această secțiune, descriem scopurile pentru care folosim informațiile personale. Cu toate acestea, este vorba despre o notificare globală și în cazul în care legile unei țări restricționează sau interzic anumite activități descrise în această notificare, nu vom folosi informații despre dvs. în aceste scopuri în acea țară.</p>
						<p>Sub rezerva celor de mai sus, folosim informațiile despre dumneavoastră în următoarele scopuri:
						</p>
						<ul>
							<li>Pentru a respecta obligațiile de reglementare, cum ar fi verificarea vârstei dvs. și a statutului de utilizator al produselor noastre
							</li>
							<li>Pentru a vă vinde produsele, inclusiv pentru onorarea comenzilor, procesarea plăților
							</li>
							<li>Pentru a vă oferi servicii legate de vânzări, inclusiv pentru a procesa solicitările și cererile dvs. și pentru a furniza servicii de garanție
							</li>
							<li>Pentru a ne comercializa produsele (acolo unde legea permite), inclusiv gestionarea programelor de fidelizare, îmbunătățirea produselor, cercetare de piață, dezvoltarea de strategii de marketing, administrarea campaniilor de marketing și personalizarea experiențelor dvs. la punctele de vânzare care vând produse PMI și la evenimente
							</li>
							<li>Pentru ca noi sau partenerii noștri de afaceri să vă informăm despre oportunitățile potențiale de implicare în marketing sau promovarea produselor PMI
							</li>
							<li>Pentru a susține toate cele de mai sus, inclusiv administrarea conturilor dvs., pentru a vă permite să utilizați punctele de contact PMI corespunzătoare dvs., gestionarea întâlnirilor dvs. cu noi sau cu cineva care oferă servicii de suport pentru produsele sau serviciile noastre (de exemplu, cu privire la un produs nou sau la un serviciu post-vânzare), pentru personalizarea experiențelor dvs. la punctele de contact PMI și administrare și depanare
							</li>
							<li>Pentru analize și îmbunătățiri ale afacerii, inclusiv îmbunătățirea produselor, punctelor de vânzare și evenimentelor PMI și a informațiilor pe care noi (sau afiliații noștri) le furnizăm clienților noștri
							</li>
							<li>în alte scopuri pe care vi le aducem la cunoștință sau care vor fi clare din context, la momentul în care informațiile despre dvs. sunt colectate prima dată
							</li>
						</ul>
						<p>Baza legală pentru folosirea informațiilor despre dvs. este una dintre următoarele (pe care o explicăm mai detaliat în secțiunea "află mai multe"):</p>
						<ul>
							<li>respectarea unei obligații legale la care suntem supuși;</li>
							<li>executarea unui contract la care sunteți parte;</li>
							<li>un interes comercial legitim care nu este înlăturat de interesele pe care le aveți de a proteja informațiile;
							</li>
							<li>în cazul în care nu se aplică niciuna dintre cele de mai sus, consimțământul dvs. (pe care îl vom cere înainte de prelucrarea informațiilor).
							</li>
						</ul>
						<p>Scopurile pentru care utilizăm informații despre dvs., cu metodele corespunzătoare de colectare și baza legală de utilizare sunt:</p>
						<table>
							<thead>
							<tr>
								<td width="413">
									<p><strong>Scop</strong></p>
								</td>
								<td width="765">
									<p><strong>Metoda de colectare și baza legală pentru Prelucrare</strong></p>
								</td>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td width="413">
									<p><strong><em>Respectarea obligațiilor de reglementare</em></strong></p>
									<p>verificarea vârstei dumneavoastră ca utilizator al produselor noastre
									</p>
								</td>
								<td width="765">
									<p>Aceste informații ne sunt, în general, furnizate direct de dvs.</p>
									<p>Le folosim deoarece este necesar să respectăm obligația legală de a vinde produse numai adulților sau, în țările în care nu există o asemenea obligație legală, deoarece avem un interes comercial legitim să vindem produsele noastre numai adulților care sunt nu sunt înlăturate de interesele, drepturile și libertățile dumneavoastră de a proteja informațiile despre dvs.</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p><strong><em>Pentru a ne vinde produsele</em></strong></p>
									<p>Pentru a vă onora comenzile (inclusiv trimiterea de chitanțe)</p>
									<p>Pentru a vă procesa plățile</p>
									<p>Pentru furnizarea de servicii de garanție</p>
								</td>
								<td width="765">
									<p>Aceste informații ne sunt, în general, furnizate direct de dumneavoastră (de obicei numele, adresa, adresa de email, informații de plată).</p>
									<p>Noi o folosim pentru a ne îndeplini obligațiile contractuale față de dvs. ca și cumpărător al produselor noastre.</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p><strong><em>Pentru furnizarea de servicii legate de vânzări</em></strong>
									</p>
									<p>pentru a ne ocupa de de întrebările și cererile dumneavoastră.</p>
									<p>pentru a coresponda cu dvs.</p>
									<p>administrare generală și depanare</p>
									<p>administrarea programelor de loialitate</p>
								</td>
								<td width="765">
									<p>Aceste informații ne sunt, în general, furnizate direct de dumneavoastră.</p>
									<p>Le folosim pentru că avem un interes legitim în afaceri pentru a furniza clienților noștri servicii legate de vânzări, care sunt anulate de interesele, drepturile și libertățile dumneavoastră. de a proteja informațiile despre dumneavoastră.
									</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p><strong><em>Comercializarea produselor noastre (acolo unde este permisă de
										lege)</em></strong></p>
									<p>pentru a vă înțelege preferințele (cum ar fi ce produse sau evenimente ar putea să vă intereseze sau ar putea fi mai bine adaptate la nevoile dumneavoastră) și, dacă este permis de lege, să vi le comercializăm dvs. personal</p>
									<p>administrarea programelor de loialitate</p>
									<p>pentru a vă invita să participați la, și să administrăm sondaje sau campanii de cercetare de piață</p>
									<p>pentru cercetare de piață</p>
									<p>pentru a dezvolta strategii de marketing</p>
									<p>pentru a administra campanii de marketing</p>
									<p>pentru a vă personaliza experiența la punctele de contact PMI (de exemplu, pentru a vă personaliza vizita, cum ar fi cu salutări sau sugestii care ar putea să vă intereseze)</p>
								</td>
								<td width="765">
									<p>Aceasta va fi, în mod obișnuit, o combinație de informații pe care ni le furnizați (de exemplu, numele și informațiile dvs. de contact și de social media); informațiile pe care le colectăm în mod automat (de exemplu, folosind tehnologia pentru a monitoriza utilizarea punctelor de contact PMI) și, dacă este permis de lege, informațiile pe care le dobândim de la terți (cum ar fi postările publice pe social media).</p>
									<p>Le folosim în temeiul faptului că avem un interes legitim în afaceri pentru a ne comercializa produsele, a opera puncte de contact PMI și pentru a vă personaliza experiențele în moduri care sunt anulate de interesele, drepturile și libertățile dvs. de a proteja informațiile despre dumneavoastră.</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p><strong><em>Comercializarea produselor noastre (acolo unde este permisă de
										lege)</em></strong></p>
									<p>să vă oferim informații despre și pentru a administra afiliații PMI, promoțiile, produsele și serviciile acestora, piețele de desfacere, evenimentele și reglementarea produselor noastre; și să dezvoltăm și să îmbunătățim instrumentele pentru a urmări aceste scopuri</p>
								</td>
								<td width="765">
									<p>Aceasta va fi, în mod obișnuit, o combinație de informații pe care ni le furnizați (de exemplu, numele și detaliile dvs. de contact și numele pe social media); informațiile pe care le colectăm în mod automat (de exemplu, folosind cookie-uri și tehnologii asemănătoare) și, (dacă este permis de lege), informațiile pe care le dobândim de la terți (cum ar fi postările publice pe social media).</p>
									<p>Le folosim în temeiul faptului că avem un interes legitim în afaceri pentru a le comercializa, care sunt anulate de interesele, drepturile și libertățile dumneavoastră de a proteja informațiile despre dumneavoastră.</p>
									<p>în anumite țări, în cazul în care acest lucru este impus de lege, vă vom trimite aceste materiale în format electronic numai cu acordul dumneavoastră.
									</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p><strong><em>Suport pentru toate scopurile de mai sus</em></strong></p>
									<p>administrarea conturilor dvs.
									</p>
									<p>pentru a vă permite să utilizați puncte de contact PMI (de exemplu, permițându-vă să rămâneți conectat la secțiunile unui punct de contact care sunt rezervate numai utilizatorilor autorizați, gestionând preferința dvs. de limbă, asociându-vă coșul de cumpărături cu dumneavoastră)</p>
									<p>corespondarea cu dumneavoastră.</p>
									<p>gestionarea întâlnirilor dumneavoastrăcu noi sau cu cineva care oferă servicii de suport pentru produsele sau serviciile noastre (de exemplu, cu privire la un produs nou sau la un serviciu post-vânzare)
									</p>
									<p>îmbunătățirea experiențelor dumneavoastră.</p>
									<p>administrare și depanare
									</p>
								</td>
								<td width="765">
									<p>Aceasta va fi, în mod obișnuit, o combinație de informații pe care ni le furnizați (de obicei, numele, parola (sau echivalentul)) și informațiile pe care le colectăm în mod automat (de exemplu, informații despre dispozitivul dvs. și cookie-uri și tehnologii asemănătoare).</p>
									<p>Le folosim pe motiv că corespund scopului utilizării informațiilor pe care le susținem. De exemplu, în cazul în care administrăm contul dvs. pentru a sprijini o achiziție sau pentru a furniza servicii post-vânzare, folosim informațiile pentru a ne îndeplini obligațiile contractuale față de dumneavoastră, în calitate de cumpărător al produselor noastre; când vă administram contul pentru a vă arăta produsele noastre, susținem marketingul și, prin urmare, le folosim pe motiv că avem un interes legitim de afaceri pentru a comercializa produsele noastre care nu este înlăturat de interesele, drepturile și libertățile dvs. de a proteja informațiile despre dumneavoastră, si așa mai departe.</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p><strong><em>Analize și îmbunătățiri în afaceri</em></strong></p>
									<p>ne permite nouă sau partenerilor noștri de afaceri să vă informăm despre oportunitățile potențiale de implicare în promovarea produselor PMI</p>
									<p>pentru analize și îmbunătățiri ale afacerii, (inclusiv pentru produsele, punctele de vânzare, evenimentele, punctele de contact digitale PMI și a informațiilor pe care noi (sau afiliații noștri) le furnizăm clienților noștri)</p>
								</td>
								<td width="765">
									<p>Aceasta va fi, în mod obișnuit, o combinație de informații pe care ni le furnizați; informații pe care le colectăm în mod automat; și, (dacă este permis de lege), informațiile pe care le dobândim de la terți.</p>
									<p>Le folosim pe motiv că avem un interes legitim de afaceri pentru a analiza și a îmbunătăți performanța afacerii noastre, produsele noastre, punctele de contact PMI, punctele de desfacere și evenimentele, și pentru a-i invita pe alții să se implice în promovarea produselor PMI, care nu sunt suprascrise de interese, drepturi și libertăți de a proteja informațiile despre dvs.</p>
								</td>
							</tr>
							</tbody>
						</table>
						<p>În cazul în care nu folosim informațiile despre dumneavoastră pe baza juridică de mai sus, vom cere consimțământul dumneavoastră înainte de a procesa informațiile (aceste cazuri vor fi clare din context).</p>
						<p>În unele cazuri, putem utiliza informațiile despre dvs. în moduri care nu sunt descrise mai sus. în acest caz, vom furniza o notificare suplimentară privind confidențialitatea care explică această utilizare. Trebuie să citiți orice notificare suplimentară în legătură cu această notificare.</p>

						<h2>Cu cine partajăm informațiile dvs. și în ce scopuri?</h2>
						<p>Este posibil să partajăm informații despre dumneavoastră cu:</p>
						<ul>
							<li>Afiliații PMI;</li>
							<li>Terțe părți care furnizează produse sau servicii afiliaților PMI sau dumneavoastră;</li>
							<li>Partenerii de afaceri și agenții de publicitate selectați cu atenție ai afiliaților PMI (în zonele legate de produsele noastre sau în concordanță cu stilul și imaginea acestora), astfel încât să vă poată contacta cu ofertele care consideră că v-ar interesa, în funcție de preferințele dumneavoastră; și
							</li>
							<li>alte terțe părți, dacă este necesar sau permis de lege.</li>
						</ul>

						<h2>Partajarea datelor cu alți afiliați PMI</h2>
						<ul>
							<li>Informațiile despre dumneavoastră vor fi partajate cu Philip Morris International Management SA (bazată în Lausanne, Elveția), care este locul administrării centrale a prelucrării datelor cu caracter personal pentru afiliații PMI. Philip Morris International Management SA prelucrează informațiile despre dvs. pentru toate scopurile descrise în această notificare. </li>
							<li>Informațiile despre dvs. pot fi partajate afiliatului PMI care este responsabil de țara în care locuiți (în cazul în care nu a fost afiliatul PMI care a colectat prima dată informațiile) pentru toate scopurile descrise în această notificare. </li>
							<li>Informațiile despre dvs. pot fi distribuite oricărui alt afiliat PMI pe care îl contactați (de exemplu, dacă călătoriți și doriți să aflați de unde să cumpărați produse PMI într-o țară nouă sau unde să găsiți servicii sau suport pentru produsele PMI) pentru a ne îmbunătăți serviciile noastre pentru dumneavoastră. </li>
						</ul>
						<p>Detalii despre afiliații PMI și țările în care există sunt disponibile <a href="https://www.pmi.com/">aici.</a></p>

						<h2>Partajarea datelor cu Terții</h2>
						<ul>
							<li>Este posibil să partajăm informații despre dvs. terților care furnizează produse sau servicii afiliaților PMI sau dvs. (cum ar fi consilieri, furnizori de servicii de plăți, furnizori de livrări, comercianți cu amănuntul, consilieri de produs, furnizori de servicii de informare și furnizori de verificare a vârstei).
							</li>
							<li>Este posibil să partajăm informații despre dvs. cu partenerii de afaceri și cu agenții de publicitate selectați cu atenție ai afiliaților PMI (în funcție de tipul de lucruri pe care le-ați putea asocia cu produsele noastre, de exemplu pentru că au o imagine, un stil sau o funcționalitate asemănătoare sau complementară) pentru a vă putea contacta cu produsele, serviciile și promoțiile care consideră că v-ar interesa, în funcție de preferințele dvs.
							</li>
							<li>Este posibil să partajăm informații despre dvs. cu alte terțe părți, dacă este necesar sau permis de lege, de exemplu: autorități de reglementare; departamentele guvernului; ca răspuns la o cerere a autorităților de aplicare a legii sau a altor funcționari guvernamentali; atunci când considerăm că dezvăluirea este necesară sau adecvată pentru a preveni vătămarea fizică sau pierderile financiare sau în legătură cu o investigație a unei activități ilegale suspectate sau reale; și în contextul restructurării organizaționale.
							</li>
						</ul>

						<h2>Unde pot fi trimise informațiile despre dvs.?</h2>
						<p>Ca și în cazul oricărei organizații multinaționale, afiliații PMI transferă informații la nivel global. Prin urmare, informațiile despre dvs. pot fi transferate la nivel global (dacă informațiile dvs. sunt colectate în Spațiul Economic European, aceasta înseamnă că informațiile dvs. pot fi transferate în afara acestuia).</p>
						<p>Când utilizați informațiile descrise în această notificare, informațiile despre dvs. pot fi transferate în interiorul sau în afara țării sau teritoriului în care au fost colectate, inclusiv către o țară sau un teritoriu care poate să nu aibă standarde de protecție a datelor echivalente.</p>
						<p>De exemplu, afiliații PMI din cadrul Spațiului Economic European ("SEE") pot transfera informații personale către afiliații PMI din afara SEE. în toate cazurile, transferul va fi:</p>
						<ul>
							<li>pe baza unei decizii de adecvare a Comisiei Europene;</li>
							<li>sub rezerva unor garanții adecvate, de exemplu, Contactele Model UE; sau</li>
							<li>necesare pentru îndeplinirea obligațiilor în baza unui contract încheiat între dvs. și noi (sau punerea în aplicare a măsurilor precontractuale luate la cererea dvs.) sau pentru încheierea sau executarea unui contract încheiat în interesul dvs. între noi și o terță parte, cum ar fi aranjamente de călătorie.
							</li>
						</ul>
						<p>În toate cazurile, în aceste țări sau teritorii vor fi aplicate măsuri de securitate adecvate pentru protejarea informațiilor cu caracter personal, în conformitate cu legile aplicabile privind protecția datelor.</p>

						<h2>Cum protejăm informațiile despre dvs.?</h2>
						<p>Punem în aplicare măsuri tehnice și organizatorice adecvate pentru protejarea informațiilor personale pe care le deținem de la dezvăluirea, utilizarea, modificarea sau distrugerea neautorizată. Dacă este cazul, folosim tehnologii de criptare și alte tehnologii care pot ajuta la securizarea informațiilor pe care le furnizați. De asemenea, solicităm furnizorilor noștri de servicii să respecte cerințele stricte de confidențialitate și securitate a datelor.</p>

						<h2>Cât timp vor fi păstrate informațiile despre dvs.?</h2>
						<p>Vom păstra informațiile despre dvs. pentru perioada necesară pentru a îndeplini scopurile pentru care au fost colectate informațiile. După aceea, le vom șterge. Perioada va varia în funcție de scopurile pentru care au fost colectate informațiile. Rețineți că, în anumite circumstanțe, aveți dreptul să ne cereți să ștergem informațiile. De asemenea, uneori suntem obligați din punct de vedere legal să păstrăm informațiile, de exemplu, în scopuri fiscale și contabile. </p>
						<p>De obicei, păstrăm datele pe baza criteriilor descrise în tabelul de mai jos:</p>

						<table>
							<thead>
							<tr>
								<td width="413">
									<p><strong>Tip</strong></p>
								</td>
								<td width="765">
									<p><strong>Explicație / criterii tipice de păstrare</strong></p>
								</td>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td width="413">
									<p>marketing pentru dvs. (inclusiv comunicări de marketing) (dacă utilizați puncte de contact digitale și care pot fi contactate)</p>
								</td>
								<td width="765">
									<p>Majoritatea informațiilor din profilul dvs. de marketing sunt păstrate pe durata relației noastre de marketing cu dvs.; de exemplu, cât timp continuați să utilizați puncte de contact digitale sau răspundeți la comunicările noastre. Cu toate acestea, anumite elemente ale profilului dvs. de marketing, cum ar fi înregistrările despre modul în care interacționăm cu dvs., devin depășite în mod firesc după o anumită perioadă de timp, așa că le ștergem automat după perioade definite (de obicei 3 ani), potrivit scopului pentru care le-am colectat.</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p>marketing pentru dvs. (inclusiv comunicări de marketing) (dacă nu mai păstrați contactul cu noi)</p>
								</td>
								<td width="765">
									<p>Acest scenariu este același cu cel de mai sus, dar dacă nu avem niciun contact cu dvs. pentru o perioadă lungă de timp (de obicei, 2 ani), vom înceta să vă trimitem comunicări de marketing și vom șterge istoricul răspunsurilor la acestea. Acest lucru se va întâmpla, de exemplu, dacă nu faceți niciodată clic pe o invitație la un eveniment, nu vă conectați la un punct de contact digital sau contactați asistența clienților, în acel interval. Motivul este că, în aceste condiții, presupunem că preferați să nu primiți comunicațiile.</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p>marketing pentru dvs. (inclusiv comunicări de marketing) (dacă nu puteți fi contactat)</p>
								</td>
								<td width="765">
									<p>Dacă v-ați înregistrat pentru a primi comunicări de marketing, dar informațiile pe care ni le furnizați pentru a vă contacta nu funcționează, vă vom păstra detaliile pentru o perioadă de obicei de numai 6 luni pentru a vă permite să reveniți și să le corectați.
									</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p>marketing pentru dvs. (inclusiv comunicări de marketing) (înregistrări incomplete)</p>
								</td>
								<td width="765">
									<p>Dacă începeți să vă înscrieți într-o bază de date, dar nu finalizați procesul (de exemplu, dacă nu finalizați procesul de verificare a vârstei sau nu acceptați termenii de utilizare a punctului de contact), vom păstra datele dvs. numai pentru 6 luni pentru a vă permite să reveniți și să finalizați procesul.</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p>cercetare de piață</p>
								</td>
								<td width="765">
									<p>Dacă nu sunteți înregistrat la noi în alte scopuri (de ex. comunicări de marketing, garanție, asistență pentru clienți) și folosim informații despre dvs. disponibile public, pentru a înțelege piața sau preferințele dvs., vom păstra informațiile despre dvs. pentru o perioadă scurtă în scopul de a efectua un anumit punct de cercetare de piață.</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p>achiziții și garanție</p>
								</td>
								<td width="765">
									<p>Dacă cumpărați bunuri, vom păstra detalii despre acest lucru atâta timp cât este necesar pentru a finaliza vânzarea și pentru a respecta orice obligații legale (de exemplu, în scopuri fiscale și contabile). Dacă vă înregistrați și pentru o garanție pentru un dispozitiv, vom păstra detalii despre acest lucru atâta timp cât este relevant pentru garanție.</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p>asistență clienți</p>
								</td>
								<td width="765">
									<p>Dacă contactați asistența pentru clienți, vom face o înregistrare a problemei (inclusiv detaliile solicitării dvs. și a răspunsului nostru) și o vom păstra cât timp aceasta rămâne relevantă pentru relația noastră, de exemplu dacă trebuie să înlocuiți un dispozitiv în garanție sau dacă solicitările dvs. recente sunt relevante. înregistrările temporare (de exemplu, o înregistrare automată a unui apel telefonic în care ne cereți să vă direcționăm către un punct de vânzare cu amănuntul) pot fi relevante numai până când se înregistrează mai multe înregistrări permanente și vor fi păstrate doar temporar.</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p>jurnale de audit a sistemului</p>
								</td>
								<td width="765">
									<p>Jurnalele de audit a sistemului sunt păstrate de obicei o perioadă de numai câteva luni.</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p>analize de afaceri</p>
								</td>
								<td width="765">
									<p>Datele de analiză a afacerii sunt de obicei colectate automat atunci când utilizați puncte de contact PMI și sunt anonimizate / agregate la scurt timp după aceea.
									</p>
								</td>
							</tr>
							</tbody>
						</table>

						<h2>Ce drepturi și opțiuni aveți?</h2>
						<p>Este posibil să aveți o parte sau toate următoarele drepturi cu privire la informațiile despre dumneavoastră pe care le deținem:</p>
						<ul>
							<li>să ne cereți să vă permitem accesul la ele;</li>
							<li>să ne cereți să le rectificăm, să le actualizăm sau să le ștergem;</li>
							<li>să ne cereți să restricționăm utilizarea acestora, în anumite circumstanțe;</li>
							<li>să obiectați cu privire la utilizarea acestora de către noi, în anumite circumstanțe;</li>
							<li>să vă retrageți consimțământul privind utilizarea acestora de către noi;</li>
							<li>portabilitatea datelor, în anumite circumstanțe;</li>
							<li>să optați să nu le mai folosim pentru marketing direct; și</li>
							<li>să depuneți o plângere la autoritatea de supraveghere din țara dumneavoastră (dacă există una).</li>
						</ul>
						<p>Vă oferim modalități ușoare de a vă exercita aceste drepturi, cum ar fi link-uri de "dezabonare", sau oferindu-vă o adresă de contact, în mesajele pe care le primiți.</p>
						<p>Unele aplicații mobile pe care le oferim, de asemenea, vă pot trimite mesaje push, de exemplu despre produse sau servicii noi. Puteți dezactiva aceste mesaje prin setările din telefon sau din aplicație.
						</p>
						<p>Drepturile pe care le aveți depind de legile țării dvs. Dacă vă aflați în Spațiul Economic European, veți avea drepturile enumerate în tabelul de mai jos. Dacă sunteți în altă parte, ne puteți contacta (pentru a afla mai multe, consultați paragraful "pe cine contactați dacă aveți întrebări?" de la sfârșitul acestei notificări).</p>
						<table>
							<thead>
							<tr>
								<td width="413">
									<p><strong>Drept cu privire la informațiile despre dvs. pe care le deținem</strong>
									</p>
								</td>
								<td width="765">
									<p><strong>Mai multe detalii (notă: se aplică anumite limite legale pentru toate aceste drepturi)</strong></p>
								</td>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td width="413">
									<p>să ne cereți să vă permitem accesul la ele</p>
								</td>
								<td width="765">
									<p>Aceasta este confirmarea:</p>
									<p>dacă prelucrăm sau nu informații despre dvs.;</p>
									<p>numele și datele noastre de contact;
									</p>
									<p>scopul prelucrării;</p>
									<p>categoriile de informații în cauză;</p>
									<p>categoriile de persoane cu care partajăm informațiile și, în cazul în care orice persoană se află în afara SEE și nu beneficiază de o <u><a
										href="http://ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.htm">decizie
										a Comisiei Europene privind adecvarea</a></u>, garanțiile corespunzătoare pentru protejarea informațiilor;</p>
									<p>(dacă o avem) sursa informațiilor, dacă nu le-am colectat de la dvs.;</p>
									<p>(în măsura în care o vom face, caz în care vi se va aduce la cunoștință), existența unui proces decizional automatizat, inclusiv a profilării, care produce efecte juridice cu privire la dvs. sau vă afectează în mod semnificativ în mod similar și informații despre logica implicată, precum și semnificația și consecințele preconizate ale unei astfel de prelucrări pentru dvs.; și</p>
									<p>criteriile pentru determinarea perioadei pentru care vom stoca informațiile.</p>
									<p>La solicitarea dvs. vă vom oferi o copie a informațiilor despre dvs. pe care le folosim (cu condiția ca acestea să nu afecteze drepturile și libertățile celorlalți).
									</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p>să ne cereți să le rectificăm sau să le actualizăm</p>
								</td>
								<td width="765">
									<p>Acest lucru este valabil dacă informațiile pe care le deținem sunt inexacte sau incomplete.
									</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p>să ne cereți să le ștergem</p>
								</td>
								<td width="765">
									<p>Acest lucru se aplică dacă:</p>
									<p>informațiile pe care le deținem nu mai sunt necesare în legătură cu scopurile pentru care le folosim;</p>
									<p>utilizăm informațiile pe baza consimțământului dvs. și dvs. vă retrageți consimțământul (în acest caz, vom reține să nu vă mai contactăm din nou, dacă nu ne spuneți că doriți să ștergem toate informațiile despre dvs., caz în care vă vom respecta dorințele);
									</p>
									<p>folosim informațiile pe baza unui interes legitim și constatăm că, în urma obiecției dumneavoastră., nu avem un interes major pentru continuarea utilizării acestora;</p>
									<p>informațiile au fost obținute sau utilizate ilegal; sau</p>
									<p>pentru respectarea unei obligații legale.</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p>să ne cereți să restricționăm prelucrarea acestora</p>
								</td>
								<td width="765">
									<p>Acest drept se aplică temporar în timp ce analizăm în cazul dvs. dacă:
									</p>
									<p>contestă acuratețea informațiilor pe care le folosim; sau</p>
									<p>v-ați opus utilizării informațiilor de către noi pe baza unui interes legitim</p>
									<p>(dacă faceți uz de dreptul dvs. în aceste cazuri, vă vom spune înainte de a utiliza informațiile din nou).</p>
									<p>Acest drept se aplică și în cazul în care:</p>
									<p>utilizarea noastră este ilegală și vă opuneți ștergerii datelor; sau</p>
									<p>nu mai avem nevoie de date, dar dvs. aveți nevoie de acestea pentru a stabili un caz juridic.</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p>pentru a obiecta la prelucrarea acestora de către noi</p>
								</td>
								<td width="765">
									<p>Aici aveți două drepturi:</p>
									<p>
										i. dacă folosim informații despre dvs. pentru marketing direct: puteți &rdquo;renunța&rdquo; (fără a fi nevoie să vă justificați) și ne vom conforma solicitării; și</p>
									<p>
										ii. dacă folosim informațiile despre dvs. pe baza interesului legitim în alte scopuri decât marketingul direct, puteți să vă opuneți utilizării noastre în aceste scopuri, oferind o explicație a situației dvs. speciale și vom lua în considerare obiecția dvs.</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p>să vă retrageți consimțământul privind utilizarea acestora de către noi
									</p>
								</td>
								<td width="765">
									<p>Acest lucru este valabil dacă baza legală prin care folosim informațiile despre dvs. este consimțământul. Aceste cazuri vor fi clare din context.</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p>la portabilitatea datelor</p>
								</td>
								<td width="765">
									<p>Dacă:</p>
									<p>i. dvs. ne-ați furnizat datele; și</p>
									<p>ii. noi folosim aceste date, prin mijloace automatizate, și pe baza fie a consimțământului dvs., fie pe baza îndeplinirii obligațiilor noastre contractuale față de dumneavoastră,</p>
									<p>atunci aveți dreptul să primiți înapoi datele de la noi într-un format utilizat în mod obișnuit și dreptul de a ne solicita să transmitem datele altcuiva dacă este posibil din punct de vedere tehnic pentru noi.</p>
								</td>
							</tr>
							<tr>
								<td width="413">
									<p>să depuneți o plângere la autoritatea de supraveghere din țara dvs.</p>
								</td>
								<td width="765">
									<p>Fiecare țară din Spațiul Economic European trebuie să asigure una sau mai multe autorități publice în acest scop.</p>
									<p>Le puteți găsi detaliile de contact aici:</p>
									<p><u><a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a></u></p>
									<p>Pentru alte țări, consultați site-ul web al autorității țării dvs.</p>
								</td>
							</tr>
							</tbody>
						</table>

						<h2>Puncte suplimentare specifice fiecărei țări</h2>
						<p>în funcție de țara în care vă aflați, este posibil să aveți anumite drepturi suplimentare. </p>
						<p>Dacă ești în Franța, află mai multe...</p>

						<ul>
							<li>Dacă sunteți în Franța, aveți dreptul să ne dați instrucțiuni cu privire la informațiile pe care le deținem în legătură cu dvs. în caz de deces (în special, dacă ar trebui să le stocăm sau să le ștergem și dacă alții vor avea dreptul să le vadă). Puteți: </li>
							<li>(A) să dați instrucțiuni generale către un furnizor de servicii digitale înregistrat la autoritatea franceză de supraveghere a protecției datelor (denumită "CNIL&rdquo;) (aceste instrucțiuni se aplică tuturor utilizărilor informațiilor despre dvs.); sau </li>
							<li>(B) să ne dați instrucțiuni specifice care se aplică numai utilizării de către noi a informațiilor despre dvs. </li>
						</ul>

						<p>Instrucțiunile dvs. pot necesita ca noi să transferăm informațiile despre dvs. către o terță parte (dar în cazul în care informațiile conțin informații despre alte persoane, obligația noastră de a respecta și drepturile lor de confidențialitate ar putea însemna că nu putem respecta în totalitate instrucțiunile dumneavoastră). Puteți desemna o terță parte responsabilă de asigurarea respectării instrucțiunilor dvs. Dacă nu desemnați un terț în acest mod, succesorii dvs. (cu excepția cazului în care specificați altfel în instrucțiunile dvs.) au dreptul de a vă exercita drepturile asupra informațiilor despre dvs. după moartea dvs.:</p>
						<ul>
							<li>(i) pentru a vă administra proprietatea (caz în care succesorii dumneavoastră vor putea accesa informațiile despre dvs. pentru a identifica și obține informații care ar putea fi utile pentru administrarea proprietății dumneavoastră, inclusiv orice bunuri sau date digitale care ar putea fi considerate o amintire de familie care este transferabilă succesorilor dvs.); și </li>
							<li>(ii) pentru a vă asigura că părțile care utilizează informații despre dvs. iau în considerare decesul dvs. (cum ar fi închiderea contului dvs. și restricționarea utilizării sau actualizarea informațiilor despre dvs.). </li>
						</ul>

						<p>Puteți modifica sau revoca instrucțiunile dvs. în orice moment. Pentru informații suplimentare privind prelucrarea informațiilor despre dvs. în caz de deces, a se vedea Articolul 40-1 din Legea 78-17 din 6 ianuarie 1978. La decesul dvs., în mod implicit, veți opri utilizarea contului dumneavoastră și vom șterge informațiile despre dvs. în conformitate cu politicile noastre de retenție (vedeți paragraful "Cat timp vor fi păstrate informațiile despre dumneavoastră?&rdquo; pentru detalii).</p>

						<h2>Pe cine contactați dacă aveți întrebări?</h2>
						<p>Dacă aveți întrebări sau doriți să vă exercitați oricare dintre drepturile dvs., puteți găsi detalii de contact pentru afiliații PMI relevanți și, dacă este cazul, ofițerul pentru protecția datelor, <a href="https://www.pmi.com/">aici</a>. Datele de contact vor fi, de asemenea, furnizate în orice comunicare pe care v-o trimite un afiliat PMI.</p>
						<p>Dacă țara dvs. are o autoritate de protecție a datelor, aveți dreptul să o contactați cu orice întrebări sau preocupări. Dacă afiliatul PMI relevant nu vă poate rezolva întrebările sau preocupările, aveți, de asemenea, dreptul de a solicita o cale de atac în fața unei instanțe naționale.</p>

						<h2>Modificări ale acestei notificări</h2>
						<p>Putem actualiza din când în când această notificare (și orice notificare suplimentară privind confidențialitatea). Vă vom notifica despre modificări când este necesar să o facem în temeiul legii.</p>
						<p>Ultima modificare: 27 martie 2018.</p>
					</div>
				</div>
			</Layout>
		)
	}
}

export default UserDataNotification;
