import { createReducer } from 'reduxsauce';
import { PointsTypes } from './Actions';

const INITIAL_STATE = {
	points: {
		data: [],
		pager: {}
	},
	loading: false,
	errors: null
};

export const pointsLoading = (state) => ({
	...state,
	points: {
		data: [],
		pager: {}
	},
	loading: true,
	errors: null
});

export const pointsSuccess = (state, { points }) => ({
	...state,
	points: points,
	loading: false,
	errors: null
});

export const pointsFail = (state, { errors }) => ({
	...state,
	points: {
		data: [],
		pager: {}
	},
	loading: false,
	errors: errors
});

export const reducer = createReducer(INITIAL_STATE, {
	[PointsTypes.POINTS_LOADING]: pointsLoading,
	[PointsTypes.POINTS_SUCCESS]: pointsSuccess,
	[PointsTypes.POINTS_FAIL]: pointsFail
});
