import { createReducer } from 'reduxsauce';
import { TestimonialChallengeTypes } from './Actions';

const INITIAL_STATE = {
    testimonials: {
        data: [],
        pager: undefined
    },
    testimonialModalVisible: false,
    loading: false,
    errors: null,
    uploadLoading: false,
    uploadSuccess: false,
    uploadErrors: null,
    likeLoading: false,
    likeSuccess: false,
    likeErrors: null
};

export const toggleTestimonialModal = (state) => ({
    ...state,
    testimonialModalVisible: !state.testimonialModalVisible
});

export const testimonialsLoading = (state) => ({
    ...state,
    testimonials: [],
    loading: true,
    errors: null
});

export const testimonialsSuccess = (state, { testimonials }) => ({
    ...state,
    testimonials: testimonials,
    loading: false,
    errors: null
});

export const testimonialsFail = (state, { errors }) => ({
    ...state,
    testimonials: [],
    loading: false,
    errors: errors
});

export const uploadLoading = (state) => ({
    ...state,
    uploadLoading: true,
    uploadErrors: null
});

export const uploadSuccess = (state) => ({
    ...state,
    uploadLoading: false,
    uploadSuccess: true,
    uploadErrors: null
});

export const resetUploadSuccess = (state) => ({
    ...state,
    uploadSuccess: false,
    uploadLoading: false
});

export const uploadFail = (state, { errors }) => ({
    ...state,
    uploadLoading: false,
    uploadSuccess: false,
    uploadErrors: errors
});

export const likeLoading = (state) => ({
    ...state,
    likeLoading: true,
    likeErrors: null
});

export const likeSuccess = (state, { liked, userID, testimonialID }) => {
    return {
        ...state,
        likeSuccess: true,
        likeLoading: false,
        testimonials: {
            data: state.testimonials.data.reduce((testimonials, testimonial) => {
                if (testimonial.id === testimonialID) {
                    const likes = testimonial.likes;
                    let newLikes;
                    if (liked) {
                        newLikes = [...likes, { user_id: userID }];
                    } else {
                        newLikes = likes.filter((like) => like.user_id !== userID);
                    }
                    testimonials.push({ ...testimonial, likes: newLikes });
                } else {
                    testimonials.push(testimonial);
                }
                return testimonials;
            }, []),
            pager: state.testimonials.pager
        }
    };
};

export const likeErrors = (state, { errors }) => ({
    ...state,
    likeLoading: false,
    likeSuccess: false,
    likeErrors: errors
});

export const reducer = createReducer(INITIAL_STATE, {
    [TestimonialChallengeTypes.TOGGLE_TESTIMONIAL_MODAL]: toggleTestimonialModal,
    [TestimonialChallengeTypes.TESTIMONIALS_LOADING]: testimonialsLoading,
    [TestimonialChallengeTypes.TESTIMONIALS_SUCCESS]: testimonialsSuccess,
    [TestimonialChallengeTypes.TESTIMONIALS_FAIL]: testimonialsFail,
    [TestimonialChallengeTypes.UPLOAD_LOADING]: uploadLoading,
    [TestimonialChallengeTypes.UPLOAD_SUCCESS]: uploadSuccess,
    [TestimonialChallengeTypes.RESET_UPLOAD_SUCCESS]: resetUploadSuccess,
    [TestimonialChallengeTypes.UPLOAD_FAIL]: uploadFail,
    [TestimonialChallengeTypes.LIKE_LOADING]: likeLoading,
    [TestimonialChallengeTypes.LIKE_SUCCESS]: likeSuccess,
    [TestimonialChallengeTypes.LIKE_FAIL]: likeErrors
});
