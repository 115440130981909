import React from 'react'


//Assets
import config from '../../../../config';
import styles from "../../Link4Refferal.module.scss";
import shine from '../../../images/shine.png';
import icon1 from '../../../images/1.png';
import icon2 from '../../../images/2.png';
import icon3 from '../../../images/3.png';

const reasons = [
    {
        icon: shine,
        title: 'Fără ardere, fără gudron',
        description: 'Gudronul este reziduul produs în urma arderii țigării. IQOS nu produce gudron, pentru că încălzește tutunul și nu îl arde.',
        disclaimer: 'Informații importante: Deși IQOS nu produce gudron, IQOS nu este lipsit de riscuri și eliberează nicotină, care provoacă dependență.'
    }
    ,
    {
        icon: icon1,
        title: 'Fără fum, fără cantități mari de monoxid de carbon',
        description: 'IQOS eliberează în medie cu 98% mai puțin monoxid de carbon în comparație cu țigările tradiționale.',
        disclaimer: 'Informații importante:  Aceasta nu înseamnă că există o reducere cu 98% a riscului. IQOS nu este lipsit de riscuri și eliberează nicotină, care provoacă dependență.'
    }, {
        icon: icon2,
        title: 'Fără să miroși a fum',
        description: 'Hainele, părul, mâinile nu mai miros a fum de țigară și e mai puțin probabil să îi deranjezi pe cei din jur.',
        // disclaimer: 'IQOS nu este lipsit de riscuri. IQOS este destinat exclusiv fumătorilor adulți care altfel ar continua să fumeze.'
    }, {
        icon: icon3,
        title: 'O alegere mai bună',
        description: `IQOS este o alegere mai bună față de continuarea fumatului.`,
        disclaimer: 'Informații importante: IQOS nu este lipsit de riscuri, eliberează nicotină, care provoacă dependență.'
    }];

const IqosReasons = () =>

    <div className={styles.reasonsContainer}>
        <h3>Experiența IQOS are următoarele beneficii:</h3>

        <div className={styles.reasons}>
            {reasons.map(({icon, title, description, disclaimer}, id) =>
                <div className={styles.reason} key={id}>
                    <div className={styles.iconContainer}>
                        <img src={icon} alt="icon-benefits" />
                    </div>
                    <h3> {title}</h3>
                    <p>{description}</p>
                    <span><i>{disclaimer}</i></span>
                </div>
            )
            }
        </div>
    </div>


export default IqosReasons