import { call, put, takeLatest } from 'redux-saga/effects';
import ReferralActions, { ReferralTypes } from './Actions';
import axios, { setAxiosToken } from '../../utils/axios';
import store from 'store';
import config from '../../config';
import { push, go } from 'connected-react-router';

export function* refer(params)  {
	yield put(ReferralActions.referralModal());
	yield put(ReferralActions.referralLoading());

	try {
		const response = yield call(() => axios.post('/refer', params.params));
		yield put(ReferralActions.referralSuccess(params));
		yield put(push(config.path.referralSuccess));
	} catch (error) {
		yield put(ReferralActions.referralFail(error.response.data));
	}
}

export function* getTree({params}){
	try{
		const response = yield call (() => axios.get('/profile/tree', { params }));
		yield put(ReferralActions.referralTreeSuccess(response.data));
	} catch(error){
		console.log(error);
	}
}

export function* getCards({params}){
	try{
		const response = yield call (() => axios.get('/recommendations/getAllImages', { params }));
		yield put(ReferralActions.referralCardsSuccess(response.data));
	} catch(error){
		yield put(ReferralActions.referralCardsFail(error.response.data));
	}
}

export function* submitCard({params}){
	try{
		const response = yield call(() => axios.post('/recommendations', params));
		yield put(ReferralActions.referralSubmitCardsSuccess(response.data));
		const token = response.data.token;
		yield put(ReferralActions.referralCard(token));
	}
	catch(error){
		yield put(ReferralActions.referralSubmitCardsFail(error.response.data));
	}
}

export function* getCard({params}){
	try{
		const response = yield call(() => axios.get(`/recommendations/${params}`, {params}));
		yield put(ReferralActions.referralCardSuccess(response.data));
	}
	catch(error){
		yield put(ReferralActions.referralCardFail(error.response.data));
	}
}



export default function* referralSaga() {
	yield takeLatest(ReferralTypes.REFERRAL, refer);
	yield takeLatest(ReferralTypes.REFERRAL_TREE, getTree);
	yield takeLatest(ReferralTypes.REFERRAL_CARDS, getCards);
	yield takeLatest(ReferralTypes.REFERRAL_SUBMIT_CARDS, submitCard);
	yield takeLatest(ReferralTypes.REFERRAL_CARD, getCard);

}