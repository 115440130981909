export default {
    apiUrl: (window.location.hostname === 'localhost') || (window.location.hostname === '127.0.0.1') || (window.location.hostname.includes('pmi.gd')) ? 'https://link-for-referral-api.gd.ro/' : 'https://link-for-referral-api-prod.gd.ro/',
        // 'http://localhost:8000/api/',
    cookieConfig:
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1"
            ? ";domain=localhost;path=/"
            : `;secure;domain=iqos.ro;path=/`,
    user: {
        expiresIn: 'expiresIn',
        isAdult: 'isAdult',
        isPWA: 'isPWA',
        data: 'data',
        bottomShortcuts: 'bottomShortcuts',
        menuOpenings: 'menuOpenings',
        orders: 'orders',
        points: 'points',
        pushNotificationsToken: 'pushNotificationsToken'
    },
    path: {
        index: '/club/incearca-iqos',
        index2: '/club/incearca-iqos/gratuit',
        offlineRedemption: '/club/incearca-iqos/revendica-heets-offline',
        contact: 'https://www.iqos.ro/contact',
        userDataNotification: 'https://www.iqos.ro/privacy',
        cookiesPolicy: 'https://www.iqos.ro/cookies',
        termsConditions: 'https://www.iqos.ro/conditii-de-utilizare',
        fdaArticle: 'https://club.iqos.ro/comunitate/descopera-iqos/fda-a-autorizat-comercializarea-in-statele-unite-a-iqos-si-a-trei-variante-de-heets',
        FAQs: '/club/incearca-iqos/intrebari-frecvente',
        regulation: '/club/incearca-iqos/regulament',
        map: 'https://www.iqos.ro/store/ro/search',
        shopIQOS: 'https://www.iqos.ro/iqos3-duo',
        IQOSpoints: '/club/incearca-iqos/puncte-iqos-club',
        IQOSPocket: 'https://club.iqos.ro/iqos-pocket',
        listWinners: 'https://iqos.ro/club/incearca-iqos/lista-castigatori',
        aboutIQOS: 'https://www.iqos.ro/produse/ce-este-iqos',
        termsConditionsUGC: 'https://www.iqos.ro/continut-generat-de-utilizatori',
        notFound: '/club/incearca-iqos/page-not-found',

        //Second Wave links

        offlineRedemption2: '/club/incearca-iqos/gratuit/revendica-heets-offline',
        contact2: 'https://www.iqos.ro/contact',
        userDataNotification2: '/club/incearca-iqos/gratuit/notificare-protectia-datelor',
        cookiesPolicy2: '/club/incearca-iqos/gratuit/politica-cookies',
        termsConditions2: 'https://www.iqos.ro/conditii-de-utilizare',
        fdaArticle2: 'https://club.iqos.ro/comunitate/descopera-iqos/fda-a-autorizat-comercializarea-in-statele-unite-a-iqos-si-a-trei-variante-de-heets',
        FAQs2: '/club/incearca-iqos/gratuit/intrebari-frecvente',
        regulation2: '/club/incearca-iqos/gratuit/regulament',
        map2: 'https://club.iqos.ro/harta',
        shopIQOS2: 'https://www.iqos.ro/iqos3-duo',
        IQOSpoints2: '/club/incearca-iqos/gratuit/puncte-iqos-club',
        IQOSPocket2: 'https://club.iqos.ro/iqos-pocket',
        listWinners2: 'https://iqos.ro/club/incearca-iqos/gratuit/lista-castigatori',
        aboutIQOS2: 'https://www.iqos.ro/produse/ce-este-iqos',
        notFound2: '/club/incearca-iqos/gratuit/page-not-found',
    },
    activities: {

    },
    dateFormat: {
        projects: 'MMM D, YYYY'
    },
    auth: {
        refreshInProgress: 'refreshInProgress'
    },
    googleMapsKey: 'AIzaSyDLcdVvp_yV0i0V3-WWHZ2AY2BhTHESMYk'
};
