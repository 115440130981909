import React from 'react'
import Layout from '../../../components/Layout'
import styles from './ComingSoon.module.scss'
import Logo from '../../../assets/images/ui/logo/logo_big.png'

const NotFound = () => (    
<Layout> 
    <div className={styles.pageContainer}>
        <div>
        <img src={Logo} />
            <h3>Ne pare rău dar pagina pe care ați accesat-o nu există. </h3>
        </div>
        <div/>
    </div>
</Layout>)

export default NotFound