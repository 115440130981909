import { createReducer } from 'reduxsauce';
import { CountiesTypes } from './Actions';

const INITIAL_STATE = {
	counties: [],
	cities: [],
	loading: false,
	errors: null
};

export const countiesLoading = (state) => ({
	...state,
	counties: null,
	loading: true,
	errors: null
});

export const citiesLoading = (state) => ({
	...state,
	cities: null,
	loading: true,
	errors: null
});

export const countiesSuccess = (state, { counties }) => ({
	...state,
	counties: counties,
	loading: false,
	errors: null
});
export const citiesSuccess = (state, { cities }) => ({
	...state,
	cities: cities,
	loading: false,
	errors: null
});

export const countiesFail = (state, { errors }) => ({
	...state,
	counties: null,
	loading: false,
	errors: errors
});
export const citiesFail = (state, { errors }) => ({
	...state,
	cities: null,
	loading: false,
	errors: errors
});
export const citiesReset = (state) => ({
	...state,
	cities: [], //UPDATE CITIES ARRAY WHEN COUNTY IS REMOVED OR PARTIALLY DELETED
});

export const reducer = createReducer(INITIAL_STATE, {
	[CountiesTypes.COUNTIES_LOADING]: countiesLoading,
	[CountiesTypes.COUNTIES_SUCCESS]: countiesSuccess,
	[CountiesTypes.COUNTIES_FAIL]: countiesFail,
	[CountiesTypes.CITIES_LOADING]: citiesLoading,
	[CountiesTypes.CITIES_SUCCESS]: citiesSuccess,
	[CountiesTypes.CITIES_FAIL]: citiesFail,
	[CountiesTypes.CITIES_RESET]: citiesReset
});