import React from 'react';
import store from 'store';
import classnames from 'classnames';

// Assets
import config from '../../config';

// Components
import Logo from './parts/Logo';
import {connect} from "react-redux";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";


class Header extends React.Component {
    state = {
        isLoggedIn: store.get(config.user.token),
        exploreMode: store.get(config.user.exploreMode),
        isPWA: store.get(config.user.isPWA),
        prevScrollpos: window.pageYOffset,
        visible: true
    };

    // Adds an event listener when the component is mount.
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    // Remove the event listener when the component is unmount.
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    // Hide or show the menu.
    handleScroll = () => {
        const { prevScrollpos } = this.state;

        const currentScrollPos = window.pageYOffset;
        const visible = prevScrollpos > currentScrollPos;
    };

    toggleModal = () => {
        const body = document.querySelector('body');
        this.props.isVisible === false ? disableBodyScroll(body) : enableBodyScroll(body);
    };

    render() {
        const { isPWA, isLoggedIn, visible, exploreMode } = this.state;

        return (
            <>
                <div
                    className={classnames('header', {
                        'header--hidden': !visible,
                    })}
                >
                    <Logo/>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);
