import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
	testimonials: ['page'],
	toggleTestimonialModal: null,
	testimonialsLoading: null,
	testimonialsSuccess: ['testimonials'],
	testimonialsFail: ['errors'],
	uploadTestimonial: ['title', 'content'],
	uploadLoading: null,
	uploadSuccess: null,
	resetUploadSuccess: null,
	uploadFail: ['errors'],
	likeToggleTestimonial: ['testimonial'],
	likeLoading: null,
	likeSuccess: ['liked', 'userID', 'testimonialID'],
	likeFail: ['errors'],
});

export const TestimonialChallengeTypes = Types;
export default Creators;
