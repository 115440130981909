import { put, takeLatest } from 'redux-saga/effects';
import MarkersActions, { MarkersTypes } from './Actions';
import axios from '../../utils/axios';

export function* getMarkers({ filters }) {
    yield put(MarkersActions.markersLoading());
    try {
        const mapData = yield axios.get('/map/data');
        const categories = yield axios.get('/map/categories');

        yield put(
            MarkersActions.markersSuccess({
                markers: mapData.data,
                categories: Object.keys(categories.data).map((key) => categories.data[key])
            })
        );
    } catch (error) {
        yield put(MarkersActions.markersFail(error.response.data.error));
    }
}

export default function* markersSaga() {
    yield takeLatest(MarkersTypes.GET_MARKERS, getMarkers);
}
