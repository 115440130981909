import {createReducer} from 'reduxsauce';
import {UserTypes} from './Actions';

const INITIAL_STATE = {
    user: null,
    loading: false,
    errors: null,
    userData: {},
    telcorData: {
        expiringPointsDisplay: []
    },
    dcsData: {},
    opsData: {}
};

export const currentUserLoading = (state) => ({
    ...state,
    loading: true,
    errors: null
});

export const currentUserFail = (state, {errors}) => ({
    ...state,
    loading: false,
    errors: errors
});

export const currentUserSuccess = (state, {userData}) => ({
    ...state,
    userData: userData.user,
    telcorData: userData.telcor_profile.success ? userData.telcor_profile.response.content : { availablePoints: 0 },
    opsData: userData.ops_profile,
    dcsData: userData.dcs_profile,
    loading: false,
    errors: null
});

export const currentUser = (state) => ({
    ...state,
    userData: {},
    telcorData: {},
    dcsData: {},
    loading: false,
    errors: null
});


export const reducer = createReducer(INITIAL_STATE, {
    [UserTypes.CURRENT_USER_LOADING]: currentUserLoading,
    [UserTypes.CURRENT_USER_FAIL]: currentUserFail,
    [UserTypes.CURRENT_USER_SUCCESS]: currentUserSuccess,
    [UserTypes.CURRENT_USER]: currentUser
});
