import { call, put, takeLatest } from 'redux-saga/effects';
import OfflineRedemptionActions, { OfflineRedemptionTypes } from './Actions';
import axios, { setAxiosToken } from '../../utils/axios';
import store from 'store';
import config from '../../config';
import { push, go } from 'connected-react-router';
import ReferralActions from "../Referral/Actions";

export function* redeemed(){
	try{
		const response = yield call (() => axios.get('/profile/redeemed-codes'));

		yield put(OfflineRedemptionActions.redeemedSuccess(response.data));
	} catch(error){

	}
}

export function* submitRedeem(params){
	try{
		const response = yield call(() => axios.post('/profile/generate-code', params.params));

		yield put(OfflineRedemptionActions.redeemed());
		yield put(OfflineRedemptionActions.submitRedeemSuccess(response.data));
	} catch(error){

	}
}


export default function* offlineRedemptionSaga() {
	yield takeLatest(OfflineRedemptionTypes.REDEEMED, redeemed);
	yield takeLatest(OfflineRedemptionTypes.SUBMIT_REDEEM, submitRedeem);
}
