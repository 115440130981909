import React from "react";
import store from "store";
import {connect} from "react-redux";

// Assets
import config from "../../../../config";
import styles from "./style.module.scss";

// Plugins
import Reaptcha from 'reaptcha';
import classnames from "classnames";
import ReeValidate from "ree-validate";

// Saga
import ContactAction from "../../../../stores/Contact/Actions";

class Form extends React.Component {
	constructor(props) {
		super(props);

		let dictionary = {
			en: {
				messages: {
					required: () => 'Acest câmp este obligatoriu.',
					min: () => 'Te rugăm să introduci minim 30 de caractere.',
					max: () => 'Numărul de telefon introdus nu este corect.',
					email: () => 'Te rugăm să introduci un email valid.',
					numeric: () => 'Numărul de telefon introdus nu este valid.'
				}
			}
		};

		this.validator = new ReeValidate({
			first_name: 'required|max:150',
			last_name: 'required|max:150',
			email: 'required|email|max:150',
			phone_number: 'required|numeric|max:10',
			subject: 'required|max:150',
			description: 'required|min:30|max:250',
			// gRecaptchaResponse: 'required'
		});

		this.validator.localize(dictionary);
		this.captcha = null;

		this.state = {
			formData: {
				first_name: "",
				last_name: "",
				email: "",
				phone_number: "",
				subject: "",
				description: "",
				// gRecaptchaResponse: ""
			},
			errors: this.validator.errors,
			isPWA: store.get(config.user.isPWA)
		};

		this.handleChange = this.handleChange.bind(this);
		this.validateAndSubmit = this.validateAndSubmit.bind(this);
	}

	onLoad = () => {
		this.setState({
			captchaReady: true
		});
	};

	onChange = (value) => {
		this.setState((state) => ({
			...state,
			formData: {
				...state.formData,
				gRecaptchaResponse: value
			}
		}));
	};
	handleChange = (field, value) => {
		const { errors } = this.validator;
		errors.remove(field);

		this.setState((state) => ({
			...state,
			formData: {
				...state.formData,
				[field]: value
			}
		}));

		this.validator.validate(field, value)
			.then(() => {
				this.setState({ errors })
			});
	};

	validateAndSubmit(e) {
		e.preventDefault();

		// this.captcha.execute();

		const { formData } = this.state;
		const { errors } = this.validator;

		this.validator.validateAll(formData)
			.then(success => {
				if (success) {
					this.props.submitContactForm(formData);
				} else {
					this.setState({ errors });
				}
			});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const formErrors = this.props.formErrors;

		if(formErrors && prevProps.formErrors !== formErrors) {
			const { errors } = this.validator;
			for(let vKey in formErrors.errors) {
				errors.add({
					field: vKey,
					msg: formErrors.errors[vKey]
				});
			}
			this.setState({ errors })
		}
	};

	render() {
		const {isPWA, errors} = this.state;
		const {loading, isSuccess} = this.props;

		return (
			<div className={classnames(isPWA ? styles.blackSection : "greySection")} id="contact">
				<div className={styles.container}>
					<h3 className="section-title">Contactează-ne</h3>

					<div className={classnames(isPWA ? styles.contactUsPWA : styles.contactUs)}>
						{isSuccess ? <p>Formularul a fost trimis cu succes.</p> : (
							<form className={loading ? 'isLoading' : ''} onSubmit={this.validateAndSubmit}>
								<div className={styles.group}>
									<label className={classnames({ 'has-error':  errors.has('first_name')})}>
										{isPWA ? <p>Prenume</p> : ''}
										<input type="text" placeholder="ex: Andrei" name="first_name"
											   value={this.state.formData.first_name}
											   onChange={(e) => this.handleChange('first_name', e.target ? e.target.value : e) }
										/>
										{ errors.has('first_name') && <span className="error">{ errors.first('first_name') }</span>}
									</label>

									<label className={classnames({ 'has-error':  errors.has('last_name')})}>
										{isPWA ? <p>Nume</p> : ''}
										<input type="text" placeholder="ex: Popescu" name="last_name"
											   value={this.state.formData.last_name}
											   onChange={(e) => this.handleChange('last_name', e.target ? e.target.value : e) }
										/>
										{ errors.has('last_name') && <span className="error">{ errors.first('last_name') }</span>}
									</label>

									<label className={classnames({ 'has-error':  errors.has('email')})}>
										{isPWA ? <p>Email</p> : ''}
										<input type="text" placeholder="ex: nume_prenume@gmail.com" name="email"
											   value={this.state.formData.email}
											   onChange={(e) => this.handleChange('email', e.target ? e.target.value : e) }
										/>
										{ errors.has('email') && <span className="error">{ errors.first('email') }</span>}
									</label>

									<label className={classnames({ 'has-error':  errors.has('phone_number')})}>
										{isPWA ? <p>Număr telefon</p> : ''}
										<input type="number" placeholder="ex: 07** *** ***" name="phone_number" maxLength="10"
											   value={this.state.formData.phone_number}
											   onChange={(e) => this.handleChange('phone_number', e.target ? e.target.value : e) }
										/>
										{ errors.has('phone_number') && <span className="error">{ errors.first('phone_number') }</span>}
									</label>
								</div>

								<label className={classnames({ 'has-error':  errors.has('subject')})}>
									{isPWA ? <p>Subiect</p> : ''}
									<input type="text" placeholder="Subiectul mesajului" name="subject"
										   value={this.state.formData.subject}
										   onChange={(e) => this.handleChange('subject', e.target ? e.target.value : e) }
									/>
									{ errors.has('subject') && <span className="error">{ errors.first('subject') }</span>}
								</label>

								<label className={classnames("textarea", { 'has-error':  errors.has('description')})}>
									{isPWA ? <p>Mesaj</p> : ''}
									<textarea name="description" placeholder="Scrie aici mesajul tău"
											  value={this.state.formData.description}
											  onChange={(e) => this.handleChange('description', e.target ? e.target.value : e) }
									/>
									{ errors.has('description') && <span className="error">{ errors.first('description') }</span>}
								</label>

								<button type="submit">Trimite</button>
							</form>
						)}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	loading: state.contact.loading,
	formErrors: state.contact.errors,
	isSuccess: state.contact.isSuccess
});

const mapDispatchToProps = {
	submitContactForm: (formData) => ContactAction.contactForm(formData)
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form);
