import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
    insertCodes: ['codes'],
    insertCodesLoading: null,
    insertCodesSuccess: ['success'],
    insertCodesFail: ['errors'],
    getMassCodes: ['codes'],
    massCodesSuccess: ['codes'],
    getCount: null,
    getCountSuccess: ['counts'],
    getCountLoading: null,
});

export const InsertCodesTypes = Types;
export default Creators;
