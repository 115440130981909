import moment from "moment";

const getMonthNameByIndex = (index) => {
    switch (index) {
        case '1':
            return 'Ianuarie';
        case '2':
            return 'Februarie';
        case '3':
            return 'Martie';
        case '4':
            return 'Aprilie';
        case '5':
            return 'Mai';
        case '6':
            return 'Iunie';
        case '7':
            return 'Iulie';
        case '8':
            return 'August';
        case '9':
            return 'Septembrie';
        case '10':
            return 'Octombrie';
        case '11':
            return 'Noiembrie';
        case '12':
            return 'Decembrie';
        case '01':
            return 'Ianuarie';
        case '02':
            return 'Februarie';
        case '03':
            return 'Martie';
        case '04':
            return 'Aprilie';
        case '05':
            return 'Mai';
        case '06':
            return 'Iunie';
        case '07':
            return 'Iulie';
        case '08':
            return 'August';
        case '09':
            return 'Septembrie';
        default:
            return 'Ianuarie';
    }
};

const formatDateWithRomanianMonth = (date) => {
    const momentDate = new moment(date);
    const month = momentDate.format('M');
    const day = momentDate.format('D');
    const year = momentDate.format('YYYY');
    return `${day} ${getMonthNameByIndex(month)} ${year}`;
};

export default {
    formatDateWithRomanianMonth
};
