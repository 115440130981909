import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
	faqs: ['per_page', 'page'],
	faqsLoading: null,
	faqsSuccess: ['faqs'],
	faqsFail: ['errors']
});

export const FAQsTypes = Types;
export default Creators;
