import { createReducer } from 'reduxsauce';
import { DevicesTypes } from './Actions';

const INITIAL_STATE = {
	devices: [],
	loading: false,
	errors: null
};

export const devicesLoading = (state) => ({
	...state,
	devices: [],
	loading: true,
	errors: null
});

export const devicesSuccess = (state, { devices }) => ({
	...state,
	devices: devices,
	loading: false,
	errors: null
});

export const devicesFail = (state, { errors }) => ({
	...state,
	devices: [],
	loading: false,
	errors: errors
});

export const reducer = createReducer(INITIAL_STATE, {
	[DevicesTypes.DEVICES_LOADING]: devicesLoading,
	[DevicesTypes.DEVICES_SUCCESS]: devicesSuccess,
	[DevicesTypes.DEVICES_FAIL]: devicesFail
});
