import React from 'react'
import styles from "../../Link4Refferal.module.scss";
import deliveryIcon from "../../Benefits/images/1.png";
import activityIcon from "../../Benefits/images/2.png";
import giftIcon from "../../Benefits/images/3.png";

const benefits = [{
    icon: deliveryIcon,
    title: 'Beneficii exclusive',
    description: 'Ești primul care descoperă noutățile IQOS, ai livrare gratuită la comenzile din catalog și acces la vouchere pentru experiențe speciale.'
}, {
    icon: activityIcon,
    title: 'Activități care îți aduc puncte',
    description: 'Cu cât ești mai activ în IQOS Club, cu atât strângi mai multe puncte pe care le poți folosi pentru comenzile de pe IQOS.ro și din catalogul IQOS CLUB sau pentru a revendica HEETS din magazinele partenere.',
    disclaimer: 'IQOS nu este lipsit de riscuri. IQOS este destinat exclusiv fumătorilor adulți care altfel ar continua să fumeze.'
}, {
    icon: giftIcon,
    title: 'Concursuri',
    description: `Te poți înscrie în trageri la sorți exclusive prin care poți câștiga super premii. 
`
}];


const IqosBenefits = () =>

    <div className={styles.reasonsContainer}>
        <div className={styles.benefitsContainer}>
            <div className={styles.reasons}>
                {benefits.map(({icon, title, description, disclaimer}, id) =>
                    <div className={styles.reason} key={id}>
                        <div className={styles.iconContainer}>
                            <img src={icon} alt="icon-benefits" />
                        </div>
                        <h3 style={{minHeight: '70px'}}> {title}</h3>
                        <p>{description}</p>
                    </div>
                )
                }
            </div>
        </div>
    </div>


export default IqosBenefits