import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getMarkers: null,
  markersLoading: null,
  markersSuccess: ['markers'],
  markersFail: ['errors'],
  selectMarker: ['id'],
  setCenter: ['lat', 'lng', 'zoomLevel'],
  setLocation: ['lat', 'lng']
});

export const MarkersTypes = Types;
export default Creators;
