import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
	ugcCarousel: null,
	ugcCarouselLoading: null,
	ugcCarouselSuccess: ['ugcCarousel'],
	ugcCarouselFail: ['errors']
});

export const UGCCarouselTypes = Types;
export default Creators;
